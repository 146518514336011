import React, { useEffect, useState } from "react";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import Layout from "../layout/Layout";
import ApiClient from "../../base/api/client";
import InputField from "../components/forms/InputField";
import TextArea from "../components/forms/TextArea";
import useRequest from "../../base/hooks/useRequest";
import useLazyRequest from "../../base/hooks/useLazyRequest";

const ReturnEstimate = (props) => {
  const [message, setMessage] = useState(null);
  const [close, setClose] = useState([]);
  const [prodNotes, setProdNotes] = useState([]);
  const [buttonClass, setButtonClass] = useState("button is-info");
  const [submitButtonClass, setSubmitButtonClass] = useState(
    "button is-info is-fullwidth"
  );
  const [address, setAddress] = useState("");
  const history = useHistory();
  const [{ user, userLoading }] = useStateReducer();
  const params = useParams();

  const { data: mess, loading: loadPropNotes } = useRequest(
    `/api/tasks/${params.id}`
  );

  const { data: closeClients, loading } = useRequest(
    `/api/tasks/${params.id}/fetch_close_clients`
  );

  const [
    fetchProdNotes,
    { data: newProdNotes, loading: prodNotesLoading },
  ] = useLazyRequest(`/api/tasks/${params.id}/fetch_property_notes`);

  const estimateForm = useForm({
    defaultValues: {
      estid: params.id,
      emailNotes: "",
      sendEmail: true,
      propNotes: "",
    },
  });

  const { register, handleSubmit, setValue } = estimateForm;

  useEffect(() => {
    if (mess) {
      setProdNotes(mess.notes);
    }
  }, [mess]);

  useEffect(() => {
    if (newProdNotes) {
      setProdNotes(newProdNotes);
    }
  }, [newProdNotes]);

  useEffect(() => {
    if (closeClients) {
      setClose(closeClients.close);
      setAddress(closeClients.address);
      console.log("Close", close);
    }
  }, [closeClients]);

  const onSubmit = async (data) => {
    if (data.w1 != "" || data.eh != "") {
      var updatedData = { ...data, estid: params.id, user: user.hrid };
      const result = await ApiClient.patch(
        `/api/tasks/${updatedData.estid}`,
        updatedData
      );
      setValue("w1", "");
      setValue("w2", "");
      setValue("eh", "");
      setValue("eg", "");
      setMessage(result.message);
      setButtonClass("button is-danger");
      setSubmitButtonClass("button is-danger is-fullwidth");
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
      setMessage("Submit Failed No Data!!");
    }
    //history.push("/tasks");
  };

  const deleteNote = async (id) => {
    const response = await ApiClient.request(
      `/api/tasks/${id}/delete_property_note`
    );
    fetchProdNotes();
  };

  return (
    <Layout>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="has-text-weight-bold is-size-4">
            Return Estimate {address}
          </h2>
          <button className={buttonClass} onClick={() => history.goBack()}>
            Go Back
          </button>
          <br />
          <br />

          {message ? <p className="message">{message}</p> : null}
          <InputField
            label="W1:"
            name="w1"
            type="text"
            id="text"
            ref={register}
            placeholder="W1 Price..."
          />
          <InputField
            label="W2:"
            name="w2"
            type="text"
            id="text"
            ref={register}
            placeholder="W2 Price..."
          />
          <InputField
            label="EH:"
            name="eh"
            type="text"
            id="text"
            ref={register}
            placeholder="EH Price..."
          />
          <InputField
            label="EG:"
            name="eg"
            type="text"
            id="text"
            ref={register}
            placeholder="EG Price..."
          />
          <Link className=".link" to={`/estimate/${params.id}/property-notes`}>
            Property Notes(Enter First!)
          </Link>

          <table className="table">
            <tbody>
              {prodNotes.map((note) => (
                <tr key={note.id}>
                  <td>{note.ts}</td>
                  <td>{note.recorder}</td>
                  <td>{note.notes}</td>
                  <td>
                    <button
                      className="button"
                      type="button"
                      onClick={() => deleteNote(note.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <input type="checkbox" name="sendEmail" ref={register} />
            <label htmlFor="sendEmail">Send Email</label>
          </div>
          <TextArea
            name="emailNotes"
            label="Email Notes:"
            id="emailNotes"
            ref={register}
          />

          <button className={submitButtonClass} type="submit">
            Return Estimate
          </button>
        </form>
        {close && (
          <div className="margin-top">
            <table className="table is-striped">
              <thead>
                <tr>
                  <th
                    colSpan="5"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Close Clients
                  </th>
                </tr>
                <tr>
                  <th>Client</th>
                  <th className="px-2">Address</th>
                  <th>W1</th>
                  <th>EH</th>
                  <th>EG</th>
                </tr>
              </thead>
              <tbody>
                {close.map((cc) => (
                  <tr className="table" key={cc.address}>
                    <td>{cc.client}</td>
                    <td className="px-2">{cc.address}</td>
                    <td>{cc.w1}</td>
                    <td>{cc.eh}</td>
                    <td>{cc.eg}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ReturnEstimate;

// console.log("Params Ests:", params);

// const location = useLocation();

// console.log("Params LOCATION:", location.search);

// const newParams = new URLSearchParams(location.search);

// console.log("New Params:", newParams);

// console.log("New Params toString():", newParams.toString());

// console.log("New Params getTest:", newParams.get("test"));
