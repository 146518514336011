import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";


import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { formatCurrency } from "../../helpers/helper";




const Jobs = () => {
  const [{ mgr, userLoading }] = useStateReducer();
  const [jobs, setJobs] = useState([]);
  const [total, setTotal] = useState(0);
  const [jobsRec, setJobsRec] = useState([]);
  const [totalRec, setTotalRec] = useState(0);
  const [jobsPaid, setJobsPaid] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [intro, setIntro] = useState('');
  const [showDisplay, setShowDisplay] = useState('produced')
  const [jobsType, setJobsType] = useState([]);
  const [totalType, setTotalType] = useState(0);
  const [producedClass, setProducedClass] = useState('button is-warning')
  const [recClass, setRecClass] = useState('button is-link')
  const [paidClass, setPaidClass] = useState('button is-link')


  const fetchProducedJobs = async () => {
    const myParams = { sdate: '2022-10-01', fdate: '2022-12-31', crewname: 'Roy Cascayan'};
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/jobs/jobs_produced?${qs}`);
    setJobs(result.jobs);
    setTotal(result.total);
    const tempRec = result.jobs.filter((job) => job.recstatus === 'Receivable');
    setJobsRec(tempRec);
    const tempPaid = result.jobs.filter((job) => job.recstatus === 'PAID');
    setJobsPaid(tempPaid);
    let total_rec = 0;
    let total_paid = 0;
    tempRec.forEach((job) => {
      total_rec += parseFloat(job.price);
    })
    tempPaid.forEach((job) => {
      total_paid += parseFloat(job.price);
    })
    setTotalRec(total_rec);
    setTotalPaid(total_paid);
    setJobsType(result.jobs);
    setTotalType(result.total);
    setShowDisplay('Produced');
  }

  useEffect(() => {
    if(mgr && mgr.name){
      fetchProducedJobs();
    }
  }, [mgr])

  const changeDisplay = (display) => {
    setShowDisplay(display);
    if(display === 'Produced'){
      setJobsType(jobs);
      setTotalType(total);
      setProducedClass('button is-warning');
      setRecClass('button is-link');
      setPaidClass('button is-link');
    }else if(display === 'Receivable'){
      setJobsType(jobsRec);
      setTotalType(totalRec);
      setProducedClass('button is-link');
      setRecClass('button is-warning');
      setPaidClass('button is-link');
    }else if(display === 'Paid'){
      setJobsType(jobsPaid);
      setTotalType(totalPaid);
      setProducedClass('button is-link');
      setRecClass('button is-link');
      setPaidClass('button is-warning');
    }
  }

  const showJobs = () => {

    const tableData = () => {
      const result = jobsType.map((job) => (<tr key={job.jobid}>
          <td>{ job.jobid }</td>
          <td>{ job.address }</td>
          <td>{ job.datebi }</td>
          <td>{ formatCurrency(job.price) }</td>
          <td>{ job.recstatus }</td>
        </tr>))
        return result;
      }
    return (
      <div>
        <h1 className='subtitle'>{ showDisplay } Jobs: { jobsType.length } Total: { formatCurrency(totalType) }</h1>
        <table className='table'>
          <thead>
            <tr>
              <td>JobID</td>
              <td>Address</td>
              <td>Date</td>
              <td>Price</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            { tableData() }
          </tbody>
        </table>
      </div>)
  }

  return(
    <Layout>
      <div className='container'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className='breadcrumb mt-3'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li className='is-active'><Link to="/partner_jobs">Jobs</Link></li>
          </ul>
        </nav>

        <div className='columns mt-3'>
          <div className='column'>
            <button className= { producedClass } onClick={ () => changeDisplay('Produced') }>Produced</button>
          </div>
          <div className='column'>
            <button className={ paidClass } onClick={ () => changeDisplay('Paid') }>Paid</button>
          </div>
          <div className='column'>
            <button className={ recClass } onClick={ () => changeDisplay('Receivable') }>Receivable</button>
          </div>
        </div>
        <hr/>
        { jobs.length>0 && showJobs() }
      </div>
    </Layout>
  )

}

export default Jobs;
