import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { useForm } from "react-hook-form";
import ApiClient from "../api/client";
import CrewPayForm from "../components/CrewPayForm";
import Loader from "../components/Loader";
import Layout from "../layout/Layout";
import dayjs from "dayjs";
import CrewActivity from "../components/CrewActivity";
import EmpNotes from "../components/EmpNotes";
import Dnfs from "../components/Dnfs";
import Tasks from "../components/Tasks";
import CrewProfileHeader from "../components/CrewProfileHeader";
import DailyPay from "../components/DailyPay";
import useRequest from "../hooks/useRequest";
import useLazyRequest from "../hooks/useLazyRequest";
import { toNaiveTime } from "../helpers/helper";

const CrewProfile = () => {
  const location = useLocation();
  const newParams = new URLSearchParams(location.search);
  const crewProfileParams = newParams.toString();
  const dailyProfileDate = newParams.get("dailyProfileDate");
  const crewName = newParams.get("crewName");
  const { data, loading } = useRequest(`/api/jobs/crew_activity`, {
    crewName,
    dailyProfileDate,
  });

  const [firstJob, setFirstJob] = useState(null);
  const [lastJob, setLastJob] = useState(null);
  const format = "HH:mm:ss";

  const driverForm = useForm({});
  const partnerForm = useForm({});
  const partner1Form = useForm({});

  const [driver, partner, partner1] = data?.crew || [];
  const crewActivity = data?.crewActivity;
  const empNotes = data?.empNotes || [];
  const dnfs = data?.dnfs || [];
  const tasks = data?.tasks || [];
  const selectedMgr = data?.selected_mgr || null;

  const payParams = useMemo(() => {
    if (!data?.crew) return null;
    return {
      driver: data.crew[0].hrid,
      partner: data.crew[1] ? data.crew[1].hrid : null,
      partner1: data.crew[2] ? data.crew[2].hrid : null,
      driver_name: data.crew[0].name,
      partner_name: data.crew[1] ? data.crew[1].name : null,
      partner1_name: data.crew[2] ? data.crew[2].name : null,
      date: dailyProfileDate,
    };
  }, [data?.crew]);

  const [
    fetchPayData,
    { data: payData, loading: payDataLoading },
  ] = useLazyRequest(`/api/pays/pay_data`, payParams);

  useEffect(() => {
    if (data?.crew) fetchPayData();
  }, [loading]);

  useEffect(() => {
    const setValues = (form, pay) => {
      let stime = toNaiveTime(pay.stime);
      let ftime = toNaiveTime(pay.ftime);
      stime = stime.add(1, "hour");
      ftime = ftime.add(1, "hour");
      form.setValue("startHour", stime.hour());
      form.setValue("startMinute", stime.minute());
      form.setValue("finishHour", ftime.hour());
      form.setValue("finishMinute", ftime.minute());
    };

    if (payData?.length > 0) {
      payData.forEach((pay) => {
        console.log("pay Datesah", pay)
        if (pay.name === driver.name) {
          setValues(driverForm, pay);
        } else if (pay.name === partner.name) {
          setValues(partnerForm, pay);
        } else if (pay.name === partner1.name) {
          setValues(partner1Form, pay);
        }
      });
    }
  }, [payData]);



  useEffect(() => {
    if (!crewActivity?.length) return;
    const firstJobCrew = crewActivity[0];
    setFirstJob(firstJobCrew);
    const lastJobCrew = crewActivity[crewActivity.length - 1];
    setLastJob(lastJobCrew);
    if (!(firstJobCrew && lastJobCrew && driver)) return;
    const format = "HH:mm:ss";
    let stime = dayjs(firstJobCrew.reportstime, format);
    stime = stime.subtract(30, "minutes");
    let ftime = dayjs(lastJobCrew.reportftime, format);
    ftime = ftime.add(30, "minutes");

    const resetValues = (member, stime, ftime) => {
      return {
        hrid: member ? member.hrid : null,
        rate: member && member.rate ? member.rate : null,
        name: member?.name,
        startHour: stime.hour(),
        startMinute: stime.minute(),
        finishHour: ftime.hour(),
        finishMinute: ftime.minute(),
      };
    };

    driverForm.reset(resetValues(driver, stime, ftime));
    partnerForm.reset(resetValues(partner, stime, ftime));
    partner1Form.reset(resetValues(partner1, stime, ftime));
  }, [crewActivity, driver, partner, partner1]);

  const savePay = async (newPayData) => {
    newPayData['crewname'] = driver.name;
    await ApiClient.post(`/api/pays/save_daily_pay`, newPayData);
    fetchPayData();
  };

  const onSubmit = (data) => {
    console.log('DATA%%%', data);
    const stime = dayjs()
      .hour(Number(data.startHour))
      .minute(Number(data.startMinute));
    const ftime = dayjs()
      .hour(Number(data.finishHour))
      .minute(Number(data.finishMinute));
    const workHours = ftime.diff(stime, "hours", true).toFixed(2);
    const rate = data.rate.substring(1, data.rate.length);
    const pay = (workHours * rate).toFixed(2);
    savePay({
      hrid: data.hrid,
      name: data.name,
      date: dailyProfileDate,
      rate: rate,
      startHour: data.startHour,
      startMinute: data.startMinute,
      finishHour: data.finishHour,
      finishMinute: data.finishMinute,
      hours: workHours,
      pay,
      mgr: data.mgr,
    });
  };



  const auditPay = async (id) => {
    await ApiClient.request(`/api/pays/${id}/audit_pay`);
    fetchPayData();
  };

  const deletePay = async (id) => {
    const response = await ApiClient.delete(`/api/pays/${id}`, {
      id,
    });
    const setStartFinishValues = (form) => {
      let stime = dayjs(firstJob.reportstime, format);
      let ftime = dayjs(lastJob.reportftime, format);
//      let stime = dayjs(firstJobCrew.reportstime, format);
      stime = stime.subtract(30, "minutes");
      ftime = ftime.add(30, "minutes");

      form.setValue("startHour", stime.hour());
      form.setValue("startMinute", stime.minute());
      form.setValue("finishHour", ftime.hour());
      form.setValue("finishMinute", ftime.minute());
    };
    if (driver.hrid === response.hrid) setStartFinishValues(driverForm);
    if (partner && partner.hrid === response.hrid) setStartFinishValues(partnerForm);
    if (partner1 && partner1.hrid === response.hrid) setStartFinishValues(partner1Form);
    fetchPayData();
  };

  // const firePartnerConverter = () => {
  //   console.log('data...', data);
  //   if(data.crew && data.crew[0].name){
  //     console.log('YAYYYYY', data);
  //     //console.log(crewActivity);
  //     let totalProd = 0;
  //     crewActivity.forEach(job => totalProd += parseFloat(job.price));
  //     const partnerData = {
  //       driver: data && data.driver ? data.driver.hrid: null,
  //       partner: data && data.partner ? data.partner.hrid: null,
  //       partner1: data && data.partner1 ? data.partner1.hrid: null,
  //       date: dailyProfileDate,
  //       prod: totalProd
  //
  //     }
  //     fetchPayData();
  //   }


  //}

  return (
    <Layout>
      <div className="container">
        <CrewProfileHeader
          newSearch={location.search}
          date={dailyProfileDate}
          driver={driver}
          partner={partner}
          partner1={partner1}
        />

        {payDataLoading || loading ? (
          <Loader />
        ) : crewActivity === null ? null : (
          <CrewActivity crewActivity={crewActivity} />
        )}


        {empNotes === [] || empNotes.length === 0 ? null : (
          <EmpNotes empNotes={empNotes} />
        )}

        {dnfs === [] || dnfs.length === 0 ? null : (
          <Dnfs dnfs={dnfs} />
        )}

        {tasks === [] || tasks.length === 0 ? null : (
          <Tasks tasks={tasks} />
        )}

        <div className="crewprofile-pay-panels">
          <CrewPayForm
            form={driverForm}
            onSubmit={onSubmit}
            member={driver}
            payData={payData}
            selectedMgr={selectedMgr}
          />
          <CrewPayForm
            form={partnerForm}
            onSubmit={onSubmit}
            member={partner}
            payData={payData}
            selectedMgr={selectedMgr}
          />
          <CrewPayForm
            form={partner1Form}
            onSubmit={onSubmit}
            member={partner1}
            payData={payData}
            selectedMgr={selectedMgr}
          />
      </div><br/><br/><br/>
        {payData ? (
          <DailyPay
            payData={payData}
            deletePay={deletePay}
            auditPay={auditPay}
            crewProfileParams={crewProfileParams}
            crewActivity={crewActivity}
          />
        ) : null}
        <hr />
      </div>
    </Layout>
  );
};

export default CrewProfile;
