import React from "react";

const InputField = React.forwardRef(({ label, placeholder, id, name }, ref) => {
  return (
    <div className="field">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <div className="control">
        <input
          id={id}
          className="input"
          name={name}
          type="text"
          ref={ref}
          placeholder={placeholder}
          size="50"
        />
      </div>
    </div>
  );
});

export default InputField;
