import React, { useEffect, useState, useCallback } from "react";
//import { Redirect, useHistory } from "react-router";
import { useHistory } from "react-router-dom";
import { useStateReducer } from "../../base/context/authContext";
import Loader from "../../base/layout/Loader";
import Header from "./Header";
import Footer from "./Footer";
import { createBrowserHistory } from "history";


const divStyle = {
    display: 'block'
}

// *, *:before, *:after {
//     box-sizing: inherit;
// }



const bodyStyle = {
    position: 'relative',
    flex: '1 0 auto',
    height: '100vh'
}

const Layout = ({ children }) => {
  const history = useHistory();
  const [{ user, userLoading }] = useStateReducer();

  if (userLoading) {
    return <Loader />;
  }

  return (
    <div className='divStyle'>
      <Header />
        <main className='wrapperStyle'>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
