import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrencyZero } from "../../../helpers/helper";

const Payouts = ({ showDisplay }) => {
  const [{ mgr }] = useStateReducer();
  const[payouts, setPayouts] = useState([]);
  const[totalPayout, setTotalPayout] = useState(0);
  const[showLoader, setShowLoader] = useState(true);

  const fetchPayouts = async() => {
    setShowLoader(true);

    let list;
    if (showDisplay === 'Payouts 2023'){
      list = 'payout_list_2023';
    } else if (showDisplay === 'Payouts 2024'){
      list = 'payout_list_2024';
    }

    const myParams = { list, hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams);
    const result = await ApiClient.request(`/api/partners?${qs}`);
    console.log('Payout Result:', result);
    setPayouts(result);
    setShowLoader(false);
  }

  useEffect(() => {
    if(mgr){
      fetchPayouts();
    }
  }, [mgr, showDisplay])

  const payoutData = () => {
    return payouts.map((payout) => (
      <tr key={payout.id}>
        <td>{ makeNiceDate(payout.date )}</td>
        <td>{ payout.chequenum }</td>
        <td>{ formatCurrencyZero(payout.net) }</td>
        <td>{ formatCurrencyZero(payout.wkr_cpp) }</td>
        <td>{ formatCurrencyZero(payout.wkr_ei) }</td>
        <td>{ formatCurrencyZero(payout.wkr_tax) }</td>
        <td>{ formatCurrencyZero(payout.hst) }</td>
        <td>{ formatCurrencyZero(payout.gross) }</td>
        <td className='has-background-primary'>{ formatCurrencyZero(payout.emp_cpp) }</td>
        <td className='has-background-primary'>{ formatCurrencyZero(payout.emp_ei) }</td>
        <td className='has-background-primary'>{ formatCurrencyZero(payout.eht) }</td>
        <td className='has-background-primary'>{ formatCurrencyZero(payout.wsib) }</td>
        <td className='has-background-primary'>{ formatCurrencyZero(parseFloat(payout.wsib) + parseFloat(payout.eht) + parseFloat(payout.emp_ei) + parseFloat(payout.emp_cpp)) }</td>
        <td className='has-background-primary'>{ formatCurrencyZero(parseFloat(payout.gross) + parseFloat(payout.wsib) + parseFloat(payout.eht) + parseFloat(payout.emp_ei) + parseFloat(payout.emp_cpp) )}</td>
      </tr>
    ))
  }

  const payoutDataTotal = () => {
    let totalNet = 0;
    let totalCPP = 0;
    let totalEmpCPP = 0;
    let totalEI = 0;
    let totalEmpEI = 0;
    let totalTax = 0;
    let totalHST = 0;
    let totalGross = 0;
    let totalEHT = 0;
    let totalWSIB = 0;

    payouts.forEach((payout) => {
      totalNet += parseFloat(payout.net);
      totalCPP += parseFloat(payout.wkr_cpp);
      totalEmpCPP += parseFloat(payout.emp_cpp);
      totalEI += parseFloat(payout.wkr_ei);
      totalEmpEI += parseFloat(payout.emp_ei);
      totalTax += parseFloat(payout.wkr_tax);
      totalHST += parseFloat(payout.hst);
      totalGross += parseFloat(payout.gross);
      totalEHT += parseFloat(payout.eht);
      totalWSIB += parseFloat(payout.wsib);
    })

    return <tr key='total'>
             <th>Total</th>
             <th></th>
             <th>{ formatCurrencyZero(totalNet) }</th>
             <th>{ formatCurrencyZero(totalCPP) }</th>
             <th>{ formatCurrencyZero(totalEI) }</th>
             <th>{ formatCurrencyZero(totalTax) }</th>
             <th>{ formatCurrencyZero(totalHST) }</th>
             <th>{ formatCurrencyZero(totalGross) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEmpCPP) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEmpEI) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEHT) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalWSIB) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEHT + totalWSIB + totalEmpEI + totalEmpCPP) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalGross + totalEHT + totalWSIB + totalEmpEI + totalEmpCPP) }</th>
           </tr>
  }

  const showPayouts = () => {
    return(
      <React.Fragment>
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Chq#</th>
                <th>Net</th>
                <th>CPP</th>
                <th>EI</th>
                <th>Tax</th>
                <th>HST</th>
                <th>Net Gross</th>
                <th className='has-background-primary'>Emp CPP</th>
                <th className='has-background-primary'>Emp EI</th>
                <th className='has-background-primary'>EHT</th>
                <th className='has-background-primary'>WSIB</th>
                <th className='has-background-primary'>Burden</th>
                <th className='has-background-primary'>Gross</th>
              </tr>
            </thead>
            <tbody>
              { payoutData() }
            </tbody>
            <tfoot>
              { payoutDataTotal() }
            </tfoot>
          </table>
        </div>
      </React.Fragment>
    )
  }


  return <div className='box'>
    <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ showDisplay }</h1><br/>
    { showLoader ? <Loader /> : showPayouts() }


  </div>

}

export default Payouts;
