import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { formatCurrencyZero, makeNiceDate, royaltyCalculator } from "../../../helpers/helper";


const Revenue = ({ showDisplay }) => {
  const[{ mgr }] = useStateReducer();
  const[jobsPaid, setJobsPaid] = useState([]);
  const[jobsRec, setJobsRec] = useState([]);
  const[oddJobsPaid, setOddJobsPaid] = useState([]);
  const[oddJobsRec, setOddJobsRec] = useState([]);
  const[paintJobsPaid, setPaintJobsPaid] = useState([]);
  const[paintJobsRec, setPaintJobsRec] = useState([]);
  const[totals, setTotals] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  const fetchRevenue = async () => {
    setShowLoader(true);
    let list;
    if (showDisplay === 'Revenue-Production 2023'){
      list = '2023';
    } else if (showDisplay === 'Revenue-Production 2024'){
      list = '2024';
    }
    const myParams = { crewname: mgr.name, hrid: mgr.hrid, list };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners/revenue_summary?${qs}`);
    console.log('RESULT***', result);
    setJobsPaid(result.jobs_paid);
    setJobsRec(result.jobs_rec);
    setOddJobsRec(result.odd_jobs_rec);
    setOddJobsPaid(result.odd_jobs_paid);
    setPaintJobsRec(result.paint_jobs_rec);
    setPaintJobsPaid(result.paint_jobs_paid);
    setShowLoader(false);
  }

  const fetchTotals = () => {
    let totalsTemp = {};

    let jobsRecTotal = 0;
    jobsRec.forEach(job => {jobsRecTotal = jobsRecTotal + parseFloat(job.price)})
    totalsTemp["jobsRecTotal"] = jobsRecTotal

    let adjustedJobsRecEarnings = 0;
    jobsRec.forEach(job => {
      adjustedJobsRecEarnings += (job.price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1));
    })
    totalsTemp['adjustedJobsRecEarnings'] = adjustedJobsRecEarnings;

    let jobsPaidTotal = 0;
    jobsPaid.forEach(job => {jobsPaidTotal = jobsPaidTotal + parseFloat(job.price)})
    totalsTemp["jobsPaidTotal"] = jobsPaidTotal

    let adjustedJobsPaidEarnings = 0;
    jobsPaid.forEach(job => {
      adjustedJobsPaidEarnings += (job.price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1));
    })
    totalsTemp['adjustedJobsPaidEarnings'] = adjustedJobsPaidEarnings;

    let oddJobsPaidTotal = 0;
    oddJobsPaid.forEach(job => {oddJobsPaidTotal = oddJobsPaidTotal + parseFloat(job.price)})
    totalsTemp["oddJobsPaidTotal"] = oddJobsPaidTotal

    let adjustedOddJobsPaidEarnings = 0;
    oddJobsPaid.forEach(job => {
      adjustedOddJobsPaidEarnings += (job.price - job.mat_price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1));
    })
    totalsTemp['adjustedOddJobsPaidEarnings'] = adjustedOddJobsPaidEarnings;

    let adjustedOddJobsRecEarnings = 0;
    oddJobsRec.forEach(job => {
      adjustedOddJobsRecEarnings += (job.price - job.mat_price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1));
    })
    totalsTemp['adjustedOddJobsRecEarnings'] = adjustedOddJobsRecEarnings;

    let oddJobsRecTotal = 0;
    oddJobsRec.forEach(job => {oddJobsRecTotal = oddJobsRecTotal + parseFloat(job.price)});
    totalsTemp["oddJobsRecTotal"] = oddJobsRecTotal;

    let oddJobsPaidMatPriceTotal = 0;
    oddJobsPaid.forEach(job => {oddJobsPaidMatPriceTotal = oddJobsPaidMatPriceTotal + parseFloat(job.mat_price)})
    totalsTemp["oddJobsPaidMatPriceTotal"] = oddJobsPaidMatPriceTotal

    let oddJobsRecMatPriceTotal = 0;
    oddJobsRec.forEach(job => {oddJobsRecMatPriceTotal = oddJobsRecMatPriceTotal + parseFloat(job.mat_price)})
    totalsTemp["oddJobsRecMatPriceTotal"] = oddJobsRecMatPriceTotal

    let paintJobsPaidTotal = 0;
    paintJobsPaid.forEach(job => {paintJobsPaidTotal = paintJobsPaidTotal + parseFloat(job.price)})
    totalsTemp["paintJobsPaidTotal"] = paintJobsPaidTotal

    let adjustedPaintPaidEarnings = 0;
    paintJobsPaid.forEach(job => {
      adjustedPaintPaidEarnings += (job.price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1));
    })
    totalsTemp['adjustedPaintPaidEarnings'] = adjustedPaintPaidEarnings;

    let paintJobsRecTotal = 0;
    paintJobsRec.forEach(job => {paintJobsRecTotal = paintJobsRecTotal + parseFloat(job.price)})
    totalsTemp["paintJobsRecTotal"] = paintJobsRecTotal

    let adjustedPaintRecEarnings = 0;
    paintJobsRec.forEach(job => {
      adjustedPaintRecEarnings += (job.price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1));
    })
    totalsTemp['adjustedPaintRecEarnings'] = adjustedPaintRecEarnings;

    setTotals(totalsTemp);
  }

  useEffect(() => {
    if(mgr){
      fetchRevenue();
    }
  }, [mgr, showDisplay])

  useEffect(() => {
    fetchTotals();
  }, [jobsRec, jobsPaid, oddJobsPaid, oddJobsRec, paintJobsPaid, paintJobsRec,])

  const tableJobsRecData = () => {
    const result = jobsRec.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(0) }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.price * royaltyCalculator(job, mgr.royalty, mgr.royalty1)) }</td>
        <td>{ formatCurrencyZero(job.price * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1))) }</td>
      </tr>))
      return result;
  }

  const tableJobsPaidData = () => {
    const result = jobsPaid.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(0) }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.price * royaltyCalculator(job, mgr.royalty, mgr.royalty1)) }</td>
        <td>{ formatCurrencyZero(job.price * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1))) }</td>
      </tr>))
      return result;
  }

  const tableOddJobsRecData = () => {
    const result = oddJobsRec.map((job) => {
      return(<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.mat_price) }</td>
        <td>{ formatCurrencyZero(job.price - job.mat_price) }</td>
        <td>{ formatCurrencyZero((job.price - job.mat_price) * royaltyCalculator(job, mgr.royalty, mgr.royalty1)) }</td>
        <td>{ formatCurrencyZero((job.price - job.mat_price) * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1))) }</td>
      </tr>)
    })
      return result;
  }

  const tableOddJobsPaidData = () => {
    const result = oddJobsPaid.map((job) => {
      return (<tr key={job.jobid}>
          <td>{ job.jobid }</td>
          <td>{ job.address }</td>
          <td>{ makeNiceDate(job.datebi) }</td>
          <td>{ job.crew }</td>
          <td>{ job.recstatus }</td>
          <td>{ formatCurrencyZero(job.price) }</td>
          <td>{ formatCurrencyZero(job.mat_price) }</td>
          <td>{ formatCurrencyZero(job.price - job.mat_price) }</td>
          <td>{ formatCurrencyZero((job.price - job.mat_price) * royaltyCalculator(job, mgr.royalty, mgr.royalty1)) }</td>
          <td>{ formatCurrencyZero((job.price - job.mat_price) - ((job.price - job.mat_price) * royaltyCalculator(job, mgr.royalty, mgr.royalty1))) }</td>
        </tr>)
    })
    return result;
  }

  const tablePaintJobsRecData = () => {
    const result = paintJobsRec.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.price * royaltyCalculator(job, mgr.royalty, mgr.royalty1)) }</td>
        <td>{ formatCurrencyZero(job.price * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1))) }</td>
      </tr>))
      return result;
  }

  const tablePaintJobsPaidData = () => {
    const result = paintJobsPaid.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.price * royaltyCalculator(job, mgr.royalty, mgr.royalty1)) }</td>
        <td>{ formatCurrencyZero(job.price * (1 - royaltyCalculator(job, mgr.royalty, mgr.royalty1))) }</td>
      </tr>))
      return result;
  }


  return (
    <div>
      { showLoader ? <Loader /> :
        <div className='box'>
          <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ showDisplay }</h1><br/>
          <h1 className='subtitle has-text-weight-bold has-text-info'>Recble Window Jobs: { jobsRec.length } Revenue: { formatCurrencyZero(totals.jobsRecTotal) } Earnings: { formatCurrencyZero(totals.adjustedJobsRecEarnings) }</h1>
          <table className='table'>
            <thead>
              <tr>
                <td>JobID</td>
                <td>Address</td>
                <td>Date</td>
                <td>Crew</td>
                <td>Status</td>
                <td>Price</td>
                <td>Material</td>
                <td>Net Price</td>
                <td>Royalty</td>
                <td>Earnings</td>
              </tr>
            </thead>
            <tbody>
              { mgr && tableJobsRecData() }
            </tbody>
          </table><br/>

        <h1 className='subtitle has-text-weight-bold has-text-info'>Paid Window Jobs: { jobsPaid.length } Revenue: { formatCurrencyZero(totals.jobsPaidTotal) } Earnings: { formatCurrencyZero(totals.adjustedJobsPaidEarnings) }</h1>
          <table className='table'>
            <thead>
              <tr>
                <td>JobID</td>
                <td>Address</td>
                <td>Date</td>
                <td>Crew</td>
                <td>Status</td>
                <td>Price</td>
                <td>Material</td>
                <td>Net Price</td>
                <td>Royalty</td>
                <td>Earnings</td>
              </tr>
            </thead>
            <tbody>
              { mgr && tableJobsPaidData() }
            </tbody>
          </table><br/>

        <h1 className='subtitle has-text-weight-bold has-text-info'>Recble Odd Jobs: { oddJobsRec.length } Revenue: { formatCurrencyZero(totals.oddJobsRecTotal) } Earnings: { formatCurrencyZero(totals.adjustedOddJobsRecEarnings) }</h1>
          <table className='table'>
            <thead>
              <tr>
                <td>JobID</td>
                <td>Address</td>
                <td>Date</td>
                <td>Crew</td>
                <td>Status</td>
                <td>Price</td>
                <td>Material</td>
                <td>Net Price</td>
                <td>Royalty</td>
                <td>Earnings</td>
              </tr>
            </thead>
            <tbody>
              { mgr && tableOddJobsRecData() }
            </tbody>
          </table><br/>

        <h1 className='subtitle has-text-weight-bold has-text-info'>Paid Odd Jobs: { oddJobsPaid.length } Revenue: { formatCurrencyZero(totals.oddJobsPaidTotal) } Materials: { formatCurrencyZero(totals.oddJobsPaidMatPriceTotal) } Earnings: { formatCurrencyZero(totals.adjustedOddJobsPaidEarnings) }</h1>
          <table className='table'>
            <thead>
              <tr>
                <td>JobID</td>
                <td>Address</td>
                <td>Date</td>
                <td>Crew</td>
                <td>Status</td>
                <td>Price</td>
                <td>Material</td>
                <td>Net Price</td>
                <td>Royalty</td>
                <td>Earnings</td>
              </tr>
            </thead>
            <tbody>
              { mgr && tableOddJobsPaidData() }
            </tbody>
          </table><br/>

        <h1 className='subtitle has-text-weight-bold has-text-info'>Recble Paint Jobs: { paintJobsRec.length } Revenue: { formatCurrencyZero(totals.paintJobsRecTotal) } Earnings: { formatCurrencyZero(totals.adjustedPaintRecEarnings) }</h1>
          <table className='table'>
            <thead>
              <tr>
                <td>JobID</td>
                <td>Address</td>
                <td>Date</td>
                <td>Crew</td>
                <td>Status</td>
                <td>Price</td>
                <td>Royalty</td>
                <td>Earnings</td>
              </tr>
            </thead>
            <tbody>
              { mgr && tablePaintJobsRecData() }
            </tbody>
          </table><br/>

        <h1 className='subtitle has-text-weight-bold has-text-info'>Paid Paint Jobs: { paintJobsPaid.length } Revenue: { formatCurrencyZero(totals.paintJobsPaidTotal) } Earnings: { formatCurrencyZero(totals.adjustedPaintPaidEarnings) }</h1>
          <table className='table'>
            <thead>
              <tr>
                <td>JobID</td>
                <td>Address</td>
                <td>Date</td>
                <td>Crew</td>
                <td>Status</td>
                <td>Price</td>
                <td>Royalty</td>
                <td>Earnings</td>
              </tr>
            </thead>
            <tbody>
              { mgr && tablePaintJobsPaidData() }
            </tbody>
          </table>

        </div>
      }
    </div>)

}

export default Revenue;
