import React, { useState, useEffect } from 'react';

import { StripeCardSetup } from "./StripeCardSetup";
import JobAddress from "../forms/components/JobAddress";
import JobType from "../forms/components/JobType";
import JobDate from "../forms/components/JobDate";
import JobNotes from "../forms/components/JobNotes";


const BookEavesFallForm = ({
  register,
  handleSubmit,
  submitForm,
  changeJobType,
  fltrDateClass,
  apptDateClass,
  eavesFltrCheckedClass,
  setEavesFltrCheckedClass,
  setEavesApptCheckedClass,
  eavesApptCheckedClass,
  eavesFltrClass,
  setEavesFltrClass,
  eavesApptClass,
  setEavesApptClass,
  getValues,
  setValue,
  errorMsgs,
  setErrorMsgs,
  onChangeJobSpecs,
  setDashboardMessage,
  setDashboardShowFunction,
  allowSubmit
}) => {

  const [buttonState, setButtonState] = useState('button is-primary mt-5');

  useEffect(() => {
    if(Object.keys(errorMsgs).length>0){
      setButtonState('button is-primary mt-5');
    }
  }, [errorMsgs])

  const disableButton = () => {
    setButtonState('button is-primary mt-5 is-loading');
  }

  const changeStatus = () => {
    if(eavesFltrCheckedClass === 'checked'){
      setEavesFltrCheckedClass('');
      setEavesApptCheckedClass('checked');
      setEavesFltrClass('field is-hidden');
      setEavesApptClass('field');
    }else{
      setEavesFltrCheckedClass('checked');
      setEavesApptCheckedClass('');
      setEavesFltrClass('field');
      setEavesApptClass('field is-hidden');
    }
  }


  return (<div className='box'>
    <h2 className='title is-4 has-text-centered'>Book Fall Eaves Cleaning 2024</h2>
    <form onSubmit={handleSubmit(submitForm)}>
      <JobAddress
        register={register}
        getValues={getValues}
        errorMsgs={errorMsgs}
        onChangeJobSpecs={onChangeJobSpecs}
      />

      <JobType
        register={register}
        errorMsgs={errorMsgs}
        changeJobType={changeJobType}
        type='Eaves'
      />

      <JobDate
        register={register}
        errorMsgs={errorMsgs}
        fltrDateClass={fltrDateClass}
        apptDateClass={apptDateClass}
        onChangeJobSpecs={onChangeJobSpecs}
        eavesFltrCheckedClass={eavesFltrCheckedClass}
        eavesApptCheckedClass={eavesApptCheckedClass}
        eavesFltrClass={eavesFltrClass}
        eavesApptClass={eavesApptClass}
        changeStatus={changeStatus}
        type='EavesFall'
      />

      <JobNotes
        register={register}
      />

      <button className={ buttonState } type='submit' onClick={ disableButton } disabled={ !allowSubmit }>Create Job</button>

    </form>
  </div>)
}

export default BookEavesFallForm
