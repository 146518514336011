import React, { useState, useEffect } from 'react';
import Layout from "../../../layout/Layout";
import { Link, Redirect , useParams } from "react-router-dom";
import { useForm } from "react-hook-form";


import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import MiscJobForm from "../forms/MiscJobForm"
import OddJobForm from "../forms/OddJobForm"
import { makeNiceDate } from "../../../helpers/helper"



const OddJobLeadAdmin = () => {
  const oje = "Odd Job Estimates";
  const [{ user, userLoading }] = useStateReducer();
  const routeParams = useParams();
  const paForm = useForm();
  const { register, handleSubmit, setValue, getValues } = paForm;
  const {
    property,
    type5,
    desc
  } = getValues();


  const[message, setMessage] = useState(null);
  const[properties, setProperties] = useState([]);
  const[client, setClient] = useState(null);
  const[miscJobs, setMiscJobs] = useState([]);
  const[miscJob, setMiscJob] = useState(null);
  const[renderOddJobFormClass, setRenderOddJobFormClass] = useState('is-hidden');
  const[renderMiscJobFormClass, setRenderMiscJobFormClass] = useState('is-hidden');




  const setUpMiscJobAnalysis = async () => {
    const data = await ApiClient.request(`/api/misc_jobs/${routeParams.id}`);
    console.log('DATA', data);
    setMessage(data.message);
    setClient(data.client);
    setProperties(data.properties);
    const myParams = { cfid: data.client.cfid };
    const qs = new URLSearchParams(myParams).toString();
    const miscJobs = await ApiClient.request(`/api/misc_jobs?${qs}`);
    console.log('MISC JOBSIS', miscJobs);
    setMiscJobs(miscJobs);
  }

  useEffect(() => {
    if(user){
      setUpMiscJobAnalysis();
    }
  }, [user])

  const createMiscJob = async (data) => {
    console.log("Data", data);
    data['cfid'] = client.cfid
    data['hrid'] = user.hrid
    const result = await ApiClient.post(`/api/misc_jobs`, data);
    console.log("Data", data);
    setUpMiscJobAnalysis();
    console.log('Create Result',result);
  }

  const createOddJob = async (data) => {
    // console.log("Data", data);
    // data['cfid'] = client.cfid
    // data['hrid'] = user.hrid
    // const result = await ApiClient.post(`/api/misc_jobs`, data);
    // console.log("Data", data);
    // setUpMiscJobAnalysis();
    // console.log('Create Result',result);
  }

  const resolveMessage = async (msid) => {
    const result = await ApiClient.patch(`/api/messages/${msid}`, {type: 'resolve'});
    setUpMiscJobAnalysis();
    console.log(result);
  }

  const unResolveMessage = async (msid) => {
    const result = await ApiClient.patch(`/api/messages/${msid}`, {type: 'unresolve'});
    setUpMiscJobAnalysis();
    console.log(result);
  }

  const sendEmail = () => {

  }

  const bookOddJob = (id) => {
    setRenderOddJobFormClass('');
    miscJobs.forEach((mj) => {
      if(mj.id === id){
        setMiscJob(mj);
      }
    })
  }

  const showMiscJobForm = () => {
    setRenderMiscJobFormClass('');
  }

  const renderMiscJobs = () => {
    const data = miscJobs.map((mj) => <tr key={mj.id}>
          <td>{ makeNiceDate(mj.date) }</td>
          <td>{mj.address}</td>
          <td>{mj.type5}</td>
          <td>{mj.notes}</td>
          <td><button className='button is-primary' onClick={ () => sendEmail(mj.id) }>Send Email</button></td>
          <td><button className='button is-primary' onClick={ () => bookOddJob(mj.id) }>Book Odd Job</button></td>
        </tr>)
    return (
      <div className='box'>
        <h1 className='subtitle'>Current Misc Jobs</h1><button className='button is-primary' onClick={showMiscJobForm}>Create Misc Job</button>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Address</th>
              <th>Type</th>
              <th>Notes</th>
              <th>Send Email</th>
              <th>Create Odd Job</th>
            </tr>
          </thead>
          <tbody>
            { data }
          </tbody>
        </table>
      </div>
    )
  }

  const renderMessages = () => {
    return( message ?
      <div className='box'>
        <h1 className='subtitle'>Current Messages</h1>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Message</th>
              <th>Resolve</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ makeNiceDate(message.messdate) }</td>
              <td>{ message.message }[{message.msid}]</td>
              <td>{ message.resolved?
                    <button className='button is-danger' onClick={ () => unResolveMessage(message.msid) }>{message.resolved}</button>:
                    <button className='button is-primary' onClick={ () => resolveMessage(message.msid) }>Resolve</button>
                  }
              </td>
            </tr>
          </tbody>
        </table>
      </div> : null
    )
  }

  const renderMiscJobForm = () => {
    return( (message && properties) ?
      <MiscJobForm
        message={message}
        properties={properties}
        register={register}
        handleSubmit={handleSubmit}
        createMiscJob={createMiscJob}
      /> : null)
  }

  const renderOddJobForm = () => {
    return( miscJob ?
      <OddJobForm
        miscJob={miscJob}
        register={register}
        handleSubmit={handleSubmit}
        createOddJob={createOddJob}
      /> : null)
  }

  return(
    <Layout>
      <div className='container'>
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li><Link to={`/partner_leads/${oje}`}>Leads</Link></li>
            <li className='is-active'><Link to={`/odd_job_admin/${routeParams.id}`}>Odd Job Admin</Link></li>
          </ul>
        </nav>
        { renderMessages() }
        { miscJobs.length>0 && renderMiscJobs() }

        <div className={renderMiscJobFormClass}>
          { renderMiscJobForm() }
        </div>
        <div className={renderOddJobFormClass}>
          { renderOddJobForm() }
        </div>


      </div>
    </Layout>
  )

}

export default OddJobLeadAdmin;
