import React, { useState } from 'react';

import { ErrorMsg } from "../components/ErrorMsg";


const SignPickUpForm = ({ client, title, handleSubmit, submitForm, register, clientAction, jobplus, getValues, setValue, errorMsgs }) => {

  const[chars, setChars] = useState(0);

  const changeMenu = (e) => {
    e.preventDefault();
    clientAction('completedJobs', null);
  }

  const countLetters = () => {
    const text = getValues("comments");
    if(text && text.length>40){
      setValue('comments', text.substring(0,40));
    }
    setChars(text.length);
  }

  return (
    <div className='box'>

      <nav className='breadcrumb'>
        <ol className='breadcrumb'>
          <li onClick={changeMenu}><a href="#">Completed Jobs</a></li>
          <li className='is-active' onClick={changeMenu}><a href="#">Request Sign Pick Up</a></li>
        </ol>
      </nav>

      <h2 className='title is-5 has-text-centered'>Request Sign Pick Up</h2>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className='field has-text-weight-bold '>JobID: { jobplus && jobplus.job.jobid}</div>
        <div className='field has-text-weight-bold '>Address: { jobplus && jobplus.address}</div>

        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='comments'>Sign location on property(40 characters max { chars>0 ? '- ' + chars : null})</label>
            <textarea className='textarea' id='comments' name='comments' onKeyUp={countLetters} ref={register} placeholder='Enter details here...'/>
          </div>
        </div>

        <button className='button is-primary' type='submit'>Submit Sign Pick Up Request</button>
      </form>
    </div>)
}

export default SignPickUpForm;

//<ErrorMsg msg={errorMsgs.comments} num={ Object.keys(errorMsgs).length-1 }/>
