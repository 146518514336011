import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useParams } from "react-router";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";
import { formatCurrency } from "../helpers/helper"

// Add uniform, ontime for each crew member

const initialOpenState = {
  signsChecked: 'f',
  ragsChecked: 'f',
  framesChecked: 'f',
  debris_bagsChecked: 'f',
  step_laddChecked: 'f',
  d_healthyChecked: 'f',
  windowkit1Checked: 'f',
  snakeChecked: 'f',
  ladder_arms1Checked: 'f',
  ladder_arms2Checked: 'f',
  water_bucketChecked: 'f',
  scoops: 'f',
  poles: 'f',
  small_broom: 'f',
  dustpan: 'f',
  push_broom: 'f',
  water_jug: 'f',
  ckeysChecked: 'f',
  gascardChecked: 'f',
  laptopChecked: 'f',

}

const openReducer = (state, action) => {
  switch(action.type){
    case 'signs':
      return { ...state, signsChecked: action.value}
    case 'rags':
      return { ...state, ragsChecked: action.value}
    case 'frames':
      return { ...state, framesChecked: action.value}
    case 'debris_bags':
      return { ...state, debris_bagsChecked: action.value}
    case 'step_ladd':
      return { ...state, step_laddChecked: action.value}
    case 'd_healthy':
      return { ...state, d_healthyChecked: action.value}
    case 'windowkit1':
      return { ...state, windowkit1Checked: action.value}
    case 'snake':
      return { ...state, snakeChecked: action.value}
    case 'ladder_arms1':
      return { ...state, ladder_arms1Checked: action.value}
    case 'ladder_arms2':
      return { ...state, ladder_arms2Checked: action.value}
    case 'water_bucket':
      return { ...state, water_bucketChecked: action.value}
    case 'ckeys':
      return { ...state, ckeysChecked: action.value}
    case 'gascard':
      return { ...state, gascardChecked: action.value}
    case 'laptop':
      return { ...state, laptopChecked: action.value}
    case 'scoops':
      return { ...state, scoopsChecked: action.value}
    case 'poles':
      return { ...state, polesChecked: action.value}
    case 'small_broom':
      return { ...state, small_broomChecked: action.value}
    case 'dustpan':
      return { ...state, dustpanChecked: action.value}
    case 'push_broom':
      return { ...state, push_broomChecked: action.value}
    case 'water_jug':
      return { ...state, water_jugChecked: action.value}
    default:
      return state;
  }
}

// Add debris_bags, gascard, datasheet, return to shop to close

const initialCloseState = {
  ragsChecked: 'f',
  debris_bagsChecked: 'f',
  step_laddChecked: 'f',
  snakeChecked: 'f',
  ladder_arms1Checked: 'f',
  ladder_arms2Checked: 'f',
  water_bucketChecked: 'f',
  ckeysChecked: 'f',
  chqsChecked: 'f',
  cashChecked: 'f',
  vehicle_cleanChecked: 'f',
  gascardinChecked: 'f',
  keyinChecked: 'f',
  laptopChecked: 'f',
  data_sheetChecked: 'f',
  scoopsChecked: 'f',
  polesChecked: 'f',
  small_broomChecked: 'f',
  dustpanChecked: 'f',
  push_broomChecked: 'f',
  water_jugChecked: 'f',
  gascardChecked: 'f',
  vehicle_bedChecked: 'f',
}

const closeReducer = (state, action) => {
  switch(action.type){
    case 'rags':
      return { ...state, ragsChecked: action.value}
    case 'debris_bags':
      return { ...state, debris_bagsChecked: action.value}
    case 'step_ladd':
      return { ...state, step_laddChecked: action.value}
    case 'laptop':
      return { ...state, laptopChecked: action.value}
    case 'snake':
      return { ...state, snakeChecked: action.value}
    case 'ladder_arms1':
      return { ...state, ladder_arms1Checked: action.value}
    case 'ladder_arms2':
      return { ...state, ladder_arms2Checked: action.value}
    case 'water_bucket':
      return { ...state, water_bucketChecked: action.value}
    case 'chqs':
      return { ...state, chqsChecked: action.value}
    case 'cash':
      return { ...state, cashChecked: action.value}
    case 'gascardin':
      return { ...state, gascardinChecked: action.value}
    case 'ckeys':
      return { ...state, ckeysChecked: action.value}
    case 'laptop':
      return { ...state, laptopChecked: action.value}
    case 'vehicle_clean':
      return { ...state, vehicle_cleanChecked: action.value}
    case 'data_sheet':
      return { ...state, data_sheetChecked: action.value}
    case 'scoops':
      return { ...state, scoopsChecked: action.value}
    case 'poles':
      return { ...state, polesChecked: action.value}
    case 'small_broom':
      return { ...state, small_broomChecked: action.value}
    case 'dustpan':
      return { ...state, dustpanChecked: action.value}
    case 'push_broom':
      return { ...state, push_broomChecked: action.value}
    case 'water_jug':
      return { ...state, water_jugChecked: action.value}
    case 'gascard':
      return { ...state, gascardChecked: action.value}
    case 'vehicle_bed':
      return { ...state, vehicle_bedChecked: action.value}
    default:
      return state;
  }
}

const CrewDetails = () => {
  const [stateOpen, dispatchOpen] = useReducer(openReducer, initialOpenState);
  const [stateClose, dispatchClose] = useReducer(closeReducer, initialCloseState);
  const [crew, setCrew] = useState(null);
  const [scheduledJobs, setScheduledJobs] = useState(null);
  const [completedJobs, setCompletedJobs] = useState(null);
  const [totalJobs, setTotalJobs] = useState(null);
  const [totalProd, setTotalProd] = useState(null);
  const [open, setOpen] = useState(null);
  const [close, setClose] = useState(null);
  const [ocid, setOcid] = useState(null);
  const [oc, setOc] = useState(null);
  const [car, setCar] = useState(null);
  const [truckOptions, setTruckOptions] = useState(null);
  const [openOptions, setOpenOptions] = useState({});
  const [closeOptions, setCloseOptions] = useState({});
  const [{ user, userLoading }] = useStateReducer();
  const params = useParams();

  // useEffect(() => {
  //   if(open){
  //     console.log('Open:', open);
  //   }
  // }, [open])

  const fetchCrewDetails = async () => {
    if (user) {
      const result = await ApiClient.request(`/api/crews/${params.id}/crew_details`);
      console.log('Result', result);
      setTruckOptions(result.truck_options);
      setScheduledJobs(result.scheduled_jobs);
      setCompletedJobs(result.completed_jobs);
      setTotalJobs(result.total_jobs);
      setTotalProd(result.total_prod);
      setCrew(result.crew);
      setCar(result.car);
      setOcid(result.ocid);
      setOc(result.oc);
      setOpenOptions({ opener: user, ocid: result.ocid, oc: result.oc, sender_email: user.email });
      setCloseOptions({ closer: user, ocid: result.ocid, oc: result.oc, sender_email: user.email });
      setOpen(result.open);
      setInitialOpenValues(result.open, result.oc);
      setClose(result.close);
      setInitialCloseValues(result.close, result.oc);
    }
  }

  useEffect(() => {
    fetchCrewDetails();
  }, [user])

  const sendDispatchOpen = (type, value) => {
    dispatchOpen({ type, value })
  }

  const setInitialOpenValues = (open,oc) => {
    sendDispatchOpen('rags', (open && open.rags==='t' ? 'checked' : ''));
    sendDispatchOpen('signs', (open && open.signs==='t' ? 'checked' : ''));
    sendDispatchOpen('frames', (open && open.frames==='t' ? 'checked' : ''));
    sendDispatchOpen('debris_bags', (open && open.debris_bags==='t' ? 'checked' : ''));
    sendDispatchOpen('step_ladd', (open && open.step_ladd==='t' ? 'checked' : ''));
    sendDispatchOpen('d_healthy', (open && open.d_healthy==='t' ? 'checked' : ''));
    sendDispatchOpen('laptop', (open && open.laptop==='t' ? 'checked' : ''));
    sendDispatchOpen('windowkit1', (open && open.windowkit1==='t' ? 'checked' : ''));
    sendDispatchOpen('snake', (open && open.snake==='t' ? 'checked' : ''));
    sendDispatchOpen('ladder_arms1', (open && open.ladder_arms1==='t' ? 'checked' : ''));
    sendDispatchOpen('ladder_arms2', (open && open.ladder_arms2==='t' ? 'checked' : ''));
    sendDispatchOpen('water_bucket', (open && open.water_bucket==='t' ? 'checked' : ''));
    sendDispatchOpen('ckeys', (open && open.ckeys==='t' ? 'checked' : ''));
    sendDispatchOpen('gascard', (open && open.gascard==='t' ? 'checked' : ''));
    sendDispatchOpen('laptop', (open && open.laptop==='t' ? 'checked' : ''));
    sendDispatchOpen('scoops', (open && open.scoops==='t' ? 'checked' : ''));
    sendDispatchOpen('poles', (open && open.poles==='t' ? 'checked' : ''));
    sendDispatchOpen('small_broom', (open && open.small_broom==='t' ? 'checked' : ''));
    sendDispatchOpen('dustpan', (open && open.dustpan==='t' ? 'checked' : ''));
    sendDispatchOpen('push_broom', (open && open.push_broom==='t' ? 'checked' : ''));
    sendDispatchOpen('water_jug', (open && open.water_jug==='t' ? 'checked' : ''));
  }

  const sendDispatchClose = (type, value) => {
    dispatchClose({ type, value })
  }

  const setInitialCloseValues = (close, oc) => {
    sendDispatchClose('rags', (close && close.rags==='t' ? 'checked' : ''));
    sendDispatchClose('debris_bags', (close && close.debris_bags==='t' ? 'checked' : ''));
    sendDispatchClose('step_ladd', (close && close.step_ladd==='t' ? 'checked' : ''));
    sendDispatchClose('snake', (close && close.snake==='t' ? 'checked' : ''));
    sendDispatchClose('ladder_arms1', (close && close.ladder_arms1==='t' ? 'checked' : ''));
    sendDispatchClose('ladder_arms2', (close && close.ladder_arms2==='t' ? 'checked' : ''));
    sendDispatchClose('water_bucket', (close && close.water_bucket==='t' ? 'checked' : ''));
    sendDispatchClose('ckeys', (close && close.ckeys==='t' ? 'checked' : ''));
    sendDispatchClose('gascardin', (close && close.gascardin==='t' ? 'checked' : ''));
    sendDispatchClose('laptop', (close && close.laptop==='t' ? 'checked' : ''));
    sendDispatchClose('chqs', (close && close.chqs==='t' ? 'checked' : ''));
    sendDispatchClose('cash', (close && close.cash==='t' ? 'checked' : ''));
    sendDispatchClose('vehicle_clean', (close && close.vehicle_clean==='t' ? 'checked' : ''));
    sendDispatchClose('data_sheet', (close && close.data_sheet==='t' ? 'checked' : ''));
    sendDispatchClose('scoops', (close && close.scoops==='t' ? 'checked' : ''));
    sendDispatchClose('poles', (close && close.poles==='t' ? 'checked' : ''));
    sendDispatchClose('small_broom', (close && close.small_broom==='t' ? 'checked' : ''));
    sendDispatchClose('dustpan', (close && close.dustpan==='t' ? 'checked' : ''));
    sendDispatchClose('push_broom', (close && close.push_broom==='t' ? 'checked' : ''));
    sendDispatchClose('water_jug', (close && close.water_jug==='t' ? 'checked' : ''));
    sendDispatchClose('gascard', (close && close.gascard==='t' ? 'checked' : ''));
    sendDispatchClose('vehicle_bed', (close && close.vehicle_bed==='t' ? 'checked' : ''));
  }

  const openForm = useForm();
  const closeForm = useForm();
  const { register: openRegister, getValues: getOpenValues, setValue: setOpenValue } = openForm;
  const { register: closeRegister, getValues: getCloseValues, setValue: setCloseValue } = closeForm;

  const openNotesForm = useForm();
  const closeNotesForm = useForm();
  const { register: openNotesRegister, getValues: getNotesOpenValues, setValue: setNotesOpenValue, handleSubmit: handleOpenNotesSubmit } = openNotesForm;
  const { register: closeNotesRegister, getValues: getNotesCloseValues, setValue: setNotesCloseValue, handleSubmit: handleCloseNotesSubmit } = closeNotesForm;

  const truckForm = useForm();
  const { register: truckRegister, setValue: setTruckValue, handleSubmit: handleTruckSubmit } = truckForm;


  useEffect(() => {
    if(car){
      setTruckValue('car', car);
    }
  }, [car])

  const onChangeOpen = async (e) => {
    const item = e.target.name;
    const value = getOpenValues(item);
    let obj = {};
    obj[item] = value;
    let myOptions = { ...openOptions, ...obj };
    let result;
    if(open){
      result = await ApiClient.patch(`/api/opens/${open.id}`, myOptions);
    } else {
      result = await ApiClient.post(`/api/opens`, myOptions);
    }
    setOpen(result.open);
    setOc(result.oc);
    dispatchOpen({ type: item, value: (value ? 'checked' : '') });
  }

  const openNotesSubmit = async (data) => {
    let myOptions = { ...openOptions, ...data };
    let result;
    if(open){
      result = await ApiClient.patch(`/api/opens/${open.id}`, myOptions);
    } else {
      result = await ApiClient.post(`/api/opens`, myOptions);
    }
    setOpen(result.open);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const closeNotesSubmit = async (data) => {
    let myOptions = { ...openOptions, ...data };
    let result;
    if(close){
      result = await ApiClient.patch(`/api/closes/${close.id}`, myOptions);
    } else {
      result = await ApiClient.post(`/api/closes`, myOptions);
    }
    setClose(result.close);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // const emailOpenNotes = async (e) => {
  //   const item = e.target.name;
  //   const value = getOpenValues(item);
  //   let obj = {};
  //   obj[item] = value;
  //   let myOptions = { ...openOptions, ...obj };
  //   let result;
  //   if(open){
  //     result = await ApiClient.patch(`/api/opens/${open.id}`, myOptions);
  //   } else {
  //     result = await ApiClient.post(`/api/opens`, myOptions);
  //   }
  //   setOpen(result.open);
  //   setOc(result.oc);
  //   dispatchOpen({ type: item, value: (value ? 'checked' : '') });
  // }



  const onChangeClose = async (e) => {
    const item = e.target.name;
    const value = getCloseValues(item);
    let obj = {};
    obj[item] = value;
    let myOptions = { ...closeOptions, ...obj };
    let result;
    if(close){
      result = await ApiClient.patch(`/api/closes/${close.id}`, myOptions);
    } else {
      result = await ApiClient.post(`/api/closes`, myOptions);
    }
    setClose(result.close);
    setOc(result.oc);
    dispatchClose({ type: item, value:  (value ? 'checked' : '') });
  }

  const showScheduledJobs = () => scheduledJobs.map((job) =>
        <tr key={job.jobid}>
          <td>{job.num}</td>
          <td>{job.jobid}</td>
          <td>{job.address}</td>
          <td>{job.jobdesc}</td>
          <td>{job.stime}</td>
          <td>{formatCurrency(job.price)}</td>
        </tr>
    )

  const showCompletedJobs = () => completedJobs.map((job) =>
        <tr key={job.jobid}>
          <td>{job.num}</td>
          <td>{job.jobid}</td>
          <td>{job.address}</td>
          <td>{job.jobdesc}</td>
          <td>{job.reportstime}</td>
          <td>{job.reportftime}</td>
          <td>{formatCurrency(job.price)}</td>
          <td>{job.recstatus}</td>
        </tr>
    )

  const showTruckOptions = () => truckOptions.map(truck => <option key={ truck.id }>{ truck.car }</option>);

  const truckSubmit = async(data) => {
    console.log('DATAS', data);

    let obj = {};
    obj['ocid'] = oc.ocid;
    obj['open_id'] = open ? open.id : null ;
    obj['close_id'] = close ? close.id : null ;
    let myOptions = { ...data, ...obj };
    let result;
    result = await ApiClient.post(`/api/crews/update_vehicle`, myOptions);
    result.open && setOpen(result.open);
    result.close && setClose(result.close);
    setOc(result.oc);
    setCar(result.oc.car);
  }

  return (
    <Layout>
        <div className='section'>
          <div className="container content">
            <nav className='breadcrumb'>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/open-close">Open-Close</Link></li>
                <li className='is-active'><Link to="/crew-details">Crew Details</Link></li>
              </ul>
            </nav>
            <div className='box'>
              <h3 className='title'>Crew Details</h3>
              <h3 className='subtitle'>{crew}</h3>
              <h3 className='subtitle'>Vehicle {car}</h3>
            </div>


            <div className='box'>
              <h3 className='subtitle has-text-weight-bold has-text-centered'>Vehicle Assignment</h3>
              <form className="form" onSubmit={handleTruckSubmit(truckSubmit)}>
                <div className="select">
                  <select ref={truckRegister} name='car' defaultValue={car ? car : ''}>
                    { truckOptions && showTruckOptions() }
                  </select>
                </div>
                <div className='has-text-centered'>
                  <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Save Vehicle Assignment</button>
                </div>
              </form>
            </div>


            <div className='columns'>
              <div className='column is-half-tablet'>
                <div className='box'>
                  <h3 className='subtitle has-text-weight-bold has-text-centered'>Scheduled Jobs</h3>
                  <div className='columns is-mobile'>
                    <div className='column is-half-mobile'>
                      <div className="table-container ">
                        <table className='table has-background-light'>
                          <thead>
                            <tr>
                              <th>Num</th>
                              <th>JobID</th>
                              <th>Address</th>
                              <th>Job Desc</th>
                              <th>Start Time</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            { scheduledJobs && showScheduledJobs() }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column is-half-tablet'>
                <div className='box'>
                  <h3 className='subtitle has-text-weight-bold has-text-centered'>Completed Jobs</h3>
                  <div className='columns is-mobile'>
                    <div className='column is-half-mobile'>
                      <div className="table-container">
                        <table className='table has-background-info has-text-white'>
                          <thead>
                            <tr>
                              <th>Num</th>
                              <th>JobID</th>
                              <th>Address</th>
                              <th>Job Desc</th>
                              <th>Start Time</th>
                              <th>Finish Time</th>
                              <th>Price</th>
                              <th>Rec Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            { completedJobs && showCompletedJobs() }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={2}>
                                # Jobs: { completedJobs ? completedJobs.length : 0 }</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td colSpan={2}>Prod: { formatCurrency(totalProd) }</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='columns'>
              <div className='column is-half-tablet'>
                <div className='box'>
                  <h3 className='title has-text-weight-bold has-text-centered'>Open [Truck: { car }]</h3>
                  <div className='columns is-mobile'>
                    <div className='column is-half-mobile'>

                      <div className="field">
                        <input id="switchRagsOpen" type="checkbox" name="rags" checked={ stateOpen.ragsChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchRagsOpen">Rags</label>
                      </div>
                      <div className="field">
                        <input id="switchStepLadderOpen" type="checkbox" name="step_ladd" checked={ stateOpen.step_laddChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchStepLadderOpen">2-Step Ladder</label>
                      </div>
                      <div className="field">
                        <input id="switchSnakeOpen" type="checkbox" name="snake" checked={ stateOpen && stateOpen.snakeChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchSnakeOpen">Snake</label>
                      </div>
                      <div className="field">
                        <input id="LadderArms1Open" type="checkbox" name="ladder_arms1" checked={ stateOpen && stateOpen.ladder_arms1Checked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="LadderArms1Open">Ladder Arms 1</label>
                      </div>
                      <div className="field">
                        <input id="LadderArms2Open" type="checkbox" name="ladder_arms2" checked={ stateOpen && stateOpen.ladder_arms2Checked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="LadderArms2Open">Ladder Arms 2</label>
                      </div>
                      <div className="field">
                        <input id="PushBroomOpen" type="checkbox" name="push_broom" checked={ stateOpen && stateOpen.push_broomChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="PushBroomOpen">Push Broom</label>
                      </div>

                      <hr/>

                      <div className="field">
                        <input id="switchSignsOpen" type="checkbox" name="signs" checked={ stateOpen.signsChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchSignsOpen">Signs</label>
                      </div>
                      <div className="field">
                        <input id="switchDebrisBagsOpen" type="checkbox" name="debris_bags" checked={ stateOpen.debris_bagsChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchDebrisBagsOpen">Debris Bags</label>
                      </div>
                      <div className="field">
                        <input id="switchSafetyTalkOpen" type="checkbox" name="d_healthy"  checked={ stateOpen.d_healthyChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchSafetyTalkOpen">Safety Talk</label>
                      </div>
                      <div className="field">
                        <input id="switchTabletOpen" type="checkbox" name="laptop" checked={ stateOpen.laptopChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchTabletOpen">Tablet Out</label>
                      </div>
                    </div>




                    <div className='column is-half-mobile'>
                      <div className="field">
                        <input id="switchWaterBucketOpen" type="checkbox" name="water_bucket" checked={ stateOpen && stateOpen.water_bucketChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchWaterBucketOpen">Water Bucket</label>
                      </div>
                      <div className="field">
                        <input id="switchScoopsOpen" type="checkbox" name="scoops" checked={ stateOpen && stateOpen.scoopsChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchScoopsOpen">2 Scoops</label>
                      </div>
                      <div className="field">
                        <input id="switchPolesOpen" type="checkbox" name="poles" checked={ stateOpen && stateOpen.polesChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchPolesOpen">2 Poles</label>
                      </div>
                      <div className="field">
                        <input id="switchSmallBroomOpen" type="checkbox" name="small_broom" checked={ stateOpen && stateOpen.small_broomChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchSmallBroomOpen">Small Broom</label>
                      </div>
                      <div className="field">
                        <input id="switchDustPanOpen" type="checkbox" name="dustpan" checked={ stateOpen && stateOpen.dustpanChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchDustPanOpen">Dust Pan</label>
                      </div>
                      <div className="field">
                        <input id="switchWaterJugOpen" type="checkbox" name="water_jug" checked={ stateOpen && stateOpen.water_jugChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchWaterJugOpen">Water Jug</label>
                      </div>

                    <hr/>

                    <div className="field">
                        <input id="switchFrames" type="checkbox" name="frames" checked={ stateOpen.framesChecked || '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchFrames">Frames</label>
                      </div>
                      <div className="field">
                        <input id="switchWindowKitsOpen" type="checkbox" name="windowkit1" checked={ stateOpen && stateOpen.windowkit1Checked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchWindowKitsOpen">Window Kits</label>
                      </div>
                      <div className="field">
                        <input id="switchKeysOutOpen" type="checkbox" name="ckeys" checked={ stateOpen && stateOpen.ckeysChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchKeysOutOpen">Keys Out</label>
                      </div>
                      <div className="field">
                        <input id="switchGasCardOutOpen" type="checkbox" name="gascard" checked={ stateOpen && stateOpen.gascardChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ openRegister } onChange={ onChangeOpen } />
                        <label htmlFor="switchGasCardOutOpen">Gas Card Out</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='column is-half-tablet'>
                <div className='box'>
                  <h3 className='title has-text-weight-bold has-text-centered'>Close [Truck: { car }]</h3>
                  <div className='columns is-mobile'>
                    <div className='column is-half-mobile'>
                      <div className="field">
                        <input id="switchRags" type="checkbox" name="rags" checked={ stateClose && stateClose.ragsChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchRags">Rags</label>
                      </div>
                        <div className="field">
                        <input id="switchStepLadder" type="checkbox" name="step_ladd" checked={ stateClose && stateClose.step_laddChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchStepLadder">2-Step Ladder</label>
                      </div>
                      <div className="field">
                        <input id="switchSnake" type="checkbox" name="snake" checked={ stateClose && stateClose.snakeChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchSnake">Snake</label>
                      </div>
                      <div className="field">
                        <input id="LadderArms1" type="checkbox" name="ladder_arms1" checked={ stateClose && stateClose.ladder_arms1Checked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="LadderArms1">Ladder Arms 1</label>
                      </div>
                      <div className="field">
                        <input id="LadderArms2" type="checkbox" name="ladder_arms2" checked={ stateClose && stateClose.ladder_arms2Checked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="LadderArms2">Ladder Arms 2</label>
                      </div>
                      <div className="field">
                        <input id="PushBroomClose" type="checkbox" name="push_broom" checked={ stateClose && stateClose.push_broomChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="PushBroomClose">Push Broom</label>
                      </div>

                      <hr/>

                      <div className="field">
                        <input id="switchCheques" type="checkbox" name="chqs" checked={ stateClose && stateClose.chqsChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchCheques">Cheques In</label>
                      </div>
                      <div className="field">
                        <input id="switchKeys" type="checkbox" name="ckeys" checked={ stateClose && stateClose.ckeysChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchKeys">Keys In</label>
                      </div>
                      <div className="field">
                        <input id="switchGasCard" type="checkbox" name="gascard" checked={ stateClose && stateClose.gascardChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchGasCard">Gas Card In</label>
                      </div>
                      <div className="field">
                        <input id="switchVehicleClean" type="checkbox" name="vehicle_clean" checked={ stateClose && stateClose.vehicle_cleanChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchVehicleClean">Truck Cab Cleaned</label>
                      </div>
                    </div>
                    <div className='column is-half-mobile'>
                      <div className="field">
                        <input id="switchWaterBucketClose" type="checkbox" name="water_bucket" checked={ stateClose && stateClose.water_bucketChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchWaterBucketClose">Water Bucket</label>
                      </div>
                      <div className="field">
                        <input id="switchScoopsClose" type="checkbox" name="scoops" checked={ stateClose && stateClose.scoopsChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchScoopsClose">2 Scoops</label>
                      </div>
                      <div className="field">
                        <input id="switchPolesClose" type="checkbox" name="poles" checked={ stateClose && stateClose.polesChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchPolesClose">2 Poles</label>
                      </div>
                      <div className="field">
                        <input id="switchSmallBroomClose" type="checkbox" name="small_broom" checked={ stateClose && stateClose.small_broomChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchSmallBroomClose">Small Broom</label>
                      </div>
                      <div className="field">
                        <input id="switchDustPanClose" type="checkbox" name="dustpan" checked={ stateClose && stateClose.dustpanChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchDustPanClose">Dust Pan</label>
                      </div>
                      <div className="field">
                        <input id="switchWaterJugClose" type="checkbox" name="water_jug" checked={ stateClose && stateClose.water_jugChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchWaterJugClose">Water Jug</label>
                      </div>

                      <hr/>

                      <div className="field">
                        <input id="switchCash" type="checkbox" name="cash" checked={ stateClose && stateClose.cashChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchCash">Cash In</label>
                      </div>
                      <div className="field">
                        <input id="switchTablet" type="checkbox" name="laptop" checked={ stateClose && stateClose.laptopChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchTablet">Tablet In</label>
                      </div>
                      <div className="field">
                      <input id="switchDebrisBagsClose" type="checkbox" name="debris_bags" checked={ stateClose.debris_bagsChecked || '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                      <label htmlFor="switchDebrisBagsClose">Debris Bags Protected</label>
                      </div>
                      <div className="field">
                        <input id="switchVehicleBed" type="checkbox" name="vehicle_bed" checked={ stateClose && stateClose.vehicle_bedChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchVehicleBed">Truck Bed Organized</label>
                      </div>
                      <div className="field">
                        <input id="switchDataSheet" type="checkbox" name="data_sheet" checked={ stateClose && stateClose.data_sheetChecked ? 'checked' : '' } className="switch is-rounded is-info" ref={ closeRegister } onChange={ onChangeClose } />
                        <label htmlFor="switchDataSheet">Data Sheet Filled/Signed</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

                <div className='columns'>
                  <div className='column is-half-tablet'>
                    <div className='box'>
                      <h3 className='subtitle has-text-weight-bold has-text-centered'>Open Notes/Needs</h3>
                        <form className="form" onSubmit={handleOpenNotesSubmit(openNotesSubmit)}>
                          <div className='field'>
                            <div className='control'>
                              <label className="label" forhtml='email_crew_notes'>Email Open Notes/Needs
                                <label className="ml-3 radio">
                                  <input
                                    type="checkbox"
                                    name="email_crew_notes"
                                    ref={ openNotesRegister }
                                    defaultChecked
                                  />
                                </label>
                              </label>

                              <textarea
                                className='textarea'
                                rows='3'
                                id='email_crew_notes'
                                name='crew_notes'
                                ref={ openNotesRegister }
                                defaultValue={ (open && open.crew_notes) || "" }
                                placeholder="Open Notes..."
                              />
                            </div>
                          </div>
                          <div className='field'>
                            <div className='control'>
                              <textarea
                                className='textarea'
                                rows='3'
                                id='email_crew_needs'
                                name='crew_needs'
                                ref={ openNotesRegister }
                                defaultValue={ (open && open.crew_needs) || "" }
                                placeholder="Open Needs..."
                              />
                            </div>
                          </div>
                          <div className='has-text-centered'>
                            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Save Open Notes/Needs</button>
                          </div>
                        </form>

                    </div>
                  </div>
                  <div className='column is-half-tablet'>
                    <div className='box'>
                      <h3 className='subtitle has-text-weight-bold has-text-centered'>Close Notes/Needs</h3>
                        <form className="form" onSubmit={handleCloseNotesSubmit(closeNotesSubmit)}>
                          <div className='field'>
                            <div className='control'>
                              <label className="label" forhtml='email_close_notes'>Email Close Notes/Needs
                                <label className="ml-3 radio">
                                  <input
                                    type="checkbox"
                                    name="email_crew_notes"
                                    ref={ closeNotesRegister }
                                    defaultChecked
                                  />
                                </label>
                              </label>

                              <textarea
                                className='textarea'
                                rows='3'
                                id='email_close_notes'
                                name='crew_notes'
                                ref={ closeNotesRegister }
                                defaultValue={ (close && close.crew_notes) || "" }
                                placeholder="Close Notes..."
                              />
                            </div>
                          </div>
                          <div className='field'>
                            <div className='control'>
                              <textarea
                                className='textarea'
                                rows='3'
                                id='email_crew_needs'
                                name='crew_needs'
                                ref={ closeNotesRegister }
                                defaultValue={ (close && close.crew_needs) || "" }
                                placeholder="Close Needs..."
                              />
                            </div>
                          </div>
                          <div className='has-text-centered'>
                            <button name='close_notes' type='submit' className='button is-link is-rounded has-text-weight-bold'>Save Close Notes/Needs</button>
                          </div>
                        </form>
                    </div>
                  </div>
                </div>

          </div>
        </div>
    </Layout>
  );
};

export default CrewDetails;


// <form className="form" onSubmit={handleSubmit(openSubmit)}>
// </form>



// | driver_si    | varchar(50)  | YES  |     | NULL    |                |
// | partner1_si  | varchar(50)  | YES  |     | NULL    |                |
// | partner2_si  | varchar(50)  | YES  |     | NULL    |                |
// | uniform1     | varchar(50)  | YES  |     | NULL    |                |
// | uniform2     | varchar(50)  | YES  |     | NULL    |                |
// | uniform3     | varchar(50)  | YES  |     | NULL    |                |


// <form className="form" onSubmit={handleSubmit(openSubmit)}>
// </form>
