import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect , useParams } from "react-router-dom";

import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../helpers/helper"




const EstimatesSigns = () => {
  let routeParams = useParams();
  console.log('Route Params', routeParams);
  const [{ user, userLoading }] = useStateReducer();
  const [showDisplay, setShowDisplay] = useState(null);   //('Window Estimates')
  const [windowEstsNum, setWindowEstsNum] = useState(0);
  const [windowEsts, setWindowEsts] = useState([]);
  const [windowEstsInd, setWindowEstsInd] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [completedWindowEsts, setCompletedWindowEsts] = useState([]);

  const fetchNumbers = async () => {
    const myParams = { list: 'lead_numbers_ests_only', hrid: user.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const numbers = await ApiClient.request(`/api/partners?${qs}`);
    setWindowEstsNum(numbers.windowEstsNum);
  }

  const fetchWinEavesEstimates = async () => {
    setShowLoader(true);
    console.log('User', user);
    const myParams = { list: 'window_estimate_list', hrid: user.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const windowEsts = await ApiClient.request(`/api/partners?${qs}`);
    setWindowEsts(windowEsts.pending_windows_ests);
    const ests = windowEsts.pending_windows_ests;
    const windowEstsIndTemp = ests.filter((est) => (est.estimator === user.fullname || est.holder === user.fullname));
    setWindowEstsInd(windowEstsIndTemp);
    setShowLoader(false);
    setCompletedWindowEsts(windowEsts.completed_windows_ests);
  }

  useEffect(() => {
    if(user){
      fetchNumbers();
      fetchWinEavesEstimates();
    }
  }, [user])

  const assignEstimate = async (msid) => {
    const myParams = { hrid: user.hrid };
    //const qs = new URLSearchParams(myParams).toString();
    const success = await ApiClient.patch(`/api/partners/${msid}`, myParams);
    fetchWinEavesEstimates();
  }

  const buttonEstimateChoices = (est) => {
    console.log('ESTID', est);
    // if(est.followobject === user.hrid){
    //   return <button className='button is-danger' onClick={ () => assignEstimate(est.msid) }>{ est.holder }</button>
    // } else if(est.followobject === user.hrid) {
    //   return <button className='button is-link' onClick={ () => assignEstimate(est.msid) }>{ est.holder }</button>
    // } else {
    //   return <button className='button is-primary' onClick={ () => assignEstimate(est.msid) }>Assign</button>
    // }
    if(est.followobject && est.followobject.includes('HR')){
      return <button className='button is-danger' onClick={ () => assignEstimate(est.msid) }>{ est.holder }</button>
    } else {
      return <button className='button is-primary' onClick={ () => assignEstimate(est.msid) }>Assign</button>
    }
  }

  const tableData = (list) => {
     const result = list.map((est) => {
      if(est.type === 'estsign'){
      return(
      <tr key={est.msid} className={est.message.includes('Written') ? 'has-background-info has-text-white' : null}>
        <td>{ makeNiceDate(est.messdate) }</td>
        <td>{ est.age }</td>
        <td>
          <u>
            <a style={{color: 'black'}} href={`https://www.google.ca/maps/place/${est.address_plus} ${est.city}`} target="_blank">
              {est.address_plus}
            </a>
          </u>
        </td>
        <td>{ est.postcode }</td>
        <td>{ est.perly }</td>
        <td>{ est.message }</td>
        <td>{ buttonEstimateChoices(est) } </td>
        <td>
          { user.hrid === est.followobject && est.message.includes("Written") ?
            <Link className='button is-primary' to={`/window_lead_admin_crew/${est.msid}`}>Admin</Link> :
          null }
          { user.hrid === est.followobject && est.message.includes("Pick Up") ?
            <Link className='button is-primary' to={`/pick_up_admin_crew/${est.msid}`}>Admin</Link> :
          null }
        </td>
      </tr>)
    }else{
      return(
        <tr key={est.msid} className={'has-background-dark has-text-white'}>
        <td>{ est.tag }</td>
        <td>{ est.age }</td>
          <td>
            <u>
              <a style={{color: 'white'}} href={`https://www.google.ca/maps/place/${est.address_plus} ${est.city}`} target="_blank">
                <span>{est.address_plus}</span>
              </a>
            </u>
          </td>
          <td>{ est.postcode }</td>
          <td>{ est.perly }</td>
          <td>{ est.name }</td>
          <td>{ est.message }</td>
          <td><Link to={`/partner_inspection/${est.jobid}`}>Inspection</Link></td>

       </tr>
      )
    }
    })
    return result;
  }


  const showReport = (list) => {
    return (
      <div>
        <h1 className='mb-6 title has-text-centered has-text-link'>Window Estimates/Signs</h1>
        { list.length > 0 ?
          <div>
            { windowEstsInd && showIndReport(windowEstsInd) }
            <h1 className='subtitle has-text-link'>Pending</h1>
            <div className='table-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Age</th>
                  <th>Address</th>
                  <th>Post Code</th>
                  <th>Perly</th>
                  <th>Details</th>
                  <th>Holder</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                { tableData(list) }
              </tbody>
            </table><br/>
          </div>
        </div> : null }
      </div>
    );
  }

  const showIndReport = (list) => {
    return (
      <div>
        { list.length > 0 ?
          <div>
            <h1 className='subtitle has-text-link'>Individual Pending</h1>
            <div className='table-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Age</th>
                  <th>Mgr</th>
                  <th>Address</th>
                  <th>Post Code</th>
                  <th>Perly</th>
                  <th>Details</th>
                  <th>Holder</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                { tableData(list) }
              </tbody>
            </table><br/>
            <hr/>
          </div>
        </div> : null }
      </div>
    );
  }

  const showCompletedWindowEstsReport = () => {

    const estData = (list) => {
       const result = list.map((est) => (
        <tr key={est.msid}>
          <td>{ makeNiceDate(est.resolved) }</td>
          <td>{ est.estimator }</td>
          <td>{ est.address_plus }</td>
          <td>{ est.postcode }</td>
          <td>{ est.perly }</td>
          <td>{ formatCurrency(est.w1) }</td>
          <td>{ formatCurrency(est.eh) }</td>
          <td className='has-text-centered'>{ est.days_to_estimate }</td>
        </tr>
      ))
      return result;
    }

    return (
      <div>
        <h1 className='subtitle has-text-link'>Completed Estimates</h1>
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Date Done</th>
                <th>Estimator</th>
                <th>Address</th>
                <th>Post Code</th>
                <th>Perly</th>
                <th>W1</th>
                <th>EH</th>
                <th>Days To Complete</th>
              </tr>
            </thead>
            <tbody>
              { estData(completedWindowEsts) }
            </tbody>
          </table>
        </div>
      </div>
    );

  }


  return(
    <Layout>
      <div className='container'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{ user && user.name }</h1>
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li className='is-active'><Link to="/crew-estimates-signs">Estimates/Signs</Link></li>
          </ul>
        </nav>
        { showLoader && <Loader /> }
        { windowEsts && showReport(windowEsts) }
        { completedWindowEsts && showCompletedWindowEstsReport() }
      </div>
    </Layout>
  )

}

export default EstimatesSigns;
