import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import Services from "../views/Services";
import Dashboard from "../views/Dashboard";
import About from "../views/About";
import Contact from "../views/Contact";
import ResetPassword from "../views/ResetPassword";
import SmartSearch from "../views/SmartSearch";

export const history = createBrowserHistory({ basename: "crews/react_client_dashboard" });

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={ Home } exact />
      <Route path="/services" component={ Services } exact />
      <Route path="/dashboard" component={ Dashboard } exact />
      <Route path="/about" component={ About } exact />
      <Route path="/contact" component={ Contact } exact />
      <Route path="/reset_password" component={ ResetPassword } exact />
      <Route path="/smart_search" component={ SmartSearch } exact />
    </Switch>
  </Router>
);

export default AppRouter;
