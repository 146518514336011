import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import trash from "../../assets/trashcan.jpg";

const TransactionPanel = ({ tran, onDeleteTransaction }) => {
  const [jobdescClass, setJobdescClass] = useState(
    "display-panel-container__item-2"
  );

  return (
    <div className="display-panel-container">
      <Link to={`/transactions/${tran.id}/edit`}>
        <div className="display-panel-container__row">
          <span className="display-panel-container__item-1">{tran.date}</span>
          <span className="display-panel-container__item-1">
            {tran.location}
          </span>
          <span className="display-panel-container__item-1">
            {tran.typeIncome}
          </span>
          <span className="display-panel-container__item-1">
            ${parseFloat(tran.amount).toFixed(2)}
          </span>
        </div>
      </Link>
      <div className="display-panel-container__row display-panel-container__row__padding-b">
        <span className={jobdescClass}>
          {tran.description} {tran.notes ? ` : ${tran.notes}` : ""}
        </span>
        <span className={jobdescClass}>
          <img
            className="icon"
            src={trash}
            onClick={() => onDeleteTransaction(tran.id)}
          />
        </span>
      </div>
    </div>
  );
};

export default TransactionPanel;
