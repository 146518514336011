import ApiClient from "./client";

export default class LoginAPI {
  static async logIn(username, password) {
    return ApiClient.post("/api/sessions", {
      username,
      password,
    });
  }

  static async logOut() {
    return ApiClient.request("/api/sessions/log_out");
  }

  static async verifySession() {
    return ApiClient.request("/api/sessions/verify_user");
  }
}
