import React from "react";
import { formatCurrency, makeNiceDate } from "../../helpers/helper";

const WeeklyPay = ({ week }) => {
  let revenue = 0;
  return (
    <table className="crewpay-table crewpay-table--margin-bottom crewpay-table--margin-top">
      <thead>
        <tr>
          <th colSpan="6" className="crewpay-table__header">
            Week 1
          </th>
        </tr>
        <tr>
          <th className="crewpay-table__header">Name</th>
          <th className="crewpay-table__header">Date</th>
          <th className="crewpay-table__header">Details</th>
          <th className="crewpay-table__header">Hours</th>
          <th className="crewpay-table__header">Rate</th>
          <th className="crewpay-table__header">Amount</th>
        </tr>
      </thead>
      <tbody>
        {week.map((entry) => {
          revenue += Number(entry.pay);
          return (
            <tr>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {entry.name}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {makeNiceDate(entry.date)}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {entry.type}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {entry.hours}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {formatCurrency(entry.rate)}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {formatCurrency(entry.pay)}
              </td>
            </tr>
          );
        })}
        <tr>
          <td className="crewpay-table__body crewpay-table__body--pad crewpay-table__body--yellow crewpay-table__body--center">
            Total
          </td>
          <td
            colSpan="4"
            className="crewpay-table__body crewpay-table__body--pad crewpay-table__body--yellow"
          ></td>
          <td className="crewpay-table__body crewpay-table__body--pad crewpay-table__body--yellow crewpay-table__body--center">
            {revenue}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default WeeklyPay;
