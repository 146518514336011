import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";


import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../../helpers/helper";


const WindowEstimateForm = ({ msid, register, handleSubmit, returnEstimate, getValues, setValue }) => {
  const [client, setClient] = useState(null);
  const [property, setProperty] = useState(null);
  const [message, setMessage] = useState(null);
  const [pastJobs, setPastJobs] = useState(null);
  const [closeProperties, setCloseProperties] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [propertyPics, setPropertyPics] = useState([]);


  const fetchPropertyPlus = async () => {
    setShowLoader(true);
    const { mess, client, property, jobs, close_properties } = await ApiClient.request(`/api/messages/${msid}`);
    console.log('Resultsis:', close_properties)
    setMessage(mess);
    setProperty(property);
    setClient(client);
    setPastJobs(jobs);
    setCloseProperties(close_properties);
    const property_pics = await ApiClient.request(`/api/ben/${property.jobinfoid}/property_images`);
    console.log('Property Pics', property_pics);
    setPropertyPics(property_pics);

    setShowLoader(false);
  }

  useEffect(() => {
    if(msid){
      fetchPropertyPlus();
    }
  }, [msid])

  const showInfo = () => {
    return <div >
      <h1 className='subtitle'>{client.honorific} {client.firstname} {client.lastname}</h1>
      <h1 className='subtitle'>
        <a href={`https://www.google.ca/maps/place/${property.address} Toronto`} target="_blank">
          {property.address} [{property.postcode} ]
        </a>
      </h1>
      <h1 className='subtitle'>Perly: {property.perly}</h1>
      <h1 className='subtitle has-text-link has-text-weight-bold'>Message: {message.message}</h1>
      <hr/>
    </div>
  }

  const showPastJobs = () => {
     const pastJobData = () => pastJobs.map((job) => <tr key={job.jobid}>
       <td>{ job.jobid }</td>
       <td>{ job.address }</td>
       <td>{ job.name }</td>
       <td>{ makeNiceDate(job.date) }</td>
       <td>{ job.jobdesc }</td>
       <td>{ job.crew }</td>
       <td>{ formatCurrency(job.price) }</td>
       <td>{ job.minutes }</td>
     </tr>)

    return <React.Fragment>
        <h1 className='subtitle'>Past Jobs at { property.address }</h1>
        <table className='table'>
          <thead>
            <tr>
              <th>JobID</th>
              <th>Address</th>
              <th>Client</th>
              <th>Date</th>
              <th>Job Desc</th>
              <th>Crew</th>
              <th>Price</th>
              <th>Mins</th>
            </tr>
          </thead>
          <tbody>
            { pastJobData() }
          </tbody>
        </table><hr/>
    </React.Fragment>
  }

  const showCloseProperties = () => {
     const closePropertiesData = () => closeProperties.map((cp) => <tr key={cp.id}>
       <td>{ cp.address }</td>
       <td>{ formatCurrency(cp.w1) }</td>
       <td>{ formatCurrency(cp.eh) }</td>
     </tr>)

    return <React.Fragment>
        <h1 className='subtitle'>Close Properties</h1>
        <table className='table'>
          <thead>
            <tr>
              <th>Address</th>
              <th>W1</th>
              <th>EH</th>
            </tr>
          </thead>
          <tbody>
            { closePropertiesData() }
          </tbody>
        </table><hr/>
    </React.Fragment>
  }

  const addToPermanentNotes = (e) => {
    e.preventDefault();
    const ans = getValues('email_notes');
    console.log('ANS', ans);
    setValue('property_notes_1', ans);
  }

  const showPropertyPics = () => {
    console.log("In Property Pics");
    const property_pic_images = propertyPics.map(pic => (
      <div className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Property Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time datetime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div className='columns is-multiline'>
        { property_pic_images }
      </div>
    )
  }



  return(<div>
      { showLoader ? <Loader /> :
      <div className='container box has-background-grey-lighter'>
        <h1 className='title has-text-centered'>Return Estimate</h1>
        { message && property && client && showInfo() }
        { pastJobs && showPastJobs() }
        { closeProperties && showCloseProperties() }
        <hr/>
          {property &&
          <u>
            <a className="navbar-item is-fullwidth" href={`/property_images?property_id=${property.jobinfoid}&source=react_window_estimate_form&delete_source=react_window_estimate_form&mess_id=${msid}`}>
              Add Property Image
            </a>
          </u>}

          { property && propertyPics && showPropertyPics() }
        <hr/>


        <form className='form' onSubmit={handleSubmit(returnEstimate)}>
        { client && client.email &&
          <div>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='email_input'>
                  <input
                    type='checkbox'
                    name='send_email'
                    ref={ register }
                    defaultChecked='checked'
                  />
                <span className='pl-3'>Send Email</span>
                <span className='pl-1'>({ client.email })</span>
                  </label>
              </div>
            </div><br/>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='email_notes_input'>
                  Email Notes
                  </label>
                  <button type='button' className='button is-small is-link is-rounded mb-4' onClick={ addToPermanentNotes }>
                    Add To Permanent Notes
                  </button>
                  <textarea
                    className='textarea'
                    rows='3'
                    id='email_notes_input'
                    name='email_notes'
                    placeholder='Enter email notes here. These notes will only show up in email IF Send Email is selected above. Select Add To Permanent Notes to transfer to Property Notes 1'
                    ref={ register }
                  />
              </div>
            </div><br/>
          </div>
        }

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='w1_input'>W1</label>
                <input
                  type='text'
                  className='input is-large has-background-danger has-text-white'
                  id='w1_input'
                  name='w1'
                  ref={ register }
                  size="10"
                  placeholder="W1 must be entered"
                />
            </div>
          </div>


          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='eh_input has-background-link has-text-white'>EH</label>
                <input
                  type='text'
                  className='input is-large'
                  id='eh_input'
                  name='eh'
                  ref={ register }
                  size="10"
                  placeholder="EH optional"
                />
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='eg_input'>EG</label>
                <input
                  type='text'
                  className='input is-large'
                  id='eg_input'
                  name='eg'
                  ref={ register }
                  size="10"
                  placeholder="EG optional"
                />
            </div>
          </div>



          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='property_notes_1_input'>Property Notes 1
                <label className="ml-3 radio">
                  <input
                    type="radio"
                    name="note1_access"
                    ref={ register }
                    value='public'
                    defaultChecked
                  />
                  Public
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="note1_access"
                    ref={ register }
                    value='private'
                  />
                  Private
                </label>
              </label>

                <textarea
                  className='textarea'
                  rows='3'
                  id='property_notes_1_input'
                  name='property_notes_1'
                  ref={ register }
                  placeholder="Property Notes 1 optional"
                />
            </div>
          </div><br/>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='property_notes_2_input'>Property Notes 2
                <label className="ml-3 radio">
                  <input
                    type="radio"
                    name="note2_access"
                    defaultChecked
                    ref={ register }
                    value='public'
                  />
                  Public
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="note2_access"
                    ref={ register }
                    value='private'
                  />
                  Private
                </label>
              </label>
                <textarea
                  className='textarea'
                  rows='3'
                  id='property_notes_2_input'
                  name='property_notes_2'
                  ref={ register }
                  placeholder="Property Notes 2 optional"
                />
            </div>
          </div><br/>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='property_notes_3_input'>Property Notes 3
                <label className="ml-3 radio">
                  <input
                    type="radio"
                    name="note3_access"
                    ref={ register }
                    defaultChecked
                    value='public'
                  />
                  Public
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="note3_access"
                    ref={ register }
                    value='private'
                />
                  Private
                </label>
              </label>
                <textarea
                  className='textarea'
                  rows='3'
                  id='property_notes_3_input'
                  name='property_notes_3'
                  ref={ register }
                  placeholder="Property Notes 3 optional"
                />
            </div>
          </div><br/>

          <div className='has-text-centered'>
            <button name='return_estimate' type='submit' className='button is-link is-rounded has-text-weight-bold'>Return Estimate</button>
          </div>
        </form>

      </div>}
    </div>
  )

}

export default WindowEstimateForm;


// <input
//   type='hidden'
//   name='msid'
//   ref={ register }
//   defaultValue = { msid }
// />

// <div className='field'>
//   <div className='control'>
//     <label className="label" forhtml='w2_input'>W2</label>
//       <input
//         type='text'
//         className='input is-narrow'
//         id='w2_input'
//         name='w2'
//         ref={ register }
//         size="10"
//         placeholder="optional"
//       />
//   </div>
// </div>
