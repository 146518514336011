import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";


//import Loader from "../../../base/layout/Loader";
//import { useStateReducer } from "../../../base/context/authContext";
//import ApiClient from "../../../base/api/client";



const OddJobForm = ({miscJob, register, handleSubmit, createMiscJob }) => {

  return(
      <div className='container box'>
        <h1 className='subtitle'>Create Odd Job</h1>
        <form className='form' onSubmit={handleSubmit(createMiscJob)}>
          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='details_input'>Job Description</label>
                <textarea
                  className='textarea'
                  rows='4'
                  id='details_input'
                  name='details'
                  ref={ register }
                  defaultValue={ miscJob.notes}
                />
            </div>
          </div><br/>

          <div className='field'>
            <div className='control'>
              <label className="label" htmlFor='payor_label'>Payor</label>
              <div className='select' id='payor_label'>
                <select name='payor' ref={ register }>
                  <option value='White Shark'>White Shark</option>
                  <option value='Client'>Client</option>
                </select>
              </div>
            </div>
          </div><br/>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='order_input'>Order</label>
                <input
                  type='text'
                  className='input is-narrow'
                  rows='4'
                  id='order_input'
                  name='order'
                  ref={ register }
                  size="10"
                />
            </div>
          </div>

          <button name='return_job' type='submit' className='button is-link is-rounded has-text-weight-bold'>Create Odd Job</button> :

        </form>

      </div>
  )

}

export default OddJobForm;
