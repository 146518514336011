import { useState, useEffect } from "react";
import ApiClient from "../api/client";

const useRequest = (url, body, condition = true, id = null) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const payload = body ? JSON.stringify(body) : undefined;

  let method = "GET";
  if (body && !id) {
    method = "POST";
  } else if (body && id) {
    method = "PATCH";
  }

  const fetchData = async () => {
    setLoading(true);
    const response = await ApiClient.request(url, {
      method,
      body: payload,
    });
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    if (condition) {
      fetchData();
    }
  }, [url, payload, condition]);

  return {
    data,
    loading,
    refetch: fetchData,
  };
};

export default useRequest;
