import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import dayjs from "dayjs";

import FormFields from "./FormFields";
import FormCard from "../../components/FormCard";
import Layout from "../../layout/Layout";
import ApiClient from "../../api/client";

const NewPropertyTransaction = () => {
  const history = useHistory();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { date: dayjs().format("YYYY-MM-DD") },
  });

  const onSubmit = async (params) => {
    console.log("PARAMS: ", params);
    const response = await ApiClient.post(`/api/props/add_transaction`, params);

    if (response) {
      history.push("/transactions");
    }
  };

  return (
    <Layout>
      <button className="button button-assign" onClick={() => history.goBack()}>
        Back
      </button>
      <FormCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFields register={register} />
          <button className="button button-assign">Create Transaction</button>
        </form>
      </FormCard>
    </Layout>
  );
};

export default NewPropertyTransaction;
