import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from "../helpers/helper";
import { markCcStatusRequired } from "../helpers/stripe";

const myStyle = {
  textDecoration: 'underline'
}

import ApiClient from "../../base/api/client";

const UpcomingJobs = ({ client, deleteJob, clientAction, sendConfirmation }) => {
  const [upcomingJobs, setUpcomingJobs] = useState([]);
  const TRASH = <FontAwesomeIcon icon={ faTrash } size='lg' className='ml-4 mt-2' />;

  const fetchUpcomingJobs = async () => {
    const response = await markCcStatusRequired(client.cfid);
    const result = await ApiClient.request(`/api/clients/${client.cfid}/upcoming_jobs`);
    setUpcomingJobs(result);
  }

  useEffect(() => {
    if(client){
      fetchUpcomingJobs();
    }
  }, [client])

  const showUpcomingJobs = () => {
    return upcomingJobs.map((cj) =>
    <div key={cj.jobid} className="box is-clipped">
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong>{ cj.jobid }</strong>
            </p>
          </div>
        </div>

        <div className="level-center">
          <div className="level-item">
        <p className="subtitle is-5">
          { cj.cc_status_client==='required' && cj.cc_status_job==='unsecured' ?
          <a style={myStyle} className="is-underlined has-text-weight-bold is-size-5" onClick={() => clientAction('manageCreditCards', null)}>
            Secure with credit card
          </a>
            : null
          }
        </p>
        </div>
      </div>



        <div className="level-right">
        <div className="level-item">
          <p className="subtitle is-5">
            <strong>{ cj.address }</strong>
          </p>
        </div>
      </div>

      </nav>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5"><strong>Start Date: { cj.sdate }
              [{ cj.sdate === cj.fdate ? 'Appt' : 'Fltr'}
               { cj.sdate === cj.fdate ? '-'+cj.stime : null}]
              </strong>
            </p>
          </div>
        </div>
      </nav>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong>Job Desc: { cj.jobdesc }</strong>
            </p>
          </div>
        </div>
      </nav>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong>Price: { formatCurrency(cj.price) }</strong>
            </p>
          </div>
        </div>
      </nav>

      { cj.email?
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <a className="has-text-link has-text-weight-bold" onClick={() => sendConfirmation(cj.jobid)}>
                <u>Email Confirmation To { cj.email }</u>
              </a>
            </div>
          </div>
        </nav> : null
      }

      <hr/>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong className="has-text-link">
                If you wish to make changes to this order,
              </strong>
            </p>
          </div>
        </div>
      </nav>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong className="has-text-link">
                please email us at dashboard@whiteshark.ca.
              </strong>
            </p>
          </div>
        </div>
      </nav>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
          <p className="subtitle is-5">
            <strong className="has-text-link">
              We will email back confirmation of changes.
            </strong>
          </p>
          </div>
        </div>
      </nav>
    </div>

    )
  }

  // jobid: job.jobid,
  // address: job.property.address,
  // jobdesc: job.jobdesc,
  // datebi: job.datebi,
  // crewname: job.crewname,
  // price: job.price


  return (
    <div className='box has-background-light'>
      <h2 className='title is-4 has-text-centered'>Upcoming Jobs</h2>
      { upcomingJobs === null ? <LoaderBox /> :
        <div className="container">
          { showUpcomingJobs() }
        </div>
      }
    </div>
  )
}

export default UpcomingJobs



// <tfoot>
//   <tr>
//     <th><abbr title="Job ID">jobid</abbr></th>
//     <th><abbr title="Booked On">Bkd</abbr></th>
//     <th><abbr title="Job Type">Type</abbr></th>
//     <th><abbr title="Job Address">Loc</abbr></th>
//     <th><abbr title="Job Description">Desc</abbr></th>
//   </tr>
// </tfoot>

// <div className="level-right">
//   <div className="level-item" onClick={() => {deleteJob(cj.jobid)}}>
//     { TRASH }
//   </div>
// </div>
