import React, { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";
import Loader from "../../base/layout/Loader";


import AdminMgt from '../forms/AdminMgt'
import RecruitMgt from '../forms/RecruitMgt'
import SalesMgt from '../forms/SalesMgt'
import ProdMgt from '../forms/ProdMgt'
import OfficeDNFMgt from '../forms/OfficeDNFMgt'
import SafetyMgt from '../forms/SafetyMgt'
import HRMgt from '../forms/HRMgt'
import OpenMgt from '../forms/OpenMgt'
import CloseMgt from '../forms/CloseMgt'
import CloseBldgMgt from '../forms/CloseBldgMgt'
import LockerMgt from '../forms/LockerMgt'
import TrainingMgt from '../forms/TrainingMgt'
import ChisholmInventoryMgt from '../forms/ChisholmInventoryMgt'
import ShopInventoryMgt from '../forms/ShopInventoryMgt'
import LadderMgt from '../forms/LadderMgt'
import LadderShopMgt from '../forms/LadderShopMgt'
import TruckGearMgt from '../forms/TruckGearMgt'
import LockerInventoryMgt from '../forms/LockerInventoryMgt'
import VehicleMgt from '../forms/VehicleMgt'
import EstSignMgt from '../forms/EstSignMgt'
import TestMgt from '../forms/TestMgt'
import FailureMgt from '../forms/FailureMgt'
import UpdateMgt from '../forms/UpdateMgt'

const DailyReport = () => {
  const [{ user, userLoading }] = useStateReducer();
  const [message, setMessage] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [adminMgtButton, setAdminMgtButton] = useState('button is-info');
  const [recruitMgtButton, setRecruitMgtButton] = useState('button is-info');
  const [salesMgtButton, setSalesMgtButton] = useState('button is-info');
  const [prodMgtButton, setProdMgtButton] = useState('button is-info');
  const [offDNFMgtButton, setOffDNFMgtButton] = useState('button is-info');
  const [safetyMgtButton, setSafetyMgtButton] = useState('button is-info');
  const [hrMgtButton, setHrMgtButton] = useState('button is-info');
  const [openMgtButton, setOpenMgtButton] = useState('button is-info');
  const [closeMgtButton, setCloseMgtButton] = useState('button is-info');
  const [closeBldgMgtButton, setCloseBldgMgtButton] = useState('button is-info');
  const [lockerMgtButton, setLockerMgtButton] = useState('button is-info');
  const [trainingMgtButton, setTrainingMgtButton] = useState('button is-info');
  const [chisholmInventoryMgtButton, setChisholmInventoryMgtButton] = useState('button is-info');
  const [shopInventoryMgtButton, setShopInventoryMgtButton] = useState('button is-info');
  const [ladderMgtButton, setLadderMgtButton] = useState('button is-info');
  const [ladderShopMgtButton, setLadderShopMgtButton] = useState('button is-info');
  const [truckGearMgtButton, setTruckGearMgtButton] = useState('button is-info');
  const [lockerInventoryMgtButton, setLockerInventoryMgtButton] = useState('button is-info');
  const [vehicleMgtButton, setVehicleMgtButton] = useState('button is-info');
  const [estSignMgtButton, setEstSignMgtButton] = useState('button is-info');
  const [testMgtButton, setTestMgtButton] = useState('button is-info');
  const [failureMgtButton, setFailureMgtButton] = useState('button is-info');
  const [updateMgtButton, setUpdateMgtButton] = useState('button is-info');

  useEffect(async() => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if(month.toString().length === 1){
      month = '0' + month.toString();
    }
    if(day.toString().length === 1){
      day = '0' + day.toString();
    }
    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`
    console.log('Date: ', currentDate);
    setCurrentDate(currentDate);
  }, [])



  useEffect(() => {
    if(user){
      console.log('User in App', user);
      checkReportIssued('Test Mgt')
      checkReportIssued('Admin Mgt');
      checkReportIssued('Recruit Mgt');
      checkReportIssued('Sales Mgt');
      checkReportIssued('Prod Mgt');
      checkReportIssued('Office DNF Mgt');
      checkReportIssued('Safety Mgt');
       checkReportIssued('HR Mgt');







      if(user.hrid === 'HR00005560' || user.hrid === 'HR00000001'){ // Noel
        checkReportIssued('Training Mgt(East)');
        checkReportIssued('Open Mgt(East)');
        checkReportIssued('Shop Inventory Mgt(East)');
        checkReportIssued('Ladder Shop Mgt(East)');
      }

      if(user.hrid === 'HR00006242'){ // Hannah
        checkReportIssued('Close Bldg Mgt(West)');
      }



      if(user.hrid === 'HR00006221'){ // Ryansh
        checkReportIssued('Open Mgt(West)');
        checkReportIssued('Shop Inventory Mgt(West)');
        checkReportIssued('Ladder Shop Mgt(West)');
      }

      if(user.hrid === 'HR00005884'){ // Nat
        checkReportIssued('Close Mgt(West)');
        checkReportIssued('Locker Mgt(West)');
      }


      if(user.hrid === 'HR00005930' || user.hrid === 'HR00000001'){ // Jack
        checkReportIssued('EstSign Mgt(East)');
        checkReportIssued('Close Mgt(East)');
        checkReportIssued('Close Bldg Mgt(East)');
        checkReportIssued('Locker Mgt(East)');
      }

      if(user.hrid === 'HR00006110'){ //Malik
        checkReportIssued('EstSign Mgt(West)');
      }

      if(user.hrid === 'HR00006229'){ //Juwan
        checkReportIssued('Training Mgt(West)');
      }


      //checkReportIssued('Training Mgt(West)');
      checkReportIssued('Chisholm Inventory Mgt');
      checkReportIssued('Ladder Mgt');
      checkReportIssued('Truck Gear Mgt');
      checkReportIssued('Locker Inventory Mgt');
      checkReportIssued('Vehicle Mgt');
      checkReportIssued('Update Mgt');
      checkReportIssued('Failure Mgt');
    }
  }, [user])


  useEffect(() => {
    if(currentForm){
      setMessage(null);
      resetButtons();
      console.log('Current Form:', currentForm.type.name);
      const form = currentForm.type.name;
      if(form === 'TestMgt'){
        (testMgtButton !== 'button is-danger') && setTestMgtButton('button is-warning');
      }else if(form === 'AdminMgt'){
        (adminMgtButton !== 'button is-danger') && setAdminMgtButton('button is-warning');
      }else if(form === 'RecruitMgt'){
        console.log('Current Admin Mgt Label:', adminMgtButton);
        (recruitMgtButton !== 'button is-danger') && setRecruitMgtButton('button is-warning');
      }else if(form === 'SalesMgt'){
        (salesMgtButton !== 'button is-danger') && setSalesMgtButton('button is-warning');
      }else if(form === 'ProdMgt'){
        (prodMgtButton !== 'button is-danger') && setProdMgtButton('button is-warning');
      }else if(form === 'OfficeDNFMgt'){
        (offDNFMgtButton !== 'button is-danger') && setOffDNFMgtButton('button is-warning');
      }else if(form === 'SafetyMgt'){
        (safetyMgtButton !== 'button is-danger') && setSafetyMgtButton('button is-warning');
      }else if(form === 'HRMgt'){
        (hrMgtButton !== 'button is-danger') && setHrMgtButton('button is-warning');
      }else if(form === 'OpenMgt'){
        (openMgtButton !== 'button is-danger') && setOpenMgtButton('button is-warning');
      }else if(form === 'CloseMgt'){
        (closeMgtButton !== 'button is-danger') && setCloseMgtButton('button is-warning');
      }else if(form === 'CloseBldgMgt'){
        (closeBldgMgtButton !== 'button is-danger') && setCloseBldgMgtButton('button is-warning');
      }else if(form === 'LockerMgt'){
        (lockerMgtButton !== 'button is-danger') && setLockerMgtButton('button is-warning');
      }else if(form === 'LadderShopMgt'){
        (ladderShopMgtButton !== 'button is-danger') && setLadderShopMgtButton('button is-warning');
      }else if(form === 'TrainingMgt'){
        (trainingMgtButton !== 'button is-danger') && setTrainingMgtButton('button is-warning');
      }else if(form === 'ChisholmInventoryMgt'){
        (chisholmInventoryMgtButton !== 'button is-danger') && setChisholmInventoryMgtButton('button is-warning');
      }else if(form === 'ShopInventoryMgt'){
        (shopInventoryMgtButton !== 'button is-danger') && setShopInventoryMgtButton('button is-warning');
      }else if(form === 'LadderMgt'){
        (ladderMgtButton !== 'button is-danger') && setLadderMgtButton('button is-warning');
      }else if(form === 'TruckGearMgt'){
        (truckGearMgtButton !== 'button is-danger') && setTruckGearMgtButton('button is-warning');
      }else if(form === 'LockerInventoryMgt'){
        (lockerInventoryMgtButton !== 'button is-danger') && setLockerInventoryMgtButton('button is-warning');
      }else if(form === 'VehicleMgt'){
        (vehicleMgtButton !== 'button is-danger') && setVehicleMgtButton('button is-warning');
      }else if(form === 'EstSignMgt'){
        (estSignMgtButton !== 'button is-danger') && setEstSignMgtButton('button is-warning');
      }else if(form === 'FailureMgt'){
        (failureMgtButton !== 'button is-danger') && setFailureMgtButton('button is-warning');
      }else if(form === 'UpdateMgt'){
        (updateMgtButton !== 'button is-danger') && setUpdateMgtButton('button is-warning');
      }

    }
  }, [currentForm])


   const resetButtons = () => {
     (adminMgtButton !== 'button is-danger') && setAdminMgtButton('button is-info');
     (recruitMgtButton !== 'button is-danger') && setRecruitMgtButton('button is-info');
     (salesMgtButton !== 'button is-danger') && setSalesMgtButton('button is-info');
     (prodMgtButton !== 'button is-danger') && setProdMgtButton('button is-info');
     (offDNFMgtButton !== 'button is-danger') && setOffDNFMgtButton('button is-info');
     (safetyMgtButton !== 'button is-danger') && setSafetyMgtButton('button is-info');
     (hrMgtButton !== 'button is-danger') && setHrMgtButton('button is-info');
     (openMgtButton !== 'button is-danger') && setOpenMgtButton('button is-info');
     (closeMgtButton !== 'button is-danger') && setCloseMgtButton('button is-info');
     (closeBldgMgtButton !== 'button is-danger') && setCloseBldgMgtButton('button is-info');
     (lockerMgtButton !== 'button is-danger') && setLockerMgtButton('button is-info');
     (trainingMgtButton !== 'button is-danger') && setTrainingMgtButton('button is-info');
     (ladderShopMgtButton !== 'button is-danger') && setLadderShopMgtButton('button is-info');
     (chisholmInventoryMgtButton !== 'button is-danger') && setChisholmInventoryMgtButton('button is-info');
     (shopInventoryMgtButton !== 'button is-danger') && setShopInventoryMgtButton('button is-info');
     (ladderMgtButton !== 'button is-danger') && setLadderMgtButton('button is-info');
     (truckGearMgtButton !== 'button is-danger') && setTruckGearMgtButton('button is-info');
     (lockerInventoryMgtButton !== 'button is-danger') && setLockerInventoryMgtButton('button is-info');
     (vehicleMgtButton !== 'button is-danger') && setVehicleMgtButton('button is-info');
     (estSignMgtButton !== 'button is-danger') && setEstSignMgtButton('button is-info');
     (testMgtButton !== 'button is-danger') && setTestMgtButton('button is-info');
     (failureMgtButton !== 'button is-danger') && setFailureMgtButton('button is-info');
     (updateMgtButton !== 'button is-danger') && setUpdateMgtButton('button is-info');
  }

  const showMessage = () => <div className='has-text-centered has-text-weight-bold is-size-2'>{message}<br/><br/></div>

  const handleDailyReport = async (data) => {
    console.log("DATA", data);
    setShowLoader(true);
    setShowLoader(true);
    const result = await ApiClient.post(`/api/daily_reports`, data);
    setShowLoader(false);
    window.scrollTo(0, 0);
    if(data.area === 'Admin Mgt'){
      setAdminMgtButton('button is-danger');
    }else if(data.area === 'Recruit Mgt'){
      setRecruitMgtButton('button is-danger');
    }else if(data.area === 'Sales Mgt'){
      setSalesMgtButton('button is-danger');
    }else if(data.area === 'Prod Mgt'){
      setProdMgtButton('button is-danger');
    }else if(data.area === 'Office DNF Mgt'){
      setOffDNFMgtButton('button is-danger');
    }else if(data.area === 'Safety Mgt'){
      setSafetyMgtButton('button is-danger');
    }else if(data.area === 'HR Mgt'){
      setHrMgtButton('button is-danger');
    }else if(data.area === 'Open Mgt'){
      setOpenMgtButton('button is-danger');
    }else if(data.area === 'Close Mgt'){
      setCloseMgtButton('button is-danger');
    }else if(data.area === 'Close Bldg Mgt'){
      setCloseBldgMgtButton('button is-danger');
    }else if(data.area === 'Locker Mgt'){
      setLockerMgtButton('button is-danger');
    }else if(data.area === 'Training Mgt(East)'){
      setTrainingMgtButton('button is-danger');
    }else if(data.area === 'Training Mgt(West)'){
      setTrainingMgtButton('button is-danger');
    }else if(data.area === 'Ladder Shop Mgt'){
      setLadderShopMgtButton('button is-danger');
    }else if(data.area === 'Chisholm Inventory Mgt'){
      setChisholmInventoryMgtButton('button is-danger');
    }else if(data.area === 'Shop Inventory Mgt'){
      setShopInventoryMgtButton('button is-danger');
    }else if(data.area === 'Ladder Mgt'){
      setLadderMgtButton('button is-danger');
    }else if(data.area === 'Truck Gear Mgt'){
      setTruckGearMgtButton('button is-danger');
    }else if(data.area === 'Locker Inventory Mgt'){
      setLockerInventoryMgtButton('button is-danger');
    }else if(data.area === 'Vehicle Mgt'){
      setVehicleMgtButton('button is-danger');
    }else if(data.area === 'EstSign Mgt'){
      setEstSignMgtButton('button is-danger');
    }else if(data.area === 'Test Mgt'){
      setTestMgtButton('button is-danger');
    }else if(data.area === 'Failure Mgt'){
      setFailureMgtButton('button is-danger');
    }else if(data.area === 'Update Mgt'){
      setUpdateMgtButton('button is-danger');
    }
    setMessage(result.msg==='success' ? "Form Emailed Successfully" : null);
    setShowLoader(false);
  }

  const checkReportIssued = async(area) => {
    console.log('AREA *** ', area);
    const params = new URLSearchParams({
      area, hrid: user.hrid, date: currentDate
    }).toString();
    const response = await ApiClient.request(`/api/daily_reports/?${params}`); // FALL appt_limits
    if(area === 'Test Mgt'){
      if(response.needs_report){
        setTestMgtButton('button is-info');
      }else{
        setTestMgtButton('button is-danger');
      }
    }
    if(area === 'Admin Mgt'){
      if(response.needs_report){
        setAdminMgtButton('button is-info');
      }else{
        setAdminMgtButton('button is-danger');
      }
    }
    if(area === 'Recruit Mgt'){
      if(response.needs_report){
        setRecruitMgtButton('button is-info');
      }else{
        setRecruitMgtButton('button is-danger');
      }
    }
    if(area === 'Sales Mgt'){
      if(response.needs_report){
        setSalesMgtButton('button is-info');
      }else{
        setSalesMgtButton('button is-danger');
      }
    }
    if(area === 'Prod Mgt'){
      if(response.needs_report){
        setProdMgtButton('button is-info');
      }else{
        setProdMgtButton('button is-danger');
      }
    }
    if(area === 'Office DNF Mgt'){
      if(response.needs_report){
        setOffDNFMgtButton('button is-info');
      }else{
        setOffDNFMgtButton('button is-danger');
      }
    }
    if(area === 'Safety Mgt'){
      if(response.needs_report){
        setSafetyMgtButton('button is-info');
      }else{
        setSafetyMgtButton('button is-danger');
      }
    }
    if(area === 'HR Mgt'){
      if(response.needs_report){
        setHrMgtButton('button is-info');
      }else{
        setHrMgtButton('button is-danger');
      }
    }
    if(area === 'Open Mgt(East)' || area === 'Open Mgt(West)'){
      if(area === 'Open Mgt(East)'){
        if(response.needs_report){
          setOpenMgtButton('button is-info');
        }else{
          setOpenMgtButton('button is-danger');
        }
      }else if(area === 'Open Mgt(West)'){
        if(response.needs_report){
          setOpenMgtButton('button is-info');
        }else{
          setOpenMgtButton('button is-danger');
        }
      }
    }
    if(area === 'Close Mgt(East)' || area === 'Close Mgt(West)'){
      if(area === 'Close Mgt(East)'){
        if(response.needs_report){
          setCloseMgtButton('button is-info');
        }else{
          setCloseMgtButton('button is-danger');
        }
      }else if(area === 'Close Mgt(West)'){
        if(response.needs_report){
          setCloseMgtButton('button is-info');
        }else{
          setCloseMgtButton('button is-danger');
        }
      }
    }
    if(area === 'Close Bldg Mgt(East)' || area === 'Close Bldg Mgt(West)'){
      if(area === 'Close Bldg Mgt(East)'){
        if(response.needs_report){
          setCloseBldgMgtButton('button is-info');
        }else{
          setCloseBldgMgtButton('button is-danger');
        }
      }else if(area === 'Close Bldg Mgt(West)'){
        if(response.needs_report){
          setCloseBldgMgtButton('button is-info');
        }else{
          setCloseBldgMgtButton('button is-danger');
        }
      }
    }
    if(area === 'Locker Mgt(East)' || area === 'Locker Mgt(West)'){
      if(area === 'Locker Mgt(East)'){
        if(response.needs_report){
          setLockerMgtButton('button is-info');
        }else{
          setLockerMgtButton('button is-danger');
        }
      }else if(area === 'Locker Mgt(West)'){
        if(response.needs_report){
          setLockerMgtButton('button is-info');
        }else{
          setLockerMgtButton('button is-danger');
        }
      }
    }
    if(area === 'Training Mgt(East)' || area === 'Training Mgt(West)'){
      if(area === 'Training Mgt(East)'){
        if(response.needs_report){
          setTrainingMgtButton('button is-info');
        }else{
          setTrainingMgtButton('button is-danger');
        }
      }else if(area === 'Training Mgt(West)'){
        if(response.needs_report){
          setTrainingMgtButton('button is-info');
        }else{
          setTrainingMgtButton('button is-danger');
        }
      }
    }

    if(area === 'Shop Inventory Mgt(East)' || area === 'Shop Inventory Mgt(West)'){
      if(area === 'Shop Inventory Mgt(East)'){
        if(response.needs_report){
          setShopInventoryMgtButton('button is-info');
        }else{
          setShopInventoryMgtButton('button is-danger');
        }
      }else if(area === 'Shop Inventory Mgt(West)'){
        if(response.needs_report){
          setShopInventoryMgtButton('button is-info');
        }else{
          setShopInventoryMgtButton('button is-danger');
        }
      }
    }
    if(area === 'Ladder Shop Mgt(East)' || area === 'Ladder Shop Mgt(West)'){
      if(area === 'Ladder Shop Mgt(East)'){
        if(response.needs_report){
          setLadderShopMgtButton('button is-info');
        }else{
          setLadderShopMgtButton('button is-danger');
        }
      }else if(area === 'Ladder Shop Mgt(West)'){
        if(response.needs_report){
          setLadderShopMgtButton('button is-info');
        }else{
          setLadderShopMgtButton('button is-danger');
        }
      }
    }

    if(area === 'Vehicle Mgt(East)' || area === 'Vehicle Mgt(West)'){
      if(area === 'Vehicle Mgt(East)'){
        if(response.needs_report){
          setVehicleMgtButton('button is-info');
        }else{
          setVehicleMgtButton('button is-danger');
        }
      }else if(area === 'Vehicle Mgt(West)'){
        if(response.needs_report){
          setVehicleMgtButton('button is-info');
        }else{
          setVehicleMgtButton('button is-danger');
        }
      }

    }

    if(area === 'Est Sign Mgt(East)' || area === 'Est Sign Mgt(West)'){
      if(area === 'Est Sign Mgt(East)'){
        if(response.needs_report){
          setEstSignMgtButton('button is-info');
        }else{
          setEstSignMgtButton('button is-danger');
        }
      }else if(area === 'Est Sign Mgt(West)'){
        if(response.needs_report){
          setEstSignMgtButton('button is-info');
        }else{
          setEstSignMgtButton('button is-danger');
        }
      }
    }


    if(area === 'Chisholm Inventory Mgt'){
      if(response.needs_report){
        setChisholmInventoryMgtButton('button is-info');
      }else{
        setChisholmInventoryMgtButton('button is-danger');
      }
    }
    if(area === 'Ladder Mgt'){
      if(response.needs_report){
        setLadderMgtButton('button is-info');
      }else{
        setLadderMgtButton('button is-danger');
      }
    }
    if(area === 'Truck Gear Mgt'){
      if(response.needs_report){
        setTruckGearMgtButton('button is-info');
      }else{
        setTruckGearMgtButton('button is-danger');
      }
    }
    if(area === 'Locker Inventory Mgt'){
      if(response.needs_report){
        setLockerInventoryMgtButton('button is-info');
      }else{
        setLockerInventoryMgtButton('button is-danger');
      }
    }
    if(area === 'Update Mgt'){
      if(response.needs_report){
        setUpdateMgtButton('button is-info');
      }else{
        setUpdateMgtButton('button is-danger');
      }
    }
    if(area === 'Failure Mgt'){
      if(response.needs_report){
        setFailureMgtButton('button is-info');
      }else{
        setFailureMgtButton('button is-danger');
      }
    }
  }

  const fireDateChanged = (newDate) => {
    console.log('New Date', newDate);
    if(newDate !== currentDate){
      setCurrentDate(newDate);
    }
  }


  const showButtons = () => {
    console.log('USER showButtons', user);
    console.log('Check Report', checkReportIssued('Admin Mgt'));
    if(user && user.hrid == 'HR00000001'){ //Roger
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ testMgtButton } onClick={ () => setCurrentForm(<TestMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Test Mgt</button>
        </p>
        <p className="control">
          <button className={ adminMgtButton } onClick={ () => setCurrentForm(<AdminMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Admin Mgts</button>
        </p>
        <p className="control">
          <button className={ recruitMgtButton } onClick={ () => setCurrentForm(<RecruitMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Recruit Mgt</button>
        </p>
        <p className="control">
          <button className={ salesMgtButton } onClick={ () => setCurrentForm(<SalesMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Sales Mgt</button>
        </p>
        <p className="control">
          <button className={ prodMgtButton } onClick={ () => setCurrentForm(<ProdMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Prod Mgt</button>
        </p>
        <p className="control">
          <button className={ offDNFMgtButton } onClick={ () => setCurrentForm(<OfficeDNFMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Office DNF Mgt</button>
        </p>
        <p className="control">
          <button className={ safetyMgtButton } onClick={ () => setCurrentForm(<SafetyMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Safety Mgt</button>
        </p>
        <p className="control">
          <button className={ openMgtButton } onClick={ () => setCurrentForm(<OpenMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Open Mgt(East)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ closeMgtButton } onClick={ () => setCurrentForm(<CloseMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Close Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ closeBldgMgtButton } onClick={ () => setCurrentForm(<CloseBldgMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Close Bldg Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ lockerMgtButton } onClick={ () => setCurrentForm(<LockerMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Locker Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ trainingMgtButton } onClick={ () => setCurrentForm(<TrainingMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Training Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ chisholmInventoryMgtButton } onClick={ () => setCurrentForm(<ChisholmInventoryMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Chisholm Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ shopInventoryMgtButton } onClick={ () => setCurrentForm(<ShopInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Shop Inventory Mgt(East)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ ladderShopMgtButton } onClick={ () => setCurrentForm(<LadderShopMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Ladder Shop Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ ladderMgtButton } onClick={ () => setCurrentForm(<LadderMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Ladder Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ truckGearMgtButton } onClick={ () => setCurrentForm(<TruckGearMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Truck Gear Mgt</button>
        </p>
        <p className="control">
          <button className={ lockerInventoryMgtButton } onClick={ () => setCurrentForm(<LockerInventoryMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Locker Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ vehicleMgtButton } onClick={ () => setCurrentForm(<VehicleMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Vehicle Mgt</button>
        </p>
        <p className="control">
          <button className={ estSignMgtButton } onClick={ () => setCurrentForm(<EstSignMgt area={'East'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>EstSign Mgt</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
    </div>
  } else if(user && user.hrid == 'HR00005884'){ //Nat
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ prodMgtButton } onClick={ () => setCurrentForm(<ProdMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Prod Mgt</button>
        </p>
        <p className="control">
          <button className={ offDNFMgtButton } onClick={ () => setCurrentForm(<OfficeDNFMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Office DNF Mgt</button>
        </p>
        <p className="control">
          <button className={ hrMgtButton } onClick={ () => setCurrentForm(<HRMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>HR Mgt</button>
        </p>
        <p className="control">
          <button className={ safetyMgtButton } onClick={ () => setCurrentForm(<SafetyMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Safety Mgt</button>
        </p>
        <p className="control">
          <button className={ closeMgtButton } onClick={ () => setCurrentForm(<CloseMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Close Mgt(West)</button>
        </p>
        <p className="control">
          <button className={ lockerMgtButton } onClick={ () => setCurrentForm(<LockerMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Locker Mgt(West)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    } else if(user && user.hrid == 'HR00006242'){ // Hannah
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ adminMgtButton } onClick={ () => setCurrentForm(<AdminMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Admin Mgt</button>
        </p>
        <p className="control">
          <button className={ recruitMgtButton } onClick={ () => setCurrentForm(<RecruitMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Recruit Mgt</button>
        </p>
        <p className="control">
          <button className={ salesMgtButton } onClick={ () => setCurrentForm(<SalesMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Sales Mgt</button>
        </p>
        <p className="control">
          <button className={ closeBldgMgtButton } onClick={ () => setCurrentForm(<CloseBldgMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Close Bldg Mgt(East)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    } else if(user && user.hrid == 'HR00000429'){ // Shantz
      return <div>
      <div className="field is-grouped">
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    } else if(user && user.hrid == 'HR00005560'){ // Noel
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ openMgtButton } onClick={ () => setCurrentForm(<OpenMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Open Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ trainingMgtButton } onClick={ () => setCurrentForm(<TrainingMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Training Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ shopInventoryMgtButton } onClick={ () => setCurrentForm(<ShopInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Shop Inventory Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ ladderShopMgtButton } onClick={ () => setCurrentForm(<LadderShopMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Ladder Shop Mgt(East)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    } else if(user && user.hrid == 'HR00006229'){ // Juwan
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ trainingMgtButton } onClick={ () => setCurrentForm(<TrainingMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Training Mgt(West)</button>
        </p>
        <p className="control">
          <button className={ chisholmInventoryMgtButton } onClick={ () => setCurrentForm(<ChisholmInventoryMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Chisholm Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ ladderMgtButton } onClick={ () => setCurrentForm(<LadderMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Ladder Mgt</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    } else if(user && user.hrid == 'HR00006110'){ // Malik
      return <div>
        <div className="field is-grouped">
          <p className="control">
            <button className={ estSignMgtButton } onClick={ () => setCurrentForm(<EstSignMgt area={'West'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>EstSign Mgt(West)</button>
          </p>
          <p className="control">
            <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
          </p>
          <p className="control">
            <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
          </p>
        </div>
      </div>
    }else if(user && user.hrid == 'HR00006221'){ // Ryansh
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ openMgtButton } onClick={ () => setCurrentForm(<OpenMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Open Mgt(West)</button>
        </p>
        <p className="control">
          <button className={ shopInventoryMgtButton } onClick={ () => setCurrentForm(<ShopInventoryMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Shop Inventory Mgt(West)</button>
        </p>
        <p className="control">
          <button className={ ladderShopMgtButton } onClick={ () => setCurrentForm(<LadderShopMgt area={'(West)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Ladder Shop Mgt(West)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    } else if(user && user.hrid == 'HR00005930'){ // Jack
      return <div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ closeMgtButton } onClick={ () => setCurrentForm(<CloseMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Close Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ closeBldgMgtButton } onClick={ () => setCurrentForm(<CloseBldgMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Close Bldg Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ chisholmInventoryMgtButton } onClick={ () => setCurrentForm(<ChisholmInventoryMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Chisholm Inventory Mgt</button>
        </p>
        <p className="control">
          <button className={ vehicleMgtButton } onClick={ () => setCurrentForm(<VehicleMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Vehicle Mgt</button>
        </p>
        <p className="control">
          <button className={ lockerMgtButton } onClick={ () => setCurrentForm(<LockerMgt area={'(East)'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Locker Mgt(East)</button>
        </p>
      </div>
      <div className="field is-grouped">
        <p className="control">
          <button className={ estSignMgtButton } onClick={ () => setCurrentForm(<EstSignMgt area={'East'} handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>EstSign Mgt(East)</button>
        </p>
        <p className="control">
          <button className={ updateMgtButton } onClick={ () => setCurrentForm(<UpdateMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Update Mgt</button>
        </p>
        <p className="control">
          <button className={ failureMgtButton } onClick={ () => setCurrentForm(<FailureMgt handleDailyReport={handleDailyReport} user={user} checkReportIssued={checkReportIssued} fireDateChanged={fireDateChanged} currentDate={currentDate} />) }>Failure Mgt</button>
        </p>
      </div>
      </div>
    }
  }


  return (
    <Layout>
      <section className='section'>
        <div className='container'>
        { showLoader && <Loader /> }
        { message && showMessage() }
        { user && showButtons() }
        <hr/>
        <br/>

        { currentForm ? currentForm : '' }

        </div>
      </section>
    </Layout>
  )

};

export default DailyReport;
