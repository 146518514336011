import React from 'react'

const SelectOptionPrompt = ({ boxStyle }) => {
  return (
    <div style={boxStyle} className='box has-background-light'>
      <h2 className='title is-4 has-text-centered'>Select a dashboard option</h2>
    </div>
  )
}

export default SelectOptionPrompt
