//const BASE_PATH = `http://${window.location.hostname}:3020`;

export default class ApiClient {
  static async request(url, options = {}) {
    let mergedOptions = {
      headers: {
        "content-type": "application/json",
      },
      mode: "cors",
      credentials: "include",
      ...options,
    };
    //  const raw = await fetch(`${BASE_PATH}${url}`, mergedOptions);
    const raw = await fetch(url, mergedOptions);
    if (raw) {
      return raw.json();
    }
  }

  static async post(url, body, options = {}) {
    return ApiClient.request(url, {
      body: JSON.stringify(body),
      method: "POST",
      ...options,
    });
  }

  static async patch(url, body, options = {}) {
    return ApiClient.request(url, {
      body: JSON.stringify(body),
      method: "PUT",
      ...options,
    });
  }

  static async delete(url, options = {}) {
    return ApiClient.request(url, {
      method: "DELETE",
      ...options,
    });
  }
}
