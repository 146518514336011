import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCogs, faDiceFour, faAd, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { faAmazon } from '@fortawesome/free-brands-svg-icons'

import useRequest from "../../base/hooks/useRequest";
import useLazyRequest from "../../base/hooks/useLazyRequest";
import ApiClient from "../../base/api/client";

const element = <p className='has-text-link is-size-3'>Test Has Text</p>

const element1 = <FontAwesomeIcon icon={ faMapMarker } />
//const element1 = <FontAwesomeIcon icon={ github} />
//const element1 = <FontAwesomeIcon icon={["fas", "envelope"]} />

const Recruiting = () => {
  const [recruits, setRecruits] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [recruit, setRecruit] = useState(null);
  const [myFilters, setMyFilters] = useState({});
  const [name, setName] = useState('');
  const [training, setTraining] = useState('All');
  const [status, setStatus] = useState('All');
  const [shop, setShop] = useState('All');
  const [driving, setDriving] = useState('All');
  const [grade, setGrade] = useState('All');
  const [textButton, setTextButton] = useState('button is-warning');
  const [{ user, userLoading }] = useStateReducer();
  const [recruitPics, setRecruitPics] = useState([]);


  const ccForm = useForm();
  const { register, handleSubmit, setValue, getValues } = ccForm;

  const fetchRecruits = async () => {
    if (user) {
       const result = await ApiClient.request(`/api/recruits`);
       console.log(result);
       setRecruits(result.recruits);
       setTrainings(result.trainingOptions);
    }
  }

  const fetchRecruitPics = async () => {
    if (recruit) {
      const recruit_images = await ApiClient.request(`/api/ben/${recruit.id}/recruit_images`);
      console.log('Recruit Pics', recruit_images);
      setRecruitPics(recruit_images);
    }
  }


  useEffect(() => {
    fetchRecruits();
  }, [user])

  useEffect(() => {
    fetchRecruitPics();



  //   {recruit &&
  //   <div>
  //   <u>
  //     <a className="navbar-item is-fullwidth" href={`/recruit_images?recruit_id=${recruit.id}&source=react_recruit&delete_source=react_recruit`}>
  //       Add Recruit Image
  //     </a>
  //   </u>
  //   </div>
  // }
  //
  //
  // { recruit && recruitPics && showRecruitPics() }


  }, [recruit])


  const editForm = async (data) => {
      var updatedData = { ...data, hrid: user.hrid };
      const result = await ApiClient.patch(
        `/api/recruits/${data.id}`, updatedData);
      setValue("shop", "");
      setValue("status", "");
      setValue("drive", "");
      setRecruit(null);
      setShowForm(false);
      fetchRecruits();
  };


  const editRecruit = (recruit) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setRecruit(recruit);
    setShowForm(true);
  }

  const filterRecruits = () => {
    console.log('Filters dot name', name);
    console.log('Recruits length:', recruits.length);
    let result = recruits.filter( recruit => {
      //console.log('Filters dot name', recruit.name.includes(myFilters.name));
      let ans = true;
      if(name!==''){
        if(!recruit.name.includes(name)){
          ans = false;
        }
      }
      console.log('Name', ans);

      if(training!=='All'){
        if(recruit.training===null || recruit.training==='' || !recruit.training.includes(training)){
          ans = false;
        }
      }

      if(shop!=='All'){
        if(recruit.shop !== shop){
          ans = false;
        }
      }

      if(status!=='All'){
        if(recruit.status !== status){
          ans = false;
        }
      }

      if(driving!=='All'){
        if(driving === 'Valid G/G2 License'){
          if(recruit.drive!=='Valid G License' && recruit.drive!=='Valid G2 License'){
            ans = false;
          }
        }else{
          if(recruit.drive===null || recruit.drive !== driving){
            ans = false;
          }
        }
      }

      if(grade!=='All'){
        if(recruit.notes5 !== grade){
          ans = false;
        }
      }
      return ans;
    })
    console.log('Result', result);
    return result;
  }


  const editFilters = e => {
    if(e.target.name == 'name'){
      setName(e.target.value);
    }else if(e.target.name == 'training'){
      setTraining(e.target.value);
    }else if(e.target.name == 'shop'){
      setShop(e.target.value);
    }else if(e.target.name == 'status'){
      setStatus(e.target.value);
    }else if(e.target.name == 'driving'){
      setDriving(e.target.value);
    }else if(e.target.name == 'grade'){
      setGrade(e.target.value);
    }
  }

  const sendReminderText = async id => {
    setTextButton('button is-warning is-loading');
    const result = await ApiClient.request(`/api/recruits/${id}/send_text`);
    fetchRecruits();
    setTextButton('button is-warning');
  }

  const testText = async () => {
    setTextButton('button is-warning is-loading');
    const result = await ApiClient.request(`/api/recruits/${'test'}/send_text`);
    fetchRecruits();
    setTextButton('button is-warning');
  }


  const showRecruits = () => {
    return filterRecruits().map(recruit =>
    <div className='column is-one-quarter' key={recruit.id}>
      <div className={ recruit.status==='Dead' ? "card has-background-danger" : "card" }>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="subtitle is-4">{recruit.name} {recruit.name1}</p>
              <p className="subtitle is-6">{recruit.shop}</p>
              <p className="subtitle is-6">{recruit.drive ? recruit.drive : 'No License'}</p>
              <p className="subtitle is-6">{recruit.phone}</p>
              <p className="subtitle is-6">{recruit.email}</p>
              <p className="subtitle is-6 has-text-weight-bold">Status: {recruit.status}</p>
              <p className="subtitle is-6 has-text-weight-bold">Training: {recruit.training}</p>
              <p>{recruit.notes3 ? recruit.notes3 : <button className={ textButton} onClick={ () => sendReminderText(recruit.id)}>Send Reminder Text</button>}</p><br/>
              <p className="subtitle is-6 has-text-weight-bold">Grade: {recruit.notes5 ? recruit.notes5 : 'n/a'}</p>
              <button type='button' className='button is-link' onClick={() => editRecruit(recruit)} >Edit Recruit Data</button>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }



  const showRecruitPics = () => {
    console.log("In Recruit Pics");
    const recruit_pic_images = recruitPics.map(pic => (
      <div className='column is-one-third' key={pic.created_at}>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Recruit Image" height='600' width="600"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time dateTime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div className='columns is-multiline'>
        { recruit_pic_images }
      </div>
    )
  }


  const recruitForm = recruit => {
    console.log('RECRUIT:', recruit);


    return(
    <div className='container has-background-primary'>
    <form className='form' onSubmit={handleSubmit(editForm)}>
      <div className='column'>
        <div className='field'>
          <div className='control'>
            <h1 className="subtitle is-3">Editing {recruit.name} {recruit.name1}...</h1>
            <hr/>
          </div>
        </div>
      </div>
      <input
        type='hidden'
        name='id'
        value={ recruit.id }
        ref={ register }
      />


        {recruit &&
        <div>
        <u>
          <a className="navbar-item is-fullwidth" href={`/recruit_images?recruit_id=${recruit.id}&source=react_recruit&delete_source=react_recruit`}>
            Add Recruit Image
          </a>
        </u>
        </div>
      }


      { recruit && recruitPics && showRecruitPics() }



      <div className='column'>
        <div className='field'>
          <div className='control'>
          <label className='label' htmlFor='shop'>Shop</label>
          <select className='select' name='shop' id='shop' ref={register} defaultValue={recruit.shop}>
            <option value="Central(Morse/Osler)">Central(Morse/Osler)</option>
            <option value="East(Carlaw Ave/Lakeshore Blvd)">East(Carlaw Ave/Lakeshore Blvd)</option>
            <option value="West(Dundas St West/Bloor St West)">West(Dundas St West/Bloor St West)</option>
          </select>
          </div>
        </div>
      </div>


      <div className='column'>
        <div className='field'>
          <div className='control'>
          <label className='label' htmlFor='status'>Status</label>
          <select className='select' name='status' id='status' ref={register}  defaultValue={recruit.status}>
            <option value="Training">Training</option>
            <option value="Scheduled Field">Scheduled Field</option>
            <option value="Field">Field</option>
            <option value="Dead">Dead</option>
          </select>
          </div>
        </div>
      </div>

      <div className='column'>
        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='driving'>Driving Status</label>
            <select className='select' name='driving' id='driving' ref={register}  defaultValue={recruit.drive ? recruit.drive : 'No License'}>
              <option value='No License'>No License</option>
              <option value='Valid G1 License'>Valid G1 License</option>
              <option value='Valid G2 License'>Valid G2 License</option>
              <option value='Valid G License'>Valid G License</option>
            </select>
          </div>
        </div>
      </div>

      <div className='column'>
        <div className='field'>
          <div className='control'>
          <label className='label' htmlFor='grade'>Grade</label>
          <select className='select' name='grade' id='grade' ref={register}  defaultValue={recruit.notes5 ? recruit.notes5 : 'n/a'}>
            <option value="n/a">n/a</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
          </div>
        </div>
      </div>

      <div className='column'>
        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='notes'>Notes</label>
            <textarea className='textarea' name='notes' id='notes' ref={register}  defaultValue={recruit.notes4} />
          </div>
        </div>
      </div>

      <div className='column'>
        <div className='field'>
          <div className='control'>
          <div>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Edit Recruit Data</button>
          </div>
          </div>
        </div>
      </div>

    </form><br/><br/>
    </div>

  )}



  const renderFilters = () => (
    <div>
    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <div className="field has-addons">
            <p className="control">
              <select className='select' onChange = { editFilters } name='training'>
                <option value='All'>Training:All</option>
                { trainings.map( training => <option key={training} value={training}>Status:{training}</option>)}
              </select>
            </p>
          </div>
        </div>
        <div className="level-item">
          <div className="field has-addons">
            <p className="control">
              <input className="input" onChange = { editFilters } name='name' type="text" placeholder="Name" />
            </p>
          </div>
        </div>
        <div className="level-item">
          <div className="field has-addons">
            <p className="control">
              <select className='select' onChange = { editFilters } name='status'>
                <option value='All'>Status:All</option>
                <option value='Training'>Training</option>
                <option value='Scheduled Field'>Scheduled Field</option>
                <option value='Field'>Field</option>
                <option value='Dead'>Dead</option>
              </select>
            </p>
          </div>
        </div>
        <div className="level-item">
          <div className="field has-addons">
          <p className="control">
            <select className='select' onChange = { editFilters } name='shop'>
              <option value='All'>Shop:All</option>
              <option value='Central(Morse/Osler)'>Central(Morse/Osler)</option>
              <option value='East(Carlaw Ave/Lakeshore Blvd)'>East(Carlaw Ave/Lakeshore Blvd)</option>
              <option value='West(Dundas St West/Bloor St West)'>West(Dundas St West/Bloor St West)</option>
            </select>
          </p>
          </div>
        </div>
        <div className="level-item">
          <div className="field has-addons">
            <p className="control">
              <select className='select' onChange = { editFilters } name='driving'>
                <option value='All'>License:All</option>
                <option value='No License'>No License</option>
                <option value='Valid G1 License'>Valid G1 License</option>
                <option value='Valid G2 License'>Valid G2 License</option>
                <option value='Valid G License'>Valid G License</option>
                <option value='Valid G/G2 License'>Valid G/G2 License</option>
              </select>
            </p>
          </div>
        </div>
        <div className="level-item">
          <div className="field has-addons">
          <p className="control">
            <select className='select' onChange = { editFilters } name='grade'>
              <option value='All'>Grade:All</option>
              <option value='A'>A</option>
              <option value='B'>B</option>
              <option value='C'>C</option>
            </select>
          </p>
          </div>
        </div>
      </div>
      <br/>
    </nav>
    <button type='button' className={ textButton } onClick={() => testText()} >Test Text</button>
    <br/><br/>
    <h1 className="subtitle">Number: {filterRecruits().length}</h1>
  </div>
  )

  return (
    <Layout>
      <div className='section'>
        <div className="container">
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li className='is-active'><Link to="/recruiting">Recruiting</Link></li>
          </ul>
        </nav>

        <h2 className='subtitle is-1 has-text-centered has-text-weight-bold has-text-link'>Recruits</h2>
          { renderFilters() }
          <br/>

          { showForm && recruit && recruitForm(recruit) }

          <div className = 'columns is-multiline'>
          { showRecruits() }
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default Recruiting;
