import React, { useReducer, useState, useCallback, useEffect } from "react";
import ActionType from "../base/context/actionType";
import LoginAPI from "../base/api/login";
import AppRouter from "./routers/AppRouter";
import { stateReducer, initialState } from "../base/context/stateReducer";
import { AuthContext } from "../base/context/authContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

function App({ mgr_hrid, mgr_name, mgr_royalty, mgr_royalty1, mgr_royalty2, crew_name, crew_hrid, crew_master }) {

  console.log('INFO', mgr_hrid, mgr_name, mgr_royalty, mgr_royalty1, mgr_royalty2, crew_name);

  const stateTuple = useReducer(stateReducer, initialState);
  const [{ userLoading }, dispatch] = stateTuple;
  const [user, setUser] = useState(null);

  const fetchUser = useCallback(async () => {
    // check if signed in(run loader while waiting)
    // if yes, dispatch object that sets the user
    // check request finished/error(show message)
    // if (userLoading) return;
    const user  = await LoginAPI.verifySession();
    setUser(user);
    dispatch({
      type: ActionType.SetUser,
      payload: user,
    });

    const crew = {
      crew_name,
      crew_hrid,
      crew_master
    }

    dispatch({
      type: ActionType.SetCrew,
      payload: crew,
    });

    let mgr = {
      hrid: mgr_hrid,
      name: mgr_name,
      royalty: mgr_royalty,
      royalty1: mgr_royalty1,
      royalty2: mgr_royalty2
    }

    console.log('MGR 1', mgr);

    const mgr_session  = await LoginAPI.verifyMgr();

    if(mgr_session){
      mgr = mgr_session;
    }

    console.log('MGR 2', mgr);


    if(mgr){
      dispatch({
        type: ActionType.SetMgr,
        payload: mgr,
      });
    }

  }, [dispatch, userLoading]);

  useEffect(() => {
    if(!user){
      fetchUser();
    }
  }, [fetchUser]);

  return (
    <AuthContext.Provider value={stateTuple}>
      <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
