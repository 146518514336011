import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import LoginAPI from "../api/login";
import { useStateReducer } from "../context/authContext";
import ActionType from "../context/actionType";


const HeaderLink = ({ children, to, logOut, className, activeClassName }) => {
  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={to}
      onClick={logOut}
      exact
    >
      {children}
    </NavLink>
  );
};

const Header = () => {
  const [, dispatch] = useStateReducer();
  const [menuLaunched, setMenuLaunched] = useState("menu-links-container");
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuClass, setMenuClass] = useState("menu-btn");
  const [{ user, mgr }] = useStateReducer();


  useEffect(() => {
    console.log('User in Prod Mgt', user);
  }, [user])

  const attemptLogout = async () => {
    await LoginAPI.logOut();
    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
  };

  const logOut = () => {
    attemptLogout();
  };

  const launchMenu = () => {
    menuLaunched === "menu-links-container"
      ? setMenuLaunched("menu-links-container menu-links-container--show")
      : setMenuLaunched("menu-links-container");
  };

  const menuBtn = () => {
    if (!menuOpen) {
      setMenuClass("menu-btn menu-btn--open");
      setMenuOpen(true);
      launchMenu();
    } else {
      setMenuClass("menu-btn");
      setMenuOpen(false);
      launchMenu();
    }
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header-logo">White Shark Ltd</div>
        <div className="menu-links-row">
          <HeaderLink
            to="/"
            className="link-item-row"
            activeClassName="link-item-row--selected"
          >
            Home
          </HeaderLink>
          <HeaderLink
            to="/makepackages"
            className="link-item-row"
            activeClassName="link-item-row--selected"
          >
            Make Packages
          </HeaderLink>
          <HeaderLink
            to="/crewsetup"
            className="link-item-row"
            activeClassName="link-item-row--selected"
          >
            Crew Set Up
          </HeaderLink>
          <HeaderLink
            to="/crewpay"
            className="link-item-row"
            activeClassName="link-item-row--selected"
          >
            Crew Pay
          </HeaderLink>

          <React.Fragment>
          <a className="link-item-row" href="/menu_items/crew_menu">
            Crew Menu
          </a>
          </React.Fragment>

          { user.hrid === 'HR00000001' || user.hrid === 'HR00000429' ?
          <HeaderLink
            to="/transactions"
            className="link-item-row"
            activeClassName="link-item-row--selected"
          >
            Prop Mgt
          </HeaderLink> : null }
        </div>

        <div className={menuClass} onClick={menuBtn}>
          <div className="menu-btn__burger"></div>
        </div>
      </div>

      <div className={menuLaunched}>
        <div className="menu-links">
          <HeaderLink
            to="/"
            className="link-item"
            activeClassName="link-item--selected"
          >
            Home
          </HeaderLink>
          <HeaderLink
            to="/makepackages"
            className="link-item"
            activeClassName="link-item--selected"
          >
            Make Packages
          </HeaderLink>
          <HeaderLink
            to="/crewsetup"
            className="link-item"
            activeClassName="link-item--selected"
          >
            Crew Set Up
          </HeaderLink>
          <HeaderLink
            to="/crewpay"
            className="link-item"
            activeClassName="link-item--selected"
          >
            Crew Pay
          </HeaderLink>


          <React.Fragment>
          <a className="navbar-item is-fullwidth" href="/menu_items/crew_menu">
            Crew Menu
          </a>
          </React.Fragment>



          { user.hrid === 'HR00000001' || user.hrid === 'HR00000429' ?
          <HeaderLink
            to="/transactions"
            className="link-item  link-item--last"
            activeClassName="link-item--selected"
          >
            Prop Mgt { user.hrid}
          </HeaderLink> : null }
        </div>
      </div>
    </div>
  );
};

export default Header;



// <HeaderLink
//   to="/stufftodo"
//   className="link-item"
//   activeClassName="link-item--selected"
// >
//   STD
// </HeaderLink>
