import React from "react";
import useRequest from "../hooks/useRequest";
import CrewNameOptions from "./CrewNameOptions";
import Loader from "./Loader";
import SelectOptions from "./SelectOptions";
import SelectRangeOptions from "./SelectRangeOptions";

const Filters = ({
  onUpdateParams,
  numberJobs,
  schDate,
  soldBefore,
  onPostCodeChange,
  onAddressChange,
  onPerlyChange,
  view,
  crewName,
}) => {
  const { data, loading } = useRequest(`/api/crews/${schDate}/driver_list`);

  return loading ? (
    <Loader />
  ) : (
    <div className="filters-container">
      <div className="filters">
        <div className="filters--set">
          <input
            type="date"
            className="filters__input"
            value={schDate}
            onChange={onUpdateParams}
            name="schDate"
          />
          <input
            type="date"
            className="filters__input"
            value={soldBefore}
            onChange={onUpdateParams}
            name="soldBefore"
          />
          <CrewNameOptions
            className="filters__input filters__input--crewname"
            onChange={onUpdateParams}
            value={crewName === "" || crewName === null ? "" : crewName}
            options={data}
            name="crewName"
          />
        </div>
        <div className="filters--set">

          <SelectRangeOptions onChange={onUpdateParams} value={view} />

          <input
            className="filters__input filters__input--lp"
            onKeyUp={onPostCodeChange}
            placeholder="Postal Code"
          />
        </div>
        <div className="filters--set">
          <input
            className="filters__input filters__input--lp"
            onKeyUp={onAddressChange}
            placeholder="Address"
          />
          <input
            className="filters__input"
            onKeyUp={onPerlyChange}
            placeholder="Perly"
          />
        </div>
      </div><br/>
      <div className="filters__title">#Jobs: {numberJobs}</div>
    </div>
  );
};

export default Filters;
