import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";

const OpenClose = () => {
  const [crews, setCrews] = useState([])
  const [osler, setOsler] = useState(0)
  const [morse, setMorse] = useState(0)
  const [absentCrews, setAbsentCrews] = useState([])
  const [presentCrews, setPresentCrews] = useState([])
  const [signinCrews, setSigninCrews] = useState([])
  const [{ user, userLoading }] = useStateReducer();

  const absentClass = "button is-small is-danger"
  const presentClass = "button is-small is-link"


  const fetchCrews = async () => {
    if (user) {
       const result = await ApiClient.request(`/api/crews/crew_list`);
       setCrews(result);
       let morsec = 0;
       let oslerc = 0;
       let presentArray = [];
       let absentArray = [];
       let signinArray = [];
       result.forEach((crew) => {
         if(crew.shop === 'MORSE'){
           morsec += 1;
         } else {
           oslerc += 1;
         }

         if(crew.dsignin){
           signinArray = signinArray.concat(crew.driver);
         }
         if(crew.psignin){
           signinArray = signinArray.concat(crew.partner);
         }
         if(crew.partner1){
           if(crew.p1signin){
             signinArray = signinArray.concat(crew.partner1);
           }
         }

         if(crew.d_status){
           presentArray = presentArray.concat(crew.driver);
         }else{
           absentArray = absentArray.concat(crew.driver);
         }
         if(crew.p_status){
           presentArray = presentArray.concat(crew.partner);
         }else{
           absentArray = absentArray.concat(crew.partner);
         }
         if(crew.partner1){
           if(crew.p1_status){
             presentArray = presentArray.concat(crew.partner1);
           }else{
             absentArray = absentArray.concat(crew.partner1);
           }
         }

       })
       setMorse(morsec);
       setOsler(oslerc);
       setAbsentCrews(absentArray);
       setPresentCrews(presentArray);
       setSigninCrews(signinArray);
    }
  }

  useEffect(() => {
    fetchCrews();
  }, [user])

  const changeStatus = async (name) => {
    if(absentCrews.includes(name)){
      setAbsentCrews(absentCrews.filter(crew => crew!==name));
      setPresentCrews(presentCrews.concat(name));
      const result = await ApiClient.request(`/api/crews/${name}/change_status`);
  //    console.log('CRew Result', result);
    } else if(presentCrews.includes(name)){
      setPresentCrews(presentCrews.filter(crew => crew!==name));
      setAbsentCrews(absentCrews.concat(name));
      const result = await ApiClient.request(`/api/crews/${name}/change_status`);
  //    console.log('CRew Result', result);
    }

  }

  const checkStatus = (name) => {
    let label = '';
    if(presentCrews.includes(name)){
      label = 'Present';
      if(signinCrews.includes(name)){
        label += ' +[SI]';
      }
    } else {
      label = 'Absent';
    }
    return label;
  }

  const checkClass = (name) => {
    if(presentCrews.includes(name)){
      return presentClass;
    } else {
      return absentClass;
    }
  }

  const partner1Display = (crew) => {
    if(crew && crew.partner1){
      return (<tr>
        <td>{crew.partner1}</td>
        <td><button className={checkClass(crew.partner1)} onClick={()=>changeStatus(crew.partner1)}>{ checkStatus(crew.partner1) }</button></td>
      </tr>)

    }
  }

  const shopCrews = (shop) => {

    let shopCrews = crews.filter((crew) => crew.shop===shop);
    let result = shopCrews.map((crew , i)=>{
        return (
          <div key={crew.driver}>
            <table className='table has-background-light is-fullwidth'>
              <tbody>
              <tr>
                <td style={{width:'70%'}}>{crew.driver}</td>
                <td className='is-right'><button className={checkClass(crew.driver)} onClick={()=>changeStatus(crew.driver)}>{ checkStatus(crew.driver) }</button></td>
              </tr>
              <tr>
                <td>{crew.partner}</td>
                <td><button className={checkClass(crew.partner)} onClick={()=>changeStatus(crew.partner)}>{ checkStatus(crew.partner) }</button></td>
              </tr>
              { partner1Display(crew) }
              <tr>
                <td colSpan={2}><Link to={`/crew-details/${crew.id}`} className='button is-small is-link is-fullwidth is-rounded has-text-weight-bold'>Crew Details</Link></td>
              </tr>
              </tbody>
            </table>
            <hr/>
          </div>
        )
    })
    return result
  }

  return (
    <Layout>
      <div className='section'>
        <div className="container">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li className='is-active'><Link to="/open-close">Open-Close</Link></li>
            </ul>
          </nav>

          <div className='mt-2 columns'>
            <div className='column mr-3 mb-3 has-background-light'>
              <div className='box'>
                  <h3 className='title is-4'>Osler Crews {osler}</h3>
              { shopCrews('OSLER') }
              </div>
            </div>
           <div className='column mr-3 mb-3 has-background-light'>
             <div className='box'>
                 <h3 className='title is-4'>Morse Crews {morse}</h3>
              { shopCrews('MORSE') }
             </div>
           </div>
          </div>
          <div className="content">
          <h1 className='title'><u>Open List</u></h1>
            <ol type="1">
              <li className='subtitle'>On Time</li>
              <li className='subtitle'>Uniform</li>
              <li className='subtitle'>Vehicle Prepared</li>
              <li className='subtitle'>Look at package</li>
              <li className='subtitle'>Stretch/Jacks</li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OpenClose;
