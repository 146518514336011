import React, { useState, useEffect, useLocation } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { formatCurrencyZero, makeNiceDate } from "../../helpers/helper";


const Receivables = ({ showDisplay }) => {
  const [{ mgr }] = useStateReducer();
  const[jobsRec, setJobsRec] = useState([]);
  const[oddJobsRec, setOddJobsRec] = useState([]);
  const[paintRec, setPaintRec] = useState([]);
  const[totals, setTotals] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  const fetchReceivables = async () => {
    setShowLoader(true);
    const myParams = { hrid: mgr.hrid};
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners/receivable_summary?${qs}`);
    setJobsRec(result.jobs_rec);
    setOddJobsRec(result.odd_jobs_rec);
    setPaintRec(result.paint_rec);
    setShowLoader(false);
  }

  const fetchTotals = () => {
    let totalsTemp = {};
    let jobsRecTotal = 0;
    jobsRec.forEach(job => {jobsRecTotal = jobsRecTotal + parseFloat(job.price)});
    totalsTemp["jobsRecTotal"] = jobsRecTotal;
    let oddJobsRecTotal = 0;
    oddJobsRec.forEach(job => {oddJobsRecTotal = oddJobsRecTotal + parseFloat(job.price)});
    totalsTemp["oddJobsRecTotal"] = oddJobsRecTotal;
    let oddJobsRecNoMatTotal = 0;
    oddJobsRec.forEach(job => {oddJobsRecNoMatTotal = oddJobsRecNoMatTotal + parseFloat(job.price - job.mat_price)});
    totalsTemp["oddJobsRecNoMatTotal"] = oddJobsRecNoMatTotal;

    let paintRecTotal = 0;
    paintRec.forEach(job => {paintRecTotal = paintRecTotal + parseFloat(job.price)});
    totalsTemp["paintRecTotal"] = paintRecTotal;


    setTotals(totalsTemp);
  }

  useEffect(() => {
    if(mgr && mgr.royalty){
      fetchReceivables();
    }
  }, [mgr])

  useEffect(() => {
    fetchTotals();
  }, [jobsRec, oddJobsRec, paintRec])



  const tableJobsRecData = () => {
    const result = jobsRec.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(0) }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.price * mgr.royalty) }</td>
        <td>{ formatCurrencyZero(job.price * (1-mgr.royalty)) }</td>
        <td>{ job.cards ? job.cards.data.length : 'null' }</td>
        <td><Link to={
          {
            pathname: `/partner_client_admin/${job.cfid}`,
            state: {
              jobid: job.jobid
            }
          }
        }>
          <u>Admin</u></Link></td>
      </tr>))
      return result;
  }

  const tableOddJobsRecData = () => {
    const result = oddJobsRec.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero(job.mat_price) }</td>
        <td>{ formatCurrencyZero(job.price - job.mat_price) }</td>
        <td>{ formatCurrencyZero((job.price - job.mat_price) * mgr.royalty) }</td>
        <td>{ formatCurrencyZero((job.price - job.mat_price) * (1-mgr.royalty)) }</td>
      </tr>))
      return result;
  }

  const tablePaintRecData = () => {
    console.log("SIZE:", paintRec.length);
    const result = paintRec.map((job) => (<tr key={job.jobid}>
        <td>{ job.jobid }</td>
        <td>{ job.address }</td>
        <td>{ makeNiceDate(job.datebi) }</td>
        <td>{ job.crew }</td>
        <td>{ job.recstatus }</td>
        <td>{ formatCurrencyZero(job.price) }</td>
        <td>{ formatCurrencyZero((job.price) * mgr.royalty) }</td>
        <td>{ formatCurrencyZero((job.price) * (1-mgr.royalty)) }</td>
      </tr>))
      return result;
  }

  return (
    <div>
      { showLoader ? <Loader /> :
        <Layout>
          <div className="container">
          <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
            <nav className="breadcrumb">
              <ul>
                <li>
                  <Link to="/partner_dashboard">Partner Dashboard</Link>
                </li>
                <li className="is-active">
                  <Link to="/partner_receivables">Receivables</Link>
                </li>
              </ul>
            </nav>
            <hr />
            <div className='box'>
              <h1 className='title has-text-centered'>{ showDisplay }</h1><br/>
              <h1 className='subtitle'>Recble Window Jobs: { jobsRec.length } Receivables: { formatCurrencyZero(totals.jobsRecTotal) } Earnings: { formatCurrencyZero(totals.jobsRecTotal * (1-mgr.royalty)) }</h1>
              <table className='table'>
                <thead>
                  <tr>
                    <td>JobID</td>
                    <td>Address</td>
                    <td>Date</td>
                    <td>Crew</td>
                    <td>Status</td>
                    <td>Price</td>
                    <td>Material</td>
                    <td>Net Price</td>
                    <td>Royalty</td>
                    <td>Earnings</td>
                    <td># Cards</td>
                    <td>Client Admin</td>
                  </tr>
                </thead>
                <tbody>
                  { tableJobsRecData() }
                </tbody>
              </table><br/>

              <h1 className='subtitle'>Recble Odd Jobs: { oddJobsRec.length } Receivables: { formatCurrencyZero(totals.oddJobsRecTotal) } Earnings: { formatCurrencyZero(totals.oddJobsRecNoMatTotal * (1-mgr.royalty)) }</h1>
              <table className='table'>
                <thead>
                  <tr>
                    <td>JobID</td>
                    <td>Address</td>
                    <td>Date</td>
                    <td>Crew</td>
                    <td>Status</td>
                    <td>Price</td>
                    <td>Material</td>
                    <td>Net Price</td>
                    <td>Royalty</td>
                    <td>Earnings</td>
                  </tr>
                </thead>
                <tbody>
                  { tableOddJobsRecData() }
                </tbody>
              </table><br/>

            <h1 className='subtitle'>Recble Paint Jobs: { paintRec.length } Receivables: { formatCurrencyZero(totals.paintRecTotal) } Earnings: { formatCurrencyZero(totals.paintRecTotal * (1-mgr.royalty)) }</h1>
              <table className='table'>
                <thead>
                  <tr>
                    <td>JobID</td>
                    <td>Address</td>
                    <td>Date</td>
                    <td>Crew</td>
                    <td>Status</td>
                    <td>Price</td>
                    <td>Royalty</td>
                    <td>Earnings</td>
                  </tr>
                </thead>
                <tbody>
                  { tablePaintRecData() }
                </tbody>
              </table><br/>
          </div>
        </div>
        </Layout>
      }
    </div>)

}

export default Receivables;
