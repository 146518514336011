import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import { useLocation, useParams, useHistory } from "react-router";
import { useForm } from "react-hook-form";

import ApiClient from "../../../base/api/client";
import { useStateReducer } from "../../../base/context/authContext";



const targets = ['Peanut', 'Office', 'Field']
const actionTypes = ['Sales', 'DNF', 'Price Dispute', 'Need Gear']
const status = ['Unclaimed', 'In Progress', 'Resolved']


const LiveActionForm = () => {
  const [{user}] = useStateReducer();
  const history = useHistory();

  const ccForm = useForm();
  const { register, handleSubmit, setValue, getValues } = ccForm;

  const createActionType = async (data) => {
    console.log('Register', data);
    let newData = {...data, recorder: user.hrid};
    console.log('New Data', newData)
    const result = await ApiClient.post(`/api/live_actions`, newData);
    history.push(`/live_action/${result.msg}`);

  }

  return (
    <div className='container'>

    <form className='form' onSubmit={handleSubmit(createActionType)}>
      <div className='columns multiline'>
        <div className='column is-half'>
          <div className='field'>
            <div className='control'>
              <label className='label' htmlFor='target_label'>Target:</label>
              <div className='select' id='target-label'>
                <select name='target' ref={register} >
                  { targets.map(target=>(<option key={target} value={target}>{target}</option>)) }
                </select>
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <label className='label' htmlFor='actionType_label'>Action Type:</label>
              <div className='select' id='actionType-label'>
                <select name='action_type' ref={register}>
                  { actionTypes.map(actionType=>(<option key={actionType} value={actionType}>{actionType}</option>)) }
                </select>
              </div> :
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <label className='label' htmlFor='description'>Description</label>
              <textarea
                className='textarea'
                rows='3'
                name='description'
                placeholder='Enter Live Action description(optional)...'
                ref={ register }
              />
            </div>
          </div>
        </div>
      </div>

      <button name='start_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold'>Create Live Action</button>
    </form><br/>



    </div>
    );
};

export default LiveActionForm;


//<FontAwesomeIcon className='fa-2x' icon={ faTruckPickup } label='1' />


// style={ style }
// title={newProps.title}
// label={newProps.label}

// {newProps.label}
// <FontAwesomeIcon className='fa-2x' icon={ faLaptopHouse } label='1' />
