import React from "react";

import OPEN from "../assets/open.png";
import { calcPercentage } from "../helpers/helper";

const ShowPayData = ({ payData, dailyProfileDate, openDayProfile }) => {
  return (
    <table className="crewpay-table">
      <thead>
        <tr>
          <th className="crewpay-table__header">Days</th>
          <th className="crewpay-table__header">Amount</th>
          <th className="crewpay-table__header">Labour</th>
          <th className="crewpay-table__header">%</th>
          <th className="crewpay-table__header">No Pay</th>
          <th className="crewpay-table__header">No Audit</th>
          <th className="crewpay-table__header">Open</th>
        </tr>
      </thead>
      <tbody>
        {payData.map((item) => (
          <tr key={item.date}>
            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.date}
            </td>
            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.daily[0].total}
            </td>

            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.labour[0].total_labour}
            </td>
            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {calcPercentage(item.labour[0].total_labour, item.daily[0].total)}
            </td>

            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.no_pays.length}
            </td>
            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.names_unaudited.length}
            </td>
            <td
              className={
                item.date === dailyProfileDate
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              <img
                className="icon--large"
                src={OPEN}
                alt="open"
                onClick={() => openDayProfile(item.date)}
                name="dailyProfileDate"
                value={item.date}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ShowPayData;
