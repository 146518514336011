import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../../helpers/helper";


const RescheduleJobForm = ({ jobid, handleRescheduleJob }) => {
  const [info, setInfo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonState, setButtonState] = useState('hide');
  const rescheduleForm = useForm();
  const { register, handleSubmit, getValues } = rescheduleForm;

  const fetchInfo = async () => {
    setShowLoader(true);
    const info = await ApiClient.request(`/api/jobs/${jobid}/job_info`);
    console.log('Resultsis:', info);
    setInfo(info);
    setShowLoader(false);
  }

  useEffect(() => {
    if(jobid){
      fetchInfo();
    }
  }, [jobid])

  const showInfo = () => {
    return <div >
      <h1 className='subtitle'>{info.client.honorific} {info.client.firstname} {info.client.lastname}</h1>
      <h1 className='subtitle'>
        <a href={`https://www.google.ca/maps/place/${info.property.address} ${info.property.postcode}`} target="_blank">
          {info.property.address} [{info.property.postcode} ]
        </a>
      </h1>
      <h1 className='subtitle'>Perly: {info.property.perly}</h1>
      <hr/>
    </div>
  }

  const checkNotes = () => {
    const { reschedule_notes } = getValues();
    console.log(reschedule_notes);
    if(reschedule_notes.length>0){
      setButtonState('show');
    }else{
      setButtonState('hide');
    }
  }

  return(<div>
      { showLoader ? <Loader /> :
      <div className='container box has-background-info is-white'>
        <h1 className='title has-text-centered'>Reschedule Job</h1>
        { info && showInfo() }

        <form className='form' onSubmit={handleSubmit(handleRescheduleJob)}>
        <input
          type='hidden'
          name='jobid'
          ref={ register }
          defaultValue = { jobid }
        />
          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='reschedule_notes'>Reschedule Notes</label>
              <textarea
                className='textarea'
                rows='2'
                id='reschedule_notes'
                name='reschedule_notes'
                ref={ register }
                placeholder="Reschedule notes..."
                onKeyUp={checkNotes}
              />
            </div>
          </div><br/>
          <div className='has-text-centered'>
            { buttonState === 'show' ?
              <button name='reschedule job' type='submit' className='button is-link is-rounded has-text-weight-bold'>Reschedule Job</button> :
              <button name='reschedule job' type='submit' className='button is-link is-rounded has-text-weight-bold' disabled>Reschedule Job</button>
            }
          </div>
        </form>

      </div>}
    </div>
  )

}

export default RescheduleJobForm;


// <input
//   type='hidden'
//   name='msid'
//   ref={ register }
//   defaultValue = { msid }
// />
