import dayjs from "dayjs";

export const formatCurrency = (amount) => {
  if (!amount || amount === "0.0") return "TBD";
  return "$" + parseFloat(amount).toFixed(2);
};

export const addDaysToCurrentDate = (days) => {
  const newDate = new Date(Date.now() + days * 864e5);
  return newDate;
};

export const formatDate = (date = new Date()) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const convertToMinutes = (time) => {
  let hrs = time.substring(0, 2);
  hrs = hrs * 60;
  let mins = time.substring(3, 5);
  mins = parseInt(hrs) + parseInt(mins);
  return mins;
};

export const convertToTime = (mins) => {
  let hrs = Math.floor(parseInt(mins) / 60);
  hrs = hrs.length === 1 ? "0" + hrs : hrs;
  mins = mins % 60;
  mins = mins.toString().length === 1 ? "0" + mins.toString() : mins.toString();
  return hrs + ":" + mins;
};

export const convertToHours = (date, stime, ftime) => {
  const start = dayjs(date + "T" + stime);
  const finish = dayjs(date + "T" + ftime);
  const hours = finish.diff(start, "h", true).toFixed(2);

  return finish.diff(start, "h", true).toFixed(2);
};

export const getPay = (hours, rate) => {
  return (parseFloat(rate.substring(1, rate.length)) * hours).toFixed(2);
};

export const makeNiceDate = (sqlDate) => {
  const day = dayjs(sqlDate);
  return day.format("ddd MMM DD YYYY");
};

export const toNaiveTime = (ts) => {
  const offset = new Date().getTimezoneOffset();
  return dayjs(ts).add(offset, "minutes");
};

export const calcPercentage = (amount, total) => {
  let ans = (parseFloat(amount) / parseFloat(total)) * 100;
  return ans.toFixed(0);
};
