import React from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../helpers/helper'

const style = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}

const styleFooter = {
  marginTop: 'auto'
}

const DnfCard = ({dnfid, client, address, postcode, jobdesc, price, datebi, perly, dnfdesc, sdate, fdate, stime, reportstime, prodcomm}) => {
  const maxSize = (jobdesc) => {
    if(jobdesc.length>20){
      jobdesc = jobdesc.substring(0,20)
    }
    return jobdesc
  }


  const maxSizejobdesc = (jobdesc) => {
    if(jobdesc.length>20){
      jobdesc = jobdesc.substring(0,20)
    }
    return jobdesc
  }

  const maxSizeDnfDesc = (dnfdesc) => {
    if(dnfdesc && dnfdesc.length>30){
      dnfdesc = dnfdesc.substring(0,30)
    }
    return dnfdesc
  }

  const dnfType = () => {
    let typeDesc = 'Floater'
    if(sdate===fdate){
      typeDesc = `Appt[${stime}]`
    }
    return typeDesc
  }

  const renderActionLink = () => {
    if(reportstime === null && datebi === null){
      return <Link to={`/dnf-details/${dnfid}`} className="button card-footer-item is-warning">Return DNF</Link>
    }else if(reportstime !== null && datebi === null){
      return <Link to={`/dnf-details/${dnfid}`} className="button card-footer-item is-danger">Edit DNF</Link>
    }else{
      return <Link to={`/dnf-details/${dnfid}`} className="button card-footer-item is-black">Edit DNF</Link>
    }
  }

  const statusDnf = () => {
    status = null;
    if(reportstime === null && datebi === null){
      status = 'Pending';
    }else if(reportstime !== null && datebi === null){
      status = 'Started';
    }else if(reportstime !== null && datebi !== null){
      status = 'Completed';
    }
    return status;
  }

  return(
        <div style={style} className='card'>
          <div className='card-header'>
            <div className='card-header-title is-centered has-background-warning'>
              <a href={`https://www.google.ca/maps/place/${address} ${postcode}`} target="_blank">
                {address} [{postcode}]
              </a>

            </div>
          </div>
          <div className='card-content'>
            <div className='content'>
              <p>{ dnfType() }</p><br/>
              <p>Desc:{ maxSizeDnfDesc(dnfdesc) }</p>
              <p>Follow Up:{ maxSizeDnfDesc(prodcomm) }</p>
              <p>{ formatCurrency(price) }</p>
              <p className='has-text-weight-bold'>Status: { statusDnf() }</p>
            </div>
          </div>
          <footer style={styleFooter} className="card-footer">
            { renderActionLink() }
          </footer>
        </div>
  )
}

export default DnfCard
