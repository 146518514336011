import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const EmpNotes = ({ empNotes }) => {
  console.log('Emp Notes', empNotes);
  return (
    <table className="crewpay-table">
      <thead>
        <tr>
          <th className="crewpay-table__header">Name</th>
          <th className="crewpay-table__header">Emp Notes</th>
        </tr>
      </thead>
      <tbody>
        {empNotes.map((note) => {
          return (
            <tr key={note.id + note.hrid}>
              <td className="crewpay-table__body">{note.employee}</td>
              <td className="crewpay-table__body">{note.notes}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default EmpNotes;
