import React from "react";

const RadioField = React.forwardRef(({ label, name, checked }, ref) => {
  return (
    <label className="radio margin-right mb-3">
      <input
        name={name}
        type="radio"
        value={label}
        defaultChecked={checked}
        ref={ref}
      />
      {label}
    </label>
  );
});

export default RadioField;
