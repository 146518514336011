
import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { formatCurrency } from "../../helpers/helper";
import Revenue from "./components/Revenue";
import RevenueMisc from "./components/RevenueMisc";
import RevenueEstsSigns from "./components/RevenueEstsSigns";
import Report from "./components/Report";
import Labour from "./components/Labour";
import Expenses from "./components/Expenses";
import Payouts from "./components/Payouts";

const Financials = () => {
  const [{ mgr, userLoading }] = useStateReducer();
  const [royalty, setRoyalty] = useState(0);
  const [royalty1, setRoyalty1] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showDisplay, setShowDisplay] = useState('')

  const [reportClass2023, setReportClass2023] = useState('button is-link')
  const [expensesClass2023, setExpensesClass2023] = useState('button is-link')
  const [revenueProductionClass2023, setRevenueProductionClass2023] = useState('button is-link')
  const [revenueMiscClass2023, setRevenueMiscClass2023] = useState('button is-link')
  const [revenueEstsSignsClass2023, setRevenueEstsSignsClass2023] = useState('button is-link')
  const [payoutsClass2023, setPayoutsClass2023] = useState('button is-link')
  const [labourClass2023, setLabourClass2023] = useState('button is-link')

  const [reportClass2024, setReportClass2024] = useState('button is-link')
  const [expensesClass2024, setExpensesClass2024] = useState('button is-link')
  const [revenueProductionClass2024, setRevenueProductionClass2024] = useState('button is-link')
  const [revenueMiscClass2024, setRevenueMiscClass2024] = useState('button is-link')
  const [revenueEstsSignsClass2024, setRevenueEstsSignsClass2024] = useState('button is-link')
  const [payoutsClass2024, setPayoutsClass2024] = useState('button is-link')
  const [labourClass2024, setLabourClass2024] = useState('button is-link')

  useEffect(() => {
    if(mgr && mgr.royalty){
      setRoyalty(mgr.royalty);
      setRoyalty1(mgr.royalty1);
    }
  }, [mgr])

  const resetButtons = () => {

    setReportClass2023('button is-link');
    setExpensesClass2023('button is-link');
    setRevenueProductionClass2023('button is-link');
    setRevenueMiscClass2023('button is-link');
    setRevenueEstsSignsClass2023('button is-link');
    setPayoutsClass2023('button is-link');
    setLabourClass2023('button is-link');

    setReportClass2024('button is-link');
    setExpensesClass2024('button is-link');
    setRevenueProductionClass2024('button is-link');
    setRevenueMiscClass2024('button is-link');
    setRevenueEstsSignsClass2024('button is-link');
    setPayoutsClass2024('button is-link');
    setLabourClass2024('button is-link');

  }

  const changeDisplay = (display) => {
    setShowDisplay(display);
    resetButtons();
    if(display === 'Report 2023'){
      setReportClass2023('button is-warning');
    }else if(display === 'Report 2024'){
      setReportClass2024('button is-warning');
    }else if(display === 'Revenue-Production 2023'){
      setRevenueProductionClass2023('button is-warning');
    }else if(display === 'Revenue-Production 2024'){
      setRevenueProductionClass2024('button is-warning');




    }else if(display === 'Expenses 2023'){
      setExpensesClass2023('button is-warning');
    }else if(display === 'Expenses 2024'){
      setExpensesClass2024('button is-warning');

    }else if(display === 'Revenue-Production 2023'){
      setRevenueProductionClass2023('button is-warning');
    }else if(display === 'Revenue-Production 2024'){
      setRevenueProductionClass2024('button is-warning');

    }else if(display === 'Revenue-Misc 2023'){
      setRevenueMiscClass2023('button is-warning');
    }else if(display === 'Revenue-Misc 2024'){
      setRevenueMiscClass2024('button is-warning');

    }else if(display === 'Revenue-Ests 2023'){
      setRevenueEstsSignsClass2023('button is-warning');
    }else if(display === 'Revenue-Ests 2024'){
      setRevenueEstsSignsClass2024('button is-warning');

    }else if(display === 'Payouts 2023'){
      setPayoutsClass2023('button is-warning');
    }else if(display === 'Payouts 2024'){
      setPayoutsClass2024('button is-warning');

    }else if(display === 'Labour 2023'){
      setLabourClass2023('button is-warning');
    }else if(display === 'Labour 2024'){
      setLabourClass2024('button is-warning');
    }
  }



  const showData = () => {
    switch(showDisplay){
      case 'Report 2023':
        return <Report
                 showDisplay={showDisplay}
               />;
      case 'Report 2024':
        return <Report
                 showDisplay={showDisplay}
               />;
      case 'Revenue-Production 2023':
        return mgr && <Revenue
                showDisplay={showDisplay}
              />
      case 'Revenue-Production 2024':
        return mgr && <Revenue
                 showDisplay={showDisplay}
               />
      case 'Revenue-Misc 2023':
        return mgr && <RevenueMisc
                 showDisplay={showDisplay}
               />
      case 'Revenue-Misc 2024':
        return mgr && <RevenueMisc
                showDisplay={showDisplay}
              />
      case 'Revenue-Ests 2023':
        return mgr && <RevenueEstsSigns
                 showDisplay={showDisplay}
              />

      case 'Revenue-Ests 2024':
        return mgr && <RevenueEstsSigns
                 showDisplay={showDisplay}
              />

      case 'Expenses 2023':
        return mgr && <Expenses
               showDisplay={showDisplay}
             />

      case 'Expenses 2024':
        return mgr && <Expenses
              showDisplay={showDisplay}
            />

      case 'Labour 2023':
        return mgr && <Labour
                 showDisplay={showDisplay}
               />
      case 'Labour 2024':
        return mgr && <Labour
                  showDisplay={showDisplay}
                />
      case 'Payouts 2023':
        return mgr && <Payouts
               showDisplay={showDisplay}
             />
      case 'Payouts 2024':
        return mgr && <Payouts
              showDisplay={showDisplay}
             />
    }

  }

  return(
    <Layout>
      <div className='container'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className='breadcrumb mt-3'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li className='is-active'><Link to="/partner_financials">Financials</Link></li>
          </ul>
        </nav>




        <div className="buttons">
          <button className= { reportClass2023 } onClick={ () => changeDisplay('Report 2023') }>Report 2023</button>
          <button className={ revenueProductionClass2023 } onClick={ () => changeDisplay('Revenue-Production 2023') }>Revenue-Prod 2023</button>
          <button className={ revenueMiscClass2023 } onClick={ () => changeDisplay('Revenue-Misc 2023') }>Revenue-Misc 2023</button>
          <button className={ revenueEstsSignsClass2023 } onClick={ () => changeDisplay('Revenue-Ests 2023') }>Revenue-Ests 2023</button>
          <button className={ expensesClass2023 } onClick={ () => changeDisplay('Expenses 2023') }>Expenses 2023</button>
          <button className={ labourClass2023 } onClick={ () => changeDisplay('Labour 2023') }>Labour 2023</button>
          <button className={ payoutsClass2023 } onClick={ () => changeDisplay('Payouts 2023') }>Payouts 2023</button>
        </div><br/>

        <div className="buttons">
          <button className= { reportClass2024 } onClick={ () => changeDisplay('Report 2024') }>Report 2024</button>
          <button className={ revenueProductionClass2024 } onClick={ () => changeDisplay('Revenue-Production 2024') }>Revenue-Prod 2024</button>
          <button className={ revenueMiscClass2024 } onClick={ () => changeDisplay('Revenue-Misc 2024') }>Revenue-Misc 2024</button>
          <button className={ revenueEstsSignsClass2024 } onClick={ () => changeDisplay('Revenue-Ests 2024') }>Revenue-Ests 2024</button>
          <button className={ expensesClass2024 } onClick={ () => changeDisplay('Expenses 2024') }>Expenses 2024</button>
          <button className={ labourClass2024 } onClick={ () => changeDisplay('Labour 2024') }>Labour 2024</button>
          <button className={ payoutsClass2024 } onClick={ () => changeDisplay('Payouts 2024') }>Payouts 2024</button>
        </div><br/>
        { showLoader ? <Loader /> : showData() }
      </div>
    </Layout>
  )

}

export default Financials;


//
// <div className='columns mt-3 is-flex is-justify-content-space-between'>
//   <div className='column is-2'>
//     <button className= { reportClass } onClick={ () => changeDisplay('Report') }>Report 2023</button>
//   </div>
//   <div className='column is-2'>
//     <button className={ revenueProductionClass } onClick={ () => changeDisplay('Revenue-Production') }>Revenue-Prod 2023</button>
//   </div>
//   <div className='column is-2'>
//     <button className={ revenueMiscClass } onClick={ () => changeDisplay('Revenue-Misc') }>Revenue-Misc 2023</button>
//   </div>
//   <div className='column is-2'>
//     <button className={ revenueEstsSignsClass } onClick={ () => changeDisplay('Revenue-Ests') }>Revenue-Ests 2023</button>
//   </div>
//   <div className='column is-2'>
//     <button className={ expensesClass } onClick={ () => changeDisplay('Expenses') }>Expenses 2023</button>
//   </div>
//   <div className='column is-2'>
//     <button className={ labourClass } onClick={ () => changeDisplay('Labour') }>Labour 2023</button>
//   </div>
//   <div className='column is-2'>
//     <button className={ payoutsClass } onClick={ () => changeDisplay('Payouts') }>Payouts 2023</button>
//   </div>
// </div><br/>
