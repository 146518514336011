import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";


import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { formatCurrencyZero } from "../../helpers/helper";

 const Training = () => {
  const [{ mgr, userLoading }] = useStateReducer();
  const [showLoader, setShowLoader] = useState(true);

  return(
    <Layout>
      <div className='container m-4'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className='breadcrumb mt-3'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li className='is-active'><Link to="/partner_training">Training</Link></li>
          </ul>
        </nav>

        <hr/>
        <div className='content'>
          <h1 className='title has-text-centered'>Training Notes</h1>

          <br/><br/><h1 className='mt-3 subtitle has-text-weight-bold'>Training Mindset</h1>

          <div className='mb-3'>We are not trying to do 6 months of skills in one morning</div>
          <div className='mb-3'>Personality</div>
          <div className='mb-3'>Coachability</div>
          <div className='mb-3'>Attitude</div>
          <div className='mb-3'>Eagerness to work hours</div>
          <div className='mb-3'>Stamina</div>
          <div className='mb-3'>Paying attention in classroom sessions</div>
          <div className='mb-3'>Intelligent questions</div>
          <div className='mb-3'>Appearance and grooming</div>
          <div className='mb-3'>Skills</div>

          <br/><h1 className='subtitle has-text-weight-bold'>Intro</h1>

            <div className='mb-3'>Welcome to Training</div>
            <div className='mb-3'>Thank them for coming.</div>
            <div className='mb-3'>Introduce yourself(experience, background) and your partner.</div>
            <div className='mb-3'>Company facts 32 years old, services are window/eaves cleaning, painting, odd obs and lawn care.</div>
            <div className='mb-3'>Company culture/values. Seek to provide excellent service while building long term relationships.</div>
            <div className='mb-3'>Profile of people we try and hire and current crews.</div>
            <div className='mb-3'>Typical client profile and our philosophy towards client service.</div>
            <div className='mb-3'>Have trainees introduce themselves. Where do they live(ie. shop closest to), do they drive(&age), paint/odd job experience. Write on paper in same seating arrangement.</div>
            <br/><h1 className='subtitle has-text-weight-bold'>Training Agenda</h1>
            <div className='mb-3'>Explain training agenda(ladder work, classroom, climbing, classroom, lunch, window cleaning practice). Reps are important - DO NOT STAND AROUND / NO PHONES!!</div>
            <br/><div className='mb-3 has-text-weight-bold'>Ladder Agility.</div>
            <div className='mb-3'>Introduce ladders and parts. How to lift, walk with ladder, turn with ladder, put up ladder and take down. REPS!!</div>
            <div className='mb-3'>3-point contact, Climb up and down smoothly., getting on/off roof, carrying items up ladder, cleaning from ladder. REPS!!</div>

            <br/><div className='mb-3 has-text-weight-bold'>Classroom 1</div>
            <div className='mb-3'>Safety(priority, no ego, ask for help, listen to your instinct, 3-point of contact, no phone, work together).</div>
            <div className='mb-3'>Morning Open. Arrival time, prepare, stretch - stress importance.</div>
            <div className='mb-3'>Typical Year. Sept/Oct->build up. Nov/Dec->explode. Jan/Feb/Mar->EI and some work(banked). Apr/May/Jun/Jul->Very busy. Aug->vacation time/slow work schedule</div>
            <br/><div className='mb-3 has-text-weight-bold'>Break.</div>
            <div className='mb-3'>Order Pizza, check driver info, enter into system, get profile pics, answer profile questions.</div>
            <br/><div className='mb-3 has-text-weight-bold'>Classroom 2</div>
            <div className='mb-3'>Typical Day. Arrive 7:15am, make sure vehicle is ready/stocked, stretch, travel to and perform jobs, return to shop together and close.</div>
            <div className='mb-3'>Pay. 2 week pay periods. Non-drivers start at $18/hr. Drivers start at $20. Experience is a factor.</div>
            <div className='mb-3'>Products(W1, W2, W3, W4, EH, EG. Cages. Snaking, Lawnsigns, Reading Notes)</div>
            <div className='mb-3'>Customer Service(greet, communicate, say good bye and thank you). Dealing with office.</div>
            <br/><div className='mb-3 has-text-weight-bold'>Lunch</div>
            <br/><div className='mb-3 has-text-weight-bold'>Window Technique</div>
            <div className='mb-3'>Explain importance of cleaning windows perfectly. Have lunch and train.</div>
            <div className='mb-3'>Show technique, practice, rinse and repeat.</div>
            <br/><div className='mb-3 has-text-weight-bold'>Conclusion</div>
            <div className='mb-3'>Have one on one meetings(equipment area) while group is practicing. Have Nat enter good prospects into system. Give them an idea of when they may start or if they may not be suited.</div>
            <div className='mb-3'>Start an email draft with quick summary on each guy. Cover attitude, ladder agility, ladder climbing, window cleaning, DL cleared(drivers), your input and opinion.</div>

        </div>

    </div>
    </Layout>
  )

}

export default Training;


//content as container. It can handle almost any HTML tag:

// <p> paragraphs
// <ul> <ol> <dl> lists
// <h1> to <h6> headings
// <blockquote> quotes
// <em> and <strong>
// <table> <tr> <th> <td> tables
