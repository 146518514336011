


  export const checkAreaRange = (wc, areaRange)=> {
    if(areaRange === 'Scarborough +' &&
      (wc.postcode.includes('M1B') ||
       wc.postcode.includes('M1X') ||
       wc.postcode.includes('M1C') ||
       wc.postcode.includes('M1E') ||
       wc.postcode.includes('M1G') ||
       wc.postcode.includes('M1H') ||
       wc.postcode.includes('M1J') ||
       wc.postcode.includes('M1K') ||
       wc.postcode.includes('M1L') ||
       wc.postcode.includes('M1M') ||
       wc.postcode.includes('M1N') ||
       wc.postcode.includes('M1P') ||
       wc.postcode.includes('M1R') ||
       wc.postcode.includes('M1S') ||
       wc.postcode.includes('M1T') ||
       wc.postcode.includes('M1W') ||
       wc.postcode.includes('M1V')
      )
    ){
      return true;
    } else if (areaRange === 'North Toronto' &&
      (
        wc.postcode.includes('M2H') ||
        wc.postcode.includes('M2J') ||
        wc.postcode.includes('M2K') ||
        wc.postcode.includes('M2L') ||
        wc.postcode.includes('M2P') ||
        wc.postcode.includes('M3H') ||
        wc.postcode.includes('M3J') ||
        wc.postcode.includes('M3N') ||
        wc.postcode.includes('M3A') ||
        wc.postcode.includes('M5M') ||
        wc.postcode.includes('M6A') ||
        wc.postcode.includes('M6B') ||
        wc.postcode.includes('M6L') ||
        wc.postcode.includes('M3B') ||
        wc.postcode.includes('M3C') ||
        wc.postcode.includes('M2H') ||
        wc.postcode.includes('M2J') ||
        wc.postcode.includes('M2K') ||
        wc.postcode.includes('M2L') ||
        wc.postcode.includes('M2L') ||
        wc.postcode.includes('M2P') ||
        wc.postcode.includes('M3H') ||
        wc.postcode.includes('M3J') ||
        wc.postcode.includes('M3N') ||
        wc.postcode.includes('M3A') ||
        wc.postcode.includes('M4A') ||
        wc.postcode.includes('M5M') ||
        wc.postcode.includes('M6A') ||
        wc.postcode.includes('M6B') ||
        wc.postcode.includes('M6L') ||
        wc.postcode.includes('M3K') ||
        wc.postcode.includes('M3L') ||
        wc.postcode.includes('M3M') ||
        wc.postcode.includes('M2M') ||
        wc.postcode.includes('M2N') ||
        wc.postcode.includes('M2R')
      )
    ){
      return true
    } else if (areaRange === 'Etobicoke +' &&
      (
        wc.postcode.includes('M6S') ||
        wc.postcode.includes('M8V') ||
        wc.postcode.includes('M8W') ||
        wc.postcode.includes('M8Y') ||
        wc.postcode.includes('M8Z') ||
        wc.postcode.includes('M8X') ||
        wc.postcode.includes('M9H') ||
        wc.postcode.includes('M9A') ||
        wc.postcode.includes('M9B') ||
        wc.postcode.includes('M9C') ||
        wc.postcode.includes('M9M') ||
        wc.postcode.includes('M9N') ||
        wc.postcode.includes('M9P') ||
        wc.postcode.includes('M9R') ||
        wc.postcode.includes('M9V') ||
        wc.postcode.includes('M9W') ||
        wc.postcode.includes('L4T') ||
        wc.postcode.includes('L4V') ||
        wc.postcode.includes('L5S') ||
        wc.postcode.includes('L5T') ||
        wc.postcode.includes('L4W') ||
        wc.postcode.includes('L4X') ||
        wc.postcode.includes('L4Y') ||
        wc.postcode.includes('L4Z') ||
        wc.postcode.includes('L5R') ||
        wc.postcode.includes('L5A') ||
        wc.postcode.includes('L5B') ||
        wc.postcode.includes('L5C') ||
        wc.postcode.includes('L5E') ||
        wc.postcode.includes('L5G') ||
        wc.postcode.includes('L5H') ||
        wc.postcode.includes('L5J') ||
        wc.postcode.includes('L5K') ||
        wc.postcode.includes('L5L') ||
        wc.postcode.includes('L5M') ||
        wc.postcode.includes('L5N') ||
        wc.postcode.includes('L5W') ||
        wc.postcode.includes('L5V')
      )
    ){
      return true
    } else if (areaRange === 'West End' &&
      (
        wc.postcode.includes('M6C') ||
        wc.postcode.includes('M6E') ||
        wc.postcode.includes('M6M') ||
        wc.postcode.includes('M6H') ||
        wc.postcode.includes('M6J') ||
        wc.postcode.includes('M6K') ||
        wc.postcode.includes('M6R') ||
        wc.postcode.includes('M6N') ||
        wc.postcode.includes('M6P') ||
        wc.postcode.includes('M6S')
      )
    ){
      return true
    } else if (areaRange === 'East End' &&
      (
        wc.postcode.includes('M4B') ||
        wc.postcode.includes('M4C') ||
        wc.postcode.includes('M4C') ||
        wc.postcode.includes('M4H') ||
        wc.postcode.includes('M4J') ||
        wc.postcode.includes('M4E') ||
        wc.postcode.includes('M4L') ||
        wc.postcode.includes('M4K') ||
        wc.postcode.includes('M4M')
      )
    ){
      return true
    } else if (areaRange === 'Central South' &&
      (
        wc.postcode.includes('M4W') ||
        wc.postcode.includes('M4X') ||
        wc.postcode.includes('M4Y') ||
        wc.postcode.includes('M5A') ||
        wc.postcode.includes('M5B') ||
        wc.postcode.includes('M5C') ||
        wc.postcode.includes('M5T') ||
        wc.postcode.includes('M5E') ||
        wc.postcode.includes('M5G') ||
        wc.postcode.includes('M5W') ||
        wc.postcode.includes('M5H') ||
        wc.postcode.includes('M5J') ||
        wc.postcode.includes('M6G') ||
        wc.postcode.includes('M5K') ||
        wc.postcode.includes('M5L') ||
        wc.postcode.includes('M5V') ||
        wc.postcode.includes('M5N') ||
        wc.postcode.includes('M5X') ||
        wc.postcode.includes('M7A') ||
        wc.postcode.includes('M7Y') ||
        wc.postcode.includes('M5R') ||
        wc.postcode.includes('M5S')
      )
    ){
      return true


    } else if (areaRange === 'Central' &&
      (
        wc.postcode.includes('M4V') ||
        wc.postcode.includes('M5P') ||
        wc.postcode.includes('M4G') ||
        wc.postcode.includes('M4S') ||
        wc.postcode.includes('M4T')
      )
    ){
      return true
    } else if (areaRange === 'Central North' &&
      (
        wc.postcode.includes('M4N') ||
        wc.postcode.includes('M5M') ||
        wc.postcode.includes('M4R') ||
        wc.postcode.includes('M4P') ||
        wc.postcode.includes('M4A')
      )
    ){
      return true
    }
  };
