import React, { useState, useEffect } from "react";

const EstimatesStats = ({ tasks }) => {
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  const [scheduled, setScheduled] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [paid, setPaid] = useState(0);
  const [commPendingOwed, setCommPendingOwed] = useState(0);
  const [commOwed, setCommOwed] = useState(0);

  useEffect(() => {
    let total = tasks.length;
    let pending = 0;
    let scheduled = 0;
    let completed = 0;
    let paid = 0;
    let commPendingOwed = 0;
    let commOwed = 0;
    tasks.forEach((task) => {
      if (task.status.includes("Paid")) {
        paid += 1;
        commOwed += parseFloat(task.comm);
      } else if (task.status.includes("Completed")) {
        completed += 1;
        commPendingOwed += parseFloat(task.comm);
      } else if (task.status.includes("Scheduled")) {
        scheduled += 1;
      }
    });
    pending = total - paid - completed - scheduled;
    setPaid(paid);
    setCommOwed(commOwed);
    setCompleted(completed);
    setCommPendingOwed(commPendingOwed);
    setScheduled(scheduled);
    setPending(pending);
    setTotal(total);

    console.log(commOwed);
  }, [tasks]);

  return (
    <div className="filters">
      <table className="table">
        <thead>
          <tr>
            <th>Tot</th>
            <th>Pend</th>
            <th>Schd</th>
            <th>Comp</th>
            <th>Owe-P</th>
            <th>Paid</th>
            <th>Owed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{total}</td>
            <td>{pending}</td>
            <td>{scheduled}</td>
            <td>{completed}</td>
            <td>{commPendingOwed}</td>
            <td>{paid}</td>
            <td>{commOwed}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EstimatesStats;
