import React from "react";
import { Redirect } from "react-router";
import { useState } from "react";

import LoginAPI from "../api/login";
import { useStateReducer } from "../context/authContext";
import ActionType from "../context/actionType";

const Login = () => {
  const [{ user }, dispatch] = useStateReducer();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMessage, setErrMessage] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    attemptLogin();
  };

  const changeUserName = (e) => {
    setUsername(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const attemptLogin = async () => {
    const login = await LoginAPI.logIn(username, password);
    if (login.user) {
      dispatch({
        type: ActionType.SetUser,
        payload: login.user,
      });
      dispatch({
        type: ActionType.SetVehicleList,
        payload: login.vehicleOptions,
      });
    } else {
      setErrMessage("Wrong Username/Password!");
      setPassword("");
    }
  };

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-container">
      <div className="login-container-group">
        <div className="login-container-group__title">
          <h2 className="login-container-group__title__label">
            White Shark Production
          </h2>
          <h4 className="login-container-group__title__error">{errMessage}</h4>
        </div>
        <form className="login-container-group__form" onSubmit={onFormSubmit}>
          <div className="login-container-group__form-item">
            <span className="login-container-group__form-label">Username</span>
            <input
              className="login-container-group__form-input"
              type="text"
              name="username"
              value={username}
              onChange={(e) => changeUserName(e)}
            />
          </div>
          <div className="login-container-group__form-item">
            <span className="login-container-group__form-label--password">
              Password
            </span>
            <input
              className="login-container-group__form-input"
              type="password"
              name="password"
              value={password}
              onChange={(e) => changePassword(e)}
            />
          </div>
          <div className="login-container-group__form-item">
            <button
              className="login-container-group__form-item--button"
              type="submit"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
