import React from "react";
import useRequest from "../../base/hooks/useRequest";
import CrewNameOptions from "./CrewNameOptions";
import Loader from "./Loader";
import SelectOptions from "./SelectOptions";
import SelectRangeOptions from "./SelectRangeOptions";

const Filters = ({ numberTasks, register }) => {
  //const { data, loading } = useRequest("/api/crews/driver_list");
  const loading = false;
  const data = [
    "Joey Armstrong",
    "Joel Caprani",
    "Joseph Reis",
    "Nicholas Milligan",
    "Roger Singh",
    "Ryan Rausch",
    "Terrence Hughes",
  ];
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="filters">
        <div className="filters--set">
          <CrewNameOptions
            className="filters__input filters__input--crewname"
            ref={register}
            options={data}
            name="manager"
          />
          <div className="filters--input">
            <SelectRangeOptions ref={register} />
          </div>
        </div>
      </div>
      <div className="filters__title">#Tasks: {numberTasks}</div>
    </div>
  );
};

export default Filters;

{
  /* <input
type="date"
className="filters__input"
ref={register}
name="startDate"
/>
<input
type="date"
className="filters__input"
ref={register}
name="finishDate"
/>
<input
className="filters__input filters__input--lp"
placeholder="Search"
ref={register}
name="searchText"
/> */
}
