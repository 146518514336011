import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCogs, faDiceFour, faAd, faMapMarker, faLaptopHouse, faCity, faTruckPickup} from '@fortawesome/free-solid-svg-icons'


const styleMe = {
//    position: 'absolute',
    // top: '80%',
    // left: '80%',
    // width: '48px',
    // height: '48px',
    // backgroundcolor: 'red',
    // color: 'red',
    // border: '2px solid blue',
    // borderRadius: '70%',
    // transform: "translate(-50%, -50%)",
      position: 'absolute',
      color: 'white',
      borderBottom: '35px solid blue',
    	borderLeft: '15px solid transparent',
    	borderRight: '15px solid transparent',
    	height: '0',
    	width: '65px',
      cursor: 'pointer'
  }
  const style = {
      // position: 'absolute',
      // top: '80%',
      // left: '80%',
      // width: '48px',
      // height: '48px',
     //background: 'red',
      color: 'blue',
      // border: '2px solid #fff',
      // borderRadius: '100%',
      // userSelect: 'none',
      // transform: "translate(-50%, -50%)",
      cursor: 'pointer'
    }


// backgroundColor: color, cursor: 'pointer'
const Marker = ({label, text , infoWindow, map, marker, content}) => {
  const [mapText, setMapText] = useState(text);
  const [showText, setShowText] = useState('is-hidden');

    const hoverIn = () => {
      console.log('Nut');
      setShowText('has-background-light has-text-danger');
    }

    const hoverOut = () => {
      console.log('Nut');
      setShowText('is-hidden has-background-light has-text-danger');
    }

    const clickMe = () => {
      console.log('Click Nut');
      console.log('Label', label);

      // infoWindow.open({
      //   anchor: marker,
      //   map
      // });

      // var graphic = map.graphics.graphics[map.graphics.graphics.length -1];
      //
      //       var screenPoint = map.toScreen(graphic.geometry);
      //       infoWindow.setFeatures([graphic]);
      //       infoWindow.show(screenPoint, map.getInfoWindowAnchor(screenPoint));

      console.log('No problem here');

    }




    return (
      <div style={styleMe} onMouseEnter={ hoverIn } onMouseLeave={ hoverOut } onClick={ clickMe }>
        <p className='has-text-danger has-text-weight-bold'>{label}</p>
      </div>
      );
  };

export default Marker;


//<FontAwesomeIcon className='fa-2x' icon={ faTruckPickup } label='1' />


// style={ style }
// title={newProps.title}
// label={newProps.label}

// {newProps.label}
// <FontAwesomeIcon className='fa-2x' icon={ faLaptopHouse } label='1' />
