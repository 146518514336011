import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import DailyReport from "../views/DailyReport";


export const history = createBrowserHistory({ basename: "/crews/react_reports" });

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/daily_reports" component={DailyReport} exact />
    </Switch>
  </Router>
);

export default AppRouter;
