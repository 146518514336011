import React from "react";
import { Link } from "react-router-dom";
import { makeNiceDate } from "../helpers/helper";

const CrewProfileHeader = ({ newSearch, date, driver, partner, partner1 }) => {
  return (
    <div className="crewprofile-title">
      <h3>
        <Link to={`/crewpay?${newSearch}`}>Go Back</Link>
      </h3>
      <h3>
        Crew Profile [{makeNiceDate(date)}]
        {driver ? (
          <span className="crewprofile-span">{driver.name}</span>
        ) : null}{" "}
        {partner ? (
          <span className="crewprofile-span">{partner.name}</span>
        ) : null}
        {partner1 ? (
          <span className="crewprofile-span">{partner1.name}</span>
        ) : null}
      </h3>
    </div>
  );
};

export default CrewProfileHeader;
