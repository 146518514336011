import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

import { useStateReducer } from "../../../base/context/authContext";
import ActionType from "../../../base/context/actionType";
import ApiClient from "../../../base/api/client";
import { formatCurrency } from "../../helpers/helper";
import { ErrorMsg } from "../../components/ErrorMsg";

const JobAddress = ({ register, getValues, errorMsgs, onChangeJobSpecs }) => {
  const [{ props }, dispatch] = useStateReducer();
  const[address, setAddress] = useState(null);
  const[numAddress, setNumAddress] = useState(null);

  const[prices, setPrices] = useState(null);
  const[search, setSearch] = useState('');

  const MagnifyingGlass = <FontAwesomeIcon icon={ faSearchengin } />;

  const fetchPrices = async (jobinfoid) => {
    const prices = await ApiClient.request(`/api/properties/${jobinfoid}`);
    console.log('PRICES***', prices);
    setPrices(prices);
  }

  useEffect(() => {
  }, [props])

  const priceBar = () => {
    return prices ?
    <span className='has-text-weight-bold'>
      <span className='mr-3'>W1:{ formatCurrency(prices.w1) }</span>
      <span className='mr-3'>EH:{ formatCurrency(prices.eh)}</span>
      <span className='mr-3'>EG:{ formatCurrency(prices.eg)}</span>
    </span> : null
  }

  const selectProperty = (e) => {
    const jobinfoid = e.target.value;
    const temp = props.filter(prop => prop.jobinfoid === jobinfoid)
    setAddress(temp[0].address);
    if(jobinfoid !== 'None Selected'){
      fetchPrices(jobinfoid);
    }
    dispatch({type: ActionType.SetSelectedJobInfoId, payload: jobinfoid});
    onChangeJobSpecs();
  }

  const addressOptions = (search) => {
    return props.filter(prop => prop.address.toLowerCase().includes(search.toLowerCase())).map((prop, index) => <option key={index} value={prop.jobinfoid}>{ prop.address }</option>)
  }

  const updateSearch = (e) => {
    setSearch(e.target.value);
  }

  const getPrices = () => {
    return prices && address && address!=='None Selected' ?
     (<div className='field has-text-link'>
        <span className='mr-3 is-one-third'><label className='label has-text-link' htmlFor='pricebar'>{ address }</label></span>
        <span className='is-one-third' id='pricebar'>{ priceBar() }</span>
    </div>) : null;
  }

  const searchBar = () => {
    return props.length > 10 ?
    <div className='columns'>
      <div className='column is-two-fifths'>
        <div className='field'>
          <div className='control has-icons-left'>
            <input type='text' className='input' placeholder="Search Address" onChange={updateSearch} />
            <span className='icon is-medium is-left mt-2 ml-1'>
              <i className='icon'>{ MagnifyingGlass }</i>
            </span>
          </div>
        </div>
      </div>
    </div> : null
  }

  return (
    <div>
      { props && searchBar() }
      <div className='field mb-2'>
        <div className="control">
          <label className='label' htmlFor='address'>Address { props && props.length > 10 ? addressOptions(search).length : null }</label>
          <div className='select'>
            <select className='select' name='address' ref={register} onChange={ selectProperty }>
              <option value="None Selected">None Selected</option>
              { props && addressOptions(search)};
            </select>
          </div>
          <ErrorMsg msg={errorMsgs.addressMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
          { getPrices() }
        </div>
      </div>
    </div>

  )
}

export default JobAddress;





















// -------------------------------------------------------------------------------------------
