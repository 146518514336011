import React from 'react';


const SaleBlocked = ({boxStyle}) => {
  return (
    <div style={boxStyle} className='box has-background-info'>
      <h2 className='title is-3 has-text-centered has-text-light'>We are unable to book your order at this time.</h2>
      <h2 className='title is-3 has-text-centered has-text-light'>
        Please contact Shanti at shanti@whiteshark.ca to complete your order.
      </h2>
    </div>
  )
}

export default SaleBlocked;
