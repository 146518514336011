import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";

import ApiClient from "../api/client";



const StuffToDo = () => {
  const [test, setTest] = useState('');

  useEffect(() => {
    console.log("On Load");
  }, [])

  return (
    <Layout>
      <div className="container">
        <h1>Stuff To Do</h1>
        <div className='columns'>
          <div className='column is-two-fifths'>
            Hee Haw
          </div>
          <div className='column is-two-fifths'>
            LUPW
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StuffToDo;
