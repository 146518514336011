import React from "react";
import { Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import Loader from "../Loader";
import TypeRangeOptions from "./TypeRangeOptions";
import LocationOptions from "./LocationOptions";

import { locationData } from "../../helpers/lists";

const PropertyFilters = ({
  numberTrans,
  searchText,
  type,
  location,
  startDate,
  finishDate,
  onSearchTextChange,
  onTypeChange,
  onLocationChange,
  onStartDateChange,
  onFinishDateChange,
}) => {
  const { data: descriptionOptions, loading } = useRequest(
    "/api/props/fetch_descriptions"
  );

  return loading ? (
    <Loader />
  ) : (
    <div className="filters-container filters-container__margin-b">
      <div className="filters">
        <div className="filters--set">
          <input
            className="filters__input filters__input--lp"
            onChange={onSearchTextChange}
            value={searchText}
            placeholder="Search Text"
          />

          <LocationOptions
            className="filters__input filters__input--crewname"
            onChange={onLocationChange}
            value={location === "" || location === null ? "" : location}
            options={locationData}
            name="location"
          />
        </div>

        <div className="filters--set">
          <TypeRangeOptions onTypeChange={onTypeChange} value={type} />
        </div>

        <div className="filters--set">
          <input
            type="date"
            className="filters__input"
            value={startDate}
            onChange={onStartDateChange}
            name="startDate"
          />
          <input
            type="date"
            className="filters__input"
            value={finishDate}
            onChange={onFinishDateChange}
            name="finishDate"
          />
        </div>
      </div>
      <div className="filters-title filters-title__margin-L">
        <span>#Transactions: {numberTrans}</span>
        <Link to="/transactions/new">Create Transaction</Link>
        <Link to="/descriptions/new">Create Description</Link>
      </div>
    </div>
  );
};

export default PropertyFilters;
