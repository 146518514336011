import React from "react";

const SelectOptionsSmall = React.forwardRef(
  ({ options, name, errors, onChange, pad = 2 }, ref) => {
    const error = (errors || {})[name]?.[0];
    return (
      <div>
        {error ? <p className="error-explanation">{error}</p> : null}
        <select
          className="form__select--small"
          name={name}
          ref={ref}
          onChange={onChange}
        >
          <option value="">None Selected</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {pad ? String(option).padStart(pad, "0") : option}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export default SelectOptionsSmall;
