import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { Link, Redirect, useLocation } from "react-router-dom";

import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../helpers/helper";

const Schedule = () => {
  const [{ mgr, userLoading }] = useStateReducer();
  const [showLoader, setShowLoader] = useState(true);
  const [jobsToday, setJobsToday] = useState([]);
  const [oddJobsToday, setOddJobsToday] = useState([]);
  const [closeJobs, setCloseJobs] = useState([]);
  const [jobTitle, setJobTitle] = useState(null);
  const [filter, setFilter] = useState('strong');
  const [pc, setPc] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location) {
    //  console.log('Location:', location.state);

    }
  }, [location]);

  const fetchJobsToday = async () => {
    setShowLoader(true);
    const myParams = { list: "scheduled_today", hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners?${qs}`);
    setJobsToday(result.jobs_today);
    setOddJobsToday(result.odd_jobs_today);
    setShowLoader(false);
  };

  const fetchCloseFloaters = async (pc) => {
    if(pc){
      setShowLoader(true);
      const myParams = { list: "window_floaters_list", pc, filter };
      const qs = new URLSearchParams(myParams).toString();
      const result = await ApiClient.request(`/api/partners?${qs}`);
      setCloseJobs(result);
      setPc(pc);
      if(filter === 'strong'){
        pc = pc.substring(0,3);
      }else if(filter === 'stronger'){
        pc = pc.substring(0,5);
      }else if(filter === 'strongest'){
        pc = pc.substring(0,6);
      }
      setJobTitle('[' + pc + ']');
      setShowLoader(false);
    }
  };

  const fetchCloseMorseFloaters = async () => {
    setShowLoader(true);
    const myParams = { list: "window_floaters_morse_list" };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners?${qs}`);
    setCloseJobs(result);
    setJobTitle('[Close to Morse]');
    setShowLoader(false);
  };

  const fetchCloseOslerFloaters = async () => {
    setShowLoader(true);
    const myParams = { list: "window_floaters_osler_list" };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners?${qs}`);
    setCloseJobs(result);
    setJobTitle('[Close to Osler]');
    setShowLoader(false);
  };

  const fetchUnassignedApptsToday = async () => {
    setShowLoader(true);
    const myParams = { list: "window_appts_unass_list_today" };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners?${qs}`);
    setCloseJobs(result);
    setJobTitle('[Unassigned Appts Today]');
    setShowLoader(false);
  }

  const fetchUnassignedApptsTomorrow = async () => {
    setShowLoader(true);
    const myParams = { list: "window_appts_unass_list_tomorrow" };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners?${qs}`);
    setCloseJobs(result);
    setJobTitle('[Unassigned Appts Tomorrow]');
    setShowLoader(false);
  }

  const fetchUpcomingAppts = async () => {
    setShowLoader(true);
    const myParams = { list: "upcoming_ass_appts", hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const jobsToday = await ApiClient.request(`/api/partners?${qs}`);
    setCloseJobs(jobsToday);
    setJobTitle('[Upcoming Appts]');
    setShowLoader(false);
  };

  useEffect(() => {
    if (mgr && mgr.name) {
      fetchJobsToday();
    }
  }, [mgr]);

  const assignJob = async (jobid) => {
    const myParams = { hrid: mgr.hrid, task: 'assignJob' };
    const assignSuccess = await ApiClient.patch(`/api/partners/${jobid}`, myParams);
    fetchJobsToday();
    if(jobTitle === '[Close to Osler]'){
      fetchCloseOslerFloaters();
    }else if(jobTitle === '[Close to Morse]'){
      fetchCloseMorseFloaters();
    }else if(jobTitle === '[Unassigned Appts]'){
      fetchUnassignedAppts();
    }else if(jobTitle === '[Unassigned Appts Today]'){
      fetchUnassignedApptsToday();
    }else if(jobTitle === '[Unassigned Appts Tomorrow]'){
      fetchUnassignedApptsTomorrow();
    }else if(jobTitle === '[Upcoming Appts]'){
      fetchUpcomingAppts();
    }else{
      fetchCloseFloaters(pc);
    }
  }

  const unAssignJob = async (jobid) => {
    const myParams = { hrid: mgr.hrid, task: 'unAssignJob' };
    const unAssignSuccess = await ApiClient.patch(`/api/partners/${jobid}`, myParams);
    fetchJobsToday();
    if(jobTitle === '[Close to Osler]'){
      fetchCloseOslerFloaters();
    }else if(jobTitle === '[Close to Morse]'){
      fetchCloseMorseFloaters();
    }else if(jobTitle === '[Unassigned Appts]'){
      fetchUnassignedAppts();
    }else if(jobTitle === '[Unassigned Appts Today]'){
      fetchUnassignedApptsToday();
    }else if(jobTitle === '[Unassigned Appts Tomorrow]'){
      fetchUnassignedApptsTomorrow();
    }else if(jobTitle === '[Upcoming Appts]'){
      fetchUpcomingAppts();
    }else{
      fetchCloseFloaters(pc);
    }
  }

  const fetchCloseByPostCodeFloaters = async (e) => {
    let pc = e.target.value;
    const myElement = document.getElementById('postCode');

    pc = pc.toUpperCase();
    myElement.value = pc;
    setPc(pc);
    if(pc.length > 3){
      setShowLoader(true);
      const myParams = { list: "window_floaters_list", pc };
      const qs = new URLSearchParams(myParams).toString();
      const result = await ApiClient.request(`/api/partners?${qs}`);
      setCloseJobs(result);
      setPc(pc);
      if(filter === 'strong'){
        pc = pc.substring(0,3);
      }else if(filter === 'stronger'){
        pc = pc.substring(0,5);
      }else if(filter === 'strongest'){
        pc = pc.substring(0,6);
      }
      setJobTitle('[Custom Floater List]');
      setShowLoader(false);
    }
  };

  const showCloseFloaters = () => {
    const tableData = () => {
      const result = closeJobs.map(job => (
        <tr key={job.jobid}>
          <td>{job.jobid}</td>
          <td>{job.jobdesc}</td>
          <td>{formatCurrency(job.price)}</td>
          <td>{job.address}</td>
          <td>{job.postcode}</td>
          <td>{job.perly}</td>
          <td>{makeNiceDate(job.sdate)}</td>
          <td>{makeNiceDate(job.fdate)}</td>
          <td>{job.stime}</td>
          {!job.crewname ?
            <td><button className="button is-primary is-small" onClick={() => assignJob(job.jobid)}>Assign</button></td> :
            <td><button className="button is-primary is-small" onClick={() => unAssignJob(job.jobid)}>{job.crewname}</button></td>
          }
        </tr>
      ));
      return result;
    };
    return (
      <div>
        <div className='columns'>
          <div className='column'>
            <button
              className="button is-primary ml-3"
              onClick={() => fetchCloseMorseFloaters()}
            >
              Close Morse Fltrs
            </button>
          </div>
          <div className='column'>
            <button
              className="button is-primary ml-3"
              onClick={() => fetchCloseOslerFloaters()}
            >
              Close Osler Fltrs
            </button>
          </div>
          <div className='column'>
            <button
              className="button is-primary ml-3"
              onClick={() => fetchUnassignedApptsToday()}
            >
              Unass Appts Today
            </button>
          </div>
          <div className='column'>
            <button
              className="button is-primary ml-3"
              onClick={() => fetchUnassignedApptsTomorrow()}
            >
              Unass Appts Tomorrow
            </button>
          </div>
          <div className='column'>
            <button
              className="button is-primary ml-3"
              onClick={() => fetchUpcomingAppts()}
            >
              Upcoming Appts
            </button>
          </div>
        </div>
        <div className="control">
          <label className="radio">
            <input type="radio" name="answer" defaultChecked={true} onClick={ () => setFilter('strong') } />
            Strong Filter
          </label>
          <label className="radio">
            <input type="radio" name="answer" onClick={ () => setFilter('stronger') }/>
            Stronger Filter
          </label>
          <label className="radio">
            <input type="radio" name="answer" onClick={ () => setFilter('strongest') }/>
            Strongest Filter
          </label>

        </div><br/>

        <div className='columns'>
          <div className='column is-3'>
            <input id='postCode' className="input is-primary is-narrow" type="text" placeholder="Enter Postal Code(4+)" defaultValue={ pc } onKeyUp={ fetchCloseByPostCodeFloaters } />
          </div>
        </div>

        <h1 className="subtitle">Close Jobs: {closeJobs.length} { jobTitle ? jobTitle : null}</h1>
        <div className='container'>
          <table className="table">
            <thead>
              <tr>
                <td>JobID</td>
                <td>Job Desc</td>
                <td>Price</td>
                <td>Address</td>
                <td>Post Code</td>
                <td>Perly</td>
                <td>Start Date</td>
                <td>Finish Date</td>
                <td>Start Time</td>
                <td>Option</td>
              </tr>
            </thead>
            <tbody>{tableData()}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const showJobsToday = () => {
    const tableDataWindows = () => {
      const result = jobsToday.map(job => (
        <tr key={job.jobid}>
          <td>
            {job.sdate !== job.fdate ?
              <button className="button is-danger is-small" onClick={() => unAssignJob(job.jobid)}>UnAssign</button>
              : null
            }
          </td>
          <td>{job.jobid}</td>
          <td>{job.jobdesc}</td>
          <td>{formatCurrency(job.price)}</td>
          <td>{job.address}</td>
            <td>{job.postcode}</td>
            <td>{job.perly}</td>
            <td>{makeNiceDate(job.sdate)}</td>
            <td>{makeNiceDate(job.fdate)}</td>
            <td>{job.stime}</td>
            <td>
            <button
              className="button is-primary is-small"
              onClick={() => fetchCloseFloaters(job.postcode)}
            >
              Close Floaters
            </button>
          </td>
        </tr>
      ));
      return result;
    };

    const tableDataOddJobs = () => {
      const result = oddJobsToday.map(job => (
        <tr key={job.id}>
            <td>{job.id}</td>
            <td>{job.jobdesc}</td>
            <td>{formatCurrency(job.price)}</td>
            <td>{job.address}</td>
            <td>{job.postcode}</td>
            <td>{job.perly}</td>
            <td>{makeNiceDate(job.sdate)}</td>
            <td>
              <a href={`/oddjobs/${job.id}/admin?source=partner_schedule`}>
                <u>Admin</u>
              </a>
            </td>
            <td>
            <button
              className="button is-primary is-small"
              onClick={() => fetchCloseFloaters(job.postcode)}
            >
              Close Floaters
            </button>
          </td>
        </tr>
      ));
      return result;
    };


    return (
      <div>
        <h1 className="mt-1 subtitle">Window Jobs Scheduled Today: {jobsToday.length}</h1>
        { jobsToday.length > 0 ?
        <div className='container'>
          <table className="table">
            <thead>
              <tr>
                <td>Unassign</td>
                <td>JobID</td>
                <td>Job Desc</td>
                <td>Price</td>
                <td>Address</td>
                <td>Post Code</td>
                <td>Perly</td>
                <td>Start Date</td>
                <td>Finish Date</td>
                <td>Start Time</td>
                <td>Option</td>
              </tr>
            </thead>
            <tbody>{tableDataWindows()}</tbody>
          </table>
        </div> : null }
        <br/>
          <h1 className="mt-1 subtitle">Odd Jobs Scheduled Today: {oddJobsToday.length}</h1>
          { oddJobsToday.length > 0 ?
          <div className='container'>
            <table className="table">
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Job Desc</td>
                  <td>Price</td>
                  <td>Address</td>
                  <td>Post Code</td>
                  <td>Perly</td>
                  <td>Start Date</td>
                  <td>Option</td>
                </tr>
              </thead>
              <tbody>{tableDataOddJobs()}</tbody>
            </table>
          </div> : null }
          <br/>
      </div>
    );
  };

  return <div>
    { showLoader ? <Loader /> :
    (
    <Layout>
      <div className="container">
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to="/partner_dashboard">Partner Dashboard</Link>
            </li>
            <li className="is-active">
              <Link to="/partner_schedule">Schedule</Link>
            </li>
          </ul>
        </nav>
        <hr />
        <div>
            { showJobsToday() }
            <hr />
            { closeJobs && showCloseFloaters() }
        </div>
      </div>
    </Layout>
  )}
</div>
};

export default Schedule;
