import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { useStateReducer } from "../../base/context/authContext";

import ApiClient from "../../base/api/client";
//import LoaderBox from "./LoaderBox";
import Loader from "../../base/layout/Loader";

import CheckoutForm from "./CheckoutForm";
import { fetchCustomer,
   createCustomer,
   createSetupIntent,
   fetchPaymentMethod,
   testCustomer,
   deleteCard,
   unsecureUpcomingJobs,
   secureUpcomingJobs,
   fetchCardList   } from '../helpers/stripe'

export const StripeCardSetup = ({ boxStyle , propTitle, jobid, clientAction }) => {
  const [{ stripePromise, client, user, type, email, dashboardShowFunction }, dispatch] = useStateReducer();
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [setupIntents, setSetupIntents] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [cardList, setCardList] = useState(null);
  const [title, setTitle] = useState('');
  const [showLoader, setShowLoader] = useState(true);

  const fetchStripeCustomer = async (cfid) => {
    let response = await fetchCustomer(cfid);
    if(response.customer){
      setStripeCustomer(response.customer);
    } else {
      let customer = await createCustomer(cfid);
      setStripeCustomer(customer);
    }
  }

  const createStripeSetupIntent = async () => {
    const setupIntent = await createSetupIntent(stripeCustomer.id, client, jobid, user, type, email, dashboardShowFunction);
    setTitle('Enter card details.')
    setClientSecret(setupIntent.client_secret);
  }

  useEffect(() => {
    if(stripeCustomer){
      //fetchStripeSetupIntents(stripeCustomer.id);
      createCardList();
    }
  },[stripeCustomer]);

  const deleteCreditCard = async (pm_id) => {
    const response = await deleteCard(pm_id);
    createCardList();
  }

  const createCardList = async () => {
    const response = await fetchCardList(stripeCustomer.id);
    setCardList(response.cards.data ? response.cards.data : []);
  }

  useEffect(() => {
    if(cardList){
      if(cardList.length == 0){
        unsecureUpcomingJobs(client.cfid);
      }else{
        secureUpcomingJobs(client.cfid);
      }
    }
  }, [cardList])


  useEffect(() => {
    if(client){
      fetchStripeCustomer(client.cfid);
    }
  },[client]);

  const markStatus = async () => {
    const response = await ApiClient.request(`/api/clients/${client.cfid}/mark_cc_status_required`);
  }

  useEffect(() => {
    if(cardList && cardList.length>0 && client){
      setTitle(cardList.length === 1 ? 'Card on File' : 'Cards on File');
    } else {
      setTitle("No Cards on File");
      // markStatus();
    }
  },[cardList, client]);

  const options = {
    clientSecret: clientSecret,
    appearance: {
      rules: {
        '.TermsText': {
          marginBottom: '100px'
        }
      }
    }
  };

  const fetchLocalCardList = () => {
    let listCards = [];
    if(cardList && cardList.length>0){
      listCards = cardList.map((cardObject, index) => {
        return (
          <div className='columns is-mobile' key={index}>
            <span className='column is-3-mobile is-one-quarter-tablet'>{ cardObject.card.brand.charAt(0).toUpperCase() + cardObject.card.brand.slice(1) }</span>
            <span className='column is-3-mobile is-one-quarter-tablet'>....{ cardObject.card.last4 }</span>
            <span className='column is-3-mobile is-one-quarter-tablet'>Expiry: { cardObject.card.exp_month }/{ cardObject.card.exp_year }</span>
            <button className='button is-narrow mt-2 is-small is-danger column is-3-mobile is-one-quarter-tablet' onClick={ () => deleteCreditCard(cardObject.id)}>Delete Card</button>
          </div>
        )
      })
    }
    // else{
    //   return null;
    // };
    if(listCards.length > 0){
      return (
        <div className='box'>
          { listCards }
          { title && title.includes('on File')?   <button className='button is-small is-link' onClick={createStripeSetupIntent}>Add Card</button> : null}
        </div>
      )
    } else {
      return (
        <div className='box'>
          { title && title.includes('on File')?   <button className='button is-small is-link' onClick={createStripeSetupIntent}>Add Card</button> : null}
        </div>
      )
    }
  }

  const enterCardForm = () => {
    if(options.clientSecret){
      return (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          buttonText='Submit Card To Stripe'
          confirmType='confirmSetup'
          jobid={jobid}
          clientAction={clientAction}
        />
      </Elements>)
    }else{
      return null;
    }
  }

  return (<div className={boxStyle}>

      <h2 className='title is-4 has-text-centered'>{ propTitle }</h2>
      <hr/>
      <h3 className='title is-5'>{ title }</h3>
      { cardList === null ? <Loader /> : fetchLocalCardList() }
      { enterCardForm() }

      <hr/>
      <p>Payment is processed 7+ days after job completion and no pending quality issue</p>
      <p>Card Details are directly and securely stored on <b>Stripe</b>(<a href="https://stripe.com" target="_blank">more info</a>)</p>
    </div>)
};
