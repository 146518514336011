import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { totalBurdenOnlyFormat, formatCurrencyZero, makeNiceDate, CPP, EI, EHT, WSIB, VP, totalBurdenFormat } from "../../../helpers/helper";

const Labour = ({ showDisplay }) => {
  console.log("SHOW DISPLAY ", showDisplay);
  const [{ mgr }] = useStateReducer();
  const [showLoader, setShowLoader] = useState(true);
  const [wss, setWss] = useState([]);
  const [mts, setMts] = useState([]);
  const [wssTotals, setWssTotals] = useState({});
  const [mtsTotals, setMtsTotals] = useState({});

  const fetchLabour = async () => {
    setShowLoader(true)

    let list;
    if (showDisplay === 'Labour 2023'){
      list = 'list_2023';
    } else if (showDisplay === 'Labour 2024'){
      list = 'list_2024';
    }

    console.log("LIST ", list);

    let myParams = { hrid: mgr.hrid, list};
    let qs = new URLSearchParams(myParams).toString();
    let result = await ApiClient.request(`/api/workschedules?${qs}`);
    setWss(result.wss);

    myParams = { hrid: mgr.hrid, list };
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/mgt_tasks?${qs}`);
    setMts(result.mts);
    setShowLoader(false);
   }

  const fetchWssTotals = () => {
    let totPay = parseFloat(0);
    let totCPP = parseFloat(0);
    let totEI = parseFloat(0);
    let totEHT = parseFloat(0);
    let totWSIB = parseFloat(0);
    let totVP = parseFloat(0);

    wss.forEach((ws) =>{
       totPay += parseFloat(ws.pay);
       totCPP += (parseFloat(ws.pay) * CPP);
       totEI += (parseFloat(ws.pay) * EI);
       totEHT += (parseFloat(ws.pay) * EHT);
       totWSIB += (parseFloat(ws.pay) * WSIB);
       totVP += (parseFloat(ws.pay) * VP);
    })
    setWssTotals({
      totPay,
      totCPP,
      totEI,
      totEHT,
      totWSIB,
      totVP
    })
  }

  const fetchMtsTotals = () => {
    let totPay = parseFloat(0);
    let totCPP = parseFloat(0);
    let totEI = parseFloat(0);
    let totEHT = parseFloat(0);
    let totWSIB = parseFloat(0);
    let totVP = parseFloat(0);

    mts.forEach((mt) =>{
       totPay += parseFloat(mt.pay);
       totCPP += (parseFloat(mt.pay) * CPP);
       totEI += (parseFloat(mt.pay) * EI);
       totEHT += (parseFloat(mt.pay) * EHT);
       totWSIB += (parseFloat(mt.pay) * WSIB);
       totVP += (parseFloat(mt.pay) * VP);
    })
    setMtsTotals({
      totPay,
      totCPP,
      totEI,
      totEHT,
      totWSIB,
      totVP
    })
  }

  useEffect(() => {
    if(mgr){
      fetchLabour();
    }
  }, [mgr, showDisplay])

  useEffect(() => {
    if(wss){
      fetchWssTotals();
    }
  }, [wss])

  useEffect(() => {
    if(mts){
      fetchMtsTotals();
    }
  }, [mts])

  const showWssTableData = () =>
    wss.map((ws) => <tr key={ws.id}>
          <td>{ makeNiceDate(ws.profiledate) }</td>
          <td>{ ws.name }</td>
          <td>{ ws.paymethod }</td>
          <td>{ formatCurrencyZero(ws.pay) }</td>
          <td>{ formatCurrencyZero(parseFloat(ws.pay) * CPP) }</td>
          <td>{ formatCurrencyZero(parseFloat(ws.pay) * EI) }</td>
          <td>{ formatCurrencyZero(parseFloat(ws.pay) * EHT) }</td>
          <td>{ formatCurrencyZero(parseFloat(ws.pay) * WSIB) }</td>
          <td>{ formatCurrencyZero(parseFloat(ws.pay) * VP) }</td>
          <td>{ totalBurdenOnlyFormat(ws.pay) }</td>
          <td>{ totalBurdenFormat(ws.pay) }</td>
          <td>{ ws.audited ? 'Audited' : 'Pending' }</td>
        </tr>
    )

  const showWssTable = () => (
    <div className='box'>
      <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ showDisplay }</h1><br/>

      <h1 className='title has-text-weight-bold has-text-info'>Pay Entries</h1>
      <div className='table-container'>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Pay Method</th>
              <th>Pay</th>
              <th>Emp CPP</th>
              <th>Emp EI</th>
              <th>EHT</th>
              <th>WSIB</th>
              <th>VP</th>
              <th>Burden</th>
              <th>Total</th>
              <th>Audited</th>
              </tr>
          </thead>
          <tbody>
            { showWssTableData() }
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{ formatCurrencyZero(wssTotals.totPay) }</td>
              <td>{ formatCurrencyZero(wssTotals.totCPP) }</td>
              <td>{ formatCurrencyZero(wssTotals.totEI) }</td>
              <td>{ formatCurrencyZero(wssTotals.totEHT) }</td>
              <td>{ formatCurrencyZero(wssTotals.totWSIB) }</td>
              <td>{ formatCurrencyZero(wssTotals.totVP) }</td>
              <td>{ totalBurdenOnlyFormat(wssTotals.totPay) }</td>
              <td>{ totalBurdenFormat(wssTotals.totPay) }</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )

  const showMtsTableData = () =>
    mts.map((mt) => <tr key={mt.id}>
          <td>{ makeNiceDate(mt.date) }</td>
          <td>{ mt.name }</td>
          <td>{ mt.paymethod }</td>
          <td>{ formatCurrencyZero(mt.pay) }</td>
          <td>{ formatCurrencyZero(parseFloat(mt.pay) * CPP) }</td>
          <td>{ formatCurrencyZero(parseFloat(mt.pay) * EI) }</td>
          <td>{ formatCurrencyZero(parseFloat(mt.pay) * EHT) }</td>
          <td>{ formatCurrencyZero(parseFloat(mt.pay) * WSIB) }</td>
          <td>{ formatCurrencyZero(parseFloat(mt.pay) * VP) }</td>
          <td>{ totalBurdenOnlyFormat(mt.pay) }</td>
          <td>{ totalBurdenFormat(mt.pay) }</td>
          <td>{ mt.audited ? 'Audited' : 'Pending' }</td>
        </tr>
    )

  const showMtsTable = () => (
    <div className='box'>
      <h1 className='title has-text-weight-bold has-text-info'>Pending Pay Entries</h1>
      <div className='table-container'>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Pay Method</th>
              <th>Pay</th>
              <th>Emp CPP</th>
              <th>Emp EI</th>
              <th>EHT</th>
              <th>WSIB</th>
              <th>VP</th>
              <th>Burden</th>
              <th>Total</th>
              <th>Audited</th>
              </tr>
          </thead>
          <tbody>
            { showMtsTableData() }
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{ formatCurrencyZero(mtsTotals.totPay) }</td>
              <td>{ formatCurrencyZero(mtsTotals.totCPP) }</td>
              <td>{ formatCurrencyZero(mtsTotals.totEI) }</td>
              <td>{ formatCurrencyZero(mtsTotals.totEHT) }</td>
              <td>{ formatCurrencyZero(mtsTotals.totWSIB) }</td>
              <td>{ formatCurrencyZero(mtsTotals.totVP) }</td>
              <td>{ totalBurdenOnlyFormat(mtsTotals.totPay) }</td>
              <td>{ totalBurdenFormat(mtsTotals.totPay) }</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )


  return(
    <div>
      { showLoader ? <Loader /> :
        <div>
          { wss && showWssTable() }
          { mts && showMtsTable() }
        </div>
      }
    </div>
  )
}

export default Labour;
