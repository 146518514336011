import React, { useState, useEffect } from 'react';

import { StripeCardSetup } from "./StripeCardSetup";
import JobAddress from "../forms/components/JobAddress";
import JobType from "../forms/components/JobType";
import JobDate from "../forms/components/JobDate";
import JobNotes from "../forms/components/JobNotes";

const BookWindowsSummerForm = ({
  register,
  getValues,
  handleSubmit,
  submitForm,
  errorMsgs,
  changeJobType,
  onChangeJobSpecs,
  fltrDateClass,
  apptDateClass,
  boxStyle,
  setDashboardMessage,
  setDashboardShowFunction,
  allowSubmit
}) => {

  const [buttonState, setButtonState] = useState('button is-primary mt-5');

  useEffect(() => {
    if(Object.keys(errorMsgs).length>0){
      setButtonState('button is-primary mt-5');
    }
  }, [errorMsgs])

  const disableButton = () => {
    setButtonState('button is-primary mt-5 is-loading');
  }

  return (<div style={boxStyle} className='box'>
    <h2 className='title is-4 has-text-centered'>Book Spring/Summer Window Cleaning 2024</h2>

    <form onSubmit={handleSubmit(submitForm)}>
      <JobAddress
        register={register}
        getValues={getValues}
        errorMsgs={errorMsgs}
        onChangeJobSpecs={onChangeJobSpecs}
      />

      <JobType
        register={register}
        errorMsgs={errorMsgs}
        changeJobType={changeJobType}
        type='Windows'
      />

      <JobDate
        register={register}
        errorMsgs={errorMsgs}
        fltrDateClass={fltrDateClass}
        apptDateClass={apptDateClass}
        onChangeJobSpecs={onChangeJobSpecs}
        type='WindowsSummer'
      />

      <JobNotes
        register={register}
      />

      <button className={ buttonState } type='submit' onClick={ disableButton } disabled={ !allowSubmit }>Create Job</button>

    </form>


  </div>)
}

export default BookWindowsSummerForm
