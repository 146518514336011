import React from "react";

const Select = React.forwardRef(
  ({ label, id, options = [], children, name, placeholder }, ref) => {
    return (
      <div className="field margin-right">
        {label ? (
          <label className="label" htmlFor={id}>
            {label}
          </label>
        ) : null}
        <div className="select">
          <select className="select" name={name} id={id} ref={ref}>
            {placeholder ? <option value="">{placeholder}</option> : null}

            {children
              ? children
              : options.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
          </select>
        </div>
      </div>
    );
  }
);

export default Select;
