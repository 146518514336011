import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { createBrowserHistory } from "history";


import { NavLink } from "react-router-dom";
import LoginAPI from "../../base/api/login";
import { useStateReducer } from "../../base/context/authContext";
import ActionType from "../../base/context/actionType";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faCogs, faDiceFour, faAd, faMapMarker } from '@fortawesome/free-regular-svg-icons'

const USER = <FontAwesomeIcon icon={faUser} />


const HeaderLink = ({ children, to, logOut, className, activeClassName }) => {
  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={to}
      onClick={logOut}
      exact
    >
      {children}
    </NavLink>
  );
};

const headerStyle = {
    position: 'static',
    flex: '0 0 auto'
}

const Header = () => {
  const [{ client, user, email, type, logInClass, systemSource }, dispatch] = useStateReducer();
  const [menuLaunched, setMenuLaunched] = useState("menu-links-container");
  const [menuOpen, setMenuOpen] = useState(false);
  const [partialURL, setPartialURL] = useState('');
  const [partialSystemSourceURL, setPartialSystemSourceURL] = useState('');
  const [burgerClass, setBurgerClass] = useState("button navbar-burger is-link");
  const [menuClass, setMenuClass] = useState("navbar-menu");
  const history = useHistory();
  console.log('User:', user);
  useEffect(() => {
    if(client){
      setPartialURL("/client_profiles/"+ client.cfid + "/edit");
    }
  }, [client])

  useEffect(() => {
    if(systemSource){
        setPartialSystemSourceURL("/sharktales/recbles_with_card");
    }
  }, [systemSource])

  const attemptLogout = async () => {
    await LoginAPI.logOut();
    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
    dispatch({
      type: ActionType.SetSystemSource,
      payload: null,
    });
    dispatch({
      type: ActionType.SetClient,
      payload: null,
    });
    dispatch({
      type: ActionType.SetEmail,
      payload: null,
    });
    dispatch({
      type: ActionType.SetType,
      payload: null,
    });
    dispatch({
      type: ActionType.SetProps,
      payload: [],
    });
    history.push('/');
  };

  const logOut = () => {
    attemptLogout();
  };


  const onChangeLogIn = () => {
    logInClass === "modal is-small is-clipped" && !user ?
    dispatch({type: ActionType.SetLogInClass, payload: "modal is-active is-small is-clipped"}) :
    dispatch({type: ActionType.SetLogInClass, payload: "modal is-small is-clipped"});
    dispatch({type: ActionType.SetDashboardShowFunction, payload: null});
    history.push('/');
    if( !user ){
      logOut();
    }
  }

  const menuClick = () => {
    burgerClass === "button navbar-burger is-link" ?
    setBurgerClass("button navbar-burger is-link is-active") : setBurgerClass("button navbar-burger is-link")
    menuClass === "navbar-menu" ?
    setMenuClass("navbar-menu is-active") : setMenuClass("navbar-menu")
  }

  return (
    <div className='headerStyle'>
       <nav className='navbar is-link' role='navigation' aria-label='Main Navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <HeaderLink to="/" className="navbar-item has-text-weight-bold is-size-5">
              White Shark Ltd
            </HeaderLink>
            <button className={burgerClass} data-target='navMenu' onClick={menuClick}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className={menuClass} id='navMenu'>
            <div className='navbar-start'>
              <HeaderLink to="/" className="navbar-item">
                Home
              </HeaderLink>
              <HeaderLink to="/services" className="navbar-item">
                Services
              </HeaderLink>
              <HeaderLink to="/about" className="navbar-item">
                About
              </HeaderLink>
              <HeaderLink to="/contact" className="navbar-item">
                Contact
              </HeaderLink>
            </div>
            <div className='navbar-end'>
              { user ?
                 <div className='navbar-item has-dropdown is-hoverable'>
                   <a href="#0" className='navbar-link'>{ USER } <span className='ml-2'>{email} {type}</span></a>
                   <div className='navbar-dropdown is-boxed'>
                     <HeaderLink to="/dashboard" className="navbar-item is-fullwidth">
                       My Dashboard
                     </HeaderLink>

                     { type === 'INTERNAL_USER' ?
                     <HeaderLink to="/smart_search" className="navbar-item is-fullwidth">
                       Smart Search(Under Construction)
                     </HeaderLink> : null }
                     { type === 'INTERNAL_USER' ?
                     <React.Fragment>
                     <a className="navbar-item is-fullwidth" href="/functions/smartsearch">
                       Smart Search
                     </a>
                     <a className="navbar-item is-fullwidth" href={partialURL}>
                       Client Profile({ client && client.lastname })
                     </a>
                     {
                        systemSource ?
                        (<a className="navbar-item is-fullwidth" href={partialSystemSourceURL}>
                          Back To Recbles With Card</a>) : null

                     }

                     <a className="navbar-item is-fullwidth" href="/functions/login1">
                       Main Menu
                     </a>
                     <a className="navbar-item is-fullwidth" href={`/crews/react_crew_mgt/runsheet`}>
                       Back To Runsheet
                     </a>
                   </React.Fragment>
                     : null }



                   { (user === 'HR00000001' || user === 'HR00005495' || user === 'HR00005658' || user === 'HR00005352'|| user === 'HR00000762') ?
                   <React.Fragment>
                     <a className="navbar-item is-fullwidth" href={`/crews/react_crew_mgt/partner_receivables`}>
                       Back To Partner Receivables
                     </a>
                   </React.Fragment>
                     : null }

                     <button className="navbar-item button is-small is-fullwidth has-text-centered has-text-weight-bold" onClick={logOut}>
                       Log Out
                     </button>
                   </div>
                 </div>
               :
               <button className="navbar-item button is-light is-small mt-3 has-text-weight-bold has-text-info" onClick={onChangeLogIn}>
                  Log In
               </button>
             }
           </div>
         </div>
       </div>
      </nav>
      </div>
  );
};

export default Header;
