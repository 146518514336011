import dayjs from "dayjs";

export const CPP = parseFloat(.0595);
export const EI = parseFloat(.0228);
export const EHT = parseFloat(.0195);
export const WSIB = parseFloat(.0283);
export const VP = parseFloat(.04);

export const royaltyCalculator = (job, royalty, royalty1) => {
  let appliedRoyalty = royalty;

  console.log('royaltyCalculator:', job.crewname);
  console.log('royalty1:', royalty1);

  if(job.crewname === 'Gezim Kapllani'){
    console.log('In');
    if(new Date(job.datebi) >= new Date("2024-10-01")) {
      appliedRoyalty = royalty1;
      console.log('In AGAIN');

    }
  }else if(new Date(job.datebi) >= new Date("2023-08-01") && new Date(job.datebi) <= new Date("2024-04-21")) {
    appliedRoyalty = royalty1;
  }


  console.log(job.datebi, appliedRoyalty);
  return appliedRoyalty;
}

export const formatCurrency = (amount) => {
  if (!amount || amount === "0.0") return "TBD";
  return "$" + parseFloat(amount).toFixed(2);
};


function round(figureToRound){
    var roundOff = Math.round((figureToRound * 100 ).toFixed(2))/100;
    return roundOff;
}

export const formatCurrencyZero = (amount) => {
  //if (!amount) return "TBD";
  // return "$" + parseFloat(amount).toFixed(2);
   let temp  = round(amount, 2);
   temp = temp.toFixed(2);
   return "$" + temp;
};

export const formatCurrencyNoSign = (amount) => {
  if (!amount || amount === "0.0") return "0.00";
  return parseFloat(amount).toFixed(2);
};



export const addDaysToCurrentDate = (days) => {
  const newDate = new Date(Date.now() + days * 864e5);
  return newDate;
};

export const formatDate = (date = new Date()) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const convertToMinutes = (time) => {
  let hrs = time.substring(0, 2);
  hrs = hrs * 60;
  let mins = time.substring(3, 5);
  mins = parseInt(hrs) + parseInt(mins);
  return mins;
};

export const convertToTime = (mins) => {
  let hrs = Math.floor(parseInt(mins) / 60);
  hrs = hrs.length === 1 ? "0" + hrs : hrs;
  mins = mins % 60;
  mins = mins.toString().length === 1 ? "0" + mins.toString() : mins.toString();
  return hrs + ":" + mins;
};

export const convertToHours = (date, stime, ftime) => {
  const start = dayjs(date + "T" + stime);
  const finish = dayjs(date + "T" + ftime);
  const hours = finish.diff(start, "h", true).toFixed(2);

  return finish.diff(start, "h", true).toFixed(2);
};

export const getPay = (hours, rate) => {
  return (parseFloat(rate.substring(1, rate.length)) * hours).toFixed(2);
};

export const makeNiceDate = (sqlDate) => {
  const day = dayjs(sqlDate);
  return day.format("ddd MMM DD YYYY");
};

export const toNaiveTime = (ts) => {
  const offset = new Date().getTimezoneOffset();
  return dayjs(ts).add(offset, "minutes");
};

export const calcPercentage = (amount, total) => {
  let ans = (parseFloat(amount) / parseFloat(total)) * 100;
  return ans.toFixed(0);
};

export const validateData = (data,workMinutes) => {
    let temp = {};

    if(data.recStatus.includes('Cheque') && data.chqNum === ''){
      temp.chqNumberMustBeEntered = 'Cheque number must be entered';
    }
    if(data.recStatus.includes('Cash') && data.cashAmount === ''){
      temp.cashAmountMustBeEntered = 'Cash amount must be entered';
    }
    if(data.recStatus.includes('Cash') && data.cashAmount !== '' && isNaN(data.cashAmount)){
      temp.cashAmountMustBeANumber = 'Cash amount must be a number';
    }
    if(data.recStatus.includes('Cash') && data.cashAmount !== '' && !isNaN(data.cashAmount) && parseFloat(data.cashAmount)<=0){
      temp.cashAmountMustBeGreaterThanZero = 'Cash amount must be greater than zero';
    }
    if(data.startHourPrior === ''){
      temp.startHourPriorCannotBeBlank='Start Hour cannot be blank';
    }
    if(data.startMinutePrior === ''){
      temp.startMinutePriorCannotBeBlank='Start Minutes cannot be blank';
    }
    if(data.estMins === ''){
      temp.estMinsEntered='Est Mins must be entered';
    }
    if(isNaN(data.estMins)){
      temp.estMins='Est Mins must be a number';
    }
    if(!isNaN(data.estMins) && parseFloat(data.estMins)<=0){
      temp.estMinsGreaterThanZero = 'Est Mins must be greater than 0';
    }

    if(data.startHour === ''){
      temp.startHourCannotBeBlank='Start Hour cannot be blank';
    }
    if(data.startMinute === ''){
      temp.startMinuteCannotBeBlank='Start Minutes cannot be blank';
    }

    if(data.finishHour === ''){
      temp.finishHourCannotBeBlank='Finish Hour cannot be blank';
    }
    if(data.finishMinute === ''){
      temp.finishMinuteCannotBeBlank='Finish Minutes cannot be blank';
    }


    if(workMinutes <= 0){
      temp.workMinutesGreaterThanZero = 'Work Minutes must be greater than 0';
    }
    if(data.w1Check && isNaN(data.w1Text)){
      temp.w1TextMustBeANumber = 'W1 must be a number';
    }
    if(data.w1Check && !isNaN(data.w1Text) && parseFloat(data.w1Text)<=0){
      temp.w1TextMustBeGreaterThanZero = 'W1 must be greater than zero';
    }
    if(data.w2Check && isNaN(data.w2Text)){
      temp.w2TextMustBeANumber = 'W2 must be a number';
    }
    if(data.w2Check && !isNaN(data.w2Text) && parseFloat(data.w2Text)<=0){
      temp.w2TextMustBeGreaterThanZero = 'W2 must be greater than zero';
    }
    if(data.w3Check && isNaN(data.w3Text)){
      temp.w3TextMustBeANumber = 'W3 must be a number';
    }
    if(data.w3Check && !isNaN(data.w3Text) && parseFloat(data.w3Text)<=0){
      temp.w3TextMustBeGreaterThanZero = 'W3 must be greater than zero';
    }
    if(data.w4Check && isNaN(data.w4Text)){
      temp.w4TextMustBeANumber = 'W4 must be a number';
    }
    if(data.w4Check && !isNaN(data.w4Text) && parseFloat(data.w4Text)<=0){
      temp.w4TextMustBeGreaterThanZero = 'W4 must be greater than zero';
    }
    if(data.ehCheck && isNaN(data.ehText)){
      temp.ehTextMustBeANumber = 'EH must be a number';
    }
    if(data.ehCheck && !isNaN(data.ehText) && parseFloat(data.ehText)<=0){
      temp.ehTextMustBeGreaterThanZero = 'EH must be greater than zero';
    }
    if(data.egCheck && isNaN(data.egText)){
      temp.egTextMustBeANumber = 'EG must be a number';
    }
    if(data.egCheck && !isNaN(data.egText) && parseFloat(data.egText)<=0){
      temp.egTextMustBeGreaterThanZero = 'EG must be greater than zero';
    }
    if(data.customCheck && isNaN(data.customText)){
      temp.customTextMustBeANumber = 'Custom price must be a number';
    }
    if(data.customCheck && !isNaN(data.customText) && parseFloat(data.customText)<=0){
      temp.customTextMustBeGreaterThanZero = 'Custom price must be greater than zero';
    }
    if(data.recStatus === 'None Selected'){
      temp.recStatusMustBeSelected = 'Select receivable status'
    }
    if(data.lawnSign === 'None Selected'){
      temp.lawnSignMustBeSelected = 'Select lawn sign status'
    }
    if(data.clientHome === 'None Selected'){
      temp.clientHomeMustBeSelected = 'Select client home status'
    }
    if(data.nextDest === 'None Selected'){
      temp.nextDestinationMustBeSelected = 'Next destination must be selected';
    }
    if(Object.keys(temp).length > 0){
      if(Object.keys(temp).length===1){
        temp.totalErrors = `There is ${Object.keys(temp).length } error`;
      } else {
        temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
      }
    }
    return temp;
}

export const validateStartData = (data) => {
  let temp = {};
  if(data.startHourPrior === ''){
    temp.startHourPriorCannotBeBlank='Start Hour cannot be blank';
  }
  if(data.startMinutePrior === ''){
    temp.startMinutePriorCannotBeBlank='Start Minutes cannot be blank';
  }
  if(data.estMins === ''){
    temp.estMinsEntered='Est Mins must be entered';
  }
  if(isNaN(data.estMins)){
    temp.estMins='Est Mins must be a number';
  }
  if(!isNaN(data.estMins) && parseFloat(data.estMins)<=0){
    temp.estMinsGreaterThanZero='Est Mins must be greater than 0';
  }
  return temp;
}

export const validateDnfData = (data) => {
    let temp = {};

    if(data.startMinute === ''){
      temp.startMinuteCannotBeBlank = 'Start Minutes cannot be blank';
    }
    if(data.startHour === ''){
      temp.startHourCannotBeBlank = 'Start Hour cannot be blank';
    }

    if(data.finishMinute === ''){
      temp.finishMinuteCannotBeBlank = 'Finish Minutes cannot be blank';
    }
    if(data.finishHour === ''){
      temp.finishHourCannotBeBlank = 'Finish Hour cannot be blank';
    }


    if(data.minutes <= 0){
      temp.dnfMinutesGreaterThanZero = 'DNF Minutes must be greater than 0';
    }
    if(data.dnfNotes === ''){
      temp.dnfNotesMustBeFilled = 'DNF notes must be filled out'
    }
    if(data.valid === 'None Selected'){
      temp.validMustBeSelected = 'Select a valid status'
    }
    if(data.clientHome === 'None Selected'){
      temp.clientHomeMustBeSelected = 'Select client home status'
    }
    if(data.recStatus !== 'PAID' && data.recStatus === 'None Selected'){
      temp.recStatusMustBeSelected = 'Select receivable status'
    }
    if(data.recStatus.includes('Cash') && data.cashAmount === ''){
      temp.cashAmountMustBeEntered = 'Cash amount must be entered';
    }
    if(data.recStatus.includes('Cash') && data.cashAmount !== '' && isNaN(data.cashAmount)){
      temp.cashAmountMustBeANumber = 'Cash amount must be a number';
    }
    if(data.recStatus.includes('Cash') && data.cashAmount !== '' && !isNaN(data.cashAmount) && parseFloat(data.cashAmount)<=0){
      temp.cashAmountMustBeGreaterThanZero = 'Cash amount must be greater than zero';
    }
    if(data.nextDest === 'None Selected'){
      temp.nextDestinationMustBeSelected = 'Next destination must be selected';
    }

    if(Object.keys(temp).length > 0){
      if(Object.keys(temp).length===1){
        temp.totalErrors = `There is ${Object.keys(temp).length } error`;
      } else {
        temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
      }
    }
    return temp;
}

export const totalBurdenFormat = (amount) =>
  formatCurrencyZero(
    parseFloat(amount) +
    parseFloat(amount) * parseFloat(CPP) +
    parseFloat(amount) * parseFloat(EI) +
    parseFloat(amount) * parseFloat(EHT) +
    parseFloat(amount) * parseFloat(WSIB) +
    parseFloat(amount) * parseFloat(VP)
  )

export const totalBurdenOnlyFormat = (amount) =>
  formatCurrencyZero(
    parseFloat(amount) * parseFloat(CPP) +
    parseFloat(amount) * parseFloat(EI) +
    parseFloat(amount) * parseFloat(EHT) +
    parseFloat(amount) * parseFloat(WSIB) +
    parseFloat(amount) * parseFloat(VP)
  )

export const totalBurden = (amount) =>
    parseFloat(amount) +
    parseFloat(amount) * parseFloat(CPP) +
    parseFloat(amount) * parseFloat(EI) +
    parseFloat(amount) * parseFloat(EHT) +
    parseFloat(amount) * parseFloat(WSIB) +
    parseFloat(amount) * parseFloat(VP)
