import React from "react";
import SHARK from "../assets/sharkloader-3.gif";
import BALLS from "../assets/sharkloader-4.gif";


const Loader = () => {
  return (
    <div className='is-flex is-flex-direction-column is-align-items-center is-justify-content-center'>
      <div><img src={SHARK} alt="shark!!" width='150px' height='auto' /></div>
      <div><img src={BALLS} alt="shark!!" width='150px' height='auto' /></div>
    </div>
  );
};

export default Loader;
