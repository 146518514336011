import { useState } from "react";
import ApiClient from "../api/client";

const useLazyRequest = (url, body) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [called, setCalled] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    setCalled(true);
    const payload = body ? JSON.stringify(body) : undefined;
    const response = await ApiClient.request(url, {
      method: body ? "POST" : "GET",
      body: payload,
    });
    setData(response);
    setLoading(false);
  };

  return [
    fetchData,
    {
      data,
      loading,
      called,
    },
  ];
};

export default useLazyRequest;
