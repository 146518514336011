import React, { useMemo, useEffect, useState } from "react";

import DisplayPanel from "../components/DisplayPanel";
import Loader from "../components/Loader";
import Layout from "../layout/Layout";
import Filters from "../components/Filters";
//import { useStateReducer } from "../context/authContext";
import { useHistory, useLocation } from "react-router";
import useRequest from "../hooks/useRequest";
import { formatDate, addDaysToCurrentDate } from "../helpers/helper";
import ApiClient from "../api/client";
import CrewJobs from "../components/CrewJobs";

const MakePackages = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const view = params.get("view") || null;
  const schDate = params.get("schDate") || formatDate(addDaysToCurrentDate(1));
  const soldBefore = params.get("soldBefore") || formatDate(addDaysToCurrentDate(1));
  const crewName = params.get("crewName") || null;
  const [search, setSearch] = useState("");

  const filterParams = useMemo(
    () => ({
      view,
      schDate,
      soldBefore,
      crewName,
    }),
    [location.search]
  );
  const onUpdateParams = (e) => {
    const { name, value } = e.target;
    const newSearch = new URLSearchParams({
      ...filterParams,
      [name]: value,
    }).toString();
    setSearch(newSearch);
    history.push(`/makepackages?${newSearch}`);
    console.log(filterParams);
  };

  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [perly, setPerly] = useState("");

  const { data: jobs, loading, refetch: refetchJobs } = useRequest(
    "/api/jobs/upcoming",
    filterParams
  );

  const {
    data: crewJobs,
    loading: loadingCrewJobs,
    refetch: refetchCrewJobs,
  } = useRequest("/api/jobs/crew_jobs", {
    schdate: schDate,
    soldbefore: soldBefore,
    crew: crewName,
  });

  const onPostCodeChange = (e) => {
    setPostcode(e.target.value);
  };

  const onAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const onPerlyChange = (e) => {
    setPerly(e.target.value);
  };

  const resetJobs = () => {
    return (jobs ?? []).filter((job) => {
      return (
        job.postcode.toLowerCase().includes(postcode.toLowerCase()) &&
        job.perly.toLowerCase().includes(perly.toLowerCase()) &&
        job.address.toLowerCase().includes(address.toLowerCase())
      );
    });
  };

  const sortCrewJobs = () => {
    return (crewJobs ?? []).sort((a, b) => a.num - b.num);
  };

  const clearFields = () => {
    setAddress("");
    setPostcode("");
    setPerly("");
  };

  const killNumJob = async (jobid) => {
    const response = await ApiClient.request(
      `/api/jobs/${jobid}/unass_num`,
      null
    );
    refetchJobs();
    refetchCrewJobs();
  };

  return (
    <Layout>
      <div className="container">
        <Filters
          onUpdateParams={onUpdateParams}
          onPostCodeChange={onPostCodeChange}
          onAddressChange={onAddressChange}
          onPerlyChange={onPerlyChange}
          numberJobs={resetJobs(jobs).length}
          schDate={schDate}
          soldBefore={soldBefore}
          view={view}
          crewName={crewName}
        />
        {crewName ? (
          <CrewJobs
            crewJobs={sortCrewJobs(crewJobs)}
            crewName={crewName}
            schDate={schDate}
            killNumJob={killNumJob}
            refetchJobs={refetchJobs}
            refetchCrewJobs={refetchCrewJobs}
          />
        ) : null}
        {!loading ? (
          <div className="app-doodle-job-list">
            {resetJobs().map((job) => (
              <DisplayPanel
                job={job}
                crewName={crewName}
                schDate={schDate}
                key={job.jobid}
                killNumJob={killNumJob}
                refetchJobs={refetchJobs}
                refetchCrewJobs={refetchCrewJobs}
              />
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Layout>
  );
};

export default MakePackages;
