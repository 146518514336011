import React, { useState, useEffect } from "react";
import Layout from "../../../layout/Layout";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import ApiClient from "../../../../base/api/client";
import { useStateReducer } from "../../../../base/context/authContext";

import { makeNiceDate, formatCurrency } from "../../../helpers/helper";

const ClientAdmin = () => {
  const params = useParams();
  const [{ user, mgr, userLoading}, dispatch] = useStateReducer();
  const location = useLocation();
  const [cfid, setCfid] = useState(null);
  const [jobid, setJobid] = useState(null);
  const [client, setClient] = useState(null);
  const [job, setJob] = useState(null);
  const [jobAddress, setJobAddress] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [cards, setCards] = useState(null);

  useEffect(() => {
    if (location) {
      console.log('Location State:', location.state);
      setJobid(location.state.jobid);
    }
  }, [location]);

  useEffect(() => {
    if (params) {
      setCfid(params.cfid);
    }
  }, [params]);

  const fetchClient = async () => {
    const result = await ApiClient.request(`/api/partners/${jobid}/list_of_cards`);
    console.log(result);
    setClient(result.client);
    setJob(result.job);
    setJobAddress(result.job_address);
    setCards(result.cards ? result.cards.data : null);
  //  console.log('Cards:', result.cards.data);
    setFullname(result.full_name);
  }

  useEffect(() => {
    if (cfid) {
      fetchClient();
    }
  }, [cfid]);

  const showClientInfo = () => <div>
    <h1 className='subtitle'>{ client.company ? client.company : null }</h1>
    <h1 className='subtitle'>{ fullname }</h1>
    <h1 className='subtitle'>Billing Address:{ client.address }</h1>
    <h1 className='subtitle'>{ client.email }</h1>
    <h1 className='subtitle'>Job Address:{ jobAddress }</h1>
    <h1 className='subtitle'>{ job.jobdesc }</h1>
    <h1 className='subtitle'>{ formatCurrency(job.price) } + HST</h1>
    <hr/>
  </div>

  const showCardInfo = () => {
    const cardData = cards.map((card) => <tr key={card.id}>
      <td>{ card.card.last4 }</td>
      <td>{ card.card.exp_month }/{ card.card.exp_year }</td>
    </tr>)

    return <div>
      <table className='table is-striped'>
        <thead>
          <tr>
            <td>Last 4</td>
            <td>Expiry</td>
          </tr>
        </thead>
        <tbody>
          { cardData }
        </tbody>
      </table>
    </div>

  }
  return (
    <Layout>
      <div className="container">
        <nav className="breadcrumb mt-3">
          <ul>
            <li>
              <Link to="/partner_dashboard">Partner Dashboard</Link>
            </li>
            <li>
              <Link to="/partner_receivables">Receivables</Link>
            </li>
            <li className="is-active">
              <Link to="/partner_client_admin">Client Admin</Link>
            </li>
          </ul>
        </nav>
        <hr />
          { client && job && jobAddress && showClientInfo() }
          { cards && showCardInfo() }
          <a href={`/crews/react_client_dashboard?cfid=${cfid}`}>
            <u>Pay By Stripe</u>
          </a>

        <hr />
      </div>
    </Layout>
  );
};

export default ClientAdmin;
