import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";


import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { formatCurrencyZero } from "../../helpers/helper";

const Jobs = () => {
  const [{ mgr, userLoading }] = useStateReducer();
  const [showLoader, setShowLoader] = useState(true);
  const [targets, setTargets] = useState([]);
  const [totalTarget, setTotalTarget] = useState(0);
  const [totalTargetWindows, setTotalTargetWindows] = useState(0);
  const [totalTargetOddJobs, setTotalTargetOddJobs] = useState(0);
  const [totalTargetPaint, setTotalTargetPaint] = useState(0);
  const [totalProduced, setTotalProduced] = useState(0);
  const [totalProducedWindows, setTotalProducedWindows] = useState(0);
  const [totalProducedPaint, setTotalProducedPaint] = useState(0);
  const [totalProducedOddJobs, setTotalProducedOddJobs] = useState(0);


  const fetchTargets = async () => {
    setShowLoader(true);
    const myParams = { list: 'targets', hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/partners?${qs}`);
    console.log('Result', result);
    setTargets(result);
    let totalTarget = 0;
    let totalTargetWindows = 0;
    let totalTargetOddJobs = 0;
    let totalTargetPaint = 0;
    let totalProduced = 0;
    let totalProducedWindows = 0;
    let totalProducedOddJobs = 0;
    let totalProducedPaint = 0;
    result.forEach((target) => {
      totalTarget += parseFloat(target && target.windows ? target.windows : 0) + parseFloat(target && target.odd_jobs ? target.odd_jobs : 0) + parseFloat(target && target.paint ? target.paint : 0);
      totalTargetWindows += parseFloat(target && target.windows ? target.windows : 0);
      totalTargetOddJobs += parseFloat(target && target.odd_jobs ? target.odd_jobs : 0);
      totalTargetPaint += parseFloat(target && target.paint ? target.paint : 0);
      totalProduced += parseFloat(target && target.windows_prod ? target.windows_prod : 0) + parseFloat(target && target.odd_jobs_prod ? target.odd_jobs_prod : 0) + parseFloat(target && target.paint_prod ? target.paint_prod : 0);
      totalProducedWindows += parseFloat(target && target.windows_prod ? target.windows_prod : 0);
      totalProducedOddJobs += parseFloat(target && target.odd_jobs_prod ? target.odd_jobs_prod : 0);
      totalProducedPaint += parseFloat(target && target.paint_prod ? target.paint_prod : 0);
    })
    setTotalTarget(totalTarget);
    setTotalTargetWindows(totalTargetWindows);
    setTotalTargetOddJobs(totalTargetOddJobs);
    setTotalTargetPaint(totalTargetPaint);
    setTotalProduced(totalProduced);
    setTotalProducedWindows(totalProducedWindows);
    setTotalProducedOddJobs(totalProducedOddJobs);
    setTotalProducedPaint(totalProducedPaint);
    setShowLoader(false);
  }

  useEffect(() => {
    if(mgr && mgr.name){
      fetchTargets();
    }
  }, [mgr])

  const showTargets = () => {

    const tableData = () => {
      const result = targets.map((target) => {
        if(target){
        return <React.Fragment key={target.id}>
          <tr key={target.id}>
            <td className='has-text-info'>{ target.period }</td>
            <td className='has-text-info'>{ formatCurrencyZero(target.windows) }</td>
            <td className='has-text-info'>{ formatCurrencyZero(target.odd_jobs) }</td>
            <td className='has-text-info'>{ formatCurrencyZero(target.paint) }</td>
            <td className='has-text-info'>{ formatCurrencyZero(target.total_target) }</td>
            <td>{ formatCurrencyZero(target.windows_prod) }</td>
            <td>{ formatCurrencyZero(target.odd_jobs_prod) }</td>
            <td>{ formatCurrencyZero(target.paint_prod) }</td>
            <td>{ formatCurrencyZero(target.total_prod) }</td>
            <td className='has-text-success'>{ formatCurrencyZero(
                parseFloat(target.windows_prod ? target.windows_prod : 0)
              + parseFloat(target.odd_jobs_prod ? target.odd_jobs_prod : 0)
              + parseFloat(target.paint_prod ? target.paint_prod : 0)
            -  parseFloat(target.windows ? target.windows : 0)
            - parseFloat(target.odd_jobs ? target.odd_jobs : 0)
            - parseFloat(target.paint ? target.paint : 0)
            ) }</td>
            <td className='has-text-success'>N/A</td>
          </tr>
          <tr key={String(target.id) + 'notes'}>
              <td className='has-text-info'>{ target.period } Notes</td>
              <td colSpan='10'>{ target.notes }</td>
          </tr>
        </React.Fragment>
      }else{
        return null;
      }
      })
        return result;
      }
    return (
      <div>
        <h1 className='title has-text-centered'>Plan</h1>
        <table className='table is-striped'>
          <thead>
            <tr key='head1'>
              <th colSpan='5' className='has-text-centered has-text-info'>Projections</th>
              <th colSpan='4' className='has-text-centered'>Produced</th>
              <th colSpan='2' className='has-text-centered'>Difference</th>
            </tr>
            <tr key='head2'>
              <th className='has-text-info'>Period</th>
              <th className='has-text-info'>Windows</th>
              <th className='has-text-info'>Odd Jobs</th>
              <th className='has-text-info'>Painting</th>
              <th className='has-text-info'>Total Proj</th>
              <th>Windows</th>
              <th>Odd Jobs</th>
              <th>Painting</th>
              <th>Total Prod</th>
              <th className='has-text-centered has-text-success'>$</th>
              <th className='has-text-success'>%</th>
            </tr>
          </thead>
          <tbody>
            { targets && tableData() }
          </tbody>
          <tfoot>
            <tr key='total'>
              <td className='has-text-info'>Total</td>
              <td className='has-text-info'>{ formatCurrencyZero(totalTargetWindows) }</td>
              <td className='has-text-info'>{ formatCurrencyZero(totalTargetOddJobs) }</td>
              <td className='has-text-info'>{ formatCurrencyZero(totalTargetPaint) }</td>
              <td className='has-text-info'>{ formatCurrencyZero(totalTarget) }</td>
              <td>{ formatCurrencyZero(totalProducedWindows) }</td>
              <td>{ formatCurrencyZero(totalProducedOddJobs) }</td>
              <td>{ formatCurrencyZero(totalProducedPaint) }</td>
              <td>{ formatCurrencyZero(totalProduced) }</td>
              <td className='has-text-success'>{ formatCurrencyZero(
                  parseFloat(totalProducedWindows)
                + parseFloat(totalProducedOddJobs)
                + parseFloat(totalProducedPaint)
              -  parseFloat(totalTargetWindows)
              - parseFloat(totalTargetOddJobs)
              - parseFloat(totalTargetPaint)
            ) }</td>
          <td className='has-text-success'>N/A</td>
            </tr>
          </tfoot>
        </table>
      </div>)
  }

  return(
    <Layout>
      <div className='container'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className='breadcrumb mt-3'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li className='is-active'><Link to="/partner_plan">Plan</Link></li>
          </ul>
        </nav>

        <hr/>
        { showLoader ? <Loader /> : showTargets() }
      </div>
    </Layout>
  )

}

export default Jobs;
