import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const Tasks = ({ tasks }) => {
  console.log('Tasks', tasks);
  return (
    <table className="crewpay-table">
      <thead>
        <tr>
          <th className="crewpay-table__header">Task Notes</th>
          <th className="crewpay-table__header">Start Time</th>
          <th className="crewpay-table__header">Finish Time</th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task) => {
          return (
            <tr key={task.stime}>
              <td className="crewpay-table__body">{task.task_desc}</td>
              <td className="crewpay-table__body">{task.stime}</td>
              <td className="crewpay-table__body">{task.ftime}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Tasks;
