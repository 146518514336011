import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";

const SafetyTalk = () => {
  const [safetyTalk, setSafetyTalk] = useState([]);
  const [crewsOsler, setCrewsOsler] = useState([]);
  const [crewsMorse, setCrewsMorse] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  //const [buttonStatus, setButtonStatus] = useState('is-loading')
  const [{ user, userLoading }] = useStateReducer();

  const fetchSafetyTalk = async () => {
    if (user) {
       const result = await ApiClient.request(`/api/crews/safety_talk`);
       console.log('RES SAFETY Talk:', result);
       setSafetyTalk(result.safety_talk);
    }
  }

  const fetchCrewsByShop = async () => {
    if (user) {
       const result = await ApiClient.request(`/api/crews/crews_by_shop`);
       console.log('RES crew by shop:', result);
       setCrewsOsler(result.crews_osler);
       setCrewsMorse(result.crews_morse);
    }
  }

  useEffect(() => {
    fetchSafetyTalk();
    fetchCrewsByShop();
  }, [user])

  const displaySafetyTalk = () =>
  <div className='box has-background-info-light'>
   <h1 className='subtitle has-text-centered'>Safety Talk</h1>
   {safetyTalk.map(point => <li key={point.id}>{ point.point }</li>)}
  </div>

  const changeStatus = async(hrid) => {
    setShowLoader(true);
    console.log('HRIDDDD', hrid);
    const result = await ApiClient.request(`/api/crews/${hrid}/toggle_attendance`);
    console.log('RES', result);
    fetchCrewsByShop();
    setShowLoader(false);
  }

  const displayOslerCrews = () =>
    crewsOsler.map(crew =>
    <nav className="level" key={crew.hrid}>
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-5">
            <strong>{ crew.name }</strong>
          </p>
        </div>
      </div>

      <div className="level-right">
          <button
            className={ crew.safety_read == 'yes' ? "button is-rounded is-info" : "button is-rounded is-danger"}
            onClick={ () => changeStatus(crew.hrid) }
          >{ crew.safety_read == 'yes' ? 'Present' : 'Absent'}</button>
      </div>
    </nav>)

    const displayMorseCrews = () =>
      crewsMorse.map(crew =>
      <nav className="level" key={crew.hrid}>
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong>{ crew.name }</strong>
            </p>
          </div>
        </div>

        <div className="level-right">
            <button
              className={ crew.safety_read == 'yes' ? "button is-rounded is-info" : "button is-rounded is-danger"}
              onClick={ () => changeStatus(crew.hrid) }
            >{ crew.safety_read == 'yes' ? 'Present' : 'Absent'}</button>
        </div>
      </nav>)



  return (
    <Layout>
      <div className='section'>
        <div className="container">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li className='is-active'><Link to="/safety-talk">Safety Talk</Link></li>
            </ul>
          </nav>

          { safetyTalk && displaySafetyTalk() }

          { showLoader && <Loader /> }

          <div className='mt-2 columns'>
            <div className='column mr-3 mb-3 has-background-light'>
              <div className='box'>
                <h3 className='title is-4'>Osler Crews </h3>
                { crewsOsler && displayOslerCrews() }
              </div>
            </div>
           <div className='column mr-3 mb-3 has-background-light'>
             <div className='box'>
               <h3 className='title is-4'>Morse Crews </h3>
               { crewsMorse && displayMorseCrews() }
             </div>
           </div>
          </div>
          { showLoader && <Loader /> }
        </div>
      </div>
    </Layout>
  );
};

export default SafetyTalk;
