import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";
import { formatCurrency, makeNiceDate } from "../helpers/helper";


const CrewPayments = () => {
  const [payments, setPayments] = useState([])
  const [{ user, userLoading }] = useStateReducer();

  const fetchPayments = async () => {
    if (user) {
       const result = await ApiClient.request(`/api/crews/fetch_payments`);
       console.log('Result', result);
       console.log('USER', user);
       setPayments(result.payments);
    }
  }

  useEffect(() => {
    fetchPayments();
  }, [user])

  const markCloser = async(id, closer_type) => {
    console.log(id);
    console.log(user);
    const myOptions = {
      id,
      hrid: user.hrid,
      by: closer_type
    }
    const result = await ApiClient.patch(`/api/crews/mark_payment_closer`, myOptions);
    fetchPayments();
  }

  const boxStyle = (payment) => {
    if (payment.conf1 !== 'nil' && payment.conf2 !== 'nil'){
      return "box has-background-link"
    }else if(payment.conf1 !== 'nil' && payment.conf2 === 'nil'){
      return "box has-background-primary"
    }else{
      return "box has-background-danger"
    }
  }

  const showPayments = () => payments.map((payment) =>
    <div className={ boxStyle(payment) } key={payment.id}>
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="subtitle is-5">
                <strong>{ makeNiceDate(payment.date_done).substring(0, makeNiceDate(payment.date_done).length-4) }</strong>
              </p>
            </div>
            <div className="level-item">
              <p className="subtitle is-5">
                <strong>{ payment.shop }</strong>
              </p>
            </div>
            <div className="level-item">
              <p className="subtitle is-5">
                <strong>{ payment.jobid }</strong>
              </p>
            </div>
            <div className="level-item">
              <p className="subtitle is-5">
                <strong>{ payment.crew }</strong>
              </p>
            </div>
            <div className="level-item">
              <p className="subtitle is-5">
                <strong>{ payment.payment_type }</strong>
              </p>
            </div>
            <div className="level-item">
              <p className="subtitle is-4">
                <strong>{ formatCurrency(payment.amount) }</strong>
              </p>
            </div>
          </div>

          <div className="level-right">
            <p className="level-item"><a onClick={ () => markCloser(payment.id, 'conf1') } className="button is-success">{ payment.conf1 !== 'nil' ? payment.conf1 : 'Closer' }</a></p>
            <p className="level-item"><a onClick={ () => markCloser(payment.id, 'conf2') } className="button is-link">{ payment.conf2 !== 'nil' ? payment.conf2 : 'Processed' }</a></p>
          </div>
        </nav>



    </div>
  )



  return (
    <Layout>
      <div className='section'>
        <div className="container">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li className='is-active'><Link to="/open-close">Crew Payments</Link></li>
            </ul>
          </nav>
          { payments && showPayments() }
        </div>
      </div>
    </Layout>
  );
};

export default CrewPayments;
