import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect , useParams } from "react-router-dom";

import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../helpers/helper"




const Leads = () => {
  let routeParams = useParams();
  console.log('Route Params', routeParams);
  const we = "Window Estimates";
  const oje = "Odd Job Estimates";
  const pe = "Paint Estimates";
  const mess = "Messages";
  const [{ mgr, user, userLoading }] = useStateReducer();
  const [windowEsts, setWindowEsts] = useState([]);
  const [windowEstsInd, setWindowEstsInd] = useState([]);
  const [completedWindowEsts, setCompletedWindowEsts] = useState([]);
  const [oddJobEsts, setOddJobEsts] = useState([]);
  const [paintEsts, setPaintEsts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [resolvedMessages, setResolvedMessages] = useState([]);

  const [showDisplay, setShowDisplay] = useState(null);   //('Window Estimates')
  const [windowClass, setWindowClass] = useState('button is-link')
  const [paintClass, setPaintClass] = useState('button is-link ml-5')
  const [oddjobClass, setOddjobClass] = useState('button is-link')
  const [messageClass, setMessageClass] = useState('button is-link')

  const [windowEstsNum, setWindowEstsNum] = useState(0);
  const [messagesNum, setMessagesNum] = useState(0);
  const [showLoader, setShowLoader ] = useState(true);

  const fetchNumbers = async () => {
    setShowLoader(true);
    const myParams = { list: 'lead_numbers', hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const numbers = await ApiClient.request(`/api/partners?${qs}`);
    setWindowEstsNum(numbers.windowEstsNum);
    setMessagesNum(numbers.messagesNum);
    setShowLoader(false);
  }

  const fetchWinEavesEstimates = async () => {
    setShowLoader(true);
    console.log('Mgr', mgr);
    const myParams = { list: 'window_estimate_list', hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const windowEsts = await ApiClient.request(`/api/partners?${qs}`);
    setWindowEsts(windowEsts.pending_windows_ests);
    const ests = windowEsts.pending_windows_ests;
    const windowEstsIndTemp = ests.filter((est) => (est.estimator === user.fullname || est.holder === user.fullname));
    setWindowEstsInd(windowEstsIndTemp);
    setCompletedWindowEsts(windowEsts.completed_windows_ests);
    setShowDisplay('Window Estimates');
    setShowLoader(false);
  }

  const fetchOddJobEstimates = async () => {
    setShowLoader(true);
    const myParams = { list: 'odd_job_list' };
    const qs = new URLSearchParams(myParams).toString();
    const oddJobEsts = await ApiClient.request(`/api/partners?${qs}`);
    setOddJobEsts(oddJobEsts);
    setShowDisplay('Odd Job Estimates');
    setShowLoader(false);
  }

  const fetchPaintEstimates = async () => {
    setShowLoader(true);
    const myParams = { list: 'paint_list' };
    const qs = new URLSearchParams(myParams).toString();
    const paintEsts = await ApiClient.request(`/api/partners?${qs}`);
    setPaintEsts(paintEsts);
    setShowDisplay('Paint Estimates');
    setShowLoader(false);
  }

  const fetchMessages = async () => {
    setShowLoader(true);
    const myParams = { list: 'messages', hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const messages = await ApiClient.request(`/api/partners?${qs}`);
    setMessages(messages.messages);
    setResolvedMessages(messages.resolved_messages)
    setShowDisplay('Messages');
    setShowLoader(false);
  }

  useEffect(() => {
    if(mgr && mgr.name && routeParams){
      if(routeParams.list === "Odd Job Estimates"){
        changeDisplay('Odd Job Estimates');
      } else if(routeParams.list === "Paint Estimates"){
        changeDisplay('Paint Estimates');
      } else if(routeParams.list === "Messages"){
        changeDisplay('Messages');
      } else if (routeParams.list === "Window Estimates"){
        changeDisplay('Window Estimates');
      }
      fetchNumbers();
    }
  }, [mgr, routeParams])

  const changeDisplay = (display) => {
    setShowDisplay(display);
    if(display === 'Window Estimates'){
      setWindowClass('button is-warning');
      setPaintClass('button is-link ml-5');
      setOddjobClass('button is-link');
      setMessageClass('button is-link');
      fetchWinEavesEstimates();
    }else if(display === 'Paint Estimates'){
      setWindowClass('button is-link');
      setPaintClass('button is-warning ml-5');
      setOddjobClass('button is-link');
      setMessageClass('button is-link');
      fetchPaintEstimates();
    }else if(display === 'Odd Job Estimates'){
      setWindowClass('button is-link');
      setPaintClass('button is-link ml-5');
      setOddjobClass('button is-warning');
      setMessageClass('button is-link');
      fetchOddJobEstimates();
    }else if(display === 'Messages'){
      setWindowClass('button is-link');
      setPaintClass('button is-link ml-5');
      setOddjobClass('button is-link');
      setMessageClass('button is-warning');
      fetchMessages();
    }
  }

  const assignEstimate = async (msid) => {
    const myParams = { hrid: mgr.hrid };
    //const qs = new URLSearchParams(myParams).toString();
    const success = await ApiClient.patch(`/api/partners/${msid}`, myParams);
    if(showDisplay === 'Window Estimates'){
      fetchWinEavesEstimates();
    }else if(showDisplay === 'Odd Job Estimates'){
      fetchOddJobEstimates();
    }else if(showDisplay === 'Paint Estimates'){
      fetchPaintEstimates();
    }
  }

  const buttonEstimateChoices = (est) => {
    // if(est.holder !== 'Unknown' && est.followobject == mgr.hrid){
    //   return <button className='button is-danger' onClick={ () => assignEstimate(est.msid) }>{ est.holder }</button>
    // } else if(est.followobject === mgr.hrid) {
    //   return <button className='button is-link' onClick={ () => assignEstimate(est.msid) }>{ est.holder }</button>
    // } else {
    //   return <button className='button is-primary' onClick={ () => assignEstimate(est.msid) }>Assign</button>
    // }
    if(est.followobject && est.followobject.includes('HR')){
      return <button className='button is-danger' onClick={ () => assignEstimate(est.msid) }>{ est.holder }</button>
    } else {
      return <button className='button is-primary' onClick={ () => assignEstimate(est.msid) }>Assign</button>
    }
  }

  const tableData = (list) => {
     const result = list.map((est) => (
      <tr key={est.msid} className={showDisplay === 'Window Estimates' && est.message.includes('Written') ? 'has-background-info has-text-white' : null}>
        <td>{ makeNiceDate(est.messdate) }</td>
        <td>{ est.age }</td>
        <td>{ est.estimator }</td>
        <td>
          <u>
            <a style={{color: 'black'}} href={`https://www.google.ca/maps/place/${est.address_plus} ${est.city}`} target="_blank">
              {est.address_plus}
            </a>
          </u>
        </td>
        <td>{ est.postcode }</td>
        <td>{ est.perly }</td>
        <td>{ est.message }</td>
        <td>{ buttonEstimateChoices(est) } </td>
        <td>
          { showDisplay === 'Paint Estimates' ?
            <Link className='button is-primary' to={`/paint_lead_admin/${est.msid}`}>Admin</Link> :
          null }
          { showDisplay === 'Odd Job Estimates' ?
            <Link className='button is-primary' to={`/odd_job_lead_admin/${est.msid}`}>Admin</Link> :
          null }
          { showDisplay === 'Window Estimates' && mgr.hrid === est.followobject && est.message.includes("Written") ?
            <Link className='button is-primary' to={`/window_lead_admin/${est.msid}`}>Admin</Link> :
          null }
          { showDisplay === 'Window Estimates' && mgr.hrid === est.followobject && est.message.includes("Pick Up") ?
            <Link className='button is-primary' to={`/pick_up_admin/${est.msid}`}>Admin</Link> :
          null }
        </td>
      </tr>
    ))
    return result;
  }


  const showReport = (list) => {
    return (
      <div>
        <h1 className='mb-6 title has-text-centered has-text-link'>{ showDisplay }</h1>
        { list.length > 0 ?
          <div>
            { windowEstsInd && showIndReport(windowEstsInd) }
            <h1 className='subtitle has-text-link'>Pending</h1>
            <div className='table-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Age</th>
                  <th>Mgr</th>
                  <th>Address</th>
                  <th>Post Code</th>
                  <th>Perly</th>
                  <th>Details</th>
                  <th>Holder</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                { tableData(list) }
              </tbody>
            </table><br/>
          </div>
        </div> : showLoader && <Loader /> }
        { showDisplay === 'Window Estimates' && completedWindowEsts && showCompletedWindowEstsReport() }
      </div>
    );
  }

  const showIndReport = (list) => {
    return (
      <div>
        { list.length > 0 ?
          <div>
            <h1 className='subtitle has-text-link'>Individual Pending</h1>
            <div className='table-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Age</th>
                  <th>Mgr</th>
                  <th>Address</th>
                  <th>Post Code</th>
                  <th>Perly</th>
                  <th>Details</th>
                  <th>Holder</th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                { tableData(list) }
              </tbody>
            </table><br/>
          <hr/>
          </div>
        </div> : null }
      </div>
    );
  }

  const showCompletedWindowEstsReport = () => {

    const estData = (list) => {
       const result = list.map((est) => (
        <tr key={est.msid}>
          <td>{ makeNiceDate(est.resolved) }</td>
          <td>{ est.estimator }</td>
          <td>{ est.address_plus }</td>
          <td>{ est.postcode }</td>
          <td>{ est.perly }</td>
          <td>{ formatCurrency(est.w1) }</td>
          <td>{ formatCurrency(est.eh) }</td>
          <td className='has-text-centered'>{ est.days_to_estimate }</td>
        </tr>
      ))
      return result;
    }

    return (
      <div>
        <h1 className='subtitle has-text-link'>Completed Estimates</h1>
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Date Done</th>
                <th>Estimator</th>
                <th>Address</th>
                <th>Post Code</th>
                <th>Perly</th>
                <th>W1</th>
                <th>EH</th>
                <th>Days To Complete</th>
              </tr>
            </thead>
            <tbody>
              { estData(completedWindowEsts) }
            </tbody>
          </table>
        </div>
      </div>
    );

  }

  const unResolveEstimate = async (msid) => {
    const result = await ApiClient.patch(`/api/messages/${msid}`, { type: 'unresolve' })
    fetchMessages();
    fetchNumbers();
  }


  const showResolvedMessages = () => {

    const msgData = (list) => {
       const result = list.map((msg) => (
        <tr key={msg.msid}>
          <td>{ makeNiceDate(msg.messdate) }</td>
          <td>{ makeNiceDate(msg.resolved) }</td>
          <td>{ msg.recorder }</td>
          <td>{ msg.message }</td>
          <td>{ msg.days_to_resolve }</td>
          <td><button className='button is-danger' onClick= {() => unResolveEstimate(msg.msid)}>UnResolve Message</button></td>
        </tr>
      ))
      return result;
    }

    return (
      <div>
        <h1 className='subtitle has-text-link'>Resolved Messages</h1>
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Message Date</th>
                <th>Date Resolved</th>
                <th>Recorder</th>
                <th>Message</th>
                <th>Days</th>
                <th>Unresolve</th>
              </tr>
            </thead>
            <tbody>
              { msgData(resolvedMessages) }
            </tbody>
          </table>
        </div>
      </div>
    );

  }

  const resolveEstimate = async (msid) => {
    const result = await ApiClient.patch(`/api/messages/${msid}`, { type: 'resolve' })
    fetchMessages();
    fetchNumbers();
  }

  const showMessages = () => {

    const msgData = (list) => {
       const result = list.map((msg) => (
        <tr key={msg.msid}>
          <td>{ makeNiceDate(msg.messdate) }</td>
          <td>{ msg.recorder }</td>
          <td>{ msg.message }</td>
          <td>{ msg.days_old }</td>
          <td><button className='button is-link' onClick= {() => resolveEstimate(msg.msid)}>Resolve Message</button></td>
        </tr>
      ))
      return result;
    }

    return (
      <div>
        <h1 className='subtitle has-text-link'>Pending Messages</h1>
        <div className='table-container'>
          <table className='table is-striped'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Recorded By</th>
                <th>Message</th>
                <th>Days Outstanding</th>
                <th>Resolve</th>
              </tr>
            </thead>
            <tbody>
              { msgData(messages) }
            </tbody>
          </table>
        </div>
        { showDisplay === 'Messages' && resolvedMessages && showResolvedMessages() }
      </div>
    );

  }


  const showData = () => {
    switch(showDisplay){
      case 'Window Estimates':
        return showReport(windowEsts);
      case 'Paint Estimates':
        return paintEsts.length === 0 ? null : showReport(paintEsts);
      case 'Odd Job Estimates':
        return oddJobEsts.length === 0 ? null : showReport(oddJobEsts);
      case 'Messages':
        return showMessages();
  }

  }

  return(
    <Layout>
      <div className='container'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li className='is-active'><Link to="/partner_leads">Leads</Link></li>
          </ul>
        </nav>

        <div className='columns mt-3'>
          <div className='column'>
            <Link className={ windowClass } to={`/partner_leads/${we}`}>Window Estimates[{ windowEstsNum }]</Link>
          </div>
          <div className='column'>
            <Link className={ paintClass } to={`/partner_leads/${pe}`}>Paint Estimates</Link>
          </div>
          <div className='column'>
            <Link className={ oddjobClass } to={`/partner_leads/${oje}`}>Odd Job Estimates</Link>
          </div>
          <div className='column'>
            <Link className={ messageClass } to={`/partner_leads/${mess}`}>Messages[{ messagesNum }]</Link>
          </div>
        </div>
        <hr/>
        { showLoader && <Loader /> }
        { showData() }
      </div>
    </Layout>
  )

}

export default Leads;
