import React from "react";
import SHARK from "../assets/sharkloader-2.gif";

const Loader = () => {
  return (
    <div className="loader">
      <img src={SHARK} alt="shark!!" />
    </div>
  );
};

export default Loader;
