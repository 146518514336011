import React from "react";


const SelectOptions = React.forwardRef(
  ({ options, label, name, errors }, ref) => {
    const error = (errors || {})[name]?.[0];
    return (
      <div className="form__row">
        <label className="form__label" htmlFor={name}>
          {label}
        </label>
        {error ? <p className="error-explanation">{error}</p> : null}
        <select className="form__select" name={name} ref={ref}>
          <option value="">None Selected</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export default SelectOptions;
