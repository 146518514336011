import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

const LockerInventoryMgt = ({handleDailyReport, user, checkReportIssued, fireDateChanged, currentDate , area}) => {
  const ccForm = useForm();

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>Locker Inventory Mgt{area} [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          name='hrid'
          ref={ register }
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          name='area'
          ref={ register }
          defaultValue = { 'Locker Inventory Mgt'}
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              name='date'
              ref={ register }
              defaultValue = { currentDate }
              onChange = { () => fireDateChanged(getValues('date')) }
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line1'
              ref={ register }
              defaultValue={ "[Restock and charge for missing gear from lockers]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line2'
              ref={ register }
              defaultValue={ "[Keep extra locks on hand]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send Locker Inventory Mgt Report</button>
        </div>
      </form>
    </div>

  )


}

export default LockerInventoryMgt
