import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { useStateReducer } from "../../base/context/authContext";

import ApiClient from "../../base/api/client";
import Loader from "../../base/layout/Loader";
import CheckoutForm from "./CheckoutForm";
import { formatCurrency } from "../helpers/helper";

import { fetchCustomer,
  createCustomer,
  fetchPaymentIntents,
  updatePaymentIntent,
  createPaymentIntent,
  confirmPayment,
  markJobsToPay,
  createIntendedPayment,
  fetchCardList
} from '../helpers/stripe'


const ccStyle = {
  height: 'auto'
}

export const StripeJobPaySecure = ({ boxStyle, clientAction, setMessage }) => {
  const [{ stripePromise, user, type, client, email, dashboardShowFunction }, dispatch] = useStateReducer();
  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [cardList, setCardList] = useState([]);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [jobsToPay, setJobsToPay] = useState(null);
  const [selectedJobsToPay, setSelectedJobsToPay] = useState([]);
  const [selectedAmountToPay, setSelectedAmountToPay] = useState(0);

  const fetchStripeCustomer = async (cfid) => {
    const response = await fetchCustomer(cfid);
    if(response.customer){
      setStripeCustomer(response.customer);
    } else {
      const customer = await createCustomer(cfid);
      setStripeCustomer(customer);
    }
  }

  const fetchJobsToPay = async (cfid) => {
    const result = await ApiClient.request(`/api/clients/${cfid}/receivable_jobs`);
    if(result.length === 0){
      setMessage("No Outstanding Jobs Owing")
      clientAction('message', null);
    }else{
      setJobsToPay(result);
    }
  }

  const fetchStripePaymentIntent = async () => {
    const paymentIntents = await fetchPaymentIntents(stripeCustomer.id);
    if(paymentIntents.length>0){
      setPaymentIntent(paymentIntents[0]);
    }else{
      const createdPaymentIntent = await createPaymentIntent({customer_id: stripeCustomer.id, cfid: client.cfid, user: user, type: type, email: email, dashboardShowFunction: dashboardShowFunction});
      if(createdPaymentIntent){
        setPaymentIntent(createdPaymentIntent);
      }
    }
  }

  useEffect(() => {
    if(client){
      fetchStripeCustomer(client.cfid);
      fetchJobsToPay(client.cfid);
    }
  },[client]);

  useEffect(() => {
    if(jobsToPay){
      let tempJobsArray = [];
      let tempAmountToPay = 0;
      jobsToPay.forEach(job => {
        tempAmountToPay += parseFloat(job.price);
        tempJobsArray.push(job.jobid);
      })
      setSelectedAmountToPay((tempAmountToPay * 1.13).toFixed(2));
      setSelectedJobsToPay(tempJobsArray);
    }
  },[jobsToPay])

  useEffect(async () => {
    if(stripeCustomer && user){
      const response = await fetchCardList(stripeCustomer.id);
      setCardList(response.cards.data);
      !paymentIntent && fetchStripePaymentIntent();
    }
  },[stripeCustomer, user]);

  useEffect(() => {
    if(paymentIntent) {
      setClientSecret(paymentIntent.client_secret);
    }
  }, [paymentIntent]);

  const confirmStripePayment = async (pm, paymentIntent) => {
    const updatedPaymentIntent = await confirmPayment(pm, paymentIntent);
    if(updatedPaymentIntent && updatedPaymentIntent.status == 'succeeded'){
      let hrid;
      if(type == 'INTERNAL_USER'){
        hrid = user;
      }else{
        hrid = user
      }
      const result = await markJobsToPay({
        pi_id: updatedPaymentIntent.id,
        hrid: hrid
      })
      if(result.success){
        setMessage("Stripe Payment of " + formatCurrency(updatedPaymentIntent.amount/ 100) + " Successful");
        clientAction('message', null);
      }
    } else {
      setMessage(updatedPaymentIntent.status)
      clientAction('message', null);
    }
  }

  const showExistingCards = () => {
    const cards = cardList.map((cardObject, index) => (
      <div key={index}>
      <div className='columns'>
          <span className='column has-text-weight-bold is-one-fifth-tablet'>{ cardObject.card.brand.charAt(0).toUpperCase() + cardObject.card.brand.slice(1) }</span>
          <span className='column is-one-fifth-tablet'>....{ cardObject.card.last4 }</span>
          <span className='column is-two-fifth-tablet'>{ cardObject.card.exp_month }/{ cardObject.card.exp_year }</span>
        <button className='button has-text-weight-bold is-narrow mt-2 is-small is-danger column is-fullwidth-mobile is-one-fifths-tablet' disabled={selectedAmountToPay>1 ? false : true} onClick={ () => initializeStripePaymentExistingCard(cardObject)}>
          Apply Payment[Existing Card] {selectedAmountToPay ? formatCurrency(selectedAmountToPay) : null}
        </button>
      </div>
      <hr/>
      </div>
    ));

    return cardList.length > 0 ? <div className='box'>
    <p className='title is-5 has-text-centered'>Pay With Current Card</p>
    { cards } </div> : null
  }

  const checkboxStyle = {
    width:'15px',
    height:'15px',
    background:'white',
    borderRadius:'5px',
    border:'2px solid #555'
  }

  const getJobPrice = (jobid) => {
    let price = 0;
    jobsToPay.forEach((job) => {
      if(job.jobid === jobid){
        price = job.price * 1.13;
      }
    })
    return parseFloat(price);
  }

  const addJobToPay = async (e) => {
    const jobChecked = e.target.checked;
    const jobid = e.target.name;

    let tempJobsArray = selectedJobsToPay;
    let tempAmountToPay = selectedAmountToPay;
    if(jobChecked){
      tempJobsArray.push(e.target.name);
      setSelectedJobsToPay(tempJobsArray);
      tempAmountToPay += parseFloat(getJobPrice(jobid).toFixed(2));
    }else{
      tempJobsArray.pop(e.target.name);
      setSelectedJobsToPay(tempJobsArray);
      tempAmountToPay -= parseFloat(getJobPrice(jobid).toFixed(2));
    }
    if(tempJobsArray.length === 0){
      tempAmountToPay = 0;
    }
    setSelectedAmountToPay(tempAmountToPay);
    setSelectedJobsToPay(tempJobsArray);
  }


  const showJobsToPay = () => jobsToPay.map(job => (
    <div key={job.jobid} className='columns'>
    <input type='checkbox' defaultChecked={true} name={job.jobid} style={checkboxStyle} className='mt-4 column is-one-fifth' onChange={addJobToPay} />
    <span className='ml-1 has-text-warning-light column is-one-fifth'>{ job.jobid }</span>
    <span className='has-text-warning-light column is-one-fifth'>{ job.address }</span>
    <span className='has-text-warning-light column is-one-fifth'>{ job.jobdesc }</span>
    <span className='has-text-warning-light column is-one-quarter'>{ formatCurrency(job.price * 1.13)} (incl HST)</span>
    </div>
  ));

  const createStringJobids = () => {
    let stringJobids = '';
    selectedJobsToPay.forEach((jobid) => {
      stringJobids += jobid+",";
    })
    return stringJobids.substring(0, stringJobids.length - 1);
  }

  const initializeStripePaymentExistingCard = async (pm) => {
    clientAction('loaderBox', null);
    const secondPaymentIntent = await updatePaymentIntent(paymentIntent.id, selectedAmountToPay, createStringJobids());
    setPaymentIntent(secondPaymentIntent);
    await createIntendedPayment({pi_id: secondPaymentIntent.id})
    await confirmStripePayment(pm, secondPaymentIntent);
  }

  const initializeStripePaymentNewCard = async (pi_id, amountToPay, jobsToPay) => {
    const secondPaymentIntent = await updatePaymentIntent(pi_id, amountToPay, jobsToPay);
    setPaymentIntent(secondPaymentIntent);
    await createIntendedPayment({pi_id: secondPaymentIntent.id})
  }

  const displayStuff = () => {
    return (
      clientSecret ? (
        <div  style={ boxStyle }>
        <h2 className='title is-4 has-text-centered'>Apply Payment</h2>
        <div className='has-background-link-dark box'>
        <p className='title is-size-3 has-text-warning-light has-text-centered'>Select Job(s) To Be Paid {selectedAmountToPay ? formatCurrency(selectedAmountToPay) : null}</p>
        <hr/>
        { showJobsToPay() }
        <hr/>
        <p className='subtitle is-size-4 has-text-warning-light has-text-centered'>Total To Be Paid {selectedAmountToPay ? formatCurrency(selectedAmountToPay) : null}</p>
        </div>
        { showExistingCards() }
        <div className='box'>
        <p className='title is-5 has-text-centered'>Pay With New Card</p>
        <Elements stripe={stripePromise} options={{ clientSecret }} className={ccStyle}>
          <CheckoutForm
            buttonText='Apply Payment[New Card]'
            confirmType='confirmPayment'
            disabled={selectedAmountToPay>1 ? false : true}
            amountToPay={selectedAmountToPay ? selectedAmountToPay : null}
            jobsToPay={ createStringJobids() }
            pi_id = { paymentIntent.id }
            initializeStripePaymentNewCard = { initializeStripePaymentNewCard }
          />
        </Elements>
        </div>
        </div>
    ) : <Loader />
    )
  }

  return (
    <div>
    { jobsToPay && displayStuff() }
    <hr />
    <p>Card Details are directly and securely stored on <b>Stripe</b>(<a href="https:stripe.com" target="_blank">more info</a>)</p>

    </div>
  );
};


// <span className='column has-text-weight-bold is-one-third-mobile is-one-fifth-tablet'>{ cardObject.card.brand.charAt(0).toUpperCase() + cardObject.card.brand.slice(1) }</span>
// <span className='column is-one-third-mobile is-one-fifth-tablet'>....{ cardObject.card.last4 }</span>
// <span className='column is-one-third-mobile is-two-fifth-tablet'>{ cardObject.card.exp_month }/{ cardObject.card.exp_year }</span>
