import React from "react";

const NumberButton = ({ label, jobAction }) => {
  return (
    <div className="button is-link is-small" onClick={jobAction}>
      {label}
    </div>
  );
};

export default NumberButton;
