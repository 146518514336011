import React, { useState, useEffect } from "react";
import ApiClient from '../api/client'

const Ticker = () => {
  const [data, setData] = useState({})


  const reLoadData = async () => {
    const data = await ApiClient.request('/api/jobs/data');
    setData(data)
  }

  useEffect(() => {
    const loadData = setInterval(() => {
      reLoadData()  }, 5000);
      return () => {
        clearInterval(loadData);
      };
  }, []);



  return (
    <div className="ticker-wrap">
      <div className="ticker">
        <div className="ticker__item">Sales Today { data.sales }</div>
        <div className="ticker__item">Production ${ data.prod }</div>
        <div className="ticker__item">Receivable # { data.rec_total }</div>
        <div className="ticker__item">Receivable ${ data.rec_dollar }</div>
        <div className="ticker__item">Estimates Left { data.ests }</div>
        <div className="ticker__item">Estimates Done { data.ests_done }</div>
        <div className="ticker__item">DNF Outstanding { data.dnfs_os }</div>
        <div className="ticker__item">DNF Done { data.dnfs_done }</div>
        <div className="ticker__item">Price Ana Done { data.price_ana }</div>
        <div className="ticker__item">Sales Today { data.sales }</div>
        <div className="ticker__item">Production ${ data.prod }</div>
        <div className="ticker__item">Receivable # { data.rec_total }</div>
        <div className="ticker__item">Receivable ${ data.rec_dollar }</div>
        <div className="ticker__item">Estimates Left { data.ests }</div>
        <div className="ticker__item">Estimates Done { data.ests_done }</div>
        <div className="ticker__item">DNF Outstanding { data.dnfs_os }</div>
        <div className="ticker__item">DNF Done { data.dnfs_done }</div>
        <div className="ticker__item">Price Ana Done { data.price_ana }</div>
      </div>
    </div>
  );
};

export default Ticker;
