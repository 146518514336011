const ActionType = {
  SetBalance2023: "SET_BALANCE_2023",
  SetEnv: "SET_ENV",
  SetSystemSource: "SET_SYSTEM_SOURCE",
  SetType: "SET_TYPE",
  SetUser: "SET_USER",
  SetClient: "SET_CLIENT",
  SetEmail: 'SET_EMAIL',
  SetProps: 'SET_PROPS',
  SetSelectedJobInfoId: "SET_SELECTED_JOBINFOID",
  Reset: "RESET",
  SetDate: "SET_DATE",
  SetMgr: "SET_MGR",
  SetCrew: "SET_CREW",
  SetVehicleList: "SET_VEHICLE_LIST",
  SetLogInClass: 'SET_LOG_IN_CLASS',
  SetResetPasswordClass: 'SET_RESET_PASSWORD_CLASS',
  SetStripePromise: 'SET_STRIPE_PROMISE',
  SetDashboardShowFunction: 'SET_DASHBOARD_SHOW_FUNCTION',
  SetUserLoading: 'SET_USER_LOADING'
};

export default ActionType;
