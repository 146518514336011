import React, { useEffect, useState } from 'react';

import LoaderBox from "./LoaderBox";

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { formatCurrency } from "../helpers/helper";
import { useStateReducer } from "../../base/context/authContext";
import MessageInfo from "./MessageInfo";


const ccStyle = {
  height: '100%'
}


export const CheckoutForm = ({ buttonText, confirmType, pi_id, amountToPay, jobsToPay, initializeStripePaymentNewCard }) => {
  const [{ env }, _dispatch] = useStateReducer();
  const [loading, setLoading] = useState(false);
  const [liveButton, setLiveButton] = useState(true);
  const [someEnv, setSomeEnv] = useState(false);
  const [showForm, setShowForm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if(env) {
      setSomeEnv(env);
    }
  }, [env]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    event.preventDefault();
    setLiveButton(false);

    const baseUrl = window.location.origin;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    setShowForm('is-hidden');
    let result;
    if(confirmType === 'confirmSetup'){
      result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${baseUrl}/crews/react_client_dashboard/dashboard`,
        },
      });
    } else if(confirmType==='confirmPayment'){
      await initializeStripePaymentNewCard(pi_id, amountToPay, jobsToPay);
      result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${baseUrl}/crews/react_client_dashboard/dashboard`,
        },
      });
    //  console.log(result);
    }

    if (result.error) {
      //console.error("Failed to checkout because:", result.error)
      setErrorMessage(result.error.message);
      setShowForm('');
      setLiveButton(true);
      // Show error to your customer (for example, payment details incomplete)

    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // history.push('/dashboard/lb');
    }
    setLoading(false);

  };

  return (
    <div>
      {loading ? <LoaderBox /> : null}
      { errorMessage ? <MessageInfo message={ errorMessage } /> : null }
      <form onSubmit={ handleSubmit } style={ccStyle} className={showForm}>
        <PaymentElement />
        <br/><button type='submit' className='button has-text-weight-bold is-success is-fullwidth' disabled={ ((stripe && amountToPay && liveButton) || confirmType=='confirmSetup') ? false : true }>{ buttonText } { amountToPay && formatCurrency(amountToPay) }</button>
      </form>
    </div>
  );
};

export default CheckoutForm;
