import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../views/Login";
import StuffToDo from "../views/StuffToDo";
import MakePackages from "../views/MakePackages";
import JobDetails from "../views/JobDetails";
import Home from "../layout/Home";
import CrewSetUp from "../views/CrewSetUp";
import CrewPay from "../views/CrewPay";
import CrewProfile from "../views/CrewProfile";
import EmployeeDetails from "../views/EmployeeDetails";
import PropertyManagement from "../views/property-management/PropertyManagement";
import NewPropertyTransaction from "../views/property-management/NewPropertyTransaction";
import EditPropertyTransaction from "../views/property-management/EditPropertyTransaction";
import NewDescription from "../views/property-management/NewDescription";

export const history = createBrowserHistory({ basename: "/crews/react" });

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/jobs/:id" component={JobDetails} exact />
      <Route path="/makepackages" component={MakePackages} exact />
      <Route path="/stufftodo" component={StuffToDo} exact />
      <Route path="/crewsetup" component={CrewSetUp} exact />
      <Route path="/crewsetup/:ocid/edit" component={CrewSetUp} exact />
      <Route path="/crewpay" component={CrewPay} exact />
      <Route path="/crewprofile" component={CrewProfile} exact />
      <Route path="/employee_details" component={EmployeeDetails} exact />
      <Route path="/transactions" component={PropertyManagement} exact />
      <Route
        path="/transactions/new"
        component={NewPropertyTransaction}
        exact
      />
      <Route
        path="/transactions/:id/edit"
        component={EditPropertyTransaction}
        exact
      />
      <Route path="/descriptions/new" component={NewDescription} exact />
      <Route path="/" component={Home} exact />
    </Switch>
  </Router>
);

export default AppRouter;
