import React from "react";

const CrewNameOptions = ({ className, onChange, value, options, name }) => {
  return (
    <select
      className={className}
      onChange={onChange}
      name="crewName"
      value={value}
    >
      <option value="">None Selected</option>
      {options.map((option) => (
        <option key={option.hrid} value={option.name}>
          {option.name}
        </option>
      ))}
    </select>
  );
};
export default CrewNameOptions;
