import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ApiClient from "../api/client";
import AssignButton from "./AssignButton";
import NumberButton from "./NumberButton";
import UnassignButton from "./UnassignButton";
import { addDaysToCurrentDate } from "../helpers/helper";

const DisplayPanel = ({
  job,
  crewName,
  schDate,
  killNumJob,
  refetchJobs,
  refetchCrewJobs,
}) => {
  const { search } = useLocation();
  const [crewname, setCrewname] = useState(job.crewname);
  const [targetCrewname, setTargetCrewname] = useState(crewName);
  const [targetSchdate, setTargetSchdate] = useState(schDate);
  const [jobdescClass, setJobdescClass] = useState(
    "display-panel-container__item-2"
  );
  const [numClass, setNumClass] = useState("display-panel-container__item-1");
  const [unassignClass, setUnassignClass] = useState("button button-unassign");
  const [jobDesc, setjobdesc] = useState(job.jobdesc);

  useEffect(() => {
    let tag = "";
    let emgy = "";
    if (job.prodcomm === "**Emergency**") {
      emgy = "-EM";
    }

    let stime = "";
    if (job.stime !== "" && job.stime !== null) {
      stime = "-" + job.stime;
    }

    tag = "[FLTR" + emgy + "] ";
    if (job.sdate === job.fdate) {
      tag = "[APPT" + emgy + stime + "] ";
    }
    setjobdesc(tag + job.jobdesc);
    const yesterday = addDaysToCurrentDate(-1);
    if (job.schdate && job.schdate <= yesterday) {
      setNumClass(
        "display-panel-container__item-1 display-panel-container__item-1--red"
      );
      setUnassignClass("button button-unassign button-unassign--red");
    }
  }, []);

  useEffect(() => {
    if (jobDesc.includes("[APPT")) {
      setJobdescClass(
        "display-panel-container__item-2 display-panel-container__item-2--blue"
      );
    }
    if (jobDesc.includes("EM]")) {
      setJobdescClass(
        "display-panel-container__item-2 display-panel-container__item-2--red"
      );
    }
  }, [jobDesc]);

  const assJob = async () => {
    const response = await ApiClient.post("/api/jobs/ass_job", {
      jobid: job.jobid,
      crewname: targetCrewname,
      schdate: targetSchdate,
    });
    setCrewname(targetCrewname);
    setTargetCrewname(targetCrewname);
    refetchCrewJobs();
  };

  const unAssJob = async () => {
    const response = await ApiClient.post("/api/jobs/unass_job", {
      jobid: job.jobid,
    });
    setCrewname(null);
    refetchCrewJobs();
  };

  const numJob = async () => {
    const response = await ApiClient.request(
      `/api/jobs/${job.jobid}/ass_num`,
      null
    );
    refetchJobs();
    refetchCrewJobs();
  };

  return (
    <div className="display-panel-container">
      <Link to={`/jobs/${job.jobid}${search}`}>
        <div className="display-panel-container__row">
          <span className={numClass}>{job.num !== null ? job.num : ""}</span>
          <span className="display-panel-container__item-1">{job.address}</span>
          <span className="display-panel-container__item-1">
            {job.postcode}
          </span>
          <span className="display-panel-container__item-1">{job.perly}</span>
          <span className="display-panel-container__item-1">{job.jobid}</span>
        </div>
        <div className="display-panel-container__row">
          <span className={jobdescClass}>{jobDesc}</span>
          <span className="display-panel-container__item-2">
            ${parseFloat(job.price).toFixed(2)}
          </span>
        </div>
      </Link>
      <div className="display-panel-container__row">
        <span className="display-panel-container__item-3--blue">
          Sold:{job.datesold}
        </span>
        <div className="display-panel-container__row--buttons">


          {job.crewname && job.num ? (
            <span className="display-panel-container__item-1">
              <NumberButton
                jobAction={() => killNumJob(job.jobid)}
                label="UnNumber"
              />
            </span>
          ) : null}

          {crewname && !job.num ? (
            <span className="display-panel-container__item-1">
              <NumberButton jobAction={numJob} label="Number" />
            </span>
          ) : null}

          {crewname !== null ? (
            <span className="display-panel-container__item-1">
              <UnassignButton
                crewname={crewname}
                unassignClass={unassignClass}
                unAssJob={unAssJob}
              />
            </span>
          ) : (
            <span className="display-panel-container__item-1">
              <AssignButton
                label="Assigns"
                crewname={targetCrewname}
                assJob={assJob}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayPanel;
