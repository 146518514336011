import React from "react";
import { Link } from "react-router-dom";

import OPEN from "../assets/open.png";
import { calcPercentage } from "../helpers/helper";

const DailyProfile = ({
  dailyProfileDate,
  dailyProfileData,
  crewName,
  params,
}) => {
  if (dailyProfileData && dailyProfileData.length === 0) {
    return null;
  }

  return (
    <table className="crewpay-table">
      <thead>
        <tr>
          <th colSpan={7} className="crewpay-table__header">
            {dailyProfileDate}
          </th>
        </tr>
        <tr>
          <th className="crewpay-table__header">Crew</th>
          <th className="crewpay-table__header">Amount</th>
          <th className="crewpay-table__header">Labour</th>
          <th className="crewpay-table__header">%</th>
          <th className="crewpay-table__header">No Pay</th>
          <th className="crewpay-table__header">No Audit</th>
          <th className="crewpay-table__header">Open</th>
        </tr>
      </thead>
      <tbody>
        {dailyProfileData.map((item) => (
          <tr key={item.crewname}>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.crewname}
            </td>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.total}
            </td>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.tot_labour}
            </td>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {calcPercentage(item.tot_labour, item.total)}
            </td>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.no_pays.length}
            </td>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              {item.names_unaudited.length}
            </td>
            <td
              className={
                item.crewname === crewName
                  ? "crewpay-table__body crewpay-table--row-selected"
                  : "crewpay-table__body"
              }
            >
              <Link
                to={`/crewprofile?${params.toString()}&crewName=${
                  item.crewname
                }`}
              >
                <img className="icon--large" src={OPEN} alt="open" />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DailyProfile;
