import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AUDIT from "../assets/audit.png";
import TRASH from "../assets/trashcan.jpg";
import { formatCurrency, formatCurrencyZero, calcPercentage } from "../helpers/helper";

const DailyPay = ({ payData, auditPay, deletePay, crewProfileParams, crewActivity }) => {
  console.log("Pay Data", payData);
  console.log("Crew Activity", crewActivity);

  const [revenue, setRevenue] = useState(null);
  const [payout, setPayout] = useState(null);

  const calcRevenue = () => {
    let total = 0
    crewActivity.forEach((job) => {
      total += parseFloat(job.price);
    })
    setRevenue(total);
  }

  const calcPay = () => {
    let total = 0
    payData.forEach((pay) => {
      total += parseFloat(pay.pay);
    })
    setPayout(total);
  }

  useEffect(() =>{
    calcRevenue();
    calcPay();
  }, [payData])

  return (
    <div>
      <table className="crewpay-table crewpay-table--margin-bottom">
        <thead>
          <tr>
            <th className="crewpay-table__header">Prod</th>
            <th className="crewpay-table__header">Labour</th>
            <th className="crewpay-table__header">%</th>
          </tr>
        </thead>
        <tbody>
          <tr key="1">
            <td className="crewpay-table__body crewpay-table__body--pad">{ formatCurrency(revenue) }</td>
            <td className="crewpay-table__body crewpay-table__body--pad">{ formatCurrency(payout) }</td>
            <td className="crewpay-table__body crewpay-table__body--pad">{ calcPercentage(payout, revenue)}</td>
          </tr>
        </tbody>
      </table><br/>

      <table className="crewpay-table crewpay-table--margin-bottom">
        <thead>
          <tr>
            <th className="crewpay-table__header">Delete</th>
            <th className="crewpay-table__header">Date</th>
            <th className="crewpay-table__header">Mgr</th>
            <th className="crewpay-table__header">Name</th>
            <th className="crewpay-table__header">Start</th>
            <th className="crewpay-table__header">Finish</th>
            <th className="crewpay-table__header">Hours</th>
            <th className="crewpay-table__header">Rate</th>
            <th className="crewpay-table__header">Pay</th>
            <th className="crewpay-table__header">Audit</th>
            <th className="crewpay-table__header">Emp Details</th>
          </tr>
        </thead>
        <tbody>
          {payData.map((pay) => (
            <tr key={pay.id}>
              <td className="crewpay-table__body crewpay-table__body--pad">
                <img
                  className="icon--large"
                  src={TRASH}
                  alt="delete"
                  onClick={() => deletePay(pay.id)}
                />
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {pay.date}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {pay.mgr}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {pay.name}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                { pay.stime ? pay.stime.substring(11, 16) : null }
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                { pay.ftime ? pay.ftime.substring(11, 16) : null }
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                { pay.hours ? pay.hours : null }
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {formatCurrencyZero(pay.rate ? pay.rate : 0)}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {formatCurrency(pay.pay)}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {pay.ok ? (
                  <span
                    className="crewpay-table--pointer"
                    onClick={() => auditPay(pay.id)}
                  >
                    {pay.ok}
                  </span>
                ) : (
                  <img
                    className="icon--large"
                    src={AUDIT}
                    alt="audit"
                    onClick={() => auditPay(pay.id)}
                  />
                )}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                <Link
                  to={`/employee_details?${crewProfileParams}&empDetails=${pay.name}&empHrid=${pay.hrid}`}
                >
                  Employee Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DailyPay;
