import React, { useState, useEffect } from 'react';
import Layout from "../../../layout/Layout";
import { Link, Redirect , useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import WindowEstimateForm from "../forms/WindowEstimateForm"
import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";



const WindowLeadAdminCrew = () => {
  const we = "Window Estimates";
  const [{ user, userLoading }] = useStateReducer();
  const routeParams = useParams();
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [errorClassName, setErrorClassName] = useState('is-hidden');
  const [showLoader, setShowLoader] = useState(false);

  const form = useForm();
  const { register, handleSubmit, getValues, setValue } = form;

  const returnEstimate = async (data) => {
    setShowLoader(true);
    data['hrid'] = user.hrid
    console.log('Data', data);
    const myParams = data;
    // const qs = new URLSearchParams(data).toString();
    // console.log('QS', qs);
    let tempErrors = []
    if(!data.w1 || !Number.isInteger(parseInt(data.w1))){
      tempErrors.push('W1 Price must be entered and be a number only');
    }
    if(data.w1 && Number.isInteger(parseInt(data.w1)) && parseInt(data.w1)<=0){
      tempErrors.push('W1 Price must be greater than 0');
    }

    // if(data.w2 !=='' && !Number.isInteger(parseInt(data.w2))){
    //   tempErrors.push('W2 Price must be number only');
    // }
    // if(data.w2 && Number.isInteger(parseInt(data.w2)) && parseInt(data.w2)<=0){
    //   tempErrors.push('W2 Price must be greater than 0');
    // }

    if(data.eh !=='' && !Number.isInteger(parseInt(data.eh))){
      tempErrors.push('EH Price must be number only');
    }
    if(data.eh && Number.isInteger(parseInt(data.eh)) && parseInt(data.eh)<=0){
      tempErrors.push('EH Price must be greater than 0');
    }

    if(data.eg !=='' && !Number.isInteger(parseInt(data.eg))){
      tempErrors.push('EG Price must be number only');
    }
    if(data.eg && Number.isInteger(parseInt(data.eg)) && parseInt(data.eg)<=0){
      tempErrors.push('EG Price must be greater than 0');
    }

    if(tempErrors.length > 0){
      setErrors(tempErrors);
      setErrorClassName('mt-1 title has-text-centered has-text-danger has-text-weight-bold');
      window.scrollTo(0, 0);
    } else {
      const result = await ApiClient.patch(`/api/estimates/${routeParams.id}`, myParams)
      setMessage(result.mess);
      setErrors(null);
      setErrorClassName('is-hidden');
    }
    setShowLoader(false);
  }

  const showErrors = () => {
    const result = errors.map((error) => <h1 key={error} className={ errorClassName }>{ error }</h1>)
    return <div><br/><br/>{ result }<br/></div>
  }

  const getCorrectForm = () => {
    console.log('MESSAGE', message);
    if(message.include("Written")){
      return (<WindowEstimateForm
                register={ register }
                handleSubmit= { handleSubmit }
                returnEstimate={ returnEstimate }
                getValues = { getValues }
                setValue = { setValue }
                msid = { routeParams.id }
              />)
    } else if(message.include("Pick Up Sign")){
      console.log('HERE IN')
      return <h1>Pick Up Sign</h1>
    }
  }

  const showForm = () => { return (<Layout>
      <div className='container'>
      <h1 className='mt-3 subtitle has-text-centered has-text-success'>{user && user.name}</h1>
      <nav className='breadcrumb'>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/crew-estimates-signs">Estimates/Signs</Link></li>
          <li className='is-active'><Link to={`/window_lead_admin_crew/${routeParams.id}`}>Return Estimate</Link></li>
        </ul>
      </nav>
      { errors && showErrors() }

      { message ? <div><br/><br/><h1 className='mt-1 subtitle has-text-centered has-text-info has-text-weight-bold'>{ message }</h1></div> :
              <WindowEstimateForm
                register={ register }
                handleSubmit= { handleSubmit }
                returnEstimate={ returnEstimate }
                getValues = { getValues }
                setValue = { setValue }
                msid = { routeParams.id }
              /> }
      </div>
    </Layout>)

  }

  return (<div>
    { showLoader ? <Loader /> : showForm() };
  </div>)


}

export default WindowLeadAdminCrew;
