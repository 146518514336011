import React from 'react'

export const ErrorMsg = ({msg, num}) => {
  let errorsSuffix = 'errors';
  if(num === 1){
    errorsSuffix = 'error'
  }
  if(msg && (msg.includes('There are') || msg.includes('There is'))) {
    return msg? <h1 className='subtitle is-6 has-text-danger has-text-weight-bold'>{msg}</h1> : null
  } else {
    return msg? <h1 className='subtitle is-6 has-text-danger has-text-weight-bold'>{msg} [{num} {errorsSuffix}]</h1> : null
  }
}

export const ErrorSumm = ({msg}) => {
  return <h1 className='title is-1 has-text-danger has-text-weight-bold'>Ahh Shit</h1>
}
