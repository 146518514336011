import React from 'react';

import WSLOGO from "../../base/assets/white_shark.png";

const boxStyle = {
  height: '100%',
}

const MessageInfo = ({ message }) => {
  return (
  <div style={boxStyle} className='box has-background-info'>
    <h2 className='title is-3 has-text-centered has-text-light'>{ message }</h2>
  </div>
  )
}

export default MessageInfo;




// <section className='hero is-light is-bold is-fullheight'>
//   <div className='hero-body'>
//     <div className='container'>
//       <div className='columns is-centered'>
//         <div className='column is-half'>
//           <figure className='image is-1by1'>
//             <img src={WSLOGO} />
//           </figure>
//
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
//
