import dayjs from "dayjs";

export const formatCurrency = (amount) => {
  if (!amount || amount === "0.0" || amount === "nil") return "TBD";
  return "$" + parseFloat(amount).toFixed(2);
};

export const formatCurrencyNoSign = (amount) => {
  if (!amount || amount === "0.0") return "0.00";
  return parseFloat(amount).toFixed(2);
};



export const addDaysToCurrentDate = (days) => {
  const newDate = new Date(Date.now() + days * 864e5);
  return newDate;
};

export const formatDate = (date = new Date()) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const convertToMinutes = (time) => {
  let hrs = time.substring(0, 2);
  hrs = hrs * 60;
  let mins = time.substring(3, 5);
  mins = parseInt(hrs) + parseInt(mins);
  return mins;
};

export const convertToTime = (mins) => {
  let hrs = Math.floor(parseInt(mins) / 60);
  hrs = hrs.length === 1 ? "0" + hrs : hrs;
  mins = mins % 60;
  mins = mins.toString().length === 1 ? "0" + mins.toString() : mins.toString();
  return hrs + ":" + mins;
};

export const convertToHours = (date, stime, ftime) => {
  const start = dayjs(date + "T" + stime);
  const finish = dayjs(date + "T" + ftime);
  const hours = finish.diff(start, "h", true).toFixed(2);

  return finish.diff(start, "h", true).toFixed(2);
};

export const getPay = (hours, rate) => {
  return (parseFloat(rate.substring(1, rate.length)) * hours).toFixed(2);
};

export const makeNiceDate = (sqlDate) => {
  const day = dayjs(sqlDate);
  return day.format("ddd MMM DD YYYY");
};

export const toNaiveTime = (ts) => {
  const offset = new Date().getTimezoneOffset();
  return dayjs(ts).add(offset, "minutes");
};

export const calcPercentage = (amount, total) => {
  let ans = (parseFloat(amount) / parseFloat(total)) * 100;
  return ans.toFixed(0);
};

export const validateData = (data,workMinutes) => {
  let temp = {};
  if(isNaN(data.estMins)){
    temp.estMins='Est Mins must be a number';
  }
  if(!isNaN(data.estMins) && parseFloat(data.estMins)<=0){
    temp.estMinsGreaterThanZero = 'Est Mins must be greater than 0';
  }
  if(workMinutes <= 0){
    temp.workMinutesGreaterThanZero = 'Work Minutes must be greater than 0';
  }
  if(data.w1Check && isNaN(data.w1Text)){
    temp.w1TextMustBeANumber = 'W1 must be a number';
  }
  if(data.w1Check && !isNaN(data.w1Text) && parseFloat(data.w1Text)<=0){
    temp.w1TextMustBeGreaterThanZero = 'W1 must be greater than zero';
  }
  if(data.w2Check && isNaN(data.w2Text)){
    temp.w2TextMustBeANumber = 'W2 must be a number';
  }
  if(data.w2Check && !isNaN(data.w2Text) && parseFloat(data.w2Text)<=0){
    temp.w2TextMustBeGreaterThanZero = 'W2 must be greater than zero';
  }
  if(data.w3Check && isNaN(data.w3Text)){
    temp.w3TextMustBeANumber = 'W3 must be a number';
  }
  if(data.w3Check && !isNaN(data.w3Text) && parseFloat(data.w3Text)<=0){
    temp.w3TextMustBeGreaterThanZero = 'W3 must be greater than zero';
  }
  if(data.w4Check && isNaN(data.w4Text)){
    temp.w4TextMustBeANumber = 'W4 must be a number';
  }
  if(data.w4Check && !isNaN(data.w4Text) && parseFloat(data.w4Text)<=0){
    temp.w4TextMustBeGreaterThanZero = 'W4 must be greater than zero';
  }
  if(data.ehCheck && isNaN(data.ehText)){
    temp.ehTextMustBeANumber = 'EH must be a number';
  }
  if(data.ehCheck && !isNaN(data.ehText) && parseFloat(data.ehText)<=0){
    temp.ehTextMustBeGreaterThanZero = 'EH must be greater than zero';
  }
  if(data.egCheck && isNaN(data.egText)){
    temp.egTextMustBeANumber = 'EG must be a number';
  }
  if(data.egCheck && !isNaN(data.egText) && parseFloat(data.egText)<=0){
    temp.egTextMustBeGreaterThanZero = 'EG must be greater than zero';
  }
  if(data.customCheck && isNaN(data.customText)){
    temp.customTextMustBeANumber = 'Custom price must be a number';
  }
  if(data.customCheck && !isNaN(data.customText) && parseFloat(data.customText)<=0){
    temp.customTextMustBeGreaterThanZero = 'Custom price must be greater than zero';
  }
  if(data.recStatus === 'None Selected'){
    temp.recStatusMustBeSelected = 'Select receivable status'
  }
  if(data.lawnSign === 'None Selected'){
    temp.lawnSignMustBeSelected = 'Select lawn sign status'
  }
  if(data.clientHome === 'None Selected'){
    temp.clientHomeMustBeSelected = 'Select client home status'
  }
  if(Object.keys(temp).length > 0){
    if(Object.keys(temp).length===1){
      temp.totalErrors = `There is ${Object.keys(temp).length } error`;
    } else {
      temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
    }
  }
  return temp;
}

export const validateStartData = (data) => {
  let temp = {};
  if(isNaN(data.estMins)){
    temp.estMins='Est Mins must be a number';
  }
  if(!isNaN(data.estMins) && parseFloat(data.estMins)<=0){
    temp.estMinsGreaterThanZero='Est Mins must be greater than 0';
  }
  return temp;
}

export const validateDnfData = (data, dnfMinutes) => {
    let temp = {};
    if(dnfMinutes <= 0){
      temp.dnfMinutesGreaterThanZero = 'DNF Minutes must be greater than 0';
    }
    if(data.dnfNotes === ''){
      temp.dnfNotesMustBeFilled = 'DNF notes must be filled out'
    }
    if(data.valid === 'None Selected'){
      temp.validMustBeSelected = 'Select a valid status'
    }
    if(data.clientHome === 'None Selected'){
      temp.clientHomeMustBeSelected = 'Select client home status'
    }
    if(Object.keys(temp).length > 0){
      if(Object.keys(temp).length===1){
        temp.totalErrors = `There is ${Object.keys(temp).length } error`;
      } else {
        temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
      }
    }
    return temp;
}

export const validateCreateSaleData = (data, ccRequired, getValues) => {
  let temp = {};
  if(data){
    if(data.address === 'None Selected'){
      temp.addressMustBeFilled = 'Select address';
    }
    if(data.jobtype === 'None Selected'){
      temp.jobtypeMustBeFilled = 'Select job type';
    }
    if(data.floatrange === 'None Selected' && data.apptdate === 'None Selected'){
      temp.dateMustBeFilled = 'Select date';
    }
    if(ccRequired){
      if(data.ccNum === '' || data.ccCvc === '' || data.ccMonth === 'None Selected' || data.ccYear === 'None Selected'){
        temp.ccMustBeFilled = 'Credit card info incomplete';
      }
    }

  } else {
    if(getValues('address') === 'None Selected'){
      temp.addressMustBeFilled = 'Select address';
    }
    if(getValues('jobType') === 'None Selected'){
      temp.jobtypeMustBeFilled = 'Select job type';
    }
    if(getValues('floatrange') === 'None Selected' && getValues('apptdate') === 'None Selected'){
      temp.dateMustBeFilled = 'Select date';
    }
    if(ccRequired){
      if(getValues('ccNum') === '' || getValues('ccCvc') === '' || getValues('ccMonth') === 'None Selected' || getValues('ccYear') === 'None Selected'){
        temp.ccMustBeFilled = 'Credit card info incomplete';
      }
    }
  }
  if(Object.keys(temp).length > 0){
    if(Object.keys(temp).length===1){
      temp.totalErrors = `There is ${Object.keys(temp).length } error`;
    } else {
      temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
    }
  }
  return temp;
}


export const validEmail = (email) => {
  return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
}

export const validatePersonalData = (data) => {

}


export const validateRequestData = (data, getValues) => {
  let temp = {};
  if(data){
    if(data.jobinfoid === 'None Selected'){
      temp.addressMustBeFilled = 'Select address';
    }
    if(data.requestMessage === ''){
      temp.miscNotesMustBeFilled = 'Enter Notes';
    }

  } else {
    if(getValues('jobinfoid') === 'None Selected'){
      temp.addressMustBeFilled = 'Select address';
    }
    if(getValues('requestMessage') === ''){
      temp.miscNotesMustBeFilled = 'Enter Notes';
    }
  }
  if(Object.keys(temp).length > 0){
    if(Object.keys(temp).length===1){
      temp.totalErrors = `There is ${Object.keys(temp).length } error`;
    } else {
      temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
    }
  }
  return temp;
}

export const validateFeedbackData = (data) => {
  let temp = {};
  if(data){
    if(data.comments === '' && data.rating === 'None Selected'){
      temp.comments = 'Enter feedback';
    }
    if(data.comments === '' && data.rating === 'None Selected'){
      temp.rating = 'Enter rating';
    }
  }
  if(Object.keys(temp).length > 0){
    if(Object.keys(temp).length===1){
      temp.totalErrors = `There is ${Object.keys(temp).length } error`;
    } else {
      temp.totalErrors = `There are ${Object.keys(temp).length } errors`;
    }
  }
  return temp;
}
