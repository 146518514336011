import React, { useState, useEffect } from 'react';
import Layout from "../../../layout/Layout";
import { Link, Redirect , useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";



const PaintLeadAdmin = () => {
  const pe = "Paint Estimates";
  const [{ user, userLoading }] = useStateReducer();
  const routeParams = useParams();
  const paForm = useForm();
  const { register, handleSubmit, setValue, getValues } = paForm;
  const {
    startHour,
    startMinute,
    startHourPrior,
    startMinutePrior,
    finishHour,
    finishMinute
  } = getValues();


  return(
    <Layout>
      <div className='container'>
      <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
      <nav className='breadcrumb'>
        <ul>
          <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
          <li><Link to={`/partner_leads/${pe}`}>Leads</Link></li>
          <li className='is-active'><Link to={`/paint_admin/${routeParams.id}`}>Paint Admin</Link></li>
        </ul>
      </nav>
      <h1>Paint</h1>
      </div>
    </Layout>
  )

}

export default PaintLeadAdmin;
