import React from 'react';

import Layout from '../layout/Layout';
import ODDJOB from '../../base/assets/odd-job.jpeg'
import W1 from '../assets/images/reisAction.jpg'
import W2 from '../assets/images/ceaserAction.jpg'
import W3 from '../assets/images/alexAction.jpg'
import W4 from '../assets/images/juanAction.jpg'
import EH1 from '../assets/images/mamushAction.jpg'
import EH2 from '../assets/images/soliemonAction.jpg'
import PAINT from '../assets/images/chrisAction.jpg'
import LAWN from '../assets/images/JackieProfile.jpg'

const photoStyle = {
  height: '300px',
  width: '320px'
};

const Services = () => {

  return (
    <Layout>
      <section className='section'>
        <div className='container'>
          <h2 className='title is-1 has-text-centered'>Our Services</h2>

          <div className='columns'>
            <div className='column is-one-third'>
              <figure style={photoStyle} className='image is-1by1 is-clipped'>
                <img src={W1} />
              </figure>
            </div>
            <div className='column'>
              <div className='column content'>
                <h2 className='subtitle is-4'>W1 (Exterior Cleaning)</h2>
                <p>Cleaning of all exterior glass including doors and basement windows. Screen removal/cleaning/replacing and skylight cleaning is an extra charge.
                No storm removal.</p>
              </div>
            </div>
          </div>

          <hr/>

          <div className='columns'>
            <div className='column is-one-third'>
              <figure style={photoStyle} className='image is-1by1 is-clipped'>
                <img src={W2} />
              </figure>
            </div>
            <div className='column'>
              <div className='column content'>
                <h2 className='subtitle is-4'>W2 (Exterior/Interior Cleaning)</h2>
                <p>Cleaning of all exterior and interior glass including doors and basement windows. Screen removal/cleaning/replacing and skylight cleaning is an extra charge.
                No storm removal.</p>
              </div>
            </div>
          </div>

          <hr/>

          <div className='columns'>
            <div className='column is-one-third'>
              <figure style={photoStyle} className='image is-1by1 is-clipped'>
                <img src={ W3 } />
              </figure>
            </div>
            <div className='column is-two-thirds'>
              <div className='column content'>
                <h2 className='subtitle is-4'>W3 (3-Sided Cleaning)</h2>
                <p>Removal of storms and cleaning of both sides and cleaning of exterior windows and replacing storms.</p>
              </div>
            </div>
          </div>

          <hr/>

          <div className='columns'>
            <div className='column is-one-third'>
              <figure style={photoStyle} className='image is-1by1 is-clipped'>
                <img src={ W4 } />
              </figure>
            </div>
            <div className='column is-two-thirds'>
              <div className='column content'>
                <h2 className='subtitle is-4'>W4 (4-Sided Cleaning)</h2>
                <p>Removal of storms and cleaning of both sides and cleaning of exterior and interior windows and replacing storms.</p>
              </div>
            </div>
          </div>

          <hr/>

          <div className='columns'>
            <div className='column is-one-third'>
              <figure style={photoStyle} className='image is-4by3 is-clipped mb-2'>
                <img src={EH1} />
              </figure>
              <figure style={photoStyle} className='image is-4by3 is-clipped'>
                <img src={EH2} />
              </figure>
            </div>
            <div className='column'>
              <div className='column content'>
                <h2 className='subtitle is-4'>EH (Eaves Cleaning)</h2>
                <p>
                  Cleaning of eaves and snaking of downspouts. Cleaning eaves regularly(twice a year) is essential to reduce the risk of water damage. Debris is collected, bagged and left at the curb for pick up.
                  We also clean garage eaves.
                </p>
              </div>
            </div>
         </div>

         <hr/>

         <div className='columns'>
           <div className='column is-one-third'>
             <figure className='image is-1by1'>
               <img style={photoStyle} src={ PAINT } alt='nut'/>
             </figure>
           </div>
           <div className='column'>
             <div className='column content'>
               <h2 className='subtitle is-4'>Painting (Interior/Exterior)</h2>
               <p>We offer interior and exterior painting services. Our crews are specifically trained in painting
                 and produced quality paint jobs for over 30 years. We have experience working with older homes and delicate surfaces.
                 Painting takes years to master and our crews have perfected their craft over many years.
               </p>
             </div>
           </div>
         </div>

         <hr/>

         <div className='columns'>
           <div className='column is-one-third'>
             <figure className='image is-1by1'>
               <img style={photoStyle} src={ ODDJOB } alt='nut'/>
             </figure>
           </div>
           <div className='column'>
             <div className='column content'>
               <h2 className='subtitle is-4'>Odd Jobs</h2>
               <p>We perform a wide range of services including eaves repair/installation, rotten wood replacement, replacing deck boards, drywall repair, roof/shingle repairs and more.</p>
             </div>
           </div>
         </div>

         <hr/>

         <div className='columns'>
           <div className='column is-one-third'>
             <figure className='image is-1by1'>
               <img style={photoStyle} src={ LAWN } alt='nut'/>
             </figure>
           </div>
           <div className='column'>
             <div className='column content'>
               <h2 className='subtitle is-4'>Lawn Care</h2>
               <p>Our lawn care service includes Spring/Fall clean ups, lawn maintenance and trimming of hedges/plants.</p>
             </div>
           </div>
         </div>

         <hr/>

      </div>
    </section>
  </Layout>)
};

export default Services;
