import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const CrewActivity = ({ crewActivity }) => {
  let totalProd = 0;
  crewActivity.forEach(job => totalProd += parseFloat(job.price));

  return (
    <table className="crewpay-table">
      <thead>
        <tr>
          <th className="crewpay-table__header">JobID</th>
          <th className="crewpay-table__header">Job Desc</th>
          <th className="crewpay-table__header">Address</th>
          <th className="crewpay-table__header">Price</th>
          <th className="crewpay-table__header">Start</th>
          <th className="crewpay-table__header">Finish</th>
          <th className="crewpay-table__header">Minutes</th>
        </tr>
      </thead>
      <tbody>
        {crewActivity.map((job) => {
          const start = dayjs(job.datebi + "T" + job.reportstime);
          const finish = dayjs(job.datebi + "T" + job.reportftime);
          const minutes = finish.diff(start, "minutes");

          return (
            <tr key={job.jobid}>
              <td className="crewpay-table__body">{job.jobid}</td>
              <td className="crewpay-table__body">{job.jobdesc}</td>
              <td className="crewpay-table__body">{job.address}</td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {formatCurrency(job.price)}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {job.reportstime}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {job.reportftime}
              </td>
              <td className="crewpay-table__body crewpay-table__body--pad">
                {minutes}
              </td>
            </tr>
          );
        })}

        <tr key='total'>
          <th colSpan={7} className="crewpay-table__header">
            Total Prod: {formatCurrency(totalProd)}
          </th>
        </tr>

      </tbody>
    </table>
  );
};

export default CrewActivity;
