import ApiClient from "../../base/api/client";

export const testCustomer = async (cfid) => {
  const response = await ApiClient.request('/api/setup_intents', {test: 'OK TEST GOOD'});
  return response;
}

export const fetchCustomer = async (cfid) => {
  const response = await ApiClient.request(`/api/stripe/${cfid}`);
  return response;
}

export const fetchCardList = async (stripeCustomerId) => {
//  console.log('Before Fetch Card List:', stripeCustomerId);
  const response = await ApiClient.request(`/api/stripe/${stripeCustomerId}/fetch_card_list`);
//  console.log('AFTER Fetch Card List:', response);
  return response;
}

export const createCustomer = async (cfid) => {
  const response = await ApiClient.post("/api/stripe", {cfid: cfid});
  return response;
}

export const createSetupIntent = async (stripeCustomerId, client, jobid, user, type, email, dashboardShowFunction) => {
  return await ApiClient.post("/api/setup_intents", {customer_id: stripeCustomerId, cfid: client.cfid, jobid: jobid, user: user, type: type, email: email, dashboardShowFunction: dashboardShowFunction});
}

export const updateSetupIntent = async (si_id, jobid) => {
  const result = await ApiClient.patch(`/api/setup_intents/${si_id}`, { jobid: jobid });
  return result;
}

// export const fetchSetupIntents = async (stripeCustomerId) => {
//   console.log('Customer ID', stripeCustomerId);
//   let setupIntents = await ApiClient.request(`/api/setup_intents/${stripeCustomerId}/setup_intents_list`);
//   console.log('RETIURNED SI LIST', setupIntents);
//   return setupIntents;
// }

export const fetchSetupIntent = async (si_id) => {
  let setupIntent = await ApiClient.request(`/api/setup_intents/${si_id}`);
  return setupIntent;
}

export const createPaymentIntent = async (data) => {
  const result = await ApiClient.post("/api/payment_intents", data);
  return result;
}

export const fetchPaymentIntents = async (stripeCustomerId) => {
  const result = await ApiClient.request(`/api/payment_intents/${stripeCustomerId}/payment_intents_list`);
  return result;
}

export const fetchPaymentIntent = async (pi_id) => {
  const result = await ApiClient.request(`/api/payment_intents/${pi_id}`);
  return result;
}

export const updatePaymentIntent = async (pi_id, amount, jobids) => {
  const result = await ApiClient.patch(`/api/payment_intents/${pi_id}`, { amount: amount, jobids: jobids });
  return result;
}

export const fetchPaymentMethod = async (paymentMethodId) => {
  return await ApiClient.request(`/api/setup_intents/${paymentMethodId}/payment_method`);
}

export const deleteCard = async (pm_id) => {
  return await ApiClient.delete(`/api/setup_intents/${pm_id}`);
}

export const confirmPayment = async (pm, pi) => {
  return await ApiClient.post("/api/payment_intents/confirm_payment", {pm_id: pm.id, pi_id: pi.id});
}

export const markJobsToPay = async (data) => {
  return await ApiClient.post("/api/payment_intents/mark_jobs_to_pay", data);
}

export const createIntendedPayment = async (pi_id) => {
  return await ApiClient.post("/api/payment_intents/create_intended_payment", pi_id);
}

export const markCcStatusRequired = async (cfid) => {
  return await ApiClient.request(`/api/clients/${cfid}/mark_cc_status_required`);
}

export const unsecureUpcomingJobs = async (cfid) => {
  return await ApiClient.request(`/api/clients/${cfid}/unsecure_upcoming_jobs`);
}

export const secureUpcomingJobs = async (cfid) => {
  return await ApiClient.request(`/api/clients/${cfid}/secure_upcoming_jobs`);
}
