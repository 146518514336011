import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useForm } from "react-hook-form";
import Layout from "../layout/Layout";

import CrewSetUpForm from "../components/CrewSetUpForm";
import useRequest from "../hooks/useRequest";
import ApiClient from "../api/client";
import Loader from "../components/Loader";
import CrewDisplayPanel from "../components/CrewDisplayPanel";
import dayjs from "dayjs";

const defaultValues = {
  ocdate: dayjs().format("YYYY-MM-DD"),
};

const CrewSetUp = () => {
  const history = useHistory();
  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  });
  const { data: crews, loading, refetch: refetch_all_crews } = useRequest(
    "/api/crews",
    null,
    true
  );
  const [loader, setLoader] = useState(false);
  const { ocid } = useParams();
  const { data: editCrew, loading: editLoading } = useRequest(
    `/api/crews/${ocid}`,
    null,
    !!ocid
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (ocid && editCrew) {
      reset(editCrew);
    } else {
      reset(defaultValues);
    }
  }, [editLoading, ocid, editCrew, reset]);

  const onDeleteCrew = (ocid) => async () => {
    setLoader(true);
    const delete_crew = await ApiClient.delete(`/api/crews/${ocid}`);
    setLoader(false);
    // if (delete_crew.success) {
    //   //    refetch_all_crews();
    // }
  };

  const onSubmit = async (params) => {
    const newParams = { crew: { ...params, ocid } };
    setLoader(true);
    let response;
    if (ocid) {
      response = await ApiClient.patch(`/api/crews/${ocid}`, newParams);
    } else {
      response = await ApiClient.post("/api/crews", newParams);
    }

    setLoader(false);
    if (response.success) {
      setErrors({});
      history.push("/crewsetup");
      refetch_all_crews();
    } else {
      setErrors(response.errors);
    }
  };

  return (
    <Layout>
      <div className="container">
        {loading || loader ? (
          <Loader />
        ) : (
          <div>
            <h1>Crew Set Up</h1>
            <CrewSetUpForm
              onSubmit={handleSubmit(onSubmit)}
              register={register}
              errors={errors}
            />
            <hr />
            {loading
              ? null
              : crews.map((crew) => (
                  <CrewDisplayPanel
                    key={crew.driver}
                    crew={crew}
                    value={crew.ocid}
                    onDeleteCrew={onDeleteCrew}
                  />
                ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CrewSetUp;
