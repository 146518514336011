import React from "react";

const FormCard = ({ children }) => {
  return (
    <div className="container-transaction-form">
      <div className="property-form-panel">{children}</div>
    </div>
  );
};

export default FormCard;
