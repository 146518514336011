import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";


//import Loader from "../../../base/layout/Loader";
//import { useStateReducer } from "../../../base/context/authContext";
//import ApiClient from "../../../base/api/client";



const MiscJobForm = ({message, register, handleSubmit, createMiscJob, properties }) => {

  const propList = () => {
    const list = properties.map((prop) => <option key={prop.jobinfoid} value={prop.jobinfoid}>{ prop.address }</option>)
    return <React.Fragment>{list}</React.Fragment>
  }

  return(
      <div className='container box'>
        <h1 className='subtitle'>Create Misc Job</h1>
        <form className='form' onSubmit={handleSubmit(createMiscJob)}>
          <div className='field'>
            <div className='control'>
              <label className="label" htmlFor='jobinfoid_label'>Property</label>
              <div className='select' id='jobinfoid_label'>
                <select name='jobinfoid' ref={ register }>
                  { propList() }
                </select>
              </div>
            </div>
          </div><br/>

          <div className='field'>
            <div className='control'>
              <label className="label" htmlFor='type5_label'>Type</label>
              <div className='select' id='type5_label'>
                <select name='type5' ref={ register }>
                  <option value='None Selected'>None Selected</option>
                  <option value='Book Odd Job'>Book Odd Job</option>
                  <option value='Paint Est'>Paint Est</option>
                </select>
              </div>
            </div>
          </div><br/>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='notes_input'>Job Description</label>
                <textarea
                  className='textarea'
                  rows='4'
                  id='notes_input'
                  name='notes'
                  ref={ register }
                  defaultValue={ message.message}
                />
            </div>
          </div>

          <button name='return_job' type='submit' className='button is-link is-rounded has-text-weight-bold'>Create Misc Job</button> :

        </form>

      </div>
  )

}

export default MiscJobForm;
