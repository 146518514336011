import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import { formatCurrency, validateData, validateStartData, makeNiceDate, addDaysToCurrentDate } from "../helpers/helper";
import ApiClient from "../../base/api/client";


const Stats = () => {
  const params = useParams();
  const { search } = useLocation();
  const [dailyStats, setDailyStats] = useState(null);
  const [dailyDates, setDailyDates] = useState(null);
  const [dailyDnfs, setDailyDnfs] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [{ user, userLoading }] = useStateReducer();

  const fetchStats = async () => {
    if (user) {
      console.log('USER: ', user);
      setShowLoader(true)
      let result = await ApiClient.request(`/api/crews/stats`);
      console.log('Result:', result);
    //  const tempDailyStats = result[1];

      setDailyStats(result.leaders);
      setDailyDates(result.dates);
      setDailyDnfs(result.dnfs);
      setShowLoader(false);
    }
  }

  useEffect(() => {
    fetchStats();
  }, [user])

  const showDailyStats = (num) => {
    let total = 0;
    const tempDailyStats = dailyStats[num];
    console.log('TT', tempDailyStats);
    tempDailyStats.forEach(ds => total = total + parseFloat(ds ? ds.prod : 0));
    let average = (total/tempDailyStats.length);

    const fetchDailyStats = () => {
      return tempDailyStats.map( (ds) => {
        let performanceClass = ''
        if(parseFloat(ds.prod) < parseFloat(average)){
          performanceClass = 'has-background-danger';
        }
        return (<tr key={ds.driver} className={ performanceClass }>
          <td>{ ds.driver }</td>
          <td>{ ds.partner }</td>
          <td>{ ds.partner1 }</td>
          <td>{ formatCurrency(ds.prod) }</td>
          <td>{ ds.onsite } <br/>{ ds.curr_address ? <Link to={`/partner_inspection/${ds.curr_jobid}`}>{ ds.curr_address }</Link> : null }</td>
        </tr>)
      }
      )
    }

    return <div className='table-container'>
      <table className='table'>
        <thead>
          <tr>
            <th colSpan={5} className='has-text-centered has-background-link'>Production { makeNiceDate(dailyDates[num].toString()) } Total:{formatCurrency(total)} Avg: {formatCurrency(average)}</th>
          </tr>
          <tr>
            <th>Crew Leader</th>
            <th>Partner 1</th>
            <th>Partner 2</th>
            <th>Production</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          { fetchDailyStats() }
        </tbody>
      </table>
      <hr/>
    </div>
  }

  const showDailyDnfs = (num) => {
    const tempDailyDnfs = dailyDnfs[num];
    console.log('TT', tempDailyDnfs);

    const fetchDailyDnfs = () => {
      return tempDailyDnfs.map( (dnf) => {
        let performanceClass = ''
        return (<tr key={dnf.dnfid} className={ performanceClass }>
          <td>{ makeNiceDate(dnf.dnfdate) }</td>
          <td>{ dnf.dnfdesc }<span className='has-text-weight-bold'>[{dnf.address} - {makeNiceDate(dnf.jobdate)}]</span></td>
          <td>{ dnf.crew }</td>
          <td>{ dnf.valid }</td>
          <td>{ dnf.status }</td>
        </tr>)
      }
      )
    }

    return <div className='table-container'>
      <table className='table'>
        <thead>
          <tr>
            <th colSpan={5} className='has-text-centered has-background-primary'>DNFs { makeNiceDate(dailyDates[num].toString()) } Total:{ dailyDnfs.length }</th>
          </tr>
          <tr>
            <th>DNF Date</th>
            <th>DNF Desc</th>
            <th>Crew</th>
            <th>Valid</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          { fetchDailyDnfs() }
        </tbody>
      </table>
      <hr/>
    </div>
  }

  return (
    <Layout>
      <div className='section'>
        <div className="container">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li>
                <a href="/menu_items/crew_menu">
                  Back to Crew Menu
                </a>
              </li>
              <li><Link to="/runsheet">Runsheet</Link></li>
              <li className='is-active'><Link to="/stats">Stats</Link></li>
            </ul>
          </nav>
          <h1 className='mt-3 title has-text-weight-semi-bold has-text-link has-text-centered'>Stats </h1><br/>
          { showLoader ? <Loader /> :
            <div>
              <div className='columns'>
                <div className='column'>
                  { dailyStats && showDailyStats(0) }
                </div>
                <div className='column'>
                  { dailyDnfs && showDailyDnfs(0) }
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  { dailyStats && showDailyStats(1) }
                </div>
                <div className='column'>
                  { dailyDnfs && showDailyDnfs(1) }
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  { dailyStats && showDailyStats(2) }
                </div>
                <div className='column'>
                  { dailyDnfs && showDailyDnfs(2) }
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  { dailyStats && showDailyStats(3) }
                </div>
                <div className='column'>
                  { dailyDnfs && showDailyDnfs(3) }
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  { dailyStats && showDailyStats(4) }
                </div>
                <div className='column'>
                  { dailyDnfs && showDailyDnfs(4) }
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </Layout>
  );
};

export default Stats;
