import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import SimpleMap from "./SimpleMap";

import dayjs from "dayjs";

import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

import Layout from "../layout/Layout";
import Loader from "../layout/Loader";
import edit from "../../base/assets/edit";
import Filters from "../components/Filters";
import useRequest from "../../base/hooks/useRequest";

const Tasks = () => {
  const { data: tasks, loading } = useRequest("/api/tasks");
  const { register, watch } = useForm();
  const filters = watch();

  useEffect(() => {
    console.log("refresh tasks");
  }, [filteredTasks]);

  const filteredTasks = useMemo(() => {
    let offset = 0;
    if (!tasks) return [];
    const filterTasksComplete = tasks
      .filter(
        (task) =>
          (task.type === filters.view || filters.view === "All Tasks") &&
          (task.estimator === filters.manager ||
            filters.manager === "All Managers")
      )
      .map((task) => {
        task.lat = 43.6532 + offset;
        task.lng = -79.3832 + offset;
        offset += 0.01;
        return task;
      });
    return filterTasksComplete;
  }, [tasks, filters]);

  // const filteredTasks = () => {
  //   if (!tasks) return [];
  //   const filterTasksComplete = tasks.filter(
  //     (task) =>
  //       (task.type === filters.view || filters.view === "All Tasks") &&
  //       (task.estimator === filters.manager ||
  //         filters.manager === "All Managers")
  //   );
  //   return filterTasksComplete;
  // };

  if (!tasks) {
    return <Loader />;
  }

  return (
    <Layout>
      <div className="container">
        <Filters numberTasks={filteredTasks.length} register={register} />
        {filteredTasks.map((task) => (
          <div key={task.id} className="display-panel-container">
            <div className="display-panel-container__row">
              <span className="display-panel-container__item-1">
                {dayjs(task.messdate).format("ddd MMM DD YYYY")}
              </span>
              <span className="display-panel-container__item-1">
                {task.type} [{task.age_hours ? task.age_hours : "??"}]
              </span>
              <span className="display-panel-container__item-1">
                <a
                  href={`https://www.google.ca/maps/place/${task.address} ${task.postcode}`}
                  target="_blank"
                >
                  {task.address}[{task.area}]
                </a>
              </span>
              <span className="display-panel-container__item-1">
                <span>Manager:{task.estimator}</span>
              </span>
              <span className="display-panel-container__item-2">
                {task.type === "Sign" ? (
                  <Link to={`/tasks/${task.id}/return_sign`}>
                    <img className="icon--large" src={edit} />
                  </Link>
                ) : null}
                {task.type === "Estimate" ? (
                  <Link to={`/tasks/${task.id}/return_estimate`}>
                    <img className="icon--large" src={edit} alt={"NUT"} />
                  </Link>
                ) : null}
                {task.type === "DNF" ? (
                  <Link to={`/tasks/${task.id}/return_dnf`}>
                    <img className="icon--large" src={edit} />
                  </Link>
                ) : null}
              </span>
            </div>
            <div className="display-panel-container__row">
              <span className="display-panel-container__item-2">
                {task.name} ({task.email})
              </span>
            </div>
            <div className="display-panel-container__row display-panel-container__row__padding-b">
              <span className="display-panel-container__item-2">
                {task.message}
              </span>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Tasks;
