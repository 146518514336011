import React from "react";

const SelectAgeOptions = React.forwardRef(({ onChange }, ref) => {
  return (
    <select
      className="filters__input  filters__input--range"
      name="timeToComplete"
      ref={ref}
      onChange={onChange}
    >
      <option value="All">Time To Complete</option>
      <option value="Sold">Sold</option>
      <option value="Pending">Pending</option>
      <option value="Incomplete">Incomplete</option>
      <option value="Scheduled">Scheduled</option>
    </select>
  );
});

export default SelectAgeOptions;
