import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import { useParams } from "react-router";

import Loader from "../../../base/layout/Loader";
import Layout from "../../layout/Layout";
import ApiClient from "../../../base/api/client";





const LiveAction = () => {
  const params = useParams();

  console.log('PARAMS:', params);

  const [liveActions, setLiveActions] = useState([]);
  const [message, setMessage] = useState(null);

  const [unclaimedButton, setUnclaimedButton] = useState('button');
  const [inProgressButton, setInProgressButton] = useState('button');
  const [resolvedButton, setResolvedButton] = useState('button');

  const loadLiveActions = async () => {
    const result = await ApiClient.request(`/api/live_actions`);
    console.log("Result", result);
    setLiveActions(result.las);
  }

  useEffect(() => {
    loadLiveActions()
  }, [])


  const renderRows = () => {
    const result = liveActions.map( la => {
      let rowColour = ''
      if(la.status == 'Unclaimed'){
        rowColour = 'has-background-danger'
      }
      return(
        <tr className={rowColour}>
          <td>{la.target}</td>
          <td>{la.action_type}</td>
          <td>{la.description}</td>
          <td>{la.claimed_by}</td>
          <td>Action Trail</td>
          <td>{la.status}</td>
          <td><Link to={`/edit_live_action/${la && la.id}`}>Edit</Link></td>
        </tr>
      )
     }
    )

    return result;

  }

  const toggleButton = (buttonType) => {
    if(buttonType === 'unclaimed'){
      unclaimedButton==='button' ? setUnclaimedButton('button is-link is-selected') : setUnclaimedButton('button');
    }else if(buttonType === 'inProgress'){
      inProgressButton==='button' ? setInProgressButton('button is-link is-selected') : setInProgressButton('button');
    }else if(buttonType === 'resolved'){
      resolvedButton==='button' ? setResolvedButton('button is-link is-selected') : setResolvedButton('button');
    }
  }

  return (
    <Layout>
      <div className='container'>
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li>
              <a href="/menu_items/crew_menu">
                Back to Crew Menu
              </a>
            </li>
            <li className='is-active'><Link to="/runsheet">Live Actions</Link></li>
          </ul>
        </nav>

        <p className='title has-text-link has-text-weight-bold has-text-centered'>Live Actions</p>
        <hr/>
        {params.msg ? <h3 className='title is-4 has-text-success has-text-centered'>{params.msg}</h3> : null}

        <Link to={`/create_live_action`}>Create Live Action</Link>

        <div className="buttons has-addons">
          <button className={ unclaimedButton } onClick={() => toggleButton('unclaimed')}>Unclaimed</button>
          <button className={ inProgressButton } onClick={() => toggleButton('inProgress')}>In Progress</button>
          <button className={ resolvedButton } onClick={() => toggleButton('resolved')}>Resolved</button>
        </div>

        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Target</th>
                <th>Type</th>
                <th>Desc</th>
                <th>Claimed By</th>
                <th>Action Trail</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>

            <tbody>
              { renderRows() }
            </tbody>

          </table>


        </div>


      </div>
    </Layout>
  );
};

export default LiveAction;


//<FontAwesomeIcon className='fa-2x' icon={ faTruckPickup } label='1' />


// style={ style }
// title={newProps.title}
// label={newProps.label}

// {newProps.label}
// <FontAwesomeIcon className='fa-2x' icon={ faLaptopHouse } label='1' />
