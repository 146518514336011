import React from "react";

const LocationOptions = ({ className, onChange, value, options, name }) => {
  return (
    <select
      className={className}
      onChange={onChange}
      name="location"
      value={value}
    >
      <option value="">None Selected</option>
      {options.map((option) => (
        <option key={option.address} value={option.address}>
          {option.address}
        </option>
      ))}
    </select>
  );
};

export default LocationOptions;
