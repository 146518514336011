import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import FormCard from "../../components/FormCard";
import useRequest from "../../hooks/useRequest";
import Layout from "../../layout/Layout";
import ApiClient from "../../api/client";

const NewDescription = () => {
  const history = useHistory();
  const { register, handleSubmit, reset } = useForm();

  const { data: descriptionOptions, loading } = useRequest(
    "/api/props/fetch_descriptions"
  );

  const onSubmit = async (params) => {
    console.log("PARAMS: ", params);
    const response = await ApiClient.post(`/api/props/add_description`, params);
    if (response) {
      history.push("/transactions");
    }
  };

  return (
    <Layout>
      <button className="button button-assign" onClick={() => history.goBack()}>
        Back
      </button>
      <FormCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form__row form__row--small">
            <input
              type="text"
              className="filters__input--white"
              ref={register}
              name="description"
              placeholder="description"
            />
            <button className="button button-assign">Create</button>
          </div>
        </form>
        {descriptionOptions?.map((d) => (
          <h6 key={d.id}>{d.description}</h6>
        ))}
      </FormCard>
    </Layout>
  );
};

export default NewDescription;
