import React, { useState, useEffect, useMemo } from "react";
import Layout from "../layout/Layout";
import Loader from "../components/Loader";
import ApiClient from "../api/client";
import useRequest from "../hooks/useRequest";
import { useHistory, useLocation } from "react-router";

import ShowPayData from "../components/ShowPayData";
import DailyProfile from "../components/DailyProfile";

const CrewPay = () => {
  const history = useHistory();
  const location = useLocation();
  const [range, setRange] = useState(null);
  const [payData, setPayData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dailyProfileDate, setDailyProfileDate] = useState(null);
  const [dailyProfileData, setDailyProfileData] = useState(null);
  const [dailyProfileLoader, setDailyProfileLoader] = useState(false);
  const [crewName, setCrewName] = useState(null);
  const { data: payPeriodOptions, loading } = useRequest(
    `/api/pays/pay_period_options`
  );
  const [params, setParams] = useState(null);

  const crewProfileParams = useMemo(() => {
    return {
      range,
      dailyProfileDate,
    };
  }, [location.search]);

  const onUpdateParams = ({ name, value }) => {
    const newSearch = new URLSearchParams({
      ...crewProfileParams,
      [name]: value,
    }).toString();
    history.push(`/crewpay?${newSearch}`);
  };

  useEffect(() => {
    // if(payPeriodOptions?.length>0){
    //   var pay = payPeriodOptions.pop();
    //   console.log('PAY-----------:', pay.id);
    //   setRange(pay.id);
    //   onUpdateParams({ name: "range", value: pay.id });
    // }

    payPeriodOptions?.forEach((pay) => {
      if (pay.active === "yes") {
        setRange(pay.id);
        onUpdateParams({ name: "range", value: pay.id });
        return;
      }
    });
  }, [payPeriodOptions, loading]);

  useEffect(() => {
    const newParams = new URLSearchParams(location.search);
    setDailyProfileDate(newParams.get("dailyProfileDate"));
    setCrewName(newParams.get("crewName"));
    newParams.delete("crewName");
    setParams(newParams);
  }, [range, dailyProfileDate]);

  const fireRangeProdSummary = async () => {
    setLoader(true);
    const response = await ApiClient.request(
      `/api/pays/${range}/range_prod_summary`
    );
    setLoader(false);
    setPayData(response);
  };

  useEffect(() => {
    if (range !== null) {
      fireRangeProdSummary();
    }
  }, [range]);

  const fireDailyProfile = async () => {
    setDailyProfileLoader(true);
    const response = await ApiClient.request(
      `/api/pays/${dailyProfileDate}/day_profile_for_crews`
    );
    setDailyProfileLoader(false);
    setDailyProfileData(response);
  };

  useEffect(() => {
    if (dailyProfileDate) {
      fireDailyProfile();
    }
  }, [dailyProfileDate]);

  const onChangeRange = (e) => {
    setRange(e.target.value);
    onUpdateParams({ name: "range", value: e.target.value });
  };

  const openDayProfile = (date) => {
    setDailyProfileDate(date);
    onUpdateParams({ name: "dailyProfileDate", value: date });
  };

  console.log("Range", range);

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="range-header">
            <label className="range-header__label" htmlFor="payPeriod">
              Pay Period
            </label>
            <select
              name="range"
              className="filters__input  filters__input--range"
              onChange={onChangeRange}
              value={range === null ? "" : range}
            >
              <option key="" value="">
                None Selected
              </option>
              {payPeriodOptions.map((ppo) => (
                <option key={ppo.id} value={ppo.id}>
                  {ppo.date1} {"->"} {ppo.date4}
                </option>
              ))}
            </select>
          </div>

          {loader ? (
            <Loader />
          ) : payData === null ? null : (
            <ShowPayData
              payData={payData}
              dailyProfileDate={dailyProfileDate}
              openDayProfile={openDayProfile}
            />
          )}

          {dailyProfileLoader ? (
            <Loader />
          ) : dailyProfileData === null ? null : (
            <DailyProfile
              dailyProfileDate={dailyProfileDate}
              dailyProfileData={dailyProfileData}
              crewName={crewName}
              params={params}
            />
          )}
        </div>
      )}
    </Layout>
  );
};

export default CrewPay;
