import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useStateReducer } from "../../base/context/authContext";
import Layout from "../layout/Layout";
import {
  hoursWithLabels,
  minsWithLabels,
} from "../../base_project/helpers/lists";
import ApiClient from "../../base/api/client";
import InputField from "../components/forms/InputField";
import TextArea from "../components/forms/TextArea";
import Select from "../components/forms/Select";
import TimeSelect from "../components/forms/TimeSelect";

const ReturnDNF = (props) => {
  const [message, setMessage] = useState("");
  const [{ user, userLoading }] = useStateReducer();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // console.log("PROPS ID: ", props.match.params.id);
  // console.log("PARAMS ID: ", params);

  const dnfForm = useForm({ defaultValues: { dnfid: props.match.params.id } });
  const { register, handleSubmit } = dnfForm;

  useEffect(() => {
    if (user) {
      dnfForm.setValue("user", user.hrid);
    }
  }, [user]);

  const onSubmit = async (data) => {
    const merged = { ...data, dnfid, user };
    const result = await ApiClient.patch(`/api/tasks/${data.estid}`, merged);
    setMessage(result.message);
  };

  return (
    <Layout>
      <div className="container-medium">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="has-text-weight-bold is-size-3">Return DNF</h2>

          <button className="button is-info" onClick={() => history.goBack()}>
            Go Back
          </button>
          {message ? <p className="message">{message}</p> : null}
          <InputField
            label="Date:"
            name="date"
            type="date"
            id="date"
            ref={register}
          />
          <TimeSelect baseName="start" label="Start" ref={register} />
          <TimeSelect baseName="finish" label="Finish" ref={register} />

          <Select ref={register} label="Valid?" name="valid">
            <option value="VALID">VALID</option>
            <option value="INVALID">INVALID</option>
            <option value="Client Service">Client Service</option>
          </Select>

          <TextArea
            label="DNF Notes:"
            name="dnfNotes"
            id="dnfNotes"
            ref={register}
          />
          <div>
            <input type="checkbox" name="sendEmail" ref={register} />
            <label htmlFor="sendEmail">Send Email</label>
          </div>
          <TextArea
            label="Email Notes:"
            name="emailNotes"
            id="emailNotes"
            ref={register}
          />

          <button className="button is-info is-fullwidth" type="submit">
            Return DNF
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default ReturnDNF;
