import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { formatCurrencyZero, makeNiceDate } from "../../../helpers/helper";


const RevenueEstsSigns = ({ showDisplay }) => {
  const [{ mgr }] = useStateReducer();
  const[estsSigns, setEstsSigns] = useState([]);
  const[visitPay, setVisitPay] = useState(0);
  const[salesPay, setSalesPay] = useState(0);
  const[showLoader, setShowLoader] = useState(true);

  const fetchEstsSigns = async () => {
    setShowLoader(true);

    let list;
    let list_total;
    if (showDisplay === 'Revenue-Ests 2023'){
      list = 'ests_signs_2023';
    } else if (showDisplay === 'Revenue-Ests 2024'){
      list = 'ests_signs_2024';
    }

    const myParams = { list, hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/messages?${qs}`);
    setEstsSigns(result);
    setShowLoader(false);
  }

  const fetchEstsSignsTotal = () => {
    let totalVisitPay = 0;
    let totalSalesPay = 0;
    estsSigns.forEach((est) =>{
      totalVisitPay += parseFloat(est.visit_pay);
      totalSalesPay += parseFloat(est.sales_pay);
    })
    setVisitPay(totalVisitPay);
    setSalesPay(totalSalesPay);
  }

  useEffect(() => {
    if(mgr){
      fetchEstsSigns();
    }
  }, [mgr, showDisplay])

  useEffect(() => {
    fetchEstsSignsTotal();
  }, [estsSigns])

  const tableEstsSignsData = () => {
    const result = estsSigns.map((est) => (
      <tr key={est.msid} className={ est.className }>
        <td>{ makeNiceDate(est.est_date) }</td>
        <td>{ est.address }</td>
        <td>{ est.status }</td>
        <td>{ est.jobid }</td>
        <td>{ formatCurrencyZero(est.price) }</td>
        <td>{ formatCurrencyZero(est.visit_pay) }</td>
        <td>{ formatCurrencyZero(est.sales_pay) }</td>
      </tr>))
      return result;
  }

  const totalsRow = () => (
    <tr key='totals' className='has-background-link'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{ formatCurrencyZero(visitPay) }</td>
      <td>{ formatCurrencyZero(salesPay) }</td>
    </tr>
  )



  return (
    <div>
      { showLoader ? <Loader /> :
        <div className='box'>
          <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ estsSigns && showDisplay }</h1><br/>
          <h1 className='subtitle has-text-weight-bold has-text-info'>
            Window Ests/Signs: { estsSigns.length }
            &nbsp;&nbsp;Visit Pay: { formatCurrencyZero(visitPay) }
            &nbsp;&nbsp;Sales Pay: { formatCurrencyZero(salesPay) }
            &nbsp;&nbsp;Total Pay: { formatCurrencyZero(parseFloat(visitPay) + parseFloat(salesPay)) }
          </h1>
          <table className='table'>
            <thead>
              <tr>
                <td>Est Date</td>
                <td>Address</td>
                <td>Status</td>
                <td>JobID</td>
                <td>Price</td>
                <td>Visit Pay</td>
                <td>Sales Pay</td>
              </tr>
            </thead>
            <tbody>
              { tableEstsSignsData() }
              { totalsRow() }
            </tbody>
          </table><br/>


        </div>
      }
    </div>)

}

export default RevenueEstsSigns;

// <%if !@joel_win_ests.nil? && !@joel_win_ests.empty?%>
//   <table border="1" class="mystyle">
//     <tr border="1" class="cell" style="background-color:#60bbdb">
//       <th colspan=7 style="text-align:center">Window Estimates</th>
//     </tr>
//     <tr border="1" class="cell" style="background-color:#60bbdb">
//       <th style="width:120px;">Est Date</th>
//       <th style="width:420px;">Address</th>
//       <th style="width:120px;">Status</th>
//       <th style="width:100px;">jobid</th>
//       <th style="width:100px;">Price</th>
//       <th style="width:100px;">Visit Pay</th>
//       <th style="width:100px;">Sales Pay</th>
//     </tr>
//     <%
//     @joel_win_ests.each do |js|
//       jobid = 'nil'
//       if js.jobid
//         jobid = js.jobid
//       end
//       price = 'nil'
//       if js.price
//         price = number_to_currency js.price
//       end
//       sales_pay = 'nil'
//       if js.sales_pay
//         sales_pay = js.sales_pay
//       end
//       class5 = nil
//       if js.status == 'Produced'
//         class5 = 'redblack'
//       end
//     %>
//       <tr class=<%=class5%>>
//         <td><%= js.est_date.strftime('%a %d %b %Y') %></td>
//         <td><%= js.address %></td>
//         <td><%= js.status %></td>
//         <td><%= jobid %></td>
//         <td><%= price %></td>
//         <td><%= number_to_currency js.visit_pay %></td>
//         <td><%= number_to_currency sales_pay %></td>
//       </tr>
//     <% tot_visit_pay += js.visit_pay
//        if sales_pay != 'nil'
//          tot_sales_pay += js.sales_pay.to_f
//        end
//     end %>
//     <tr style="background-color:#60bbdb">
//       <td style="padding:7px 0px" colspan=5>Estimate Subtotals</td>
//       <td style="padding:7px 0px"><%= number_to_currency tot_visit_pay %></td>
//       <td style="padding:7px 0px"><%= number_to_currency tot_sales_pay %></td>
//     </tr>
//   </table>
// <%end%><br/><br/>
