import React from 'react';
import Layout from '../layout/Layout';
import SHANTI from '../assets/images/shantiProfile.jpg'
import CHRIS from '../assets/images/chrisAction.jpg'
import TONY from '../assets/images/tonyProfile.jpg'
import ROY from '../assets/images/royProfile.jpg'
import JASON from '../assets/images/jasonProfile.jpg'
import JACKIE from '../assets/images/JackieProfile.jpg'

const photoStyle = {
  height: '200px',
  width: '60%'
};

const About = () => {

  return (
    <Layout>
      <section className='section'>
        <div className='container'>
          <h2 className='title is-3 has-text-centered'>Our Story</h2>
          <p>
          White Shark Ltd was started in 1991. We originally operated as a painting company for 4 years
          before 1991. We initially started with window and eaves cleaning to complement our painting operations.
          Over the years, we have successfully added odd jobs, lawn care and power washing.
          </p><br/>

          <p>
          Our staff has always been very diverse. In 2021, our people represented 28 countries of origin! We hire
          students and full-time people. We have several people who have spent over 10 years at White Shark Ltd(profiled below).
          We place great importance on safety, team work, hard work and respect from our initial trainings. Our typical employee is between 22 and 30 years of age.
          We enjoy working with this age group as important life habits are honed during these years.
          </p><br/>

          <p>
          We service the GTA and will travel when required to complete jobs. Our clients are a major part of our team
          and our crews have built multi-year relationships with our loyal clients. Throughout the last 30 years, our clients
          have always supported us and helped keep our business going strong!
          </p>

          <hr/>

          <h2 className='title is-3 has-text-centered'>Our 10+ year people</h2>
          <div className='columns'>
            <div className='column'>
              <figure className='image is-1by2 is-clipped'>
                <img style={photoStyle} src={SHANTI} alt='Story Pic' />
                <figcaption>
                  <p>18 years. Shanti is the office quarterback. She oversees recruiting, sales, client satisfaction, receivables, payables, payroll, suppliers and safety.</p>
                </figcaption>
              </figure>
            </div>
            <div className='column'>
              <figure className='image is-1by1 is-clipped'>
                <img style={photoStyle} src={CHRIS} alt='Story Pic' />
                <figcaption>
                <p>20 years. Chris is the longest serving member of our team. Chris works in our painting division and takes great pride in working on older homes and ensuring his clients are satisfied.</p>
                </figcaption>
              </figure>
            </div>
            <div className='column'>
              <figure className='image is-1by1 is-clipped'>
                <img style={photoStyle} src={TONY} alt='Story Pic' />
                <figcaption>
                  <p>13 years. Tony works in our painting division and ensuring the shops are taken care of. Tony has earned a reputation for going to any lengths to take care of his clients.</p>
                </figcaption>
              </figure>
            </div>
          </div>

          <div className='columns'>
            <div className='column'>
              <figure className='image is-1by2 is-clipped'>
                <img style={photoStyle} src={ROY} alt='Story Pic' />
                <figcaption>
                  <p>16 years. Roy started in windows and moved into painting. Presently, Roy runs the odd job division. Roy is highly skilled and tackles uniquely challenging jobs with success.</p>
                </figcaption>
              </figure>
            </div>
            <div className='column'>
              <figure className='image is-1by1 is-clipped'>
                <img style={photoStyle} src={JASON} alt='Story Pic' />
                <figcaption>
                  <p>12 years. Jason works in windows and paint. His quality and customer service are excellent. Jason takes great pride in his work.</p>
                </figcaption>
              </figure>
            </div>
            <div className='column'>
              <figure className='image is-1by1 is-clipped'>
                <img style={photoStyle} src={JACKIE} alt='Story Pic' />
                <figcaption>
                  <p>17 years. Jackie started in windows and is now running our Lawn Care division. Jackie is meticulous, hard working and dependable. She strives to produce excellent work for her clients.</p>
                </figcaption>
              </figure>
            </div>
          </div>



        </div>
      </section>
    </Layout>
   )
};

export default About;
