import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import Tasks from "../views/Tasks";
import ReturnEstimate from "../views/ReturnEstimate";
import ReturnDNF from "../views/ReturnDNF";
import ReturnSign from "../views/ReturnSign";
import EstimateSumm from "../views/EstimateSumm";
import PropertyNotes from "../views/PropertyNotes";
import NewClients from "../views/NewClients";

export const history = createBrowserHistory({ basename: "/crews/react_area" });
const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/tasks" component={Tasks} exact />
      <Route
        path="/tasks/:id/return_estimate"
        component={ReturnEstimate}
        exact
      />
      <Route
        path="/estimate/:id/property-notes"
        component={PropertyNotes}
        exact
      />
      <Route path="/tasks/:id/return_sign" component={ReturnSign} exact />
      <Route path="/tasks/:id/return_dnf" component={ReturnDNF} exact />
      <Route path="/estimates" component={EstimateSumm} exact />
      <Route path="/new_clients" component={NewClients} exact /> New
    </Switch>
  </Router>
);

export default AppRouter;
