import React from "react";
import { useParams, useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import useRequest from "../hooks/useRequest";
import Layout from "../layout/Layout";
import { formatCurrency } from "../helpers/helper";

const JobDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const { data: job, loading } = useRequest(`/api/jobs/${id}/job_details`);

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="jobdetails">
            <Link to={`/makepackages${search}`}>Go Back</Link>
            <div className="jobdetails-title">Job Details {id}</div>
            <div className="jobdetails-row jobdetails-row--small-space">
              <span className="jobdetails-row__item">{job.name}</span>
              <span className="jobdetails-row__item">
                [Rating: {job.rating}]
              </span>
            </div>
            <div className="jobdetails-row jobdetails-row">
              <span className="jobdetails-row__item">
                Job Address: {job.address}[ {job.postcode} // {job.perly} ]
              </span>
              <span className="jobdetails-row__item">
                Billing Address: {job.billing_address}
              </span>
            </div>
            <div className="jobdetails-row jobdetails-row--small-space">
              <span className="jobdetails-row__item">Phone: {job.phone}</span>
              <span className="jobdetails-row__item">Cell: {job.cell}</span>
            </div>
            <div className="jobdetails-item">Email -> {job.email}</div>
            <div className="jobdetails-row">
              <span className="jobdetails-row__item">
                Prices({job.address})
              </span>
              <span className="jobdetails-row__item">
                W1: {formatCurrency(job.w1)}
              </span>
              <span className="jobdetails-row__item">
                EH: {formatCurrency(job.eh)}
              </span>
              <span className="jobdetails-row__item">
                EG: {formatCurrency(job.eg)}
              </span>
            </div>
            <hr />
            <div className="jobdetails-item">Job Desc: {job.jobdesc}</div>
            <div className="jobdetails-item">
              Job Price: {formatCurrency(job.price)}
            </div>
            <div className="jobdetails-item">Crew: {job.crew}</div>
            <div className="jobdetails-row jobdetails-row">
              <span className="jobdetails-row__item">Tag: {job.tag}</span>
              <span className="jobdetails-row__item">Start: {job.sdate}</span>
              <span className="jobdetails-row__item">Finish: {job.fdate}</span>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default JobDetails;

// jobid: job.jobid,
// rating: job.client.rating
// name: job.client.full_name,
// billing_address: job.client.address,
// address: job.property.address,
// phone: job.client.phone,
// cell: job.client.cellphone,
// email: job.client.email
// jobdesc: job.jobdesc,
// price: job.price,
// w1: ppr.w1,
// eh: ppr.eh,
// eg: ppr.eg,
// postcode: job.property.postcode,
// perly: job.property.perly,
// datesold: job.datesold,
// sdate: job.sdate,
// fdate: job.fdate,
// stime: job.stime,
// schdate: job.schdate,
// crewname: job.crewname,
// tag: tag,
// num: job.num
