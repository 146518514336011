import React from "react";

const AssignButton = ({ crewname, assJob }) => {
  return (
    <div className="button is-link" onClick={assJob}>
      Assign[{crewname}]
    </div>
  );
};

export default AssignButton;
