import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import trash from "../assets/trashcan.jpg";
import edit from "../assets/edit.png";

const CrewDisplayPanel = ({ crew, onDeleteCrew }) => {

  return (
    <div className="display-panel-container">
      <div className="display-panel-container__row">
        <span className="display-panel-container__item-1">{crew.shop}</span>
        <span className="display-panel-container__item-1">
          {crew.driver_name ? (
            crew.driver_name
          ) : (
            <span className="display-panel-container--red">Unknown</span>
          )}
        </span>
        <span className="display-panel-container__item-1">
          {crew.partner_name ? (
            crew.partner_name
          ) : (
            <span className="display-panel-container--red">Unknown</span>
          )}
        </span>
        <span className="display-panel-container__item-1">
          {crew.partner1_name !== "Unknown" ? (
            crew.partner1_name
          ) : (
            <span className="display-panel-container__item-1--red">
              Unknown
            </span>
          )}
        </span>
        <Link to={`/crewsetup/${crew.ocid}/edit`}>
          <img className="icon" src={edit} />
        </Link>
        <img className="icon" src={trash} onClick={onDeleteCrew(crew.ocid)} />
      </div>
      <div className="display-panel-container__row">
        <span className="display-panel-container__item-1">
          Vehicle:{crew.car}
        </span>
        <span className="display-panel-container__item-1">{crew.laptop}</span>
        <span className="display-panel-container__item-1">
          Gas:{crew.gascard}
        </span>
        <span className="display-panel-container__item-1">
          Mgr:{crew.closemgr_name}
        </span>
      </div>
    </div>
  );
};

export default CrewDisplayPanel;
