import React, { useState, useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';

import ApiClient from "../../base/api/client";
import LoaderBox from "../forms/LoaderBox";
import { formatCurrency, makeNiceDate } from "../helpers/helper";


const displayJobStyle = {
  flexShrink: 2
}

const CompletedJobs = ({ client, clientAction, reportDNFHelper, reportSatHelper, requestSignPickUp, sendReceipt }) => {
  const [completedJobs, setCompletedJobs] = useState(null);

  const fetchCompletedJobs = async () => {
    const result = await ApiClient.request(`/api/clients/${client.cfid}/completed_jobs`)
    setCompletedJobs(result);
  }

  useEffect(() => {
    if(client){
      fetchCompletedJobs()
    }
  }, [client])

  const changeMenu = (e) => {
    e.preventDefault();
  }

  const discountInfo = (job) => {
    console.log(job.jobid);
    if(job.tran_desc !== ''){
      return (
        <div>
          <div className="level-left">
            <p className="subtitle is-5">
              Job Price:<strong>{ formatCurrency(job.price) }</strong>
            </p>
          </div>
          <div className="level-left">
            <p className="subtitle is-5">
              Discount:<strong>{ formatCurrency(job.discount_price) }({job.tran_desc})</strong>
            </p>
          </div>
          <div className="level-left">
            <p className="subtitle is-5">
              Net Price:<strong>{ formatCurrency(job.price - (-1 * job.discount_price)) }</strong>
            </p>
          </div>
          <div className="level-left">
            <p className="subtitle is-5">
              <strong>{ job.recstatus === 'Receivable' ?
                <a className="has-text-link has-text-weight-bold" onClick={() => clientAction('stripeJobPay')}>
                    &nbsp;&nbsp;Receivable [Apply Payment]
                </a>
               : <span>{ job.recstatus}</span> }</strong>
            </p>
          </div>

        </div>
      )
    }else{
      return (
        <div className="level-left">
      <div className="level-item">
        <p className="subtitle is-5">
          <strong>Price:{ formatCurrency(job.price) }</strong>
        </p>
      </div>
      <div className="level-right">
        <p className="subtitle is-5">
          <strong>{ job.recstatus === 'Receivable' ?
            <a className="has-text-link has-text-weight-bold" onClick={() => clientAction('stripeJobPay')}>
                &nbsp;&nbsp;<u>Receivable [Apply Payment]</u>
            </a>
           : <span>
               { job.recstatus }
               <a className="has-text-link has-text-weight-bold" onClick={() => sendReceipt(job.jobid)}>
                   &nbsp;&nbsp;<u>Send Receipt [{ job.email }]</u>
               </a>
             </span> }
         </strong>
        </p>
      </div>
      </div>
      )
    }
  }

  const signStatus = (status, jobid) => {
    if(status === 'Pending'){
       return(
         <b className="has-text-danger has-text-weight-bold">Sign Pick Up Pending</b>
       )
    } else if(status === 'Resolved-Success'){
       return(
         <b className="has-text-danger has-text-weight-bold">Sign Pick Up Resolved(Successful)</b>
       )
    } else if(status === 'Resolved-Unsuccess'){
       return(
         <b className="has-text-danger has-text-weight-bold">Sign Pick Up Resolved(Unsuccessful)</b>
       )
    } else if(status === 'Resolved'){
       return(
         <b className="has-text-danger has-text-weight-bold">Sign Pick Up Resolved</b>
       )
    } else {
     return (<a className="has-text-link has-text-weight-bold" onClick={() => requestSignPickUp('requestSignPickUp', jobid)}>
       <u>Request Sign Pick Up</u>
     </a>)
    }


  }




  const showCompletedJobs = () => {
    const theJobs = completedJobs.map((cj) =>
    <div key={cj.jobid} className="box is-clipped">
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong>{ cj.jobid }</strong>
            </p>
          </div>
          <div className="level-item">
            <p className="subtitle is-6">
              <strong>{ cj.address }</strong>
            </p>
          </div>
        </div>

        <div className="level-right">
          <p className="level-item"><strong>Completed: { makeNiceDate(cj.datebi) }</strong></p>
        </div>

      </nav>

      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-5">
              <strong>{ cj.jobdesc }</strong>
            </p>
          </div>
        </div>
        <div className="level-right">
          <p className="level-item"><strong>Crew:{ cj.crewname }</strong></p>
        </div>
      </nav>

      <nav className="level">

          { discountInfo(cj) }



      </nav>
      <hr/>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-6">
                <a className="has-text-link has-text-weight-bold" onClick={() => reportDNFHelper('reportDNF', cj.jobid)}>
                  <u>Report Quality Issue</u>
                </a>
            </p>
          </div>
        </div>

        <div className="level-center">
          <div className="level-item">
            <p className="subtitle is-6">
              <strong>
                <a className="has-text-link has-text-weight-bold" onClick={() => reportSatHelper('reportFeedback', cj.jobid)}>
                  <u>Report Feedback</u>
                </a>
              </strong>
            </p>
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <p className="subtitle is-6">
              { signStatus(cj.sign_status, cj.jobid) }
            </p>
          </div>
        </div>

      </nav>
    </div>

    )
    return theJobs;
  }

  return (
    <div className='box has-background-light'>
      <h2 className='title is-4 has-text-centered'>Completed Jobs</h2>
      { completedJobs === null ? <LoaderBox /> :
        <div className="container">
          { showCompletedJobs() }
        </div>
      }
    </div>
  )
}

export default CompletedJobs;
