import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

// let markers = [];

// // Sets the map on all markers in the array.
// function setMapOnAll(map) {
//   for (let i = 0; i < markers.length; i++) {
//     markers[i].setMap(map);
//   }
// }

// // Removes the markers from the map, but keeps them in the array.
// function clearMarkers() {
//   setMapOnAll(null);
// }

// // Shows any markers currently in the array.
// function showMarkers() {
//   setMapOnAll(map);
// }

// // Deletes all markers in the array by removing references to them.
// function deleteMarkers() {
//   console.log("in delete markers");
//   clearMarkers();
//   markers = [];
// }

const handleApiLoaded = (map, maps, tasks) => {
  tasks.forEach((task) => {
    // let label = null;
    // if (task.type === "Sign") {
    //   label = "S";
    // } else if (task.type === "Estimate") {
    //   label = "E";
    // } else {
    //   label = "DNF";
    // }
    //placeMarker(map, task.address + " " + task.postcode, label, task.estimator);
  });
};

// function placeMarker(map, address, label, estimator) {
//   const geocoder = new google.maps.Geocoder();
//   geocoder.geocode({ address: address }, (results, status) => {
//     if (status === "OK") {
//       map.setCenter(results[0].geometry.location);
//       var marker = new google.maps.Marker({
//         map: map,
//         position: results[0].geometry.location,
//         label: label,
//       });

//       markers.push(marker);
//       console.log("Markers", markers);

//       var infoWindow = new google.maps.InfoWindow({
//         content:
//           "<span style='padding:10px; background: blue; color: yellow; font-weight: 600;'>" +
//           address +
//           "[" +
//           estimator +
//           "]" +
//           "</span>",
//       });

//       marker.addListener("click", () => {
//         infoWindow.open(map, marker);
//       });
//     } else if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
//       setTimeout("wait = true", 2000);
//     } else {
//       alert("Geocode was not successful for the following reason: " + status);
//     }
//   });
// }

const SimpleMap = ({ tasks }) => {
  console.log("Tasks", tasks);
  const defaultProps = {
    center: {
      lat: 43.6532,
      lng: -79.3832,
    },
    zoom: 13,
  };

  // useEffect(() => {
  //   console.log("Task Changed");
  //   console.log("Handle Api Loaded");
  //   //clearMarkers();
  // }, [tasks]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ margin: "25px 0 0 0", height: "700px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCmACJq9NUfvvFxEyIG0U_pO82nn6iscpE" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, tasks)}
      >
        {tasks.map((task) => {
          console.log(task.lat + "  " + task.lng);
          return (
            <Marker
              key={task.id}
              lat={task.lat}
              lng={task.lng}
              label={"Test"}
              text={"F"}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
