import React from "react";

const SelectContactOptions = React.forwardRef(({ onChange }, ref) => {
  return (
    <select
      className="filters__input  filters__input--range"
      name="contactStatus"
      ref={ref}
      onChange={onChange}
    >
      <option value="All">All Contact Status</option>
      <option value="NC">NC</option>
      <option value="Called">Called</option>
      <option value="Emailed">Emailed</option>
      <option value="Called and Emailed">Called and Emailed</option>
    </select>
  );
});

export default SelectContactOptions;
