import React from "react";

const SelectRangeOptions = React.forwardRef(({ onChange }, ref) => {
  return (
    <select
      className="filters__input  filters__input--range"
      name="view"
      ref={ref}
      onChange={onChange}
    >
      <option value="All Tasks">All Tasks</option>
      <option value="Estimate">Estimates</option>
      <option value="Sign">Signs</option>
      <option value="DNF">DNFs</option>
    </select>
  );
});

export default SelectRangeOptions;
