import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import InputField from "../components/forms/InputField";
import TextArea from "../components/forms/TextArea";

import useRequest from "../../base/hooks/useRequest";
import useLazyRequest from "../../base/hooks/useLazyRequest";
import ApiClient from "../../base/api/client";
import DisplayPanel from "../components/new-clients/DisplayPanel";

const NewClients = () => {
  const [message, setMessage] = useState(null);
  const [newClients, setNewClients] = useState([]);
  const [buttonClass, setButtonClass] = useState("button is-info");
  const [submitButtonClass, setSubmitButtonClass] = useState(
    "button is-info is-fullwidth"
  );
  const [{ user, userLoading }] = useStateReducer();

  //   const { data: mess, loading: loadPropNotes } = useRequest(
  //     `/api/tasks/${user.hrid}`
  //   );

  const id = 1;

  const ccForm = useForm();

  const { register, handleSubmit, setValue } = ccForm;

  //`/api/tasks/${user.id}/fetch_new_clients`
  const loadNewClients = async () => {
    if (user) {
      const result = await ApiClient.request(`/api/tasks/fetch_new_clients`);
      setNewClients(result);
    }
  };

  useEffect(() => {
    if (user) {
      loadNewClients();
    }
  }, [user]);

  const onSubmit = async (data) => {
    if (data.name != "" && data.address != "" && data.phone != "") {
      var updatedData = { ...data, hrid: user.hrid };
      console.log("UD", updatedData);
      const result = await ApiClient.post(
        `/api/tasks/save_new_client`,
        updatedData
      );
      console.log("Result", result);
      setMessage(result.message);
      setValue("name", "");
      setValue("address", "");
      setValue("postcode", "");
      setValue("phone", "");
      setValue("email", "");
      setValue("notes", "");

      // setButtonClass("button is-danger");
      // setSubmitButtonClass("button is-danger is-fullwidth");
      window.scrollTo(0, 0);
      loadNewClients();
    } else {
      setMessage("Submit Failed No Data");
      window.scrollTo(0, 0);
    }
  };

  return (
    <Layout>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="has-text-weight-bold is-size-3">New Client</h2>
          {message ? <p className="message">{message}</p> : null}
          <InputField
            label="Name"
            name="name"
            id="name"
            ref={register}
            placeholder="Full Name..."
          />
          <InputField
            label="Address"
            name="address"
            id="address"
            ref={register}
            placeholder="Address..."
          />
          <InputField
            label="Post Code"
            name="postcode"
            id="text"
            ref={register}
            placeholder="Post Code..."
          />
          <InputField
            label="Phone:"
            name="phone"
            id="phone"
            ref={register}
            placeholder="Phone..."
          />
          <InputField
            label="Email:"
            name="email"
            id="email"
            ref={register}
            placeholder="Email..."
          />
          <InputField
            label="W1:"
            name="w1"
            id="w1"
            ref={register}
            placeholder="W1..."
          />
          <InputField
            label="EH:"
            name="eh"
            id="eh"
            ref={register}
            placeholder="EH..."
          />
          <InputField
            label="EG:"
            name="eg"
            id="eg"
            ref={register}
            placeholder="EG..."
          />
          <TextArea
            label="Notes:"
            name="notes"
            id="notes"
            ref={register}
            placeholder="Notes..."
          />
          <button className={submitButtonClass} type="submit">
            Register New Client
          </button>
        </form>

        {newClients && (
          <div className="margin-top">
            {newClients.map((nc) => (
              <DisplayPanel
                key={nc.id}
                cfid={nc.cfid}
                source={nc.source}
                name={nc.name}
                address={nc.address}
                phone={nc.phone}
                email={nc.email}
                notes={nc.notes}
              />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NewClients;
