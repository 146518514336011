import React, { useState, useEffect } from 'react';

import Layout from "../layout/Layout";


const CrewNeeds = () => {
  return <Layout><h1>Crew Needs</h1></Layout>
};

export default CrewNeeds;
