import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { formatCurrencyZero, makeNiceDate } from "../../../helpers/helper";


const RevenueMisc = ({ showDisplay }) => {
  const[{ mgr }] = useStateReducer();
  const[personalMts, setPersonalMts] = useState([])
  const[personalMtsTotal, setPersonalMtsTotal] = useState(0);
  const[personalWss, setPersonalWss] = useState([])
  const[personalWssTotal, setPersonalWssTotal] = useState(0);
  const[showLoader, setShowLoader] = useState(true);

  const fetchRevenueMisc = async () => {
    setShowLoader(true);
    let list;
    let list_total;
    if (showDisplay === 'Revenue-Misc 2023'){
      list = 'personal_list_2023';
      list_total = 'personal_list_total_2023';
    } else if (showDisplay === 'Revenue-Misc 2024'){
      list = 'personal_list_2024';
      list_total = 'personal_list_total_2024';
    }

    let myParams = { hrid: mgr.hrid, list};
    let qs = new URLSearchParams(myParams).toString();
    let result = await ApiClient.request(`/api/mgt_tasks/?${qs}`);
    setPersonalMts(result);
    myParams = { hrid: mgr.hrid, list: list_total};
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/mgt_tasks/?${qs}`);
    setPersonalMtsTotal(result);


    myParams = { hrid: mgr.hrid, list};
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/workschedules/?${qs}`);
    setPersonalWss(result);
    myParams = { hrid: mgr.hrid, list: list_total};
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/workschedules/?${qs}`);
    setPersonalWssTotal(result);
    setShowLoader(false);
  }

  useEffect(() => {
    if(mgr && mgr.hrid){
      fetchRevenueMisc();
    }
  }, [mgr, showDisplay])

  const tableWssPersonalData = () => {
    const result = personalWss.map((ws) => (<tr key={ws.id}>
        <td>{ makeNiceDate(ws.profiledate) }</td>
        <td>White Shark</td>
        <td>{ ws.name }</td>
        <td>{ ws.type }</td>
        <td>{ ws.stime }</td>
        <td>{ ws.ftime }</td>
        <td>{ ws.hours }</td>
        <td>{ formatCurrencyZero(ws.rate) }</td>
        <td>{ formatCurrencyZero(ws.pay) }</td>
        <td>{ ws.audited ? 'Audited' : 'Pending' }</td>
      </tr>))
      return result;
  }


  const tableMtsPersonalData = () => {
    const result = personalMts.map((mt) => (<tr key={mt.id}>
        <td>{ makeNiceDate(mt.date) }</td>
        <td>White Shark</td>
        <td>{ mt.name }</td>
        <td>{ mt.task }</td>
        <td>{ mt.rstime }</td>
        <td>{ mt.rftime }</td>
        <td>{ mt.hours }</td>
        <td>{ formatCurrencyZero(mt.rate) }</td>
        <td>{ formatCurrencyZero(mt.pay) }</td>
        <td>{ mt.audited ? 'Audited' : 'Pending' }</td>
      </tr>))
      return result;
  }

  return (
    <div>
      { showLoader ? <Loader /> :
        <div className='box'>
          <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ showDisplay }</h1><br/>
          <h1 className='subtitle has-text-weight-bold has-text-info'>Mgt Tasks: { personalMts.length } Total: { formatCurrencyZero(personalMtsTotal) } </h1>
          <table className='table'>
            <thead>
              <tr>
                <td>Date</td>
                <td>Mgr</td>
                <td>Name</td>
                <td>Task</td>
                <td>Start Time</td>
                <td>Finish Time</td>
                <td>Hours</td>
                <td>Rate</td>
                <td>Pay</td>
                <td>Audited</td>
              </tr>
            </thead>
            <tbody>
              { tableMtsPersonalData() }
            </tbody>
          </table><br/>
        <h1 className='subtitle has-text-weight-bold has-text-info'>Workschedule: { personalWss.length } Total: { formatCurrencyZero(personalWssTotal) } </h1>
          <table className='table'>
            <thead>
              <tr>
                <td>Date</td>
                <td>Mgr</td>
                <td>Name</td>
                <td>Type</td>
                <td>Start Time</td>
                <td>Finish Time</td>
                <td>Hours</td>
                <td>Rate</td>
                <td>Pay</td>
                <td>Audited</td>
              </tr>
            </thead>
            <tbody>
              { tableWssPersonalData() }
            </tbody>
          </table><br/>
        <hr/>
        <h1 className='subtitle has-text-weight-bold has-text-info'>Grand Total: { formatCurrencyZero(parseFloat(personalMtsTotal) + parseFloat(personalWssTotal)) }</h1>
        </div>
      }
    </div>)

}

export default RevenueMisc;
