import React from 'react';

import Loader from "../../base/layout/Loader";

const LoaderBox = ({ boxStyle }) => {
  return (
    <div style={boxStyle} className='box'>
      <Loader />
  </div>
  )
}

export default LoaderBox;
