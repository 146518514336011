import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCogs, faDiceFour, faAd, faMapMarker, faLaptopHouse, faCity, faTruckPickup} from '@fortawesome/free-solid-svg-icons'

import LiveActionForm from "./LiveActionForm"


const CreateLiveAction = () => {



  const targetOptions = () => {
    return(
    <select>
      <option>NUT</option>
    </select>)
  }

  return (
    <div className='container'>
      <nav className='breadcrumb'>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li>
            <a href="/menu_items/crew_menu">
              Back to Crew Menu
            </a>
          </li>
          <li><Link to="/live_action">Back To Live Action</Link></li>
          <li className='is-active'>Create Live Action</li>
        </ul>
      </nav>

      <p className='title has-text-link has-text-weight-bold has-text-centered'>Create Live Action</p>
      <hr/>

      <LiveActionForm />

    </div>
  );
};

export default CreateLiveAction;


//<FontAwesomeIcon className='fa-2x' icon={ faTruckPickup } label='1' />


// style={ style }
// title={newProps.title}
// label={newProps.label}

// {newProps.label}
// <FontAwesomeIcon className='fa-2x' icon={ faLaptopHouse } label='1' />
