export const shopOptions = ["Osler", "Morse"];

export const payPeriodOptions = [
  "Nov 30 -> Dec 13",
  "Nov 16 -> Nov 29",
  "Nov 2 -> Nov 15",
];

//export const hours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const hours = [...Array(14)].map((_, i) => i + 7);

export const mins = [...Array(12)].map((_, i) => i * 5);

export const names = ["aaaa", "bbbbb", "ccccc", "dddddd"];

export const locationData = [
  {
    address: "47 Holland Avenue",
    city: "Toronto",
    province: "Ontario",
    country: "Canada",
    postcode: "M4B 2C7",
  },
  {
    address: "27 Highland Drive",
    city: "Oro Medonte",
    province: "Ontario",
    country: "Canada",
    postcode: "L0L 2L0",
  },
  {
    address: "121 Empire Drive",
    city: "Barrie",
    province: "Ontario",
    country: "Canada",
    postcode: "L4M 0B1",
  },
  {
    address: "249 Chisholm Avenue, Upper",
    city: "Toronto",
    province: "Ontario",
    country: "Canada",
    postcode: "M4C 4W5",
  },
  {
    address: "249 Chisholm Avenue, Main",
    city: "Toronto",
    province: "Ontario",
    country: "Canada",
    postcode: "M4C 4W5",
  },
  {
    address: "36 Morse Street",
    city: "Toronto",
    province: "Ontario",
    country: "Canada",
    postcode: "M4M 2P6",
  },
  {
    address: "88 Osler Street",
    city: "Toronto",
    province: "Ontario",
    country: "Canada",
    postcode: "M6P 4A2",
  },
  {
    address: "23 Deneb Street",
    city: "Toronto",
    province: "Ontario",
    country: "Canada",
    postcode: "L4M 0K6",
  },
  {
    address: "1 Leggott Avenue, #9",
    city: "Barrie",
    province: "Ontario",
    country: "Canada",
    postcode: "L4N 6B1",
  },
  {
    address: "1 Leggott Avenue, #12",
    city: "Barrie",
    province: "Ontario",
    country: "Canada",
    postcode: "L4N 6B1",
  },
  {
    address: "833 SE 2nd Ave",
    city: "Delray Beach",
    province: "Florida",
    country: "USA",
    postcode: "34383",
  },
];

export const prodNoteOptions = [
  "Custom",
  "Condensation between panes",
  "Screens that are hooked from inside",
  "Screens part of the storm",
  "Screens remove from outside",
  "Wood Storms",
  "Metal Storms",
  "Eaves have screens",
  "Windows need 40' ladder",
  "Eaves need 40' ladder",
  "Existing eaves damage",
  "Existing window damage",
  "Eaves repair required",
  "Painting required",
];
