import React from "react";
import { locationData } from "../../helpers/lists";

import Loader from "../../components/Loader";
import useRequest from "../../hooks/useRequest";

const FormFields = ({ register }) => {
  const { data: descriptions, loading } = useRequest(
    `/api/props/fetch_descriptions`
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <input type="hidden" ref={register} name="id" />
      <input
        type="date"
        className="filters__input"
        ref={register}
        name="date"
      />
      <div className="form__row form__row--small">
        <span className="form__label--medium">Location</span>
        <select className="form__select--large" name="location" ref={register}>
          <option key="xx" value="">
            None Selected
          </option>
          {locationData.map((location) => (
            <option key={location.address} value={location.address}>
              {location.address}
            </option>
          ))}
        </select>
      </div>

      <div className="form__row form__row--small">
        <span className="form__label--medium">Type</span>
        <select
          className="form__select--large"
          name="typeIncome"
          ref={register}
        >
          <option value="Expense">Expense</option>
          <option value="Revenue">Revenue</option>
        </select>
      </div>
      <div className="form__row form__row--small">
        <span className="form__label--medium">Description</span>
        <select
          className="form__select--large"
          name="description"
          ref={register}
        >
          <option key="xx" value="">
            None Selected
          </option>
          {descriptions.map((description) => (
            <option key={description.id} value={description.description}>
              {description.description}
            </option>
          ))}
        </select>
      </div>
      <div className="form__row form__row--small">
        <span className="form__label--medium">Amount</span>
        <input className="filters__input--white" ref={register} name="amount" />
      </div>
      <div className="form__row form__row--small">
        <textarea
          rows="4"
          cols="70"
          name="notes"
          ref={register}
          placeholder="Description..."
        />
      </div>
    </>
  );
};

export default FormFields;
