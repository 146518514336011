import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import LoginAPI from "../../base/api/login";
import { useStateReducer } from "../../base/context/authContext";
import ActionType from "../../base/context/actionType";

const HeaderLink = ({ children, to, logOut, className, activeClassName }) => {
  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={to}
      onClick={logOut}
      exact
    >
      {children}
    </NavLink>
  );
};

const Header = () => {
  const [, dispatch] = useStateReducer();
  const [menuLaunched, setMenuLaunched] = useState("menu-links-container");
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuClass, setMenuClass] = useState("menu-btn");

  const attemptLogout = async () => {
    await LoginAPI.logOut();
    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
  };

  const logOut = () => {
    attemptLogout();
  };

  const launchMenu = () => {
    menuLaunched === "menu-links-container"
      ? setMenuLaunched("menu-links-container menu-links-container--show")
      : setMenuLaunched("menu-links-container");
  };

  const menuBtn = () => {
    if (!menuOpen) {
      setMenuClass("menu-btn menu-btn--open");
      setMenuOpen(true);
      launchMenu();
    } else {
      setMenuClass("menu-btn");
      setMenuOpen(false);
      launchMenu();
    }
  };

  return (
    <header>
      <div className="header-container">
        <div className="header">
          <div className="header-logo">White Shark Ltd</div>
          <div className="menu-links-row">
            <a className="link-item-row" href="/functions/login1">
              Main Menu
            </a>
            <HeaderLink
              to="/"
              className="link-item-row"
              activeclassname="link-item-row--selected"
            >
              Home
            </HeaderLink>
            <HeaderLink
              to="/wine_list"
              className="link-item-row"
              activeclassname="link-item-row--selected"
            >
              Wine List
            </HeaderLink>
            <HeaderLink
              to="/create_wine_list"
              className="link-item-row"
              activeclassname="link-item-row--selected"
            >
              Create Wine List
            </HeaderLink>
          </div>

          <div className={menuClass} onClick={menuBtn}>
            <div className="menu-btn__burger"></div>
          </div>
        </div>

        <div className={menuLaunched}>
          <div className="menu-links">
            <a className="link-item" href="/functions/login1">
              Main Menu
            </a>
            <HeaderLink to="/" className="link-item">
              Home
            </HeaderLink>
            <HeaderLink to="/wine_list" className="link-item">
              Wine List
            </HeaderLink>
            <HeaderLink to="/create_wine_list" className="link-item">
              Create Wine List
            </HeaderLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
