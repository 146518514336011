import React from "react";
import { Redirect } from "react-router";
import { useStateReducer } from "../../base/context/authContext";
import Loader from "./Loader";
import Header from "./Header";
//import Ticker from "./Ticker";

const Layout = ({ children }) => {
  const [{ user, userLoading }] = useStateReducer();

  if (userLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="layout">
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default Layout;

//<Ticker />
