import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrencyZero } from "../../../helpers/helper";

const Expenses = ({ showDisplay }) => {
  const [{ mgr }] = useStateReducer();
  const[expenses, setExpenses] = useState([]);
  const[totalExpenses, setTotalExpenses] = useState(0);
  const[showLoader, setShowLoader] = useState(true);


  const fetchExpenses = async() => {
    setShowLoader(true);

    let list;
    if (showDisplay === 'Expenses 2023'){
      list = 'expense_list_2023';
    } else if (showDisplay === 'Expenses 2024'){
      list = 'expense_list_2024';
    }

    const myParams = { list, hrid: mgr.hrid };
    const qs = new URLSearchParams(myParams);
    const result = await ApiClient.request(`/api/partners?${qs}`);
    setExpenses(result);
    setShowLoader(false);
  }

  useEffect(() => {
    if(mgr){
      fetchExpenses();
    }
  }, [mgr, showDisplay])


  const expenseData = () => {
    return expenses.map((expense) => (
      <tr key={expense.id}>
        <td>{ makeNiceDate(expense.date )}</td>
        <td>{ expense.type }</td>
        <td>{ expense.notes }</td>
        <td>{ formatCurrencyZero(expense.unitprice) }</td>
        <td>{ expense.amount }</td>
        <td>{ formatCurrencyZero(expense.charge) }</td>
      </tr>
    ))
  }

  const expenseDataTotal = () => {
    let totalExpenses = 0;
    expenses.forEach((expense) => totalExpenses += parseFloat(expense.charge))
    return <tr key='total'>
             <th>Total</th>
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th>{ formatCurrencyZero(totalExpenses) }</th>
           </tr>
  }

  const showExpenses = () => {
    return(
      <React.Fragment>
        <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ showDisplay }</h1><br/>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Notes</th>
              <th>Unit Price</th>
              <th>Amount</th>
              <th>Charge</th>
            </tr>
          </thead>
          <tbody>
            { expenseData() }
          </tbody>
          <tfoot>
            { expenseDataTotal() }
          </tfoot>
        </table>
      </React.Fragment>
    )
  }

  return <div className='box'>
    { showLoader ? <Loader /> : showExpenses() }

  </div>

}

export default Expenses;
