import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { formatCurrency } from "../../helpers/helper";

const ChqRegEntry = () => {
  const [amount, setAmount] = useState("3000");

  const amountToPay = () => {};
  const setAmountToPay = () => {};

  return (
    <table className="crewpay-table crewpay-table--margin-bottom">
      <thead></thead>
      <tbody>
        <tr>
          <td className="crewpay-table__body crewpay-table__body--pad">
            Week 1
          </td>
          <td className="crewpay-table__body crewpay-table__body--pad">
            {formatCurrency("1500")}
          </td>
        </tr>
        <tr>
          <td className="crewpay-table__body crewpay-table__body--pad">
            Week 2
          </td>
          <td className="crewpay-table__body crewpay-table__body--pad">
            {formatCurrency("1500")}
          </td>
        </tr>
        <tr>
          <td className="crewpay-table__body crewpay-table__body--pad">
            Total
          </td>
          <td className="crewpay-table__body crewpay-table__body--pad">
            {formatCurrency("3000")}
          </td>
        </tr>
        <tr>
          <td className="crewpay-table__body crewpay-table__body--pad">
            YTD Amount
          </td>
          <td className="crewpay-table__body crewpay-table__body--pad">
            <input
              type="text"
              name="amount"
              value={amount}
              onChange={amountToPay}
              size="10"
            />
          </td>
        </tr>

        <tr>
          <td
            colSpan="2"
            className="crewpay-table__body crewpay-table__body--pad"
          >
            <button
              className="form__input form__input__button"
              onClick={setAmountToPay}
            >
              Create Cheque Register Entry
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ChqRegEntry;
