import React from 'react'



const CurrentJobs = () => {

  return (
    <div className='box has-background-light'>
      <h2 className='title is-4 has-text-centered'>Current Jobs</h2>
      <div className='tile'>
        <div className='tile is-child notification is-light'>
          <div className='box'>
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <p className="subtitle is-5">
                    <strong>JB123</strong>
                  </p>
                </div>
                <div className="level-item">
                  <p className="subtitle is-5">
                    32 Admiral Road
                  </p>
                </div>
              </div>

              <div className="level-right">
                <p className="level-item"><strong>April 3</strong></p>
              </div>
            </nav>

            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <p className="subtitle is-5">
                    <strong>Crew:</strong> Josh and Joey
                  </p>
                </div>
                <div className="level-item">
                  <p className="subtitle is-5">
                    W1
                  </p>
                </div>
              </div>

              <div className="level-right">
                <p className="level-item"><strong>Status</strong>On-Site</p>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurrentJobs



// <tfoot>
//   <tr>
//     <th><abbr title="Job ID">jobid</abbr></th>
//     <th><abbr title="Booked On">Bkd</abbr></th>
//     <th><abbr title="Job Type">Type</abbr></th>
//     <th><abbr title="Job Address">Loc</abbr></th>
//     <th><abbr title="Job Description">Desc</abbr></th>
//   </tr>
// </tfoot>
