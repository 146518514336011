import React from "react";

const SelectOptionsObj = React.forwardRef(
  ({ options, label, name, errors }, ref) => {
    const error = (errors || {})[name]?.[0];
    return (
      <div className="form__row">
        <label className="form__label" htmlFor={name}>
          {label}
        </label>
        {error ? <p className="error-explanation">{error}</p> : null}
        <select className="form__select" name={name} ref={ref}>
          <option value="">None Selected</option>
          {options.map((option) => {
            return (
              <option key={option.hrid} value={option.hrid}>
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
);

export default SelectOptionsObj;
