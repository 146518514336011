import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

const EstSignMgt = ({handleDailyReport, user, checkReportIssued, fireDateChanged, currentDate , area}) => {
  const ccForm = useForm();

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>Estimates/Signs Mgt{area} [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          name='hrid'
          ref={ register }
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          name='area'
          ref={ register }
          defaultValue = { 'Estimates/Signs Mgt' + area}
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              name='date'
              ref={ register }
              defaultValue = { currentDate }
              onChange = { () => fireDateChanged(getValues('date')) }
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line1'
              ref={ register }
              defaultValue={ "[Estimates Feedback]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line2'
              ref={ register }
              defaultValue={ "[Speak to Roger about far out estimates]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line3'
              ref={ register }
              defaultValue={ "[How many 3+ days estimates left]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line4'
              ref={ register }
              defaultValue={ "[Sign pick up feedback]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line5'
              ref={ register }
              defaultValue={ "[Speak to Roger about far out sign pick ups]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line6'
              ref={ register }
              defaultValue={ "[How many 3+ days sign pick ups left]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line7'
              ref={ register }
              defaultValue={ "[# Sign frames on hand at shop]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line8'
              ref={ register }
              defaultValue={ "[Sign covers on hand at shop]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send Estimates/Signs Mgt Report</button>
        </div>
      </form>
    </div>

  )


}

export default EstSignMgt
