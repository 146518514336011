import React, { useState, useEffect } from 'react'
import ApiClient from "../../base/api/client";
import { useStateReducer } from "../../base/context/authContext";
import { useLocation, useParams } from "react-router-dom";
import { createBrowserHistory } from "history";



import Layout from "../layout/Layout";
import Loader from "../layout/Loader";
import SelectOptions from "../components/SelectOptions"
import DisplayPanel from "../components/DisplayPanel";
import MasterWineList from '../components/MasterWineList'
import { formatCurrency } from '../helpers/helper'


const CreateWineList = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const[wineList, setWineList] = useState([])
  const[priorityA, setPriorityA] = useState(0)
  const[priorityB, setPriorityB] = useState(0)
  const[range, setRange] = useState('None Selected')
  const[message, setMessage] = useState('')

  const rangeParam = params.get("range") || null;
  console.log('Params**Range:', rangeParam)

  const [{ user, userLoading }] = useStateReducer();

  const options = [
    {key: '0', name: 'None Selected'},

    {key: '1i', name: 'Paint-2019(Jan1-Jun30)'},
    {key: '1j', name: 'Paint-2019(Jul1-Sep30)'},
    {key: '1k', name: 'Paint-2019(Oct1-Oct31)'},
    {key: '1l', name: 'Paint-2019(Nov1-Dec31)'},

    {key: '1a', name: 'Paint-2020(Jan1-Jun30)'},
    {key: '1b', name: 'Paint-2020(Jul1-Sep30)'},
    {key: '1c', name: 'Paint-2020(Oct1-Oct31)'},
    {key: '1d', name: 'Paint-2020(Nov1-Dec31)'},

    {key: '1e', name: 'Paint-2021(Jan1-Jun30)'},
    {key: '1f', name: 'Paint-2021(Jul1-Sep30)'},
    {key: '1g', name: 'Paint-2021(Oct1-Oct31)'},
    {key: '1h', name: 'Paint-2021(Nov1-Dec31)'},

    {key: '2a', name: 'Odd Jobs-2020(Jan1-Jun30)'},
    {key: '2b', name: 'Odd Jobs-2020(Jul1-Sep30)'},
    {key: '2c', name: 'Odd Jobs-2020(Oct1-Oct31)'},
    {key: '2d', name: 'Odd Jobs-2020(Nov1-Dec31)'},

    {key: '2e', name: 'Odd Jobs-2021(Jan1-Jun30)'},
    {key: '2f', name: 'Odd Jobs-2021(Jul1-Sep30)'},
    {key: '2g', name: 'Odd Jobs-2021(Oct1-Oct31)'},
    {key: '2h', name: 'Odd Jobs-2021(Nov1-Dec31)'},

    {key: '3', name: 'Rating 4.4+'},
    {key: '4', name: 'Rating 4.3-4.4'},
    {key: '5', name: 'Rating 4.2-4.3'},
    {key: '6', name: 'Rating 4.1-4.2'},
    {key: '7', name: 'Rating 4.0-4.1'},
    {key: '9', name: 'Master Wine List'},
  ]

  const loadWineList = async () => {
    if (user) {
      console.log('Range in loadWineList', range)
      let key = null
      options.forEach((option) => {
        console.log('Key', option.key)
        if(option.name===range){
          key = option.key
        }
      })
      console.log('KEY:', key)
      if(key !== '0'){
        const result = await ApiClient.request(`/api/clients/${key}/load_wine_list`);
        setWineList(result.wine_clients);
        setPriorityA(result.priority_A);
        setPriorityB(result.priority_B);
        const newSearch = new URLSearchParams({
          range,
        }).toString();
        history.push(`/crews/react_wine_delivery/create_wine_list?${newSearch}`);
      } else {
        history.push(`/crews/react_wine_delivery/create_wine_list`);
      }

    }
  };


  useEffect(() => {
     if(rangeParam){
       setRange(rangeParam)
     }
  }, [])




  useEffect(() => {
    setWineList([])
    if(range !== 'None Selected'){
      loadWineList()
    }else{
      setRange("None Selected")
      history.push(`/crews/react_wine_delivery/create_wine_list`);
    }
  }, [range, user])


  const onChangeRange = (e) => {
    setRange(e.target.value)
  }

  const addToList = async(cfid, type) => {
    if(type == 'A'){
      const result = await ApiClient.request(`/api/clients/${cfid}/add_wine_list_A`);
      if(result){
        setWineList(wineList.map((client) => {
          if(client.cfid === cfid){
            client.wine_list = '[On List-' + type + ']'
            setPriorityA(priorityA + 1)
          }
          return client
        }))
      } else {
        setMessage(client.fullname + ' added already!')
      }
    } else if (type == 'B') {
      const result = await ApiClient.request(`/api/clients/${cfid}/add_wine_list_B`);
      if(result){
        setWineList(wineList.map((client) => {
          if(client.cfid === cfid){
            client.wine_list = '[On List-' + type + ']'
            setPriorityB(priorityB + 1)
          }
          return client
        }))
      } else {
        setMessage(client.fullname + ' added already!')
      }
    } else  {
      const result = await ApiClient.request(`/api/clients/${cfid}/remove_wine_list`);
      setWineList(wineList.map((client) => {
        console.log('Wine List:', client.wine_list)
        if(client.cfid === cfid){
          if(client.wine_list === '[On List-A]'){
            setPriorityA(priorityA - 1)
          }
          if(client.wine_list === '[On List-B]'){
            setPriorityB(priorityB - 1)
          }
          client.wine_list = null
        }
        return client
      }))
    }
  }

  const displayWineList = () => {
    return wineList.map((client) => (
      <h2 className={ client.wine_list ? "underLine__on_wine_list" : "underLine" } key={client.cfid}>
        <div>
          { client.fullname } &nbsp;&nbsp;
          { client.address } &nbsp;&nbsp;
          { client.perly } &nbsp;&nbsp;
          { formatCurrency(client.total_spent) } &nbsp;&nbsp;
          { client.wine_list ? client.wine_list : "" } &nbsp;
        </div>

          { client.wine_list ? (<div><button className='button-wine' onClick={() => addToList(client.cfid, 'R')}>Rem</button></div>)
           :
          (<div><button className='button-wine' onClick={() => addToList(client.cfid, 'A')}>A</button>&nbsp;
          <button className='button-wine' onClick={() => addToList(client.cfid, 'B')}>B</button></div> )
          }
      </h2>
    ))
  }

  return (
    <Layout>
      <div className='container'>
        { message ? <span>{ message }</span> : null}
        <select
          name="range"
          className="filters__input  filters__input--range"
          onChange={onChangeRange}
          value={range === null ? "" : range}
        >
          <option key="" value="">
            None Selected
          </option>
          {options.map((op) => (
            <option key={op.key} value={op.name}>
              {op.name}
            </option>
          ))}
        </select><br/><br/>
        <MasterWineList priorityA={priorityA} priorityB={priorityB} /><br/>
        { wineList ? (<div><br/><h2>{ range } List: { wineList.length }</h2><br/></div>) : null }
        { wineList &&  displayWineList() }
        { (range !== 'None Selected' && wineList.length === 0) ? <Loader /> : null }
      </div>
    </Layout>
  )
}

export default CreateWineList
