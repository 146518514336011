import React, { useReducer, useCallback, useEffect } from "react";
import ActionType from "../base/context/actionType";
import LoginAPI from "../base/api/login";
import AppRouter from "./routers/AppRouter";
import { stateReducer, initialState } from "../base/context/stateReducer";
import { AuthContext } from "../base/context/authContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

function App(user) {
  console.log("HRID $$$$$$$", user.hrid);
  const stateTuple = useReducer(stateReducer, initialState);
  const [{ userLoading }, dispatch] = stateTuple;

  const fetchUser = useCallback(async () => {
    // check if signed in(run loader while waiting)
    // if yes, dispatch object that sets the user
    // check request finished/error(show message)
    // if (!userLoading) return;
    // const { user } = await LoginAPI.verifySession();
    //
    // console.log("User%%", user);

    dispatch({
      type: ActionType.SetUser,
      payload: user,
    });
  }, [dispatch, userLoading]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <AuthContext.Provider value={stateTuple}>
      <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
