import React from 'react';


const JobNotes = ({ register }) => {
  return (
    <div className='field'>
      <div className='control'>
        <br/><label className='label' htmlFor='jobNotes'>Additional Notes</label>
        <textarea className='textarea' rows='3' id='jobNotes' name='jobnotes' ref={register} placeholder='Enter Additional Notes(optional)...'/>
      </div>
    </div>
  )
}

export default JobNotes;
