import React from 'react';

const underlineLink = {
  textDecoration: 'underline'
}

const JobDefinitions = ({ showDefinitions }) => {
  const isHidden = showDefinitions ? '' : 'is-hidden';
  const showClass = 'box ' + isHidden;
  return (
    <div className={ showClass }>
      <h1 className='has-text-weight-bold' style={ underlineLink }>Job Descriptions</h1>
      <p>
        <span className='has-text-weight-bold'>W1</span> - One-sided job. Clean exterior of window or storm. No storm removal. Screen removal/cleaning is extra.
      </p>
      <p>
        <span className='has-text-weight-bold'>W2</span> - Two-sided job. Clean exterior/interior of window or storm. No storm removal. Screen removal/cleaning is extra.
      </p>
      <p>
        <span className='has-text-weight-bold'>W3</span> - Three-Sided Job. Remove storm, clean both sides. Clean exterior window. Brush screens to remove loose dirt. Replace storm.
      </p>
      <p>
        <span className='has-text-weight-bold'>W4</span> - Four-Sided Job. Remove storm, clean both sides. Clean both sides of window. Brush screens to remove loose dirt. Replace storm.
      </p>
      <p>
        <span className='has-text-weight-bold'>EH</span> - Clean interior of eaves and snake downspouts. Does not include repairs, cages or removal of screens.
      </p>
      <p>
        <span className='has-text-weight-bold'>EG</span> - Clean interior of garage eaves and snake downspouts. Does not include repairs, cages or removal of screens.
      </p><br/>

      <h1 className='has-text-weight-bold' style={ underlineLink }>Work Charged Hourly ($170/crew-hour based on a 2 man crew)</h1>
      <p>
        <span className='has-text-weight-bold'>W3/W4</span> - Storm removal/replacement.
      </p>
      <p>
        <span className='has-text-weight-bold'>Construction Clean</span> - Applies to windows that have been exposed to construction dust/debris, labels and seasonal decorations. Windows in this state may require several cleans and scrapping to come clean.
      </p>
      <p>
        <span className='has-text-weight-bold'>Power Washing</span>
      </p>
      <p>
        <span className='has-text-weight-bold'>Ivy Trimming</span>
      </p>


    </div>
  )
}

export default JobDefinitions;
