import React from "react";

const UnassignButton = ({ crewname, unassignClass, unAssJob }) => {
  return (
    <div className={unassignClass} onClick={unAssJob}>
      Unassign[{crewname}]
    </div>
  );
};

export default UnassignButton;
