import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ApiClient from "../../base/api/client";


const DisplayPanel = ({ id, name, address, perly, postcode, phone, priority, attempts, delivery_date, delivered_by, wineDelivered, wineUnDelivered, wineAttempt }) => {
  const { search } = useLocation();

  useEffect(() => {
    console.log('Search', search)
  }, [search])


  // const assJob = async () => {
  //   const response = await ApiClient.post("/api/jobs/ass_job", {
  //     jobid: job.jobid,
  //     crewname: targetCrewname,
  //     schdate: targetSchdate,
  //   });
  //   //setCrewname(targetCrewname);
  //   //setTargetCrewname(targetCrewname);
  // };

  const test = () => {
    console.log('test');
  }

  return (
    <div className="display-panel-container-wine">
      <div className="display-panel-container-wine__item-2">
        <a
          href={`https://www.google.ca/maps/place/${address} ${postcode}`}
          target="_blank"
        >{address}</a>
      </div>
      <div className="display-panel-container-wine__row">

      <div className="display-panel-container-wine__item-2">
        {perly}
      </div>
      <div className="display-panel-container-wine__item-2">
        {postcode}
      </div>
    </div>
      <div className="display-panel-container-wine__row">
        <span className="display-panel-container-wine__item-2">{priority}</span>
        <span className="display-panel-container-wine__item-2">{name}</span>
      </div>
      <div>
        { delivery_date ?

          <span className="display-panel-container-wine__item-1">
            Delivered on { delivery_date }[ { delivered_by } ]
            <button className="button-wine" onClick={()=>{wineUnDelivered(id)}}>UnDeliver</button>
          </span>

          :
      <div className="display-panel-container-wine__row">
        <span className="display-panel-container-wine__item-1">
          <button className="button-wine" onClick={()=>{wineDelivered(id)}}>Delivered</button>
        </span>
        <span className="display-panel-container-wine__item-1">
          <button className="button-wine" onClick={()=>{wineAttempt(id)}}>Attempt[{attempts}]</button>
        </span>
      </div>
        }
      </div>
    </div>
  );
};

export default DisplayPanel;
