import React, { useEffect, useState } from "react";
import SelectOptionsSmall from "./SelectOptionsSmall";
import SelectOptionsSmallTwo from "./SelectOptionsSmallTwo";
import SelectOptions from "./SelectOptions";
import { hours, mins, mgrs } from "../helpers/lists";
import dayjs from "dayjs";

const CrewPayForm = ({ onSubmit, member, form, payData, selectedMgr }) => {
  const { register, handleSubmit, getValues, setValue } = form;
  const {
    name,
    startHour,
    startMinute,
    finishHour,
    finishMinute,
    mgr
  } = getValues();

  let buttonLabel = "Save Pay";
  payData?.forEach((pay) => {
    if (name === pay.name) {
      buttonLabel = "Edit Pay";
    }
  });

  const [times, setTimes] = useState(false);
  let workHours;

  useEffect(() => {
    setTimes(false);
  }, [times]);

  const fillHoursAndPay = () => {
    const stime = dayjs().hour(Number(startHour)).minute(Number(startMinute));
    const ftime = dayjs().hour(Number(finishHour)).minute(Number(finishMinute));
    workHours = ftime.diff(stime, "hours", true).toFixed(2);
  };
  fillHoursAndPay();

  if (!member?.rate) return null;
  let rate = Number(member.rate.replace(/[^\d.]/g, ""));
  let pay = (workHours * rate).toFixed(2);

  const onChange = (e) => {
    setValue(e.target.name, e.target.value);
    setTimes(true);
  };

  return (
    <div className="crewprofile-pay-panel">
      <div className="crewprofile-pay-panel__title-group">
        <div className="crewprofile-pay-panel__title-item">{member.name}</div>
        <div className="crewprofile-pay-panel__title-item">
          Rate:{member.rate}
        </div>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="crewType" ref={register} />
        <input type="hidden" name="hrid" ref={register} />
        <input type="hidden" name="rate" ref={register} />
        <input type="hidden" name="name" ref={register} />
        <input type="hidden" name="test" ref={register} />



          <div className="form__row form__row--small">
            <span className="form__label--small">Mgr</span>
            <SelectOptionsSmallTwo
              name="mgr"
              options={mgrs}
              ref={register}
              onChange={onChange}
              selectedMgr={selectedMgr}
            />
          </div>



        <div className="form__row form__row--small">
          <span className="form__label--small">Hours</span>
          <input type="text" name="hours" value={workHours} readOnly />
        </div>
        <div className="form__row form__row--small">
          <span className="form__label--small">Pay</span>
          <input type="text" name="pay" value={pay} readOnly />
        </div>

        <div className="form__row form__row--small">
          <span className="form__label--small">Start</span>
          <SelectOptionsSmall
            name="startHour"
            options={hours}
            ref={register}
            onChange={onChange}
          />
          <span className="form__colon">:</span>
          <SelectOptionsSmall
            name="startMinute"
            options={mins}
            ref={register}
            onChange={onChange}
          />
        </div>

        <div className="form__row form__row--small">
          <span className="form__label--small">Finish</span>
          <SelectOptionsSmall
            name="finishHour"
            options={hours}
            ref={register}
            onChange={onChange}
          />
          <span className="form__colon">:</span>
          <SelectOptionsSmall
            name="finishMinute"
            options={mins}
            ref={register}
            onChange={onChange}
          />
        </div>
        <button className="form__input form__input__button" type="submit">
          {buttonLabel}
        </button>
      </form>
    </div>
  );
};

export default CrewPayForm;
