import React, { useState, useEffect } from 'react'

import ApiClient from "../../base/api/client";
import LoaderBox from "../forms/LoaderBox";
import { formatCurrency } from "../helpers/helper";





const JobDetails = ({ boxStyle, jobid, setShowFunction }) => {
  const [job, setJob] = useState(null);

  const fetchJob = async () => {
    const result = await ApiClient.request(`/api/clients/${jobid}/fetch_job`)
    setJob(result);
  }

  useEffect(() => {
    if(jobid){
      fetchJob()
    }
  }, [jobid])

  const changeMenu = (e) => {
    e.preventDefault();
    setShowFunction('completedJobs');
  }


  return (
    <div style={boxStyle} className='box has-background-light'>
      <h2 className='title is-4 has-text-centered'>Job Details</h2>

      { job === null ? <LoaderBox /> :
      <div>
      <nav className='breadcrumb'>
        <ol className='breadcrumb'>
          <li onClick={changeMenu}><a href="#">Completed Jobs</a></li>
          <li className='is-active' onClick={changeMenu}><a href="#">Jobs Details</a></li>
          <li onClick={changeMenu}><a href="#">Apply Payment</a></li>
        </ol>
      </nav>

      { job.address }
      </div> }
    </div>
  )
}

export default JobDetails



// <tfoot>
//   <tr>
//     <th><abbr title="Job ID">jobid</abbr></th>
//     <th><abbr title="Booked On">Bkd</abbr></th>
//     <th><abbr title="Job Type">Type</abbr></th>
//     <th><abbr title="Job Address">Loc</abbr></th>
//     <th><abbr title="Job Description">Desc</abbr></th>
//   </tr>
// </tfoot>
