import React from "react";

const CheckField = React.forwardRef(({ name, label }, ref) => {
  return (
    <div className="field margin-right">
      <div className="control">
        <label className="checkbox">
          <input name={name} type="checkbox" value={label} ref={ref} />
          {label}
        </label>
      </div>
    </div>
  );
});

export default CheckField;

{
  /* <div>
  <input type="checkbox" name="sendEmail" ref={register} />
  <label htmlFor="sendEmail">Send Email</label>
</div>; */
}
