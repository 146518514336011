import React from "react";

const TextArea = React.forwardRef(({ label, id, rows = 5, ...rest }, ref) => {
  return (
    <div className="field">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <textarea id={id} className="textarea" rows={rows} ref={ref} {...rest} />
    </div>
  );
});

export default TextArea;
