import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";

const PartnerDashboard = () => {
  const [{ user, mgr }] = useStateReducer();
  const [myMgr, setMyMgr] = useState(null)

  useEffect(() => {
      if(mgr){
        setMyMgr(mgr);
      }
    }, [mgr])

  return(
    <Layout>
      <div className='container'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{myMgr && myMgr.name}</h1>
        <div className='columns mt-3'>
          <div className='column'>
            <Link to="/partner_leads">Leads</Link>
          </div>
          <div className='column'>
            <Link to="/partner_tasks">Tasks</Link>
          </div>
          <div className='column'>
            <Link to="/partner_plan">Projection</Link>
          </div>
          <div className='column'>
            <Link to="/partner_training">Training</Link>
          </div>
          <div className='column'>
            <Link to="/partner_receivables">Receivables</Link>
          </div>
          <div className='column'>
            <Link to="/partner_financials">Financials</Link>
          </div>
          { user && (user.hrid === 'HR00000001' || user.hrid === 'HR00000429') ?
            <div className='column'>
              <Link to="/partner_admin">Admin</Link>
            </div>
          : null }
        </div>
        <hr/>
      </div>
  </Layout>
  )

}

export default PartnerDashboard;




// <div className='column'>
//   <Link to={{
//     pathname: "/partner_schedule",
//     state: {
//       me_name: 'Test'
//     }
//   }}>Schedule</Link>
// </div>
//
