import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import WineList from "../views/WineList";
import CreateWineList from "../views/CreateWineList"

export const history = createBrowserHistory({ basename: "crews/react_wine_delivery" });

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/wine_list" component={WineList} exact />
      <Route path="/create_wine_list" component={CreateWineList} exact />
    </Switch>
  </Router>
);

export default AppRouter;
