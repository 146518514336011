import React, { useState, useEffect } from 'react';
import Layout from "../../layout/Layout";
import { Link, Redirect } from "react-router-dom";


import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import { formatCurrencyZero } from "../../helpers/helper";

 const Tasks = () => {
  const [{ mgr, userLoading }] = useStateReducer();
  const [showLoader, setShowLoader] = useState(true);

  return(
    <Layout>
      <div className='container m-4'>
        <h1 className='mt-3 subtitle has-text-centered has-text-success'>{mgr && mgr.name}</h1>
        <nav className='breadcrumb mt-3'>
          <ul>
            <li><Link to="/partner_dashboard">Partner Dashboard</Link></li>
            <li className='is-active'><Link to="/partner_training">Tasks</Link></li>
          </ul>
        </nav>

        <hr/>
  
    </div>
    </Layout>
  )

}

export default Tasks;


//content as container. It can handle almost any HTML tag:

// <p> paragraphs
// <ul> <ol> <dl> lists
// <h1> to <h6> headings
// <blockquote> quotes
// <em> and <strong>
// <table> <tr> <th> <td> tables
