import React, { useReducer, useCallback, useEffect } from "react";

import ActionType from "../base/context/actionType";
import LoginAPI from "../base/api/login";
import AppRouter from "./routers/AppRouter";
import { stateReducer, initialState } from "../base/context/stateReducer";
import { AuthContext } from "../base/context/authContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { loadStripe } from '@stripe/stripe-js';

function stripeKey(env) {
  if(env === 'production' || env === 'staging') {
    return 'pk_live_FJz45jzZHmLzwmwj4yhlZnAu';
  } else {
    return 'pk_test_uPlFKifiDIrEfaCYOYfdigmd'
  }
}

function App({ client_encrypt, props_encrypt, dashboard_show_function, system_source, env }) {
  const stripePromise = loadStripe(stripeKey(env));
  const stateTuple = useReducer(stateReducer, initialState);
  const [{ user, userLoading }, dispatch] = stateTuple;

  useEffect(() => {
    dispatch({
      type: ActionType.SetEnv,
      payload: env,
    });
  },[dispatch])



  const fetchUserAndConnectStripe = useCallback(async () => {
    // check if signed in(run loader while waiting)
    // if yes, dispatch object that sets the user
    // check request finished/error(show message)
    //if (userLoading) return;

//crews/react_client_dashboard?key=nil&type=stripeJobPay&source=nil

    if(dashboard_show_function === 'internalUser'){
      const { user, email, type } = await LoginAPI.verifyDashSession(client_encrypt.cfid);
      dispatch({
        type: ActionType.SetClient,
        payload: client_encrypt,
      });

      dispatch({
        type: ActionType.SetProps,
        payload: props_encrypt,
      });

      dispatch({
        type: ActionType.SetEmail,
        payload: email,
      });

      dispatch({
        type: ActionType.SetDashboardShowFunction,
        payload: dashboard_show_function,
      });

      dispatch({
        type: ActionType.SetStripePromise,
        payload: stripePromise,
      });

      dispatch({
        type: ActionType.SetType,
        payload: type,
      });

      dispatch({
        type: ActionType.SetUser,
        payload: user,
      });

      dispatch({
        type: ActionType.SetSystemSource,
        payload: system_source,
      });
    } else if(dashboard_show_function === 'clientUser'){
      const { user, email, client, type, props } = await LoginAPI.logIn(client_encrypt.email, 'encryption');
        if (user === null ){
          return;
        }
        dispatch({
          type: ActionType.SetClient,
          payload: client,
        });
        dispatch({
          type: ActionType.SetEmail,
          payload: email,
        });
        dispatch({
          type: ActionType.SetProps,
          payload: props,
        });

        dispatch({
          type: ActionType.SetDashboardShowFunction,
          payload: dashboard_show_function,
        });

        dispatch({
          type: ActionType.SetStripePromise,
          payload: stripePromise,
        });

        dispatch({
          type: ActionType.SetType,
          payload: type,
        });

        dispatch({
          type: ActionType.SetUser,
          payload: user,
        });

    } else if(dashboard_show_function === 'resetPassword'){
        dispatch({
          type: ActionType.SetClient,
          payload: client_encrypt,
        });
        dispatch({
          type: ActionType.SetDashboardShowFunction,
          payload: dashboard_show_function,
        });

        dispatch({
          type: ActionType.SetStripePromise,
          payload: stripePromise,
        });

        dispatch({
          type: ActionType.SetType,
          payload: null,
        });

        dispatch({
          type: ActionType.SetUser,
          payload: null,
        });

    } else if(dashboard_show_function === 'stripeJobPay'){
      const { user, email, client, type, props } = await LoginAPI.logIn(client_encrypt.email, 'encryption');
      if (user === null ){
        return;
      }
      dispatch({
        type: ActionType.SetType,
        payload: type,
      });
      dispatch({
        type: ActionType.SetUser,
        payload: user,
      });
      dispatch({
        type: ActionType.SetClient,
        payload: client,
      });
      dispatch({
        type: ActionType.SetEmail,
        payload: email,
      });
      dispatch({
        type: ActionType.SetProps,
        payload: props,
      });
      dispatch({
        type: ActionType.SetDashboardShowFunction,
        payload: dashboard_show_function,
      });
    dispatch({
        type: ActionType.SetStripePromise,
        payload: stripePromise,
      });
    } else {  // check to see if user is logged in or go to Home page
      const { user, email, client, type, props } = await LoginAPI.verifyDashSession();
      dispatch({
        type: ActionType.SetType,
        payload: type,
      });
      dispatch({
        type: ActionType.SetUser,
        payload: user,
      });
      dispatch({
        type: ActionType.SetClient,
        payload: client,
      });
      dispatch({
        type: ActionType.SetEmail,
        payload: email,
      });
      dispatch({
        type: ActionType.SetProps,
        payload: props,
      });
      dispatch({
        type: ActionType.SetDashboardShowFunction,
        payload: null,
      });
      dispatch({
        type: ActionType.SetStripePromise,
        payload: stripePromise,
      });
    }
  }, [dispatch, userLoading]);


  useEffect(() => {
      fetchUserAndConnectStripe();
  }, [fetchUserAndConnectStripe]);


  return (
    <AuthContext.Provider value={stateTuple}>
      <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
