import React from 'react'



const MasterWineList = ({ priorityA, priorityB }) => {
  return (
    <div>
      <span className='title'>Master Wine List</span>
      <span className='title'>A List: { priorityA }</span>
      <span className='title'>B List: { priorityB }</span>
    </div>
  )
}

export default MasterWineList
