import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from "react-router";

import { useForm } from "react-hook-form";
import ApiClient from "../../base/api/client";


const HRMgt = ({handleDailyReport, user, checkReportIssued, fireDateChanged, currentDate}) => {
  const ccForm = useForm();
  const location = useLocation();

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>HR Mgt [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          name='hrid'
          ref={ register }
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          name='area'
          ref={ register }
          defaultValue = { 'HR Mgt' }
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              name='date'
              ref={ register }
              defaultValue = { currentDate }
              onChange = { () => fireDateChanged(getValues('date')) }
            />
          </div>
        </div><br/>



        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line1'
              ref={ register }
              defaultValue={ "[WAH Certificates]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line2'
              ref={ register }
              defaultValue={ "[WAH Training]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line3'
              ref={ register }
              defaultValue={ "[Bullying/Harrassment]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line4'
              ref={ register }
              defaultValue={ "[4 Steps Safety Video]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line5'
              ref={ register }
              defaultValue={ "[Maintain Safety Boards]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line6'
              ref={ register }
              defaultValue={ "[Shop Inspections/Fire Extinguishers]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line7'
              ref={ register }
              defaultValue={ "[Safety Minutes Meetings]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send HR Mgt Report</button>
        </div>
      </form>
    </div>

  )


}

export default HRMgt
