import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import InspectionForm from "../views/Partner/forms/InspectionForm";

import Home from "../layout/Home";
import Runsheet from "../views/Runsheet";
import Stats from "../views/Stats";
import CrewNeeds from "../views/CrewNeeds";
import PaintLeadAdmin from "../views/Partner/admin/PaintLeadAdmin";
import OddJobLeadAdmin from "../views/Partner/admin/OddJobLeadAdmin";
import WindowLeadAdmin from "../views/Partner/admin/WindowLeadAdmin";
import WindowLeadAdminCrew from "../views/Partner/admin/WindowLeadAdminCrew";
import PickUpAdmin from "../views/Partner/admin/PickUpAdmin";
import PickUpAdminCrew from "../views/Partner/admin/PickUpAdminCrew";
import PartnerDashboard from "../views/Partner/PartnerDashboard";
import Leads from "../views/Partner/Leads";
import Jobs from "../views/Partner/Jobs";
import Training from "../views/Partner/Training";
import EstimatesSigns from "../views/Partner/EstimatesSigns";
import Tasks from "../views/Partner/Tasks";
import Plan from "../views/Partner/Plan";
import Receivables from "../views/Partner/Receivables";
import Financials from "../views/Partner/Financials";
import Schedule from "../views/Partner/Schedule";
import Admin from "../views/Partner/Admin";
import ClientAdmin from "../views/Partner/components/ClientAdmin";
import OpenClose from "../views/OpenClose";
import SafetyTalk from "../views/SafetyTalk";
import CrewPayments from "../views/CrewPayments";
import Recruiting from "../views/Recruiting";
import CrewDetails from "../views/CrewDetails";
import JobDetails from "../views/JobDetails";
import DnfDetails from "../views/DnfDetails";
import Maps from "../views/Maps";
import LiveAction from "../views/LiveAction/LiveAction";
import EditLiveAction from "../views/LiveAction/EditLiveAction";
import CreateLiveAction from "../views/LiveAction/CreateLiveAction";

export const history = createBrowserHistory({ basename: "crews/react_crew_mgt" });

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/login" component={Home} exact />
      <Route path="/open-close" component={OpenClose} exact />
      <Route path="/safety-talk" component={SafetyTalk} exact />
      <Route path="/crew-payments" component={CrewPayments} exact />
      <Route path="/recruiting" component={Recruiting} exact />
      <Route path="/crew-estimates-signs" component={EstimatesSigns} exact />
      <Route path="/crew_needs" component={CrewNeeds} exact />
      <Route path="/crew-details/:id" component={CrewDetails} exact />
      <Route path="/partner_dashboard" component={PartnerDashboard} exact />
      <Route path="/partner_leads" component={Leads} exact />
      <Route path="/partner_leads/:list" component={Leads} exact />
      <Route path="/partner_jobs" component={Jobs} exact />
      <Route path="/partner_plan" component={Plan} exact />
      <Route path="/partner_schedule" component={Schedule} exact />
      <Route path="/partner_admin" component={Admin} exact />
      <Route path="/partner_receivables" component={Receivables} exact />
      <Route path="/partner_financials" component={Financials} exact />
      <Route path="/partner_training" component={Training} exact />
      <Route path="/partner_tasks" component={Tasks} exact />
      <Route path="/partner_inspection/:id" component={InspectionForm} exact />
      <Route path="/partner_client_admin/:cfid" component={ClientAdmin} exact />

      <Route path="/paint_lead_admin/:id" component={PaintLeadAdmin} exact />
      <Route path="/odd_job_lead_admin/:id" component={OddJobLeadAdmin} exact />
      <Route path="/window_lead_admin/:id" component={WindowLeadAdmin} exact />
      <Route path="/window_lead_admin_crew/:id" component={WindowLeadAdminCrew} exact />
      <Route path="/pick_up_admin/:id" component={PickUpAdmin} exact />
      <Route path="/pick_up_admin_crew/:id" component={PickUpAdminCrew} exact />
      <Route path="/runsheet" component={Runsheet} exact />
      <Route path="/runsheet/:success" component={Runsheet} exact />
      <Route path="/stats" component={Stats} exact />
      <Route path="/job-details/:id" component={JobDetails} exact />
      <Route path="/dnf-details/:id" component={DnfDetails} exact />
      <Route path="/maps" component={Maps} exact />
      <Route path="/live_action" component={LiveAction} exact />
      <Route path="/live_action/:msg" component={LiveAction} exact />
      <Route path="/create_live_action" component={CreateLiveAction} exact />
      <Route path="/edit_live_action/:id" component={EditLiveAction} exact />
    </Switch>
  </Router>
);

export default AppRouter;
