import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const Dnfs = ({ dnfs }) => {
  console.log('Dnfs', dnfs);
  return (
    <table className="crewpay-table">
      <thead>
        <tr>
          <th className="crewpay-table__header">Address</th>
          <th className="crewpay-table__header">DNF Desc</th>
          <th className="crewpay-table__header">Start Time</th>
          <th className="crewpay-table__header">Finish Time</th>
        </tr>
      </thead>
      <tbody>
        {dnfs.map((dnf) => {
          return (
            <tr key={dnf.dnfid}>
              <td className="crewpay-table__body">{dnf.address}</td>
              <td className="crewpay-table__body">{dnf.dnfdesc}</td>
              <td className="crewpay-table__body">{dnf.reportstime}</td>
              <td className="crewpay-table__body">{dnf.reportftime}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Dnfs;
