import React from "react";

const CrewNameOptions = React.forwardRef(
  ({ className, options, onChange }, ref) => {
    return (
      <select
        className={className}
        name="manager"
        ref={ref}
        onChange={onChange}
      >
        <option value="All Managers">All Managers</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
);
export default CrewNameOptions;
