import ActionType from "./actionType";

export const initialState = {
  user: null,
  userLoading: true,
  vehicleOptions: [],
};

export const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SetUser:
      return {
        ...state,
        user: action.payload,
        userLoading: false,
      };

    case ActionType.SetVehicleList:
      return {
        ...state,
        vehicleOptions: action.payload,
      };

    case ActionType.Reset:
      return initialState;

    default:
      return state;
  }
};

//export default stateReducer;
