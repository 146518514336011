import { useState, useEffect } from "react";
import ApiClient from "../api/client";

const useRequest = (url, body, condition = true) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const payload = body ? JSON.stringify(body) : undefined;

  const fetchData = async () => {
    setLoading(true);
    const response = await ApiClient.request(url, {
      method: body ? "POST" : "GET",
      body: payload,
    });
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    if (condition) {
      fetchData();
    }
  }, [url, payload, condition]);

  return {
    data,
    loading,
    refetch: fetchData,
  };
};

export default useRequest;
