
import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";
import { ErrorMsg } from "../components/ErrorMsg";

const SmartSearch = () => {
  const [{ props }, dispatch] = useStateReducer();
  const [search, setSearch] = useState('');
  const [matches, setMatches] = useState([]);
  const MagnifyingGlass = <FontAwesomeIcon icon={ faSearchengin } />;

  const fetchMatches = async (inputString) => {
    const result = await ApiClient.post(`/api/clients/search_clients`, { inputString });
    setMatches(result);
  }

  const trySearch = (e) => {
    const x = e.target.value
    if(x.length > 2){
      fetchMatches(e.target.value);
    } else {
      setMatches([]);
    }
  }

  const displayMatches = () => {
    const ans = matches.map( match => (
      <div key = { match.cfid }>
        <span className="mr-4">{match.cfid}</span>
        <span className="mr-4"> {match.lastname} </span>
        <span className="mr-4"> {match.address} </span>
        <span className="mr-4"> {match.phone} </span>
        <span className="mr-4"> {match.email} </span>

        <hr/>
      </div>
    ))

    return (
      <div className='columns mt-3'>
        <div className='column is-one-fifth'>
        </div>
        <div className='column is-three-fifths'>
          <div className='box'>
             <span className='has-text-weight-bold'>Matches { matches.length }</span><br/><br/>
            { ans }
          </div>
        </div>
        <div className='column is-one-fifth'>
        </div>
      </div>)
}

  const searchBar = () => (
    <div className='columns mt-3'>
      <div className='column is-one-quarter'>
      </div>
      <div className='column is-two-quarters'>
        <div className='field'>
          <div className='control has-icons-left'>
            <input type='text' className='input' placeholder="Pee Wee Smart Search" onChange={trySearch} />
            <span className='icon is-medium is-left mt-2 ml-1'>
              <i className='icon'>{ MagnifyingGlass }</i>
            </span>
          </div>
        </div>
      </div>
      <div className='column is-one-quarter'>
      </div>
    </div>)

  return (
    <div>
      { searchBar() }
      { matches.length>0 && displayMatches() }
    </div>

  )
}

export default SmartSearch;
