const ActionType = {
  SetUser: "SET_USER",
  Reset: "RESET",

  SetDate: "SET_DATE",
  SetMgr: "SET_MGR",
  SetVehicleList: "SET_VEHICLE_LIST",
};

export default ActionType;
