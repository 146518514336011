import React from "react";

const DisplayPanel = ({
  cfid,
  source,
  name,
  phone,
  address,
  postcode,
  email,
  notes,
}) => {
  // const [jobdescClass, setJobdescClass] = useState(
  //   "display-panel-container__item-2"
  // );
  // if (job.jobdesc.includes("[APPT")) {
  //   setJobdescClass(
  //     "display-panel-container__item-2 display-panel-container__item-2--blue"
  //   );
  // }
  // if (job.jobdesc.includes("EM]")) {
  //   setJobdescClass(
  //     "display-panel-container__item-2 display-panel-container__item-2--red"
  //   );
  // }

  const cfidLabel = (cfid) => {
    if (cfid === null) {
      return "Pending";
    } else if (cfid.localeCompare("CF00057764") === 1) {
      return "New Client";
    } else {
      return "Existing Client";
    }
  };

  return (
    <div className="display-panel-container">
      <div className="display-panel-container__row">
        <span className="display-panel-container__item-1">{source}</span>
        <span className="display-panel-container__item-1">{name}</span>
        <span className="display-panel-container__item-1">{address}</span>
        <span className="display-panel-container__item-1">{postcode}</span>
      </div>
      <div className="display-panel-container__row">
        <span className="display-panel-container__item-1">{phone}</span>
        <span className="display-panel-container__item-1">{email}</span>
      </div>
      <div className="display-panel-container__row">
        <span className="display-panel-container__item-1">{notes}</span>
        <span className="display-panel-container__item-1">
          cfid:{cfidLabel(cfid)}
        </span>
      </div>
    </div>
  );
};

export default DisplayPanel;
